export const tooltips = {
    who: `
        Select the pets for daycare.<br /><br />
        <strong>Guidelines:</strong><br />
        - Please ensure your pet is vaccinated.<br />
        - Collar with an ID tag is required.<br /><br />
        <strong>Notes:</strong><br />
        - Pets with special care requirements are welcome.<br />
        - Inform us of any behavioral issues.<br />
    `,
    times: `
        Specify drop-off and pick-up times.<br /><br />
        <strong>Schedule:</strong><br />
        - Drop-off time starts at 7 AM.<br />
        - Latest pick-up is at 7 PM.<br /><br />
        <strong>Notes:</strong><br />
        - Late pick-up may incur additional fees.<br />
        - Early drop-off available upon request.<br />
    `,
    notes: `
        Any special instructions for today?<br /><br />
        <strong>Examples:</strong><br />
        - Administer medication at 2 PM.<br />
        - No snacks before lunch.<br /><br />
        <strong>Notes:</strong><br />
        - List any allergies or dietary restrictions.<br />
        - Our staff will confirm all instructions with you.<br />
    `,
    activities: `
        Choose from our range of activities.<br /><br />
        <strong>Activities Include:</strong><br />
        - Play Sessions with agility equipment.<br />
        - Group Activities for socialization.<br />
        - Training Sessions by certified trainers.<br /><br />
        <strong>Notes:</strong><br />
        - Activities are supervised and safe.<br />
        - Please recommend any preferred activities for your pet.<br />
    `,
    services: `
        Select any additional services you'd like during your pet's stay.<br /><br />
        <strong>Additional Services:</strong><br />
        - Grooming: Expert grooming services for your pet's comfort and hygiene.<br />
        - Training: Professional training sessions for behavior and obedience.<br />
        - Shuttle: Convenient shuttle service to and from our facility.<br />
        - Adventure: Fun and engaging outdoor adventures for your pet.<br />
        - Walk: Daily leash walks for exercise and enjoyment.<br /><br />
        **Additional charges may be applied for the services.**<br /><br />
        <strong>Notes:</strong><br />
        - Additional services can be selected now or added during your pet's stay.<br />
        - Our staff will confirm all services and schedules upon arrival.<br />
    `
};
