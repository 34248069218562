import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../StateProvider';
import { getPaymentMethodById, addPaymentMethod, updatePaymentMethod, deletePaymentMethod } from '../../../../../Utilities/api';
import { BackButton } from '../../../../../Utilities/components/Buttons';
import './PaymentMethods.css';

import visaIcon from '../../../../../../assets/Dashboard/Profile/components/Wallet/visaIcon.png';
import mastercardIcon from '../../../../../../assets/Dashboard/Profile/components/Wallet/visaIcon.png';
import amexIcon from '../../../../../../assets/Dashboard/Profile/components/Wallet/visaIcon.png';
import discoverIcon from '../../../../../../assets/Dashboard/Profile/components/Wallet/visaIcon.png';
import defaultIcon from '../../../../../../assets/Dashboard/Profile/components/Wallet/defaultIcon.png';

const PaymentMethods = () => {
  const navigate = useNavigate();
  const { user, paymentMethods } = useContext(StateContext);

  const cardIcons = {
    Visa: visaIcon,
    MasterCard: mastercardIcon,
    'American Express': amexIcon,
    Discover: discoverIcon
  };

  const getCardIcon = (cardType) => cardIcons[cardType] || visaIcon;

  return (
    <div className="container">
      <BackButton />
      <div className="payment-methods-section">
        {paymentMethods.length > 0 ? (
          paymentMethods.map((method, index) => (
            <div key={index}>
              <div className="payment-method" onClick={() => navigate(`/profile/wallet/payment-methods/edit-payment/${method.id}`)}>
                <img 
                  className='payment-method-image' 
                  src={getCardIcon(method.cardType)} 
                  alt={`${method.cardType} Icon`} 
                />
                <div className="payment-method-details">
                  <div>
                    <div className='cardName-cardDefault'>
                      <h3 className='payment-method-name'>{method.cardType.charAt(0).toUpperCase() + method.cardType.slice(1)}</h3>
                      {String(method.id) === user.defaultPaymentMethodId && (
                        <img src={defaultIcon} alt="Default Badge" className="default-badge-image" />
                      )}                  
                    </div>
                    <p className='payment-method-type'>**** **** **** {method.cardNumber.slice(-4)}</p>
                  </div>
                  <div className="payment-method-badges-container">
                    <span className="payment-method-badge">Expires {method.expiryMonth}/{method.expiryYear}</span>
                  </div>
                </div>
              </div>
              <hr className='payment-method-breakline' />
            </div>
          ))
        ) : (
          <div className='payment-method-message'>Please add a payment method.</div>
        )}
        <div className='add-payment-method-button-container'>
          <button className="add-payment-method-button" onClick={() => navigate('/profile/wallet/payment-methods/add-payment')}>+ Add Payment</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
