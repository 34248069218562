import React, { useState, useEffect } from 'react';
import './Blog.css'; // Ensure you have a CSS file for styling
import Footer from '../../../components/Footer';

// Import a generic blog icon or image
import blogIcon from '../../../assets/Pages/Blog/blogIcon.png';
import LetsGetStarted from '../../Utilities/components/LetsGetStarted';

// Dummy blog posts data - replace this with real data fetched from an API or CMS
const dummyBlogPosts = [
  {
    id: 1,
    title: '5 Tips for Keeping Your Pet Happy and Healthy',
    excerpt: 'Discover how to ensure your furry friend stays happy and healthy with these essential tips...',
    date: 'April 5, 2024',
  },
  {
    id: 2,
    title: 'The Benefits of Regular Grooming for Your Pet',
    excerpt: 'Regular grooming is not just about keeping your pet looking good. Learn about the health benefits it offers...',
    date: 'March 22, 2024',
  },
  // Add more blog posts as needed
];

const Blog = () => {
  // State to hold blog posts
  const [blogPosts, setBlogPosts] = useState([]);

  // Simulate fetching blog posts from an API
  useEffect(() => {
    // In a real scenario, replace this with an API call
    setBlogPosts(dummyBlogPosts);
  }, []);

  return (
    <>
      <div className='blog-background-container'>
        <div className='container'>
          <div className="blog-container">
            <img className='blog-page-icon' src={blogIcon} alt="Blog" />
            <h2 className='blog-page-heading'>Pawsible Blog</h2>
            <p className='blog-page-paragraph'>
              Welcome to the Pawsible blog! Here you'll find insightful articles, tips, and stories
              to help you and your pet live your best lives together.
            </p>
          </div>
        </div>
      </div>

      <div className='blog-posts-container'>
        <div className='container'>
          {blogPosts.length > 0 ? (
            <ul className='blog-posts-list'>
              {blogPosts.map((post) => (
                <li key={post.id} className='blog-post-item'>
                  <h3>{post.title}</h3>
                  <p className='blog-post-excerpt'>{post.excerpt}</p>
                  <p className='blog-post-date'>{post.date}</p>
                  {/* Optionally, add a "Read More" button/link */}
                </li>
              ))}
            </ul>
          ) : (
            <p>No blog posts found.</p>
          )}
        </div>
      </div>

      <LetsGetStarted />

      <Footer />
    </>
  );
};

export default Blog;
