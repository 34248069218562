import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCustomers } from '../../../Utilities/api';
import { BackButton } from '../../../Utilities/components/Buttons';
import './CustomerManager.css';

function CustomerManager() {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function loadCustomers() {
        try {
            const customers = await fetchCustomers();
            setCustomers(customers.filter(user => user.role === 'customer'));
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    }
    loadCustomers();
}, []);

  const handleCustomerClick = (customerId) => {
    navigate(`/admin/customer-manager/${customerId}`);
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCustomers = customers.filter(customer =>
    customer.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='container'>
      <div className='console-customer-button'>
        <BackButton />
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="customer-search-input"
        />
      </div>
      <div className="customer-cards">
        {filteredCustomers.map((customer) => (
          <div key={customer.id} onClick={() => handleCustomerClick(customer.uid)} className='customer-card'>
            <img src={customer.image} alt={customer.username} />
            <div className='customer-info'>
              <p><strong>{customer.username}</strong></p>
              <div className="customer-email-phone">
                <p>Email: {customer.email}</p>
                <p>Phone: {customer.phone || 'Not provided'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomerManager;
