export const tooltips = {
    trainingType: `
      Choose the type of training you want for your pet.<br /><br />
      <strong>Training Types:</strong><br />
      - Puppy Training: Perfect for young pups to learn basic commands.<br />
      - Basic Obedience: Teach your dog essential commands and behaviors.<br />
      - Behavior Modification: Address and correct specific behavior issues.<br />
      - AKC Canine Good Citizen: Prepare for the AKC CGC certification.<br /><br />
      <strong>Note:</strong><br />
      - Consult our trainers for guidance on the best training type for your pet.<br />
    `,
    sessionType: `
      Select the type of training sessions you prefer.<br /><br />
      <strong>Session Types:</strong><br />
      - Group Sessions: Train your pet with others in a social setting.<br />
      - Private Sessions: One-on-one training for personalized attention.<br /><br />
      <strong>Differences:</strong><br />
      - Group sessions offer socialization opportunities.<br />
      - Private sessions focus on individual needs and challenges.<br /><br />
      <strong>Note:</strong><br />
      - The availability of group and private sessions may vary.<br />
    `,
    trainingDetails: `
      Explore the details of your selected training program.<br /><br />
      <strong>Program Information:</strong><br />
      - Duration: The number of weeks or sessions in the program.<br />
      - Curriculum: What your pet will learn and practice during training.<br />
      - Training Methods: The techniques and approaches used by our trainers.<br />
      - Eligibility: Any age or other requirements for joining the training.<br /><br />
      <strong>Benefits:</strong><br />
      - Explain the advantages of achieving AKC Canine Good Citizen certification.<br />
      - Highlight the positive outcomes of the chosen training.<br /><br />
      <strong>Pricing:</strong><br />
      - If pricing information is available, provide details here.<br />
    `,
    schedulingAvailability: `
      Check the availability of training sessions on our calendar.<br /><br />
      <strong>Availability:</strong><br />
      - Training sessions may be available on specific days or times.<br />
      - Choose a date and time that suits your schedule.<br /><br />
      <strong>Inquiry:</strong><br />
      - If you don't see a suitable slot, inquire about additional availability.<br />
      - Contact us for special scheduling requests or questions.<br />
    `,
  };
  