

export const TrainingBookingCalculator = (bookingData, serviceDetails) => {
  const trainingPrices = serviceDetails.training ? serviceDetails.training[0].additionalPrices : {};
  let originalTotal = 0; // Keep track of the price without any discounts
  let total = 0;

  // Check if training options are selected
  if (
    bookingData.trainingOptions &&
    bookingData.trainingOptions.selectedTrainingType &&
    bookingData.trainingOptions.selectedSessionType
  ) {
    const selectedTrainingType = bookingData.trainingOptions.selectedTrainingType;
    const selectedSessionType = bookingData.trainingOptions.selectedSessionType;

    // Calculate base price based on selected training type and session type
    const basePrice = trainingPrices[selectedTrainingType][selectedSessionType] || 0;
    total += basePrice;
    originalTotal += basePrice;

    // Add additional charges if applicable (e.g., advanced training, behavior modification)
    if (bookingData.trainingOptions.additionalCharges) {
      total += bookingData.trainingOptions.additionalCharges;
      originalTotal += bookingData.trainingOptions.additionalCharges;
    }
  }

  return { originalPrice: originalTotal, discountedPrice: total };
};

export default TrainingBookingCalculator;
