import React, { useContext, useState, useRef, useEffect } from 'react';
import './Login.css';
import { StateContext } from '../StateProvider';
import { Container, Form, Button, Modal, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LetsGetStarted from '../Utilities/components/LetsGetStarted';
import Footer from '../Footer';

import { signInWithGoogle, signInWithFacebook, signInWithEmailAndPass, signInWithSMS, verifyCode } from './Firebase';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';

import loginButtonGoogle from '../../assets/Login/loginButtonGoogle.png';
import loginButtonFacebook from '../../assets/Login/loginButtonFacebook.png';
import loginButtonPhone from '../../assets/Login/loginButtonPhone.png';

const auth = getAuth();

// Initialize RecaptchaVerifier function
export const initializeRecaptcha = (recaptchaContainer) => {
  // Ensure Firebase was initialized
  if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(recaptchaContainer, {
          'size': 'invisible', // or 'normal' or 'compact' depending on your needs
          'callback': (response) => {
              // reCAPTCHA solved - perhaps enable the verify button here
              console.log('Recaptcha verified', response);
          },
          'expired-callback': () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              console.error('Recaptcha expired');
          }
      }, auth); // `auth` should be your initialized Firebase Auth instance
      window.recaptchaVerifier.render().catch(error => {
          console.error('Recaptcha render error:', error);
      });
  }
  return window.recaptchaVerifier;
};

const Login = () => {
    const { setLoggedIn, setLoading } = useContext(StateContext);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [phoneNumber, setPhoneNumber] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [showSMSModal, setShowSMSModal] = useState(false);
    const [showCodeModal, setShowCodeModal] = useState(false);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [error, setError] = useState('');
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [showBrowserPrompt, setShowBrowserPrompt] = useState(false);
    const recaptchaContainer = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
      // Initialize recaptcha on first render
      if (!recaptchaVerifier && recaptchaContainer.current) {
          const verifier = initializeRecaptcha(recaptchaContainer.current);
          setRecaptchaVerifier(verifier);
      }

      return () => {
          if (recaptchaVerifier) {
              recaptchaVerifier.clear(); // Clear the recaptcha instance
          }
      };
  }, [recaptchaVerifier]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError('');
    };

    const handleLogin = async (loginMethod) => {
        setLoading(true);
        const response = await loginMethod();
        if (response.success) {
            // SMS recaptcha
            if (response.confirmationResult) {
                setConfirmationResult(response.confirmationResult);
                setError('');
                setShowSMSModal(false); // Close the phone number input modal
                setShowCodeModal(true); // Open the code verification modal
            } else {
              // Any General Login
                // console.log("User successfully logged in!", response.user);
                setLoggedIn(true);
                navigate('/');
                window.scrollTo(0, 0);
                setError('');
            }
        } else {
            console.error("Login failed:", response.error);
            setError(response.error);
            setLoggedIn(false);
        }
        setLoading(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin(() => signInWithEmailAndPass(formData.email, formData.password));
    };

    const handleVerifySMSCode = async () => {
        if (!confirmationResult) {
            console.error("No confirmation result available to verify code.");
            setError("No confirmation available. Please request code again.");
            return;
        }

        setLoading(true);
        const response = await verifyCode(smsCode, confirmationResult);
        if (response.success) {
            console.log("User successfully verified SMS and logged in!");
            setLoggedIn(true);
            navigate('/');
            window.scrollTo(0, 0);
            setError('');
            setShowCodeModal(false); // Close the code verification modal
        } else {
            console.error("Failed to verify SMS code:", response.error);
            setError(response.error);
        }
        setLoading(false);
    };

    return (
        <>
            <Container className="login-container">
                <h5 className="login-header">Welcome, Paw Lovers!</h5>
                {error && <Alert variant="danger">{error}</Alert>}
                <div className='firebase-container'>
                    <Button onClick={() => handleLogin(signInWithGoogle)} className="login-button-google">
                        <img src={loginButtonGoogle} alt="Sign In with Google" className='login-button-image'/>
                        <p className='login-button-text'>Google</p>
                    </Button>
                    <Button onClick={() => handleLogin(signInWithFacebook)} className="login-button-facebook">
                        <img src={loginButtonFacebook} alt="Sign In with Facebook" className='login-button-image'/>
                        <p className='login-button-text'>Facebook</p>
                    </Button>
                    <Button onClick={() => setShowSMSModal(true)} className="login-button-mobile">
                        <img src={loginButtonPhone} alt="Sign In with Mobile" className='login-button-image'/>
                        <p className='login-button-text'>Mobile</p>
                    </Button>
                </div>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="login-email" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" value={formData.email} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group className="login-password" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" value={formData.password} onChange={handleChange}/>
                    </Form.Group>
                    <Button type="submit" className="login-button">LOGIN</Button>
                </Form>
                <div className='login-message-container'>
                    <p>Do you need to create an account? <a href='/register'>Register</a></p>
                    <p>Forgot your password? <a href='/resetPassword'>Reset Password</a></p>
                </div>
            </Container>
            <Modal show={showSMSModal} onHide={() => setShowSMSModal(false)} className='sms-sign-in-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Your Phone Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                        </Form.Group>
                        {confirmationResult && (
                            <Form.Group>
                                <Form.Label>Enter Verification Code</Form.Label>
                                <Form.Control type="text" placeholder="Verification code" value={smsCode} onChange={(e) => setSmsCode(e.target.value)} />
                                <Button onClick={handleVerifySMSCode}>Verify Code</Button>
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='sms-sign-in-modal-close-button' onClick={() => setShowSMSModal(false)}>Close</Button>
                    {!confirmationResult && <Button variant="primary" className='sms-sign-in-modal-send-button' onClick={() => handleLogin(() => signInWithSMS(phoneNumber, 'recaptcha-container'))}>Send Verification Code</Button>}
                </Modal.Footer>
            </Modal>
            <Modal show={showCodeModal} onHide={() => setShowCodeModal(false)} className='sms-code-verification-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Verify Your Phone Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control type="text" placeholder="Enter verification code" value={smsCode} onChange={(e) => setSmsCode(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='sms-code-verification-modal-close-button' onClick={() => setShowCodeModal(false)}>Close</Button>
                    <Button variant="primary" className='sms-code-verification-modal-verify-button' onClick={handleVerifySMSCode}>Verify Code</Button>
                </Modal.Footer>
            </Modal>
            <section className='sign-up-section'>
                <LetsGetStarted />
            </section>
            <Footer />
        </>
    );
};

export default Login;
