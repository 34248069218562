import React from 'react';
import './Franchise.css'; // Make sure to create a corresponding CSS file for styling
import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/Footer';

// Importing assets
import pageMainPhoto from '../../../assets/Store/Services/pageMainPhoto.png';
import franchiseIcon from '../../../assets/Pages/Franchise/franchiseIcon.png';

const Franchise = () => {
  const navigate = useNavigate();

  // Function to handle navigation, for example, to a contact form or detailed inquiry page
  const handleLearnMoreClick = () => {
    // navigate to the desired path
    navigate('/franchise/inquiry');
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className='franchise-background-one-container'>
        <div className='container'>
          <div className="franchise-container">
            <img className='franchise-page-icon' src={franchiseIcon} alt="Franchise Opportunities" />
            <h2 className='franchise-page-heading'>Pawsible: Partner in Pet Care Excellence</h2>
            <p className='franchise-page-paragraph'>
              With Pawsible, you can build a rewarding business while making a positive impact in the lives of pets and their families. 
            </p>
            <div className="centered-container">
              <button className="franchise-page-button" onClick={handleLearnMoreClick}>Learn More</button>
            </div>
            <img className='about-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>

      <div className='franchise-background-two-container'>
        <div className='container'>
          <h2 className='opportunity-heading'>Why Franchise with Pawsible?</h2>
          <p className='opportunity-paragraph'>
            As a Pawsible franchise partner, you’ll gain access to our <span className='bold-text'>proven business model</span>,
            comprehensive support system, and a passionate community of pet lovers. It’s more than
            a business opportunity; it’s a chance to make <span className='bold-text'>a meaningful impact in the lives</span> of pets
            and their owners.
          </p>
          {/* Consider adding more detailed information or sections here */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Franchise;
