import React, { useState } from 'react';
import NailPreferences from './NailPreferences';
import BathPreferences from './BathPreferences';
import GroomingPreferences from './GroomingPreferences';
import TeethPreferences from './TeethPreferences';

const GroomingPreference = ({ groomingBookingData, setGroomingBookingData }) => {
  switch (groomingBookingData.groomingOptions.selectedPackage) {
    case 'nail':
      return <NailPreferences 
          groomingBookingData={groomingBookingData} 
          setGroomingBookingData={setGroomingBookingData}
      />;
    case 'bath':
      return <BathPreferences 
                groomingBookingData={groomingBookingData} 
                setGroomingBookingData={setGroomingBookingData}
            />;
    case 'grooming':
      return <GroomingPreferences 
                groomingBookingData={groomingBookingData} 
                setGroomingBookingData={setGroomingBookingData}
            />;
    case 'teeth':
      return <TeethPreferences 
                groomingBookingData={groomingBookingData} 
                setGroomingBookingData={setGroomingBookingData}
            />;
    default:
      return null;
  }
};

export default GroomingPreference;
