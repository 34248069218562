import React, { useContext } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { BackButton } from '../../../Utilities/components/Buttons';

function ServiceConfigurations() {
    const navigate = useNavigate();

    return (
        <div className='container'>
            <BackButton />
            <div className="service-configurations">
                <h1 className='console-header'>Service Configurations</h1>
                <div className="console-sections">
                    <button className="console-section" onClick={() => navigate('/admin/service-config/daycare')}>Daycare</button>
                    <button className="console-section" onClick={() => navigate('/admin/service-config/hotel')}>Hotel</button>
                    <button className="console-section" onClick={() => navigate('/admin/service-config/grooming')}>Grooming</button>
                    <button className="console-section" onClick={() => navigate('/admin/service-config/training')}>Training</button>
                    <button className="console-section" onClick={() => navigate('/admin/service-config/adventure')}>Adventure</button>
                    <button className="console-section" onClick={() => navigate('/admin/service-config/shuttle')}>Shuttle</button>
                </div>
            </div>
        </div>
    );
}

export default ServiceConfigurations;
