import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav } from 'react-bootstrap';
import './AdminDashboard.css';
// import { StateContext } from '../../../StateProvider';

import profileIcon from '../../../../assets/Dashboard/profileIcon.png';
import guideIcon from '../../../../assets/Dashboard/guideIcon.png';
import eventIcon from '../../../../assets/Dashboard/eventIcon.png';
import supportIcon from '../../../../assets/Dashboard/supportIcon.png';

const AdminDashboard = () => {
  // useEffect(() => {
  //   document.body.style.marginBottom = '130px';
  //   return () => {
  //     document.body.style.marginBottom = '0';
  //   };
  // }, []);

  // const {  } = useContext(StateContext);

  return (
    <footer className="dashboard">
      <Container className='dashboard-container'>
        <Nav className="dashboard-menu">
          <Nav.Link as={Link} to="/profile">
            <img className="dashboard-icon" src={profileIcon} alt="Profile" />
            <p className='dashboard-title'>Profile</p>
          </Nav.Link>
          <Nav.Link as={Link} to="/profile/cart" className="cart-icon-link">
            <div className="cart-icon-container">
              <img className="dashboard-icon" src={guideIcon} alt="Guides" />
            </div>
            <p className='dashboard-title'>Guides</p>
          </Nav.Link>
          <Nav.Link as={Link} to="/events">
            <img className="dashboard-icon" src={eventIcon} alt="Tasks" />
            <p className='dashboard-title'>Tasks</p>
          </Nav.Link>
          <Nav.Link as={Link} to="/support">
            <img className="dashboard-icon" src={supportIcon} alt="Support" />
            <p className='dashboard-title'>Chat</p>
          </Nav.Link>
        </Nav>
      </Container>
    </footer>
  );
};

export default AdminDashboard;
