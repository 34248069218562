import React, { useState, useEffect } from 'react';
import './ServiceConfig.css';
import { fetchConfig, updateConfig } from '../../../../Utilities/api';
import { BackButton } from '../../../../Utilities/components/Buttons';

function HotelConfig() {
    const [config, setConfig] = useState({
        numberOfRooms: '',
        pricePerNight: '',
        petSizeLimitations: '',
        cleaningServiceInterval: ''
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await fetchConfig('Hotel');
                setConfig(result.config);
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };
        loadData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfig(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updateData = async () => {
            try {
                const result = await updateConfig('Hotel', config);
                alert('Configuration saved successfully!', result);
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };
        updateData();
    };

    return (
        <div className="service-config-container">
            <BackButton />
            <h2>Hotel Configuration</h2>
            <form onSubmit={handleSubmit}>
                <label>Number of Rooms:
                    <input type="number" name="numberOfRooms" placeholder="Enter number of rooms" value={config.numberOfRooms} onChange={handleChange} />
                </label>
                <label>Room Price Per Night:
                    <input type="number" name="pricePerNight" placeholder="Enter price per night" value={config.pricePerNight} onChange={handleChange} />
                </label>
                <label>Pet Size Limitations:
                    <select name="petSizeLimitations" value={config.petSizeLimitations} onChange={handleChange}>
                        <option value="">Select Size</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                    </select>
                </label>
                <label>Room Cleaning Service Interval:
                    <input type="number" name="cleaningServiceInterval" placeholder="Interval in days" value={config.cleaningServiceInterval} onChange={handleChange} />
                </label>
                <button className='service-config-button' type="submit">Save Changes</button>
            </form>
        </div>
    );
}

export default HotelConfig;
