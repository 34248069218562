import React, { useState, useEffect, useContext } from 'react';
import './ServiceConfig.css';
import { fetchConfig, updateConfig } from '../../../../Utilities/api';
import { BackButton } from '../../../../Utilities/components/Buttons';

function DaycareConfig() {
    const [config, setConfig] = useState({
        maxCapacity: '',
        startTime: '',
        endTime: '',
        pricePerDay: '',
        mealService: false
    });

    useEffect(() => {
        // Fetch the configuration data and set it to the state
        const loadData = async () => {
            try {
                const result = await fetchConfig('Daycare');
                setConfig(result.config);
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };

        loadData();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updateData = async () => {
            try {
                const result = await updateConfig('Daycare', config);
                alert('Configuration saved successfully!', result);
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };

        updateData();
    };

    return (
        <div className="service-config-container">
            <BackButton />
            <h2>Daycare Configuration</h2>
            <form onSubmit={handleSubmit}>
                <label>Max Capacity Per Day:
                    <input type="number" name="maxCapacity" placeholder="Enter max capacity" value={config.maxCapacity} onChange={handleChange} />
                </label>
                <label>Operating Hours:
                    <input type="time" name="startTime" value={config.startTime} onChange={handleChange} /> to
                    <input type="time" name="endTime" value={config.endTime} onChange={handleChange} />
                </label>
                <label>Price Per Day:
                    <input type="number" name="pricePerDay" placeholder="Enter price per day" value={config.pricePerDay} onChange={handleChange} />
                </label>
                <label>Meal Service Available:
                    <input type="checkbox" name="mealService" checked={config.mealService} onChange={handleChange} />
                </label>
                <button className='service-config-button' type="submit">Save Changes</button>
            </form>
        </div>
    );
}

export default DaycareConfig;