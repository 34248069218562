import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../StateProvider';
import { fetchPaymentHistoryByUserId } from '../../../../../Utilities/api';
import { BackButton } from '../../../../../Utilities/components/Buttons';
import './PaymentHistory.css';

const PaymentHistory = () => {
  const navigate = useNavigate();
  const { user } = useContext(StateContext);
  const [transactions, setTransactions] = useState([]);
  const [search, setSearch] = useState('');
  const [view, setView] = useState('past'); // New state for toggling views

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      if (user && user.id) {
        try {
          const response = await fetchPaymentHistoryByUserId(user.id);
          setTransactions(response);
        } catch (error) {
          console.error('Error fetching payment history:', error);
        }
      }
    };
    fetchPaymentHistory();
  }, [user]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleToggleView = (newView) => {
    setView(newView);
  };

  const filteredTransactions = (transactions || []).filter(transaction => 
    (transaction.id && transaction.id.toString().includes(search)) || 
    (transaction.startTime && transaction.startTime.toString().includes(search)) || 
    (transaction.transactionStatus.toLowerCase() && transaction.transactionStatus.toLowerCase().toString().includes(search)) || 
    (transaction.selectedPets && transaction.selectedPets.join(', ').toString().includes(search)) || 
    (transaction.originalPrice && transaction.originalPrice.toString().includes(search)) ||
    (transaction.bookingType && transaction.bookingType.toLowerCase().includes(search.toLowerCase())) ||
    (transaction.status && transaction.status.toLowerCase().includes(search.toLowerCase()))
  );

  // Separate transactions into past and upcoming
  const pastTransactions = filteredTransactions.filter(transaction => new Date(transaction.startTime) < new Date());
  const upcomingTransactions = filteredTransactions.filter(transaction => new Date(transaction.startTime) >= new Date());

  const displayedTransactions = view === 'past' ? pastTransactions : upcomingTransactions;

  return (
    <div className="container">
      <div className="payment-history-section">
        <div className="filter-container">
          <BackButton />
          <input 
            type="text" 
            placeholder="Search" 
            value={search} 
            onChange={handleSearchChange} 
            className="payment-history-search-input"
          />
          <div className="payment-history-toggle-buttons">
            <button 
              className={`payment-history-toggle-button ${view === 'past' ? 'active' : ''}`}
              onClick={() => handleToggleView('past')}
            >
              Past
            </button>
            <button 
              className={`payment-history-toggle-button ${view === 'upcoming' ? 'active' : ''}`}
              onClick={() => handleToggleView('upcoming')}
            >
              Upcoming
            </button>
          </div>
        </div>
        {displayedTransactions.length > 0 ? (
          displayedTransactions.map((transaction, index) => (
            <div key={index}>
              <div className="payment-history" onClick={() => navigate(`/profile/wallet/payment-history/${transaction.id}`, { state: { transaction } })}>
                <div className="payment-history-details">
                  <div>
                    <p className='payment-history-type'><strong>{transaction.bookingType}</strong> | {transaction.selectedPets ? transaction.selectedPets.join(', ') : 'N/A'}</p>
                    <p className='payment-history-date'>{transaction.startTime ? new Date(transaction.startTime).toLocaleDateString() : 'N/A'}</p>
                  </div>
                  <div className="payment-history-status">
                    <p className={`status-badge ${(transaction.status || 'unknown').toLowerCase()}`}>{(transaction.status || 'Unknown').toLowerCase()}</p>
                    <p className='payment-history-originalPrice'>${parseFloat(transaction.originalPrice || 0).toFixed(2)}</p>
                  </div>
                </div>
              </div>
              <hr className='payment-history-breakline' />
            </div>
          ))
        ) : (
          <div className='payment-history-message'>No {view === 'past' ? 'past' : 'upcoming'} transactions available.</div>
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
