import React, { useState, useContext, useEffect } from 'react';
import '../../Booking.css';
import { StateContext } from '../../../../StateProvider';
import { FaInfoCircle } from 'react-icons/fa';
import { tooltips } from './shuttleTooltips';
import Calendar from 'react-calendar';
import ShuttleOptions from './ShuttleOptions';
import { fetchService, getShuttleAvailabilityByMonth } from '../../../../Utilities/api';

import hotelIcon from './../../../../../assets/Landing/hotelIcon.png';
import airportIcon from './../../../../../assets/Landing/shuttleIcon.png';
import daycareIcon from './../../../../../assets/Landing/daycareIcon.png';
import trainingIcon from './../../../../../assets/Landing/trainingIcon.png';
import groomingIcon from './../../../../../assets/Landing/groomingIcon.png';
import adventureIcon from './../../../../../assets/Landing/adventureIcon.png';

function ShuttleBooking() {
    const { user, setBookingData } = useContext(StateContext);
    const [selectedPetForShuttle, setSelectedPetForShuttle] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [isShuttleOptionsOpen, setIsShuttleOptionsOpen] = useState(false);
    const [specialRequests, setSpecialRequests] = useState('');
    const [tooltipVisibility, setTooltipVisibility] = useState({
        ride: false,
        petSelection: false,
        appointmentDate: false,
        groomingPreferences: false,
    });    
    const [shuttleBookingData, setShuttleBookingData] = useState({
        selectedPets: [],
        dateRange: null,
        showCalendar: false,
        foodPreference: '',
        specialNotes: '',
        pickupTime: '',
        dropoffTime: '',
        groomingOptions: [],
        trainingOptions: [],
        selectedPackage: '',
        additionalServices: {
            hotel: false,
            daycare: false,
            grooming: false,
            training: false,
            shuttle: false,
            adventure: false,
            walk: false
        }
    });

    const serviceIcons = {
        Airport: airportIcon,
        Daycare: daycareIcon,
        Hotel: hotelIcon,
        Grooming: groomingIcon,
        Training: trainingIcon,
        Adventure: adventureIcon,
    };

    useEffect(() => {
        if (selectedService) {
            fetchService(`${selectedService} Shuttle`).then(data => {
                setBookingData(prevData => ({
                    ...prevData,
                    serviceDetails: data[0]
                }));
            }).catch(error => {
                console.error(`Failed to fetch service data:`, error);
            });
        }
    }, [selectedService, shuttleBookingData.dateRange]);

    const showTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: true });
    };
    
    const hideTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: false });
    };

    const handleServiceSelection = (service) => {
        setShuttleBookingData({
            selectedPets: [],
            dateRange: null,
            showCalendar: false,
            foodPreference: '',
            specialNotes: '',
            pickupTime: '',
            dropoffTime: '',
            groomingOptions: [],
            trainingOptions: [],
            selectedPackage: '',
            additionalServices: {
                hotel: false,
                daycare: false,
                grooming: false,
                training: false,
                shuttle: false,
                adventure: false,
                walk: false
            }
        });
        setSelectedService(service);
        setShuttleBookingData(prevData => ({
            ...prevData,
            selectedService: service
        }));
        // Optionally reset selected pet when service changes
        setSelectedPetForShuttle(null);
    };

    const handleOptionSelect = (optionCategory, option) => {
        setShuttleBookingData((prevData) => ({
            ...prevData,
            shuttleOptions: {
                ...prevData.shuttleOptions,
                [optionCategory]: option,
            },
        }));
    };

    return (
        <div className='booking-backdrop'>
            <div className="booking-container">
                <div className="booking-header">
                    <h3>Pawsible Shuttle</h3>
                </div>

                <div className="booking-content">
                    <div className="booking-section">
                        <h2>Choose Service <FaInfoCircle onMouseOver={() => showTooltip('ride')} onMouseOut={() => hideTooltip('ride')} /></h2>
                        {tooltipVisibility.ride && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.ride }}></div>}
                        <div className="service-selection">
                            {['Airport', 'Daycare', 'Hotel', 'Training', 'Grooming', 'Adventure'].map(service => (
                                <button 
                                    key={service}
                                    className={`shuttle-service-button-${selectedService === service ? 'selected' : ''}`} 
                                    onClick={() => handleServiceSelection(service)}
                                >
                                    <img src={serviceIcons[service]} alt={`${service} Icon`} className="service-icon" />
                                    {service}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className={`booking-section-selectedPetForShuttle ${isShuttleOptionsOpen ? 'open' : 'close'}`}>
                        {selectedService && (
                            <div>
                                <ShuttleOptions 
                                    user={user}
                                    selectedService={selectedService}
                                    shuttleBookingData={shuttleBookingData} 
                                    setShuttleBookingData={setShuttleBookingData}
                                />
                            </div>
                        )}
                    </div>

                    {/* Emergency Contact */}
                    <div className="booking-section">
                        <h2>Emergency Contact <FaInfoCircle onMouseOver={() => showTooltip('emergencyContact')} onMouseOut={() => hideTooltip('emergencyContact')} /></h2>
                        {tooltipVisibility.emergencyContact && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.emergencyContact }}></div>}
                        <div className="input-field">
                            <input 
                                type="name" 
                                id="emergencyContactName" 
                                placeholder="Name" 
                                onChange={(e) => handleOptionSelect('emergencyContactName', e.target.value)}
                            />
                            <input 
                                type="tel" 
                                id="emergencyContactNumber" 
                                placeholder="Phone" 
                                onChange={(e) => handleOptionSelect('emergencyContactNumber', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="booking-section">
                        <h2>Special Requests <FaInfoCircle onMouseOver={() => showTooltip('specialRequests')} onMouseOut={() => hideTooltip('specialRequests')} /></h2>
                        {tooltipVisibility.specialRequests && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.specialRequests }}></div>}
                        <textarea 
                            placeholder="" 
                            value={specialRequests} 
                            onChange={(e) => setSpecialRequests(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShuttleBooking;
