import React, { useContext } from 'react';
import './Daycare.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../StateProvider';

import daycarePageIcon from '../../../../assets/Store/Services/Daycare/daycarePageIcon.png';
import pageMainPhoto from '../../../../assets/Store/Services/pageMainPhoto.png';

import playPalIcon from '../../../../assets/Store/Services/Daycare/daycarePlayPalIcon.png';
import brainToyIcon from '../../../../assets/Store/Services/Daycare/daycareBrainToysIcon.png';
import playAreaIcon from '../../../../assets/Store/Services/Daycare/daycarePlayAreaIcon.png';
import cozyAreaIcon from '../../../../assets/Store/Services/Daycare/daycareCozyAreaIcon.png';
import supervisionIcon from '../../../../assets/Store/Services/Daycare/daycareSupervisionIcon.png';

import staffIcon from '../../../../assets/Store/Services/Hotel/hotelPageStaffIcon.png';
import monitoringIcon from '../../../../assets/Store/Services/Hotel/hotelPageMonitoringIcon.png';
import sanitizationIcon from '../../../../assets/Store/Services/Hotel/hotelPageSanitizationIcon.png';
import separationIcon from '../../../../assets/Store/Services/Hotel/hotelPageSeparationIcon.png';

import Footer from '../../../Footer';
import LetsGetStarted from '../../../Utilities/components/LetsGetStarted';

const Daycare = () => {
  const navigate = useNavigate();
  const { 
    loggedIn, user, setShowAddPetsModal
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    if (loggedIn) {
      const hasPets = user.familyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
      if (hasPets) {
        navigate(`/store/services/daycare/booking`);
      } else {
        setShowAddPetsModal(true);
      }
    } else {
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className='daycare-background-one-container'>
        <div className='container'>
          <div className="daycare-container">
            <img className='daycare-page-icon' src={daycarePageIcon} />
            <h2 className='daycare-page-heading'>Enjoy & Socialize: Fun-filled Playground</h2>
            <p className='daycare-page-paragraph'>Let your pets socialize, play, and leave the stress behind in our safe and welcoming environment.</p>
            <div className="centered-container">
              <button className="daycare-page-button" onClick={handleBookingButtonClick}>BOOK A DAYCARE</button>
            </div>
            <img className='daycare-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>  

      <div className='daycare-background-two-container'>
        <div className='container'>
          <h2 className='daycare-subpage-heading'>WHY CHOOSE PAWSIBLE</h2>
          <h3 className='daycare-subpage-heading-3'>Daycare Activities</h3>
          <ul>
            <li>
              <div className="daycare-services-item">
                <img className="daycare-services-item-icon" src={playPalIcon}/>
                <div className="daycare-services-item-content">
                  <h4 className="daycare-services-item-heading">Tailored Play Pals</h4>
                  <p className="daycare-services-item-paragraph">
                    Supervised group play sessions where dogs can socialize and make new friends during supervised iteraction times.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="daycare-services-item">
                <img className="daycare-services-item-icon" src={brainToyIcon}/>
                <div className="daycare-services-item-content">
                  <h4 className="daycare-services-item-heading">Brain-Boosting Toys</h4>
                  <p className="daycare-services-item-paragraph">
                    A variety of interactive toys to keep your pets entertained and mentally engaged throughout the day.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="daycare-services-item">
                <img className="daycare-services-item-icon" src={playAreaIcon}/>
                <div className="daycare-services-item-content">
                  <h4 className="daycare-services-item-heading">Play Areas</h4>
                  <p className="daycare-services-item-paragraph">
                    Our secure and spacious outdoor play area allows dogs to run, play fetch, and enjoy the fresh environment.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="daycare-services-item">
                <img className="daycare-services-item-icon" src={cozyAreaIcon}/>
                <div className="daycare-services-item-content">
                  <h4 className="daycare-services-item-heading">Cozy Retreats</h4>
                  <p className="daycare-services-item-paragraph">
                    Designated resting areas for pets to relax and take a break during their daycare session.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="daycare-services-item">
                <img className="daycare-services-item-icon" src={supervisionIcon}/>
                <div className="daycare-services-item-content">
                  <h4 className="daycare-services-item-heading">Expert Supervision</h4>
                  <p className="daycare-services-item-paragraph">
                    Our trained staff keeps a watchful eye on every furry guest. Rest easy knowing your pet is in a protected, controlled environment.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='daycare-background-three-container'>
          <div className='container'>
            <h2 className='daycare-safety-heading'>WHY CHOOSE PAWSIBLE</h2>
            <h3 className='daycare-safety-heading-3'>Safety and Security Measures</h3>
              <ul>
                <li>
                  <div className="daycare-services-item">
                    <img className="daycare-services-item-icon" src={staffIcon}/>
                    <div className="daycare-services-item-content">
                      <h4 className="daycare-services-item-heading">Trained Staff</h4>
                      <p className="daycare-services-item-paragraph">
                        Our caring and trained staff are equipped to handle any situation and provide personalized attention to each pet.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="daycare-services-item">
                    <img className="daycare-services-item-icon" src={monitoringIcon}/>
                    <div className="daycare-services-item-content">
                      <h4 className="daycare-services-item-heading">24/7 Monitoring</h4>
                      <p className="daycare-services-item-paragraph">
                        Our facility is equipped with surveillance cameras and round-the-clock monitoring to ensure a safe and secure environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="daycare-services-item">
                    <img className="daycare-services-item-icon" src={sanitizationIcon}/>
                    <div className="daycare-services-item-content">
                      <h4 className="daycare-services-item-heading">Sanitization</h4>
                      <p className="daycare-services-item-paragraph">
                        We maintain strict cleanliness and hygiene protocols to prevent the spread of germs and maintain a healthy environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="daycare-services-item">
                    <img className="daycare-services-item-icon" src={separationIcon}/>
                    <div className="daycare-services-item-content">
                      <h4 className="daycare-services-item-heading">Tailored Grouping</h4>
                      <p className="daycare-services-item-paragraph">
                        To maintain a safe and enjoyable environment, we organize playgroups based on size and temperament.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='daycare-background-four-container'>
            <LetsGetStarted />
          </div>

          <Footer />

      {/* 
      <div className="daycare-container">
        <h2>Daycare Description:</h2>
        <p>At Pawsible Pet Daycare, we offer a fun and stimulating environment for your pets to enjoy during the day. Our daycare program is designed to provide socialization, exercise, and mental stimulation for your furry friends while you're away at work or running errands.</p>

        <h2>Daycare Activities:</h2>
        <ul>
          <li>Group Play Sessions: Our daycare includes supervised group play sessions where dogs can interact and play with other dogs of similar size and temperament.</li>
          <li>Interactive Toys: We provide a variety of interactive toys to keep your pets entertained and mentally engaged throughout the day.</li>
          <li>Outdoor Play Area: Our secure and spacious outdoor play area allows dogs to run, play fetch, and enjoy the fresh air.</li>
          <li>Resting Areas: We have designated resting areas for pets to relax and take a break during their daycare session.</li>
        </ul>

        <h2>Daycare Safety:</h2>
        <ul>
          <li>Trained Staff: Our experienced staff is trained to monitor and supervise play sessions to ensure the safety of all pets.</li>
          <li>Vaccination Requirements: All pets attending daycare must be up-to-date on vaccinations to protect the health of all our furry guests.</li>
          <li>Health Checks: We conduct health checks on all pets before they join the daycare to identify any signs of illness or discomfort.</li>
          <li>Small Play Groups: To maintain a safe and enjoyable environment, we organize playgroups based on size and temperament.</li>
        </ul>

        <p>At Pawsible Pet Daycare, we strive to provide a positive and enriching experience for your pets. Whether your furry friend is social, active, or just needs some extra attention during the day, our daycare is the perfect place for them to thrive and have a pawsitive time! Book your pet's daycare session now and let them enjoy a day full of fun and play.</p>
      </div> */}

    </>
  );
};

export default Daycare;
