import React, { useState, useContext, useEffect } from 'react';
import './BuyGiftCard.css';
import { XButton } from '../../../../../Utilities/components/Buttons';
import { StateContext } from '../../../../../StateProvider';

import giftCardBackground from '../../../../../../assets/Store/Deals/GiftCard/giftCardBackground.png';
import giftCard from '../../../../../../assets/Store/Deals/GiftCard/giftCard.png';

const BuyGiftCard = ({ onClose }) => {
  const { setBookingData } = useContext(StateContext);
  const [amount, setAmount] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState('');

  useEffect(() => {
    const giftCardAmount = customAmount ? customAmount.replace('$', '') : amount;
    setBookingData((prevData) => ({
      ...prevData,
      giftCardData: {
        amount: giftCardAmount,
        recipientEmail,
      },
    }));
  }, [amount, customAmount, recipientEmail, setBookingData]);

  const handleAmountClick = (value) => {
    setAmount(value);
    setCustomAmount('');
    setShowCustomInput(false);
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, ''); // Remove any non-digit characters
    setAmount('');
    setCustomAmount(value ? `$${value}` : '');
  };

  const handleOtherClick = () => {
    setAmount('');
    setShowCustomInput(true);
  };

  return (
    <div>
      <XButton onClick={onClose} />
      <img className='gift-card-hero-image' src={giftCardBackground} alt='gift-card-background' />
      <div className="buy-gift-card-container">
        <h2 className='buy-gift-card-header'>Send a Gift Card</h2>

        <div className="buy-gift-card-display-container">
          <div className="gift-card">
            <img src={giftCard} className='gift-card-image' alt='gift-card-image' />
          </div>
        </div>

        <form className="buy-gift-card-form">
          <div className="form-group">
            <label htmlFor="amount">Choose the amount</label>
            <div className="amount-buttons">
              <button
                type="button"
                className={`amount-btn ${amount === '50' ? 'selected' : ''}`}
                onClick={() => handleAmountClick('50')}
              >
                $50
              </button>
              <button
                type="button"
                className={`amount-btn ${amount === '100' ? 'selected' : ''}`}
                onClick={() => handleAmountClick('100')}
              >
                $100
              </button>
              <button
                type="button"
                className={`amount-btn ${amount === '300' ? 'selected' : ''}`}
                onClick={() => handleAmountClick('300')}
              >
                $300
              </button>
              {showCustomInput ? (
                <input
                  type="text"
                  id="customAmount"
                  value={customAmount}
                  onChange={handleCustomAmountChange}
                  placeholder="Enter"
                  className="custom-amount-input"
                />
              ) : (
                <button
                  type="button"
                  className={`amount-btn ${showCustomInput ? 'selected' : ''}`}
                  onClick={handleOtherClick}
                >
                  Other
                </button>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="recipient-email-container">
              <label htmlFor="recipientEmail">Recipient's Email</label>
              <input
                type="email"
                id="recipientEmail"
                value={recipientEmail}
                onChange={(e) => setRecipientEmail(e.target.value)}
                required
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BuyGiftCard;
