import React, { useState, useContext, useEffect } from 'react';
import './EditProfile.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../StateProvider';
import { BackButton } from '../../../../Utilities/components/Buttons';

import personPlaceholder from '../../../../../assets/Dashboard/Profile/personPlaceholder.png';
import dogPlaceholder from '../../../../../assets/Dashboard/Profile/dogPlaceholder.png';
import catPlaceholder from '../../../../../assets/Dashboard/Profile/catPlaceholder.png';

const EditProfile = () => {
  const navigate = useNavigate();
  const { 
    user
  } = useContext(StateContext);

  const handleNavigate = (member) => {
    const memberType = member.type === 'Human' ? 'human' : 'pet';
    if (!member.id) {
      navigate(`/profile/edit-family-member/user`);
      window.scrollTo(0, 0);
    } else {
      navigate(`/profile/edit-family-member/${memberType}/${member.id}`);
      window.scrollTo(0, 0);
    }
  };

  const placeholderImages = {
    Human: personPlaceholder,
    Dog: dogPlaceholder,
    Cat: catPlaceholder,
  };

  function getPlaceholderImage(type) {
    return placeholderImages[type] || placeholderImages.Human;
  }

  return (
    <div className="container">
      <BackButton />
      {/* <h2 className='edit-family-title'>Family Members</h2> */}
      <div className="edit-family-member-section">
        {user && user.familyMembers && user.familyMembers.map((member, index) => (
            <div key={`${member.id}_${index}`}>
                <div className="edit-family-member" onClick={() => handleNavigate(member)}>
                    <img 
                      className='edit-family-member-image' 
                      src={member.image || getPlaceholderImage(member.type)} 
                      alt={`${member.type} Placeholder`} 
                    />
                    <div className="edit-family-member-details">
                        <div>
                            <h3 className='edit-family-member-name'>{member.name}</h3>
                            <p className='edit-family-member-type'>{member.type}</p>
                        </div>
                        {member.type !== 'Human' 
                            ? (<div className="badges-container">
                                {member.Memberships && member.Memberships.length > 0 && member.Memberships[0].membershipType 
                                  ? <span className="badge membership-verified">VIP</span> 
                                  : <span className="badge membership-unverified">VIP</span>
                                }
                                {member.daycare ? <span className="badge daycare-verified">Daycare</span> : <span className="badge daycare-unverified">Daycare</span>}
                                {member.vaccination ? <span className="badge vaccination-verified">Vaccine</span> : <span className="badge vaccination-unverified">Vaccine</span> }
                              </div>)
                            : null
                        }

                    </div>
                    {/* <button className="edit-family-member-button" onClick={() => navigate(`/edit-family-member/${member.id}`)}>Edit</button> */}
                </div>
                <hr className='edit-profile-breakline'/>
            </div>
        ))}
        <div className='edit-family-add-new-container'>
          <button className="edit-family-add-new" onClick={() => navigate('/profile/edit-family-member/add-family-member')}>+ Add Member</button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
