export const ShuttleBookingCalculator = (bookingData) => {
  let total = 0;

  if (bookingData.serviceDetails && bookingData.bookingInfo && bookingData.bookingInfo.distanceDuration) {
      const serviceDetails = bookingData.serviceDetails;
      const basePay = parseFloat(serviceDetails.price) || 30;
      const pricePerMile = 2;

      // Extract distance from distanceDuration
      const distanceText = bookingData.bookingInfo.distanceDuration.distance;
      let distanceInMiles = 0;

      if (distanceText.includes('mi')) {
          distanceInMiles = parseFloat(distanceText);
      } else if (distanceText.includes('ft')) {
          const distanceInFeet = parseFloat(distanceText);
          distanceInMiles = distanceInFeet / 5280; // Convert feet to miles
      }

      // Calculate total cost
      total = basePay + (pricePerMile * distanceInMiles);
  }

  return { originalPrice: total, discountedPrice: total };
};

export default ShuttleBookingCalculator;
