import moment from 'moment-timezone';

// Set your local time zone, e.g., 'America/Los_Angeles'
const localTimeZone = 'America/Los_Angeles';

export const DaycareDiscountCalculator = (bookingData, serviceDetails) => {
    // Default prices if not specified in serviceDetails
    const basePricePerDay = serviceDetails.daycare ? serviceDetails.daycare[0].price : 50;
    const basePriceForWalk = serviceDetails.walk ? serviceDetails.walk[0].price : 20;

    // Safely handle selectedPets
    const selectedPets = bookingData.selectedPets || [];
    const numberOfPets = selectedPets.length;

    // Calculate the number of days; fallback to 1 if dateRange is invalid
    const numberOfDays = calculateNumberOfDays(bookingData.dateRange);

    let total = 0;  // Starting with a base of 0
    let originalTotal = 0; // Keep track of the price without any discounts
    let shouldCharge = 0; // Calculate the number of pets that should be charged

    if (numberOfPets >= 1) {
        // Iterate over each selected pet to calculate shouldCharge and the total cost
        selectedPets.forEach(pet => {
            // Safely access petMembership and handle potential undefined cases
            const petMembership = pet.petMembership && Array.isArray(pet.petMembership) ? pet.petMembership : [];
            const daycareRemaining = petMembership.length > 0 ? petMembership[0].daycareRemaining || 0 : 0;

            // Calculate the chargeable days for the pet
            const chargeableDays = Math.max(0, numberOfDays - daycareRemaining);
            if (chargeableDays > 0) {
                shouldCharge += 1; // Increment shouldCharge if the pet needs to be charged
                total += basePricePerDay * chargeableDays; // Add the chargeable days cost to the total
            }

            // Calculate the original total cost for all days regardless of daycare remaining
            originalTotal += basePricePerDay * numberOfDays;
        });
    }

    // Calculate food cost adjusted for the number of pets
    const foodCost = bookingData.foodPreference === 'premium' ? 20 : (bookingData.foodPreference === 'basic' ? 5 : 0);
    total += foodCost * numberOfDays * numberOfPets;
    originalTotal += foodCost * numberOfDays * numberOfPets;

    // Add the cost of the "walk" service only if selected
    if (bookingData.additionalServices && bookingData.additionalServices.walk) {
        const walkServiceCost = basePriceForWalk * numberOfPets * numberOfDays; // Apply cost per day
        total += walkServiceCost;
        originalTotal += walkServiceCost;
    }

    return { originalPrice: originalTotal, discountedPrice: total };
};

// Function to calculate the number of days based on the date range
function calculateNumberOfDays(dateRange) {
    if (Array.isArray(dateRange) && dateRange.length === 2) {
        const startDate = moment.tz(dateRange[0], localTimeZone).startOf('day');
        const endDate = moment.tz(dateRange[1], localTimeZone).startOf('day');
        const numberOfDays = endDate.diff(startDate, 'days') + 1; // +1 to include both start and end date
        return numberOfDays;
    }
    return 0;  // Default to 1 day if range is not properly defined
}

export default DaycareDiscountCalculator;
