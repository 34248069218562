export default function formatPhoneForDisplay(phone) {
    // Remove non-numeric characters
    const cleaned = phone.replace(/\D/g, '');

    // Determine the format based on the length of the phone number
    switch (cleaned.length) {
        case 7:
            // Format: XXX-XXXX
            return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
        case 10:
            // Format: (XXX) XXX-XXXX
            return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
        case 11:
            // Assuming 1 for international code or long-distance prefix
            // Format: 1 (XXX) XXX-XXXX
            return `${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
        case 12:
            // Assuming format like 001123456789
            // Format: 00 11 1234 5678 9
            return `${cleaned.slice(0, 2)} ${cleaned.slice(2, 4)} ${cleaned.slice(4, 8)} ${cleaned.slice(8)}`;
        case 13:
            // Assuming format like 0111234567890
            // Format: 011 1 234 567 890
            return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 4)} ${cleaned.slice(4, 7)} ${cleaned.slice(7, 10)} ${cleaned.slice(10)}`;
        default:
            // For other lengths, return the phone number as-is with spaces between every 3 digits
            return cleaned.replace(/(\d{3})(?=\d)/g, '$1 ');
    }
}