import React, { useState, useContext, useEffect } from 'react';
import '../../Booking.css';
import { StateContext } from '../../../../StateProvider';
import { FaInfoCircle } from 'react-icons/fa';
import Calendar from 'react-calendar';
import { tooltips } from './daycareTooltips';
import { getDaycareAvailabilityByMonth } from '../../../../Utilities/api';
import DaycareRemaining from '../../../../Utilities/components/DaycareRemaining';

function DaycareBooking() {
    const { user, setBookingData } = useContext(StateContext);
    const [showCalendar, setShowCalendar] = useState(false);
    const [datesSelected, setDatesSelected] = useState(false);
    const [remainingDaysCalculated, setRemainingDaysCalculated] = useState(0);
    const [availabilityCache, setAvailabilityCache] = useState({});
    const [tooltipVisibility, setTooltipVisibility] = useState({
        who: false,
        times: false,
        notes: false,
        activities: false,
    });
    const [daycareBookingData, setDaycareBookingData] = useState({
        selectedPets: [],
        dateRange: null,
        showCalendar: false,
        foodPreference: '',
        specialNotes: '',
        pickupTime: '',
        dropoffTime: '',
        groomingOptions: [],
        trainingOptions: [],
        selectedPackage: '',
        additionalServices: {
            hotel: false,
            daycare: false,
            grooming: false,
            training: false,
            shuttle: false,
            adventure: false,
            walk: false
        }
    });

    useEffect(() => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1;
        const year = today.getFullYear();
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        const nextMonthYear = currentMonth === 12 ? year + 1 : year;

        const currentMonthKey = `${year}-${currentMonth}`;
        const nextMonthKey = `${nextMonthYear}-${nextMonth}`;

        const fetchAvailability = async () => {
            const [currentMonthData, nextMonthData] = await Promise.all([
                !availabilityCache[currentMonthKey] && getDaycareAvailabilityByMonth(currentMonth, year),
                !availabilityCache[nextMonthKey] && getDaycareAvailabilityByMonth(nextMonth, nextMonthYear)
            ]);

            setAvailabilityCache(prev => ({
                ...prev,
                ...(currentMonthData ? { [currentMonthKey]: currentMonthData } : {}),
                ...(nextMonthData ? { [nextMonthKey]: nextMonthData } : {})
            }));
        };

        fetchAvailability();

        setBookingData(prevData => ({
            ...prevData,
            bookingInfo: daycareBookingData
        }));
    }, [daycareBookingData, setBookingData]);

    useEffect(() => {
        setBookingData(prevData => ({
            ...prevData,
            daycareRemaining: remainingDaysCalculated
        }));
    }, [remainingDaysCalculated])

    const handleActiveStartDateChange = ({ activeStartDate }) => {
        const month = activeStartDate.getMonth();
        const year = activeStartDate.getFullYear();

        const keysToFetch = [`${year}-${month + 1}`];

        const nextMonth = (month + 1) % 12;
        const nextMonthYear = month === 11 ? year + 1 : year;
        keysToFetch.push(`${nextMonthYear}-${nextMonth + 1}`);

        keysToFetch.forEach(key => {
            if (!availabilityCache[key]) {
                const [fetchYear, fetchMonth] = key.split('-').map(Number);

                getDaycareAvailabilityByMonth(fetchMonth, fetchYear).then(data => {
                    setAvailabilityCache(prev => ({ ...prev, [key]: data }));
                }).catch(error => {
                    console.error(`Failed to fetch booking data for ${key}:`, error);
                });
            }
        });
    };

    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (date < today) {
                return true;
            }

            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const key = `${year}-${month}`;

            const monthData = availabilityCache[key] ? availabilityCache[key][key] : null;

            if (monthData && Array.isArray(monthData.dailyAvailability)) {
                return day - 1 >= 0 && day - 1 < monthData.dailyAvailability.length && monthData.dailyAvailability[day - 1] <= 0;
            }
            return false;
        }
        return false;
    };

    const showTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: true });
    };

    const hideTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: false });
    };

    const togglePetSelection = (petId, petName, petMembership) => {
        setDaycareBookingData(prevBookingData => {
            let newSelectedPets;
    
            // Check if the petId is already included in the selectedPets array
            const isAlreadySelected = prevBookingData.selectedPets.some(selectedPet => selectedPet.petId === petId);
    
            if (isAlreadySelected) {
                // Filter out the petId if it's already selected
                newSelectedPets = prevBookingData.selectedPets.filter(selectedPet => selectedPet.petId !== petId);
            } else {
                // Add a new object with petId and petName if it's not already selected
                newSelectedPets = [...prevBookingData.selectedPets, { petId, petName, petMembership }];
            }
    
            return {
                ...prevBookingData,
                selectedPets: newSelectedPets
            };
        });
    };  

    const handleDateChange = newDateRange => {
        const start = newDateRange[0];
        const end = newDateRange[1];
        let isRangeValid = true;

        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            const day = d.getDate();
            const month = d.getMonth() + 1;
            const year = d.getFullYear();
            const key = `${year}-${month}`;

            const monthData = availabilityCache[key] ? availabilityCache[key][key] : null;

            if (!monthData || !Array.isArray(monthData.dailyAvailability)) {
                console.error("No valid availability data found for month:", key);
                isRangeValid = false;
                break;
            }

            if (day - 1 < 0 || day - 1 >= monthData.dailyAvailability.length) {
                console.error("Day out of bounds for month data:", day, key);
                isRangeValid = false;
                break;
            }

            if (monthData.dailyAvailability[day - 1] <= 0) {
                console.error("No slots available on:", `${year}-${month}-${day}`);
                isRangeValid = false;
                break;
            }
        }

        if (isRangeValid) {
            setDaycareBookingData(prevData => ({ ...prevData, dateRange: newDateRange }));
            setDatesSelected(true);
        } else {
            console.log("The selected range includes fully booked days. Please choose another range.");
        }
    };

    const selectedDaysCount = daycareBookingData.dateRange ? Math.ceil((daycareBookingData.dateRange[1] - daycareBookingData.dateRange[0]) / (1000 * 60 * 60 * 24)) : 0;

    const handleNotesChange = (e) => {
        setDaycareBookingData(prevData => ({ ...prevData, specialNotes: e.target.value }));
    };

    const handleFoodPreferenceChange = (preference) => {
        setDaycareBookingData(prevData => ({ ...prevData, foodPreference: preference }));
    };

    const handleServiceChange = (serviceName) => {
        const currentStatus = daycareBookingData.additionalServices[serviceName];
        const updatedServices = {
            ...daycareBookingData.additionalServices,
            [serviceName]: !currentStatus
        };
        setDaycareBookingData(prevData => ({ ...prevData, additionalServices: updatedServices }));
    };

    return (
        <div className='booking-backdrop'>
            <div className="booking-container">
                <div className="booking-header">
                    <h3>Pawsible Daycare</h3>
                </div>

                <div className="booking-content">
                    <div className="booking-section">
                        <h2>
                            Pet Guests <FaInfoCircle onMouseOver={() => showTooltip('who')} onMouseOut={() => hideTooltip('who')} />
                        </h2>
                        {tooltipVisibility.who && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.who }}></div>}
                        <div className="pet-selection">
                        {user && user.familyMembers && user.familyMembers.map(pet => (
                            (pet.type === 'Dog' || pet.type === 'Cat') && (
                                <button
                                    key={pet.id}
                                    className={`pet-button ${daycareBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id) ? 'pet-button-selected' : ''}`}
                                    onClick={() => togglePetSelection(pet.id, pet.name, pet.Memberships)}
                                >
                                    <img src={pet.image} alt={pet.name} className="pet-photo" />
                                    <p className='pet-name'>{pet.name}</p>
                                    <p className='pet-type'>{pet.type}</p>
                                    <DaycareRemaining
                                        pet={pet}
                                        selectedDaysCount={daycareBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id) ? selectedDaysCount : 0}
                                        remainingDaysCalculated={remainingDaysCalculated}
                                        setRemainingDaysCalculated={setRemainingDaysCalculated}
                                    />
                                </button>
                            )
                        ))}
                        </div>
                    </div>

                    <div className="booking-section">
                        <h2>Daycare Date <FaInfoCircle onMouseOver={() => showTooltip('when')} onMouseOut={() => hideTooltip('when')} /></h2>
                        {tooltipVisibility.when && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.when }}></div>}
                        <button
                            onClick={() => setShowCalendar(!showCalendar)}
                            className={datesSelected ? 'calendar-button-selected' : 'calendar-button'}
                        >
                            {daycareBookingData.dateRange ? `${daycareBookingData.dateRange[0].toLocaleDateString()} - ${daycareBookingData.dateRange[1].toLocaleDateString()}` : `Select Dates`}
                        </button>
                        {showCalendar && (
                            <div className='booking-calendar-container'>
                                <div className="legend">
                                    <div className="legend-item">
                                        <span className="history-dot" />
                                        Full
                                    </div>
                                    <div className="legend-item">
                                        <span className="today-dot" />
                                        Today
                                    </div>
                                    <div className="legend-item">
                                        <span className="booked-dot" />
                                        Active
                                    </div>
                                    <div className="legend-item">
                                        <span className="events-dot" />
                                        Events
                                    </div>
                                </div>
                                <Calendar
                                    selectRange
                                    value={daycareBookingData.dateRange}
                                    onChange={handleDateChange}
                                    onActiveStartDateChange={handleActiveStartDateChange}
                                    tileDisabled={tileDisabled}
                                />
                            </div>
                        )}
                    </div>

                    <div className="booking-section">
                        <h2>Meal Plans <FaInfoCircle onMouseOver={() => showTooltip('food')} onMouseOut={() => hideTooltip('food')} /></h2>
                        {tooltipVisibility.food && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.food }}></div>}
                        <div className="food-preference">
                            <button 
                                className={daycareBookingData.foodPreference === 'own' ? 'food-preference-button-selected' : 'food-preference-button'}
                                onClick={() => handleFoodPreferenceChange('own')}
                            >
                                Own
                            </button>
                            <button 
                                className={daycareBookingData.foodPreference === 'basic' ? 'food-preference-button-selected' : 'food-preference-button'}
                                onClick={() => handleFoodPreferenceChange('basic')}
                            >
                                Basic
                            </button>
                            <button 
                                className={daycareBookingData.foodPreference === 'premium' ? 'food-preference-button-selected' : 'food-preference-button'}
                                onClick={() => handleFoodPreferenceChange('premium')}
                            >
                                Premium
                            </button>
                        </div>
                    </div>

                    <div className="booking-section">
                        <div className='additional-services'>
                            <h2>Additional Services <FaInfoCircle onMouseOver={() => showTooltip('services')} onMouseOut={() => hideTooltip('services')} /></h2>
                            {tooltipVisibility.services && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.services }}></div>}
                            <div className='booking-additional-button-container'>
                                <button
                                    className={`service-button-${daycareBookingData.additionalServices.hotel ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('hotel')}
                                >
                                    Hotel
                                </button>

                                <button
                                    className={`service-button-${daycareBookingData.additionalServices.grooming ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('grooming')}
                                >
                                    Grooming
                                </button>

                                <button
                                    className={`service-button-${daycareBookingData.additionalServices.training ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('training')}
                                >
                                    Training
                                </button>

                                <button
                                    className={`service-button-${daycareBookingData.additionalServices.shuttle ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('shuttle')}
                                >
                                    Shuttle
                                </button>

                                <button
                                    className={`service-button-${daycareBookingData.additionalServices.adventure ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('adventure')}
                                >
                                    Adventure
                                </button>

                                <button
                                    className={`service-button-${daycareBookingData.additionalServices.walk ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('walk')}
                                >
                                    Leash Walk
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="booking-section">
                        <h2>
                            Additional Pet Needs <FaInfoCircle onMouseOver={() => showTooltip('notes')} onMouseOut={() => hideTooltip('notes')} />
                        </h2>
                        {tooltipVisibility.notes && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.notes }}></div>}
                        <textarea
                            placeholder=""
                            value={daycareBookingData.specialNotes}
                            onChange={handleNotesChange}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DaycareBooking;
