import React, { useState, useEffect } from 'react';
import daycareBadgeIcon from '../../../../assets/Store/Services/Booking/daycareBadgeIcon.png';
import './DaycareRemaining.css';

const DaycareRemaining = ({ pet, selectedDaysCount, remainingDaysCalculated, setRemainingDaysCalculated }) => {
    const [remainingDays, setRemainingDays] = useState(0);

    useEffect(() => {
        const initialRemaining = pet.Memberships && pet.Memberships[0] && !isNaN(pet.Memberships[0].daycareRemaining)
            ? pet.Memberships[0].daycareRemaining
            : 0;
        setRemainingDays(initialRemaining);
    }, [pet]);

    useEffect(() => {
        const initialRemaining = pet.Memberships && pet.Memberships[0] && !isNaN(pet.Memberships[0].daycareRemaining)
            ? pet.Memberships[0].daycareRemaining
            : 0;
        const calculatedRemaining = calculateRemainingDaycare(initialRemaining, selectedDaysCount);
        setRemainingDays(calculatedRemaining);
    }, [selectedDaysCount]);

    const calculateRemainingDaycare = (daycareRemaining, selectedDays) => {
        const remaining = daycareRemaining - selectedDays;
        setRemainingDaysCalculated(remaining);
        return remaining < 0 ? 0 : remaining;
    };

    return (
        <div className='daycare-remaining-container'>
            <img className="daycare-remaining-icon" src={daycareBadgeIcon} alt="Daycare Remaining" />
            {remainingDays < 10
                ? <span className="daycare-remaining-count-less">{remainingDays}</span>
                : <span className="daycare-remaining-count">{remainingDays}</span>
            }
        </div>
    );
};

export default DaycareRemaining;
