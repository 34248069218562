import React, { useState, useContext, useEffect } from 'react';
import './Membership.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../StateProvider';
import { BackButton } from '../../../../Utilities/components/Buttons';

const membershipBenefits = {
  Basic: [
    "15 days of Daycare",
    "High Quality & Nutritious Meals",
    "Access to Exclusive Events",
    "10% Discounts on any Service and Products",
    "Regular Hygiene and Health check-ups"
  ],
  Premium: [
    "30 days of Daycare",
    "High Quality & Nutritious Meals",
    "Access to Exclusive Events",
    "1 Adventure of your choice per month",
    "15% Discounts on any Service and Products",
    "Regular Hygiene and Health check-ups"
  ],
  VIP: [
    "30 days of Daycare",
    "Hand-made Premium Meals",
    "Shuttle Service for Daycare",
    "Access to Exclusive Events",
    "1 Adventure of your choice per month",
    "20% Discounts on any Service and Products",
    "Regular Hygiene and Health check-ups"
  ]
};

const groomingBenefits = {
  Basic: [
    "Daily Teeth Brushing",
    "1 Full Grooming",
    "1 Full Bath (Flea & Tick Treatment)"
  ],
  Premium: [
    "Daily Teeth Brushing",
    "Daily Deshedding/Brushing",
    "Basic Paw Care",
    "2 Full Groomings",
    "2 Full Baths (Flea & Tick Treatment)"
  ],
  VIP: [
    "Deep Teeth Cleaning every 3 months",
    "Daily Teeth Brushing",
    "Daily Deshedding/Brushing",
    "Advanced Paw Care",
    "Unlimited Full Groomings",
    "Unlimited Full Baths (Flea & Tick Treatment)"
  ]
};

const Membership = () => {
  const navigate = useNavigate();
  const { user, setBookingData } = useContext(StateContext);
  const [familyMembers, setFamilyMembers] = useState([]);

  useEffect(() => {
    if (user && user.familyMembers) {
      setFamilyMembers(user.familyMembers);
    }
  }, [user]);

  if (!familyMembers || familyMembers.length === 0) {
    return null;
  }

  const handleEditMembership = (member) => {
    window.scrollTo(0, 0);
    navigate(`/profile/membership/update/${member.id}`);
  };

  const handleEnrollMembership = (member) => {
    setBookingData(prevData => ({
      ...prevData,
      bookingType: `store/membership`
    }));
    localStorage.setItem('bookingType', `store/membership`);
    window.scrollTo(0, 0);
    navigate(`/store/membership`);
  };

  return (
    <div className="container">
      <BackButton />

      <div className="membership-family-member-section">
        {familyMembers.map((member) =>
          member.type !== 'Human' && (
            <div key={member.id}>
              <div className="membership-family-member">
                <div className="membership-family-member-details">
                  <img className='membership-family-member-image' src={member.image} alt={`${member.name}`} />
                  <div>
                    <h3 className='membership-family-member-name'>{member.name}</h3>
                    <p className='membership-family-member-type'>{member.type}</p>
                  </div>
                  <div className="badges-container">
                    {member.Memberships[0] && member.Memberships[0].membershipType ? (
                      <span className="badge membership-verified">Membership</span>
                    ) : (
                      <span className="badge membership-unverified">Membership</span>
                    )}
                    {member.daycare ? (
                      <span className="badge daycare-verified">Daycare</span>
                    ) : (
                      <span className="badge daycare-unverified">Daycare</span>
                    )}
                  </div>
                </div>
                {renderMembershipDetails(member)}
                <div className="membership-actions">
                  {member.Memberships[0] && member.Memberships[0].membershipType ? (
                    <button 
                      className='membership-update-button'
                      onClick={() => handleEditMembership(member)}
                    >
                      Update
                    </button>
                  ) : (
                    <button 
                      className='membership-renew-button'
                      onClick={() => handleEnrollMembership(member)}
                    >
                      Enroll
                    </button>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Membership;

const renderMembershipDetails = (member) => {
  if (member.type !== 'Human' && member.Memberships[0] && member.Memberships[0].membershipType) {
    const membershipType = member.Memberships[0].membershipType;
    const groomingMembershipType = member.Memberships[0].groomingMembershipType;

    const membershipTypeBenefits = membershipBenefits[membershipType] || [];
    const groomingMembershipTypeBenefits = groomingBenefits[groomingMembershipType] || [];

    return (
      <div className='membership-tier-details'>
        <p className='membership-tier-endDate'>Since {new Date(member.Memberships[0].startDate).toLocaleDateString()}</p>
        <h4 className='membership-tier-header'>{membershipType} Membership</h4>
        <ul>
          {membershipTypeBenefits.map((benefit, index) => (
            <li key={index} className='membership-tier-li'>{benefit}</li>
          ))}
        </ul>
        <h4 className='membership-tier-header'>{groomingMembershipType} Grooming</h4>
        <ul>
          {groomingMembershipTypeBenefits.map((benefit, index) => (
            <li key={index} className='membership-tier-li'>{benefit}</li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};