import React, { useState, useEffect } from 'react';
import { auth } from './components/Login/Firebase';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import StateProvider from './components/StateProvider';

// Access & Nav/Dashboard
import Login from './components/Login/index.js';
import Landing from './components/Landing/index.js';
import Dashboard from './components/Dashboard/index.js';
import Navigation from './components/Navigation/index.js';
import Registration from './components/Login/Registration/index.js';
import ResetPassword from './components/Login/ResetPassword/index.js';

// General Pages
import About from './components/Pages/About/index.js';
import Franchise from './components/Pages/Franchise/index.js';
import Locations from './components/Pages/Locations/index.js';
import Career from './components/Pages/Career/index.js';
import Blog from './components/Pages/Blog/index.js';
import FAQs from './components/Pages/FAQs/index.js';

// Legal Components
import TermsAndConditions from './components/Pages/Legal/TermsAndConditions.js';
import PrivacyPolicy from './components/Pages/Legal/PrivacyPolicy.js';

// User Components
import Profile from './components/Dashboard/Profile/index.js';
import ProfileEdit from './components/Dashboard/Profile/components/EditProfile';
import UserEdit from './components/Dashboard/Profile/components/EditProfile/userEdit';
import FamilyMemberEdit from './components/Dashboard/Profile/components/EditProfile/FamilyMemberEdit';
import FamilyMemberAdd from './components/Dashboard/Profile/components/EditProfile/FamilyMemberAdd';
import Cart from './components/Dashboard/Cart';
import Checkout from './components/Dashboard/Cart/Checkout';
import Friends from './components/Dashboard/Profile/components/Friends/index.js';
import Membership from './components/Dashboard/Profile/components/Membership/index.js';
import MembershipEdit from './components/Dashboard/Profile/components/Membership/MembershipEdit/index.js';
import Wallet from './components/Dashboard/Profile/components/Wallet/index.js';
import PaymentMethods from './components/Dashboard/Profile/components/Wallet/PaymentMethods/index.js';
import AddPaymentMethod from './components/Dashboard/Profile/components/Wallet/PaymentMethods/AddPaymentMethod/index.js';
import EditPaymentMethod from './components/Dashboard/Profile/components/Wallet/PaymentMethods/EditPaymentMethod/index.js';
import PaymentHistory from './components/Dashboard/Profile/components/Wallet/PaymentHistory/index.js';
import PaymentDetails from './components/Dashboard/Profile/components/Wallet/PaymentHistory/PaymentDetails/index.js';

// Store Components
import Store from './components/Store';
import Hotel from './components/Store/Services/Hotel';
import Shuttle from './components/Store/Services/Shuttle';
import Daycare from './components/Store/Services/Daycare';
import Grooming from './components/Store/Services/Grooming';
import Training from './components/Store/Services/Training';
import Adventure from './components/Store/Services/Adventure';
import HotelBooking from './components/Store/Services/Hotel/HotelBooking';
import DaycareBooking from './components/Store/Services/Daycare/DaycareBooking';
import ShuttleBooking from './components/Store/Services/Shuttle/ShuttleBooking';
import GroomingBooking from './components/Store/Services/Grooming/GroomingBooking';
import TrainingBooking from './components/Store/Services/Training/TrainingBooking';
import AdventureBooking from './components/Store/Services/Adventure/AdventureBooking';
import MembershipBooking from './components/Store/Services/Membership/MembershipBooking';

// User Empower
import Invite from './components/Store/Services/Deals/Invite';
import Impact from './components/Store/Services/Deals/Impact';
import GiftCard from './components/Store/Services/Deals/GiftCard';
import BuyGiftCard from './components/Store/Services/Deals/GiftCard/BuyGiftCard';

// Dashboard Components
import Events from './components/Dashboard/Events/index.js';
import Support from './components/Dashboard/Support/index.js';

// Utils
import ComingSoon from './components/Utilities/components/ComingSoon/index.js';
import QRCode from './components/Utilities/components/QRCode/index.js';
import OpenInBrowserPrompt from './components/Utilities/components/OpenInBrowserPrompt/index.js';
import MobileAccessPrompt from './components/Utilities/components/MobileAccessPrompt/index.js';

// Admin Console
import Console from './components/Console/index.js';
import ServiceConfigurations from './components/Console/components/ServiceConfig/index.js';
import DaycareConfig from './components/Console/components/ServiceConfig/components/DaycareConfig.js';
import HotelConfig from './components/Console/components/ServiceConfig/components/HotelConfig.js';
import GroomingConfig from './components/Console/components/ServiceConfig/components/GroomingConfig.js';
import TrainingConfig from './components/Console/components/ServiceConfig/components/TrainingConfig.js';
import AdventureConfig from './components/Console/components/ServiceConfig/components/AdventureConfig.js';
import ShuttleConfig from './components/Console/components/ServiceConfig/components/ShuttleConfig.js';
import StaffManager from './components/Console/components/StaffManager/index.js';
import StaffProfile from './components/Console/components/StaffManager/StaffProfile/index.js';
import BookingCalendar from './components/Console/components/BookingCalendar/index.js';
import BookingDetails from './components/Console/components/BookingCalendar/BookingDetails/index.js';
import NewBooking from './components/Console/components/BookingCalendar/NewBooking/index.js';
import CustomerManager from './components/Console/components/CustomerManager/index.js';
import CustomerProfile from './components/Console/components/CustomerManager/CustomerProfile/index.js';
import InventoryManager from './components/Console/components/InventoryManager/index.js';

import stripePromise from './stripe';
import { Elements } from '@stripe/react-stripe-js';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [role, setRole] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        setLoggedIn(true);
      } else {
        // User is signed out.
        setLoggedIn(false);
      }
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Elements stripe={stripePromise}>
        <StateProvider loggedIn={loggedIn} setLoggedIn={setLoggedIn} setRole={setRole} role={role} >
          <OpenInBrowserPrompt />
          <MobileAccessPrompt />
          <Navigation />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/resetPassword" element={<ResetPassword />} />

            <Route path="/about" element={<About />} />
            <Route path="/franchise" element={<Franchise />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/career" element={<Career />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/faqs" element={<FAQs />} />

            <Route path="/legal/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
            
            <Route path="/qr-code" element={<QRCode />} />
            <Route path="/coming-soon" element={<ComingSoon />} />

            <Route path="/store" element={<Store />} />
                <Route path="/store/services/hotel" element={<Hotel />} />
                <Route path="/store/services/shuttle" element={<Shuttle />} />
                <Route path="/store/services/daycare" element={<Daycare />} />
                <Route path="/store/services/grooming" element={<Grooming />} />
                <Route path="/store/services/training" element={<Training />} />
                <Route path="/store/services/adventure" element={<Adventure />} />
                <Route path="/store/membership" element={<MembershipBooking />} />
                
            {loggedIn && (
              <>
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/edit-profile" element={<ProfileEdit />} />
                <Route path="/profile/edit-family-member/user" element={<UserEdit />} />
                <Route path="/profile/edit-family-member/:memberType/:memberId" element={<FamilyMemberEdit />} />
                <Route path="/profile/edit-family-member/add-family-member" element={<FamilyMemberAdd />} />
                <Route path="/profile/friends" element={<Friends />} />
                <Route path="/profile/membership" element={<Membership />} />
                <Route path="/profile/membership/update/:membershipId" element={<MembershipEdit />} />
                <Route path="/profile/wallet" element={<Wallet />} />
                <Route path="/profile/wallet/payment-methods" element={<PaymentMethods />} />
                <Route path="/profile/wallet/payment-methods/add-payment" element={<AddPaymentMethod />} />
                <Route path="/profile/wallet/payment-methods/edit-payment/:id" element={<EditPaymentMethod />} />
                <Route path="/profile/wallet/payment-history" element={<PaymentHistory />} />
                <Route path="/profile/wallet/payment-history/:transactionId" element={<PaymentDetails />} />
                <Route path="/profile/cart" element={<Cart />} />
                <Route path="/profile/checkout" element={<Checkout />} />

                <Route path="/store/deals/invite" element={<Invite />} />
                <Route path="/store/deals/impact" element={<Impact />} />
                <Route path="/store/deals/gift-card" element={<GiftCard />} />

                <Route path="/store/services/gift-card-purchase" element={<BuyGiftCard />} />
                <Route path="/store/services/hotel/booking" element={<HotelBooking />} />
                <Route path="/store/services/daycare/booking" element={<DaycareBooking />} />
                <Route path="/store/services/shuttle/booking" element={<ShuttleBooking />} />
                <Route path="/store/services/grooming/booking" element={<GroomingBooking />} />
                <Route path="/store/services/training/booking" element={<TrainingBooking />} />
                <Route path="/store/services/adventure/booking" element={<AdventureBooking />} />

                <Route path="/events" element={<Events />} />

                <Route path="/support" element={<Support />} />
              </>
            )}

            {loggedIn && (role === 'admin' || role === 'staff') && (
                <>
                  <Route path="/admin" element={<Console />} />
                  <Route path="/admin/service-config" element={<ServiceConfigurations />} />
                    <Route path="/admin/service-config/daycare" element={<DaycareConfig />} />
                    <Route path="/admin/service-config/hotel" element={<HotelConfig />} />
                    <Route path="/admin/service-config/grooming" element={<GroomingConfig />} />
                    <Route path="/admin/service-config/training" element={<TrainingConfig />} />
                    <Route path="/admin/service-config/adventure" element={<AdventureConfig />} />
                    <Route path="/admin/service-config/shuttle" element={<ShuttleConfig />} />
                  <Route path="/admin/staff-manager" element={<StaffManager />} />
                    <Route path="/admin/staff-manager/:uid" element={<StaffProfile />} />
                  <Route path="/admin/schedule-calendar" element={<BookingCalendar />} />
                    <Route path="admin/schedule-calendar/event-details/:id" element={<BookingDetails />} />
                    <Route path="/admin/schedule-calendar/new-booking" element={<NewBooking />} />
                  <Route path="/admin/customer-manager" element={<CustomerManager />} />
                    <Route path="/admin/customer-manager/:uid" element={<CustomerProfile />} />
                  <Route path="/admin/inventory-manager" element={<InventoryManager />} />
                </>
            )}
          </Routes>

          {loggedIn && <Dashboard />}

        </StateProvider>
      </Elements>
    </Router>
  );
}

export default App;
