import React, { useContext } from 'react';
import './GiftCard.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../StateProvider';

import giftCardBackground from '../../../../../assets/Store/Deals/GiftCard/giftCardBackground.png';
import giftCard from '../../../../../assets/Store/Deals/GiftCard/giftCard.png';

import { XButton } from '../../../../Utilities/components/Buttons';

const GiftCard = () => {
  const navigate = useNavigate();
  const { setBookingData } = useContext(StateContext);

  const handleBuyButtonClicked = () => {
    navigate('/store/services/gift-card-purchase');
    window.scrollTo(0, 0);
    setBookingData(prevData => ({
      ...prevData,
      bookingType: 'store/services/gift-card-purchase'
    }));
    localStorage.setItem('bookingType', 'store/services/gift-card-purchase');
  }

  return (
    <div>
      <XButton />
      <img className='gift-card-hero-image' src={giftCardBackground} alt='gift-card-background'></img>
      <div className="gift-card-container">
        <h2 className='gift-card-header'>Pawsible Gift Cards</h2>
        <p className='gift-card-paragraph'><span className='highlight-gift-card'>Tail-wagging</span> journey starts with a gift of pawsitivity! Treat your friends and their furry companions to an <span className='highlight-gift-card'>un-fur-gettable</span> experience.</p>
        
        <div className="gift-card-display-container">
          <div className="gift-card">
            <img src={giftCard} className='gift-card-image' alt='gift-card-image' />
          </div>
        </div>
        
        <div className="action-buttons">
          <button className="buy-btn" onClick={handleBuyButtonClicked}>Buy a Gift Card</button>
          <button className="redeem-btn">Redeem a Gift Card</button>
        </div>
      </div>
    </div>
  );
}

export default GiftCard;
