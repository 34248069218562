import React, { useState, useContext, useEffect } from 'react';
import '../../Booking.css';
import { StateContext } from '../../../../StateProvider';
import { FaInfoCircle } from 'react-icons/fa';
import Calendar from 'react-calendar';
import { tooltips } from './trainingTooltips'; // Update tooltips for training
import TrainingOptions from './TrainingOptions';
import { getTrainingAvailabilityByMonth, getTrainingTimeSlots } from '../../../../Utilities/api';
import TimeSlots from '../../../../Utilities/components/TimeSlots';

function TrainingBooking() {
  const { user, setBookingData } = useContext(StateContext);
  const [isTrainingOptionsOpen, setIsTrainingOptionsOpen] = useState(false);
  const [selectedPetForTraining, setSelectedPetForTraining] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [datesSelected, setDatesSelected] = useState(false);
  const [availabilityCache, setAvailabilityCache] = useState({});
  const [tooltipVisibility, setTooltipVisibility] = useState({
    petSelection: false,
    appointmentDate: false,
    trainingPreferences: false,
  });
  const [trainingBookingData, setTrainingBookingData] = useState({
    selectedPets: [],
    dateRange: null, // Initialize with null
    showCalendar: false,
    foodPreference: '',
    trainingType: '',
    specialNotes: '',
    selectedTimeSlot: '',
    pickupTime: '',
    dropoffTime: '',
    groomingOptions: [],
    trainingOptions: [],
    selectedPackage: '',
    additionalServices: {
        hotel: false,
        daycare: false,
        grooming: false,
        training: false,
        shuttle: false,
        adventure: false,
        walk: false
    }
  });

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Month in human-readable format (1-12)
    const year = today.getFullYear();
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1; // Handle December to January transition
    const nextMonthYear = currentMonth === 12 ? year + 1 : year;

    const currentMonthKey = `${year}-${currentMonth}`;
    const nextMonthKey = `${nextMonthYear}-${nextMonth}`;

    const fetchAvailability = async () => {
        const [currentMonthData, nextMonthData] = await Promise.all([
            !availabilityCache[currentMonthKey] && getTrainingAvailabilityByMonth(currentMonth, year),
            !availabilityCache[nextMonthKey] && getTrainingAvailabilityByMonth(nextMonth, nextMonthYear)
        ]);

        setAvailabilityCache(prev => ({
            ...prev,
            ...(currentMonthData ? { [currentMonthKey]: currentMonthData } : {}),
            ...(nextMonthData ? { [nextMonthKey]: nextMonthData } : {})
        }));
    };

    fetchAvailability();

    // Set booking data on mount
    setBookingData(prevData => ({
        ...prevData,
        bookingInfo: trainingBookingData
    }));
  }, [trainingBookingData]);

  useEffect(() => {
    const fetchTimeSlots = async () => {
      if (trainingBookingData.dateRange && trainingBookingData.trainingOptions.selectedSessionType) {
        const selectedDate = trainingBookingData.dateRange[0];
        const day = selectedDate.getDate();
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();

        try {
          const timeSlots = await getTrainingTimeSlots(year, month, day, trainingBookingData.trainingOptions.selectedSessionType);
          setTrainingBookingData(prev => ({
            ...prev,
            availableTimeSlots: timeSlots
          }));
        } catch (error) {
          console.error('Failed to fetch training time slots:', error);
        }
      }
    };

    fetchTimeSlots();
  }, [trainingBookingData.trainingOptions.selectedSessionType, trainingBookingData.trainingOptions.selectedTrainingType]);

  useEffect(() => {
    const incompatibleGroupSessions = ['behavior', 'akc'];
    if (incompatibleGroupSessions.includes(trainingBookingData.trainingOptions.selectedTrainingType)) {
      setTrainingBookingData(prev => ({
        ...prev,
        trainingOptions: {
          ...prev.trainingOptions,
          selectedSessionType: prev.trainingOptions.selectedSessionType === 'group' ? '' : prev.trainingOptions.selectedSessionType
        }
      }));
    }
  }, [trainingBookingData.trainingOptions.selectedTrainingType]);

  const showTooltip = (key) => {
    setTooltipVisibility({ ...tooltipVisibility, [key]: true });
  };

  const hideTooltip = (key) => {
    setTooltipVisibility({ ...tooltipVisibility, [key]: false });
  };

  // Can select single pet
  const togglePetSelection = (pet) => {
    setTrainingBookingData(prevBookingData => {
        // If the selected pet is clicked again, deselect it by setting an empty array
        if (prevBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id)) {
            setSelectedPetForTraining(null);
            setIsTrainingOptionsOpen(false);
            return { ...prevBookingData, selectedPets: [], trainingOptions: [] };
        } else {
            // If another pet is clicked, replace the selectedPets array with the new pet
            const newSelectedPet = { petId: pet.id, petName: pet.name };
            setSelectedPetForTraining(pet); // Assuming 'pet' is the complete pet object
            setIsTrainingOptionsOpen(true);
            return { ...prevBookingData, selectedPets: [newSelectedPet], trainingOptions: [] };
        }
    });
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    const month = activeStartDate.getMonth();
    const year = activeStartDate.getFullYear();
    const key = `${year}-${month}`;

    if (!availabilityCache[key]) {
      getTrainingAvailabilityByMonth(month, year)
            .then(data => {
                const newCache = { ...availabilityCache, [key]: data };
                setAvailabilityCache(newCache);
            })
            .catch(error => {
                console.error('Failed to fetch booking data:', error);
            });
    }
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize today's date to the start of the day for accurate comparison

        // Disable past dates
        if (date < today) {
            return true;
        }

        const day = date.getDate();
        const month = date.getMonth() + 1; // JavaScript months are 0-indexed, but your keys are 1-indexed
        const year = date.getFullYear();
        const key = `${year}-${month}`;

        // Access the correct month data using the potentially nested structure
        const monthData = availabilityCache[key] ? availabilityCache[key][key] : null;

        // Check if the availability data for the day exists and is valid
        if (monthData && Array.isArray(monthData.dailyAvailability)) {
            // Ensure day is within bounds and check availability
            return day - 1 >= 0 && day - 1 < monthData.dailyAvailability.length && monthData.dailyAvailability[day - 1] <= 0;
        }
        return false; // If data is not loaded or not correct, don't disable (or you might choose to disable)
    }
    return false;
  };    

  const handleDateChange = async (date) => {
    let selectedDate = date;
    if (Array.isArray(date)) {
      selectedDate = date[0];
    }
  
    if (selectedDate instanceof Date && !isNaN(selectedDate)) {
      const formattedDate = [selectedDate, selectedDate];
      setTrainingBookingData(prevData => ({
        ...prevData,
        dateRange: formattedDate
      }));
      // setShowCalendar(false);
      setDatesSelected(true);
  
      // Now fetch time slots for the selected day
      const day = selectedDate.getDate();
      const month = selectedDate.getMonth() + 1; // JavaScript months are 0-based
      const year = selectedDate.getFullYear();
      const key = `${year}-${month}`;
      const dayAvailability = availabilityCache[key] && availabilityCache[key].dailyAvailability ? availabilityCache[key].dailyAvailability[day - 1] : undefined;
  
      // Assuming dailyAvailability is an object with session type keys
      if (dayAvailability) {
        try {
          const { selectedSessionType } = trainingBookingData.trainingOptions;
          const timeSlots = await getTrainingTimeSlots(year, month, day, selectedSessionType);

          setTrainingBookingData(prev => ({
            ...prev,
            availableTimeSlots: timeSlots
          }));
        } catch (error) {
          console.error('Failed to fetch training time slots:', error);
        }
      }
    } else {
      console.error('Invalid date selected:', selectedDate);
    }
  };  

  const handleTimeChange = (timeType, time) => {
    setTrainingBookingData((prevData) => ({ ...prevData, [timeType]: time }));
  };

  const handleNotesChange = (e) => {
    setTrainingBookingData((prevData) => ({ ...prevData, specialNotes: e.target.value }));
  };

  return (
    <div className="booking-backdrop">
      <div className="booking-container">
        <div className="booking-header">
          <h3>Pawsible Training</h3>
        </div>

        <div className="booking-content">
          <div className="booking-section">
            <h2>
              Pet Guests{' '}
              <FaInfoCircle onMouseOver={() => showTooltip('who')} onMouseOut={() => hideTooltip('who')} />
            </h2>
            {tooltipVisibility.who && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.who }}></div>}
            <div className="pet-selection">
              {user && user.familyMembers && user.familyMembers.map((pet) =>
                (pet.type === 'Dog' || pet.type === 'Cat') && (
                  <div key={pet.id}>
                    <button
                      className={`pet-button-${trainingBookingData.selectedPets.length > 0 && trainingBookingData.selectedPets[0].petId === pet.id ? 'selected' : ''}`}  
                      onClick={() => togglePetSelection(pet)}
                    >
                      <img src={pet.image} alt={pet.name} className="pet-photo" />
                      <p className="pet-name">{pet.name}</p>
                      <p className="pet-type">{pet.type}</p>
                    </button>
                  </div>
                )
              )}
            </div>
          </div>

          <div className={`booking-section-selectedPetForTraining ${isTrainingOptionsOpen ? 'open' : 'close'}`}>
            {selectedPetForTraining && (
              <div>
                <TrainingOptions
                  selectedPetForTraining={selectedPetForTraining}
                  trainingBookingData={trainingBookingData}
                  setTrainingBookingData={setTrainingBookingData}
                />
              </div>
            )}
          </div>

          <div className="booking-section">
            <h2>
              Date <FaInfoCircle onMouseOver={() => showTooltip('when')} onMouseOut={() => hideTooltip('when')} />
            </h2>
            {tooltipVisibility.when && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.when }}></div>}
            <button
              onClick={() => setShowCalendar(!showCalendar)}
              className={datesSelected ? 'calendar-button-selected' : 'calendar-button'}
            >
              {trainingBookingData.dateRange ? trainingBookingData.dateRange[0].toLocaleDateString() : 'Select Date'}
            </button>
            {showCalendar && (
              <div className='booking-calendar-container'>
                <div className="legend">
                  <div className="legend-item">
                  <span className="history-dot" />
                      Full
                  </div>
                  <div className="legend-item">
                  <span className="today-dot" />
                      Today
                  </div>
                  <div className="legend-item">
                  <span className="booked-dot" />
                      Active
                  </div>
                  <div className="legend-item">
                  <span className="events-dot" />
                      Events
                  </div>
                </div>
                <Calendar 
                  value={trainingBookingData.dateRange} 
                  onChange={handleDateChange} 
                  onActiveStartDateChange={handleActiveStartDateChange}
                  tileDisabled={tileDisabled}
                />
              </div>
            )}

            {/* {tooltipVisibility.times && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.times }}></div>}
            <div className="booking-time-container">
              <input
                className="booking-time-input"
                type="time"
                value={trainingBookingData.dropoffTime}
                onChange={(e) => handleTimeChange('dropoffTime', e.target.value)}
              />
            </div> */}

            <div className="booking-section">
                {trainingBookingData.availableTimeSlots && trainingBookingData.availableTimeSlots.length > 0
                    ? (<div>
                            <h2>Time <FaInfoCircle onMouseOver={() => showTooltip('times')} onMouseOut={() => hideTooltip('times')} /></h2>
                            {tooltipVisibility.times && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.times }}></div>}
                            <TimeSlots
                              type="training"
                              lessonType={trainingBookingData.trainingOptions.selectedSessionType}
                              timeSlotsData={trainingBookingData.availableTimeSlots}
                              handleTimeChange={handleTimeChange}
                              selectedTimeSlot={trainingBookingData.selectedTimeSlot}
                            />
                        </div>)
                    : null
                }
                {trainingBookingData.availableTimeSlots && trainingBookingData.availableTimeSlots.length === 0
                  ? (<div>
                      <h2>Time <FaInfoCircle onMouseOver={() => showTooltip('times')} onMouseOut={() => hideTooltip('times')} /></h2>
                      {tooltipVisibility.times && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.times }}></div>}
                      <p className='booking-timeslot-text'>No training sessions for the selected date</p>
                    </div>)
                  : null
                }
            </div>
          </div>

          <div className="booking-section">
            <h2>
              Special Requests{' '}
              <FaInfoCircle onMouseOver={() => showTooltip('trainingPreferences')} onMouseOut={() => hideTooltip('trainingPreferences')} />
            </h2>
            {tooltipVisibility.trainingPreferences && (
              <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.trainingPreferences }}></div>
            )}
            <textarea
              placeholder=""
              value={trainingBookingData.specialNotes}
              onChange={handleNotesChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingBooking;
