import React from 'react';
import ReactDOM from 'react-dom';
import './Popup.css'; // Make sure to create a corresponding CSS file for styling

const Popup = ({ isOpen, handleClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div className="popup-overlay" onClick={handleClose}></div>
      <div className="popup-content">
        <button className="popup-close" onClick={handleClose}>&times;</button>
        {children}
      </div>
    </>,
    document.getElementById('popup-root')
  );
};

export default Popup;
