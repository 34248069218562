import React, { useState } from 'react';
import teethPreferencesPhoto from './../../../../../../../../assets/Store/Services/Grooming/teethPreferencesPhoto.png';

const TeethPreferences = ({ selectedSize, selectedPackage }) => {
  const [toothpasteFlavor, setToothpasteFlavor] = useState('');

  const handleToothpasteFlavorChange = (e) => {
    setToothpasteFlavor(e.target.value);
  };

  return (
    <div className="teeth-preferences">
        <h3>What's included in the package?</h3>
        <ul>          
            <li>Before & After Photos</li>
            <li>Thorough Dental Exam</li>
            <li>No Anesthesia/Sedative</li>
            <li>Professional Scaling & Polishing</li>
            <li>Fluoride Treatment</li>
            <li>Nail & Paw Care</li>
            <li>Full Bath</li>
        </ul>

        <h3>What are the benefits?</h3>
        <img className='teeth-preferences-photo' src={teethPreferencesPhoto}></img>
        <ul>          
            <li>Improved Dental Health</li>
            <li>Dental Issue Prevention</li>
            <li>Happiness & No Pain</li>
            <li>Fresh Breath</li>
        </ul>
    </div>
  );
};

export default TeethPreferences;
