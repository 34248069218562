import React, { useContext } from 'react';
import "./Hotel.css";
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../StateProvider';

import hotelPageIcon from '../../../../assets/Store/Services/Hotel/hotelPageIcon.png';
import pageMainPhoto from '../../../../assets/Store/Services/pageMainPhoto.png';

import boardingIcon from '../../../../assets/Store/Services/Hotel/hotelPageBoardingIcon.png';
import daycareIcon from '../../../../assets/Store/Services/Hotel/hotelPageDaycareIcon.png';
import groomingIcon from '../../../../assets/Store/Services/Hotel/hotelPageGroomingIcon.png';
import trainingIcon from '../../../../assets/Store/Services/Hotel/hotelPageTrainingIcon.png';
import playareaIcon from '../../../../assets/Store/Services/Hotel/hotelPagePlayareaIcon.png';

import staffIcon from '../../../../assets/Store/Services/Hotel/hotelPageStaffIcon.png';
import monitoringIcon from '../../../../assets/Store/Services/Hotel/hotelPageMonitoringIcon.png';
import sanitizationIcon from '../../../../assets/Store/Services/Hotel/hotelPageSanitizationIcon.png';
import separationIcon from '../../../../assets/Store/Services/Hotel/hotelPageSeparationIcon.png';

import Footer from '../../../Footer';
import LetsGetStarted from '../../../Utilities/components/LetsGetStarted';

const Hotel = () => {
  const navigate = useNavigate();
  const { 
    loggedIn, user, setShowAddPetsModal
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    if (loggedIn) {
      const hasPets = user.familyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
      if (hasPets) {
        navigate(`/store/services/hotel/booking`);
      } else {
        setShowAddPetsModal(true);
      }
    } else {
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className='hotel-background-one-container'>
        <div className='container'>
          <div className="hotel-container">
            <img className='hotel-page-icon' src={hotelPageIcon} />
            <h2 className='hotel-page-heading'>Stay & Play: Perfect Staycation Spot</h2>
            <p className='hotel-page-paragraph'>A safe and cozy home away from home, with our experienced team ensuring the care your pets deserve.</p>
            <div className="centered-container">
              <button className="hotel-page-button" onClick={handleBookingButtonClick}>BOOK A HOTEL</button>
            </div>
            <img className='hotel-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>

      <div className='hotel-background-two-container'>
        <div className='container'>
          <h2 className='hotel-subpage-heading'>WHY CHOOSE PAWSIBLE</h2>
          <h3 className='hotel-subpage-heading-3'>Services and Amenities</h3>
          <ul>
            <li>
              <div className="hotel-services-item">
                <img className="hotel-services-item-icon" src={boardingIcon}/>
                <div className="hotel-services-item-content">
                  <h4 className="hotel-services-item-heading">Boarding</h4>
                  <p className="hotel-services-item-paragraph">
                    Spacious and cozy rooms for your pet's overnight stay, complete with comfortable bedding and toys.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="hotel-services-item">
                <img className="hotel-services-item-icon" src={daycareIcon}/>
                <div className="hotel-services-item-content">
                  <h4 className="hotel-services-item-heading">Daycare</h4>
                  <p className="hotel-services-item-paragraph">
                    A fun-filled environment where your pets can socialize and engage in playful activities.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="hotel-services-item">
                <img className="hotel-services-item-icon" src={groomingIcon}/>
                <div className="hotel-services-item-content">
                  <h4 className="hotel-services-item-heading">Grooming</h4>
                  <p className="hotel-services-item-paragraph">
                    Pamper your pets with our grooming services, including baths, haircuts, and nail trimming.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="hotel-services-item">
                <img className="hotel-services-item-icon" src={trainingIcon}/>
                <div className="hotel-services-item-content">
                  <h4 className="hotel-services-item-heading">Training</h4>
                  <p className="hotel-services-item-paragraph">
                    Our expert trainers can help your pets learn new skills and manners through positive reinforcement.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="hotel-services-item">
                <img className="hotel-services-item-icon" src={playareaIcon}/>
                <div className="hotel-services-item-content">
                  <h4 className="hotel-services-item-heading">Play Areas</h4>
                  <p className="hotel-services-item-paragraph">
                    Large, secure play areas where your pets can run, jump, and interact with other furry friends.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className='hotel-background-three-container'>
          <div className='container'>
            <h2 className='hotel-safety-heading'>WHY CHOOSE PAWSIBLE</h2>
            <h3 className='hotel-safety-heading-3'>Safety and Security Measures</h3>
              <ul>
                <li>
                  <div className="hotel-services-item">
                    <img className="hotel-services-item-icon" src={staffIcon}/>
                    <div className="hotel-services-item-content">
                      <h4 className="hotel-services-item-heading">Trained Staff</h4>
                      <p className="hotel-services-item-paragraph">
                        Our caring and trained staff are equipped to handle any situation and provide personalized attention to each pet.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="hotel-services-item">
                    <img className="hotel-services-item-icon" src={monitoringIcon}/>
                    <div className="hotel-services-item-content">
                      <h4 className="hotel-services-item-heading">24/7 Monitoring</h4>
                      <p className="hotel-services-item-paragraph">
                        Our facility is equipped with surveillance cameras and round-the-clock monitoring to ensure a safe and secure environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="hotel-services-item">
                    <img className="hotel-services-item-icon" src={sanitizationIcon}/>
                    <div className="hotel-services-item-content">
                      <h4 className="hotel-services-item-heading">Sanitization</h4>
                      <p className="hotel-services-item-paragraph">
                        We maintain strict cleanliness and hygiene protocols to prevent the spread of germs and maintain a healthy environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="hotel-services-item">
                    <img className="hotel-services-item-icon" src={separationIcon}/>
                    <div className="hotel-services-item-content">
                      <h4 className="hotel-services-item-heading">Tailored Grouping</h4>
                      <p className="hotel-services-item-paragraph">
                        To maintain a safe and enjoyable environment, we organize playgroups based on size and temperament.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='hotel-background-four-container'>
            <LetsGetStarted />
          </div>

          <Footer />
      </div>
    </>
  );
};

export default Hotel;
