export const MembershipBookingCalculator = (bookingData) => {
    const membershipPrices = {
      Basic: 600,
      Premium: 1100,
      VIP: 2000,
    };
  
    const groomingPackagePrices = {
      Basic: 150,
      Premium: 450,
      VIP: 1500,
    };
  
    let originalTotal = 0; // Keep track of the price without any discounts
    let total = 0;
  
    // Calculate price based on selected membership package
    if (bookingData.membershipOptions && bookingData.membershipOptions.selectedPackage) {
      const selectedPackage = bookingData.membershipOptions.selectedPackage;
      total += membershipPrices[selectedPackage] || 0;
      originalTotal += membershipPrices[selectedPackage] || 0;
    }
  
    // Calculate price based on selected grooming package
    if (bookingData.membershipOptions && bookingData.membershipOptions.selectedGroomingPackage) {
      const selectedGroomingPackage = bookingData.membershipOptions.selectedGroomingPackage;
      total += groomingPackagePrices[selectedGroomingPackage] || 0;
      originalTotal += groomingPackagePrices[selectedGroomingPackage] || 0;
    }
  
    return { originalPrice: originalTotal, discountedPrice: total };
  };
  
  export default MembershipBookingCalculator;
  