import React, { useState } from 'react';
import './FAQs.css'; // Ensure you have a corresponding CSS file for styling
import Footer from '../../../components/Footer';

// Import any icons you might need
import faqsIcon from '../../../assets/Pages/FAQs/faqsIcon.png';

const FAQs = () => {
  // Dummy data for FAQs - replace or expand as needed
  const faqData = [
    {
      question: 'How do I book a service for my pet?',
      answer: 'Booking a service for your pet is easy! Simply use our online booking system or download our mobile app. You can select the service, choose a convenient time, and confirm your booking in just a few clicks.'
    },
    {
      question: 'What is your cancellation policy?',
      answer: 'We understand that plans can change. You can cancel any service up to 24 hours in advance for a full refund. Cancellations made less than 24 hours before the appointment will incur a 50% fee.'
    },
    // Add more FAQs as needed
  ];

  // State to track the currently active FAQ
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle FAQ
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className='faqs-background-container'>
        <div className='container'>
          <div className="faqs-container">
            <img className='faqs-page-icon' src={faqsIcon} alt="FAQs" />
            <h2 className='faqs-page-heading'>Frequently Asked Questions</h2>
            <p className='faqs-page-paragraph'>
              Have questions? We've got answers. Explore our FAQs to learn more about our services and policies.
            </p>
          </div>
        </div>
      </div>

      <div className='faqs-accordion-container'>
        <div className='container'>
          {faqData.map((faq, index) => (
            <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
              <h3 className='faq-question'>{faq.question}</h3>
              <p className='faq-answer'>{activeIndex === index && faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FAQs;
