import React from 'react';
import './MembershipOptions.css';
import MembershipPreferences from './MembershipPreferences';
import GroomingPackagePreferences from './GroomingPackagePreferences';

const MembershipOptions = ({ selectedPetForMembership, membershipBookingData, setMembershipBookingData }) => {
  const handleOptionSelect = (question, option) => {
    switch (question) {
      case 'selectedPackage':
        setMembershipBookingData((prevData) => ({
          ...prevData,
          membershipOptions: {
            ...prevData.membershipOptions,
            selectedPackage: prevData.membershipOptions.selectedPackage === option ? '' : option,
          },
        }));
        break;
      case 'selectedGroomingPackage':
        setMembershipBookingData((prevData) => ({
          ...prevData,
          membershipOptions: {
            ...prevData.membershipOptions,
            selectedGroomingPackage: prevData.membershipOptions.selectedGroomingPackage === option ? '' : option,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleConsentChange = (e) => {
    setMembershipBookingData((prevData) => ({
      ...prevData,
      consentToSubscription: e.target.checked,
    }));
  };

  const selectedPackage = membershipBookingData.membershipOptions.selectedPackage;
  const selectedGroomingPackage = membershipBookingData.membershipOptions.selectedGroomingPackage;

  return (
    <div>
      <div className="options-container">
        <h2 className='options-header'><span style={{ color: '#FFB516' }}>{selectedPetForMembership.name}</span>'s Membership Packages</h2>

        {/* Membership Package */}
        <div className="option-group">
          <h3>Membership Packages</h3>
          <div className="options">
            {['Basic', 'Premium', 'VIP'].map((option) => (
              <button
                key={option}
                className={`option-button ${selectedPackage === option ? 'selected-package-button' : ''}`}
                onClick={() => handleOptionSelect('selectedPackage', option)}
              >
                {option}
              </button>
            ))}
          </div>
          <div className='membership-package-preferences'>
            {selectedPackage && (
              <MembershipPreferences 
                membershipBookingData={membershipBookingData} 
                setMembershipBookingData={setMembershipBookingData}
              />
            )}
          </div>
        </div>

        {/* Grooming Packages */}
        <div className="option-group">
          <h3>Grooming Packages</h3>
          <div className="options">
            {['Basic', 'Premium', 'VIP'].map((option) => (
              <button
                key={option}
                className={`option-button ${selectedGroomingPackage === option ? 'selected-package-button' : ''}`}
                onClick={() => handleOptionSelect('selectedGroomingPackage', option)}
              >
                {option}
              </button>
            ))}
          </div>
          <div className='grooming-package-preferences'>
            {selectedGroomingPackage && (
              <GroomingPackagePreferences 
                membershipBookingData={membershipBookingData} 
                setMembershipBookingData={setMembershipBookingData}
              />
            )}
          </div>

          {/* Consent to Subscription */}
          <div className="option-group">
            <h3>Subscription Consent</h3>
            <label className="consent-checkbox">
              <input 
                type="checkbox" 
                checked={membershipBookingData.consentToSubscription || false} 
                onChange={handleConsentChange} 
              />
                I authorize monthly recurring payment
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipOptions;
