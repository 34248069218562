import React, { useState, useRef, useEffect } from 'react';
import './FAQ.css';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const faqRefs = useRef([]);

    const faqs = [
        {
            question: "What is Pawsible?",
            answer: "Pawsible is a comprehensive pet service provider offering daycare, boarding, grooming, training, adventures, transportation, and more for your pets."
        },
        {
            question: "How can I book a service?",
            answer: "You can book a service through our web application. Just select the service you need from the dashboard and follow the booking instructions."
        },
        {
            question: "What are your operational hours?",
            answer: "We are open 24/7 for Boarding service. Daycare, Grooming and Training services are available from 7 AM to 7 PM."
        },
        {
            question: "What safety measures are in place for pets?",
            answer: "We have certified staff, regular health check-ups, and a secure environment to ensure the safety and well-being of your pets."
        },
        {
            question: "How can I contact support?",
            answer: "You can contact support via phone at 213-600-7282, email at support@pawsible.app, or use the live chat feature."
        },
        {
            question: "Do you offer services for all types of pets?",
            answer: "No, we only offer services for dogs and cats"
        },
        {
            question: "Are your staff qualified to handle pets?",
            answer: "Yes, all our staff members are trained and certified to handle pets with care and professionalism."
        },
        {
            question: "What should I bring for my pet's stay?",
            answer: "Any medications and comfort items such as a favorite toy or blanket your pet needs."
        },
        {
            question: "How do I know my pet will be safe and happy?",
            answer: "We prioritize your pet's safety and happiness with regular check-ups, secure environments, and activities to keep them engaged."
        },
        {
            question: "Can I check on my pet during their stay?",
            answer: "Yes, you can check on your pet through our video feeds available on social media, or contact our staff for updates."
        },
        {
            question: "What if my pet has special needs?",
            answer: "We cater to pets with special needs. Please provide detailed information about your pet's requirements during booking."
        },
        {
            question: "Do you provide pet transportation?",
            answer: "Yes, we offer safe and comfortable transportation services for your pets, including shuttle services for daycare, boarding, grooming, and even airport rides."
        },
        {
            question: "What types of training programs do you offer?",
            answer: "We offer a range of training programs including basic obedience, advanced training, and behavior modification sessions. The classes are held in a group or a private session."
        },
        {
            question: "How do I cancel or reschedule a booking?",
            answer: "You can cancel or reschedule a booking through our web application or by contacting our support team."
        },
        {
            question: "Do you offer any membership or loyalty programs?",
            answer: "Yes, we offer membership programs with various benefits such as discounts, priority booking, and exclusive events."
        },
        {
            question: "What are your payment options?",
            answer: "We accept various payment options including credit/debit cards, online payments, and Pawsible gift cards."
        },
        {
            question: "How can I provide feedback about your services?",
            answer: "We welcome your feedback! You can provide feedback through our app, website, or by contacting our support team."
        }
    ];

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleClickOutside = (event) => {
        if (faqRefs.current && !faqRefs.current.some(ref => ref && ref.contains(event.target))) {
            setOpenIndex(null);
        }
    };

    useEffect(() => {
        if (openIndex !== null) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openIndex]);

    return (
        <div className="faq-component">
            {faqs.map((faq, index) => (
                <div
                    key={index}
                    className="faq-item"
                    ref={el => (faqRefs.current[index] = el)}
                >
                    <h4 className="faq-question" onClick={() => handleClick(index)}>
                        {faq.question}
                    </h4>
                    {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
                </div>
            ))}
        </div>
    );
};

export default FAQ;
