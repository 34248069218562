import React from 'react';

const BasicGroomingPreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  return (
    <div className="basic-grooming-preferences">
      <h3>Basic Grooming Benefits</h3>
      <ul>
        <li>Daily Teeth Brushing</li>
        <li>1 Full Grooming</li>
        <li>1 Full Bath (Flea & Tick Treatment)</li>
      </ul>
    </div>
  );
};

export default BasicGroomingPreferences;
