import React from 'react';
import './SelectedCardDetails.css';

function SelectedCardDetails({ adventure }) {
  return (
    <div className="selected-card-details">
      <h2 className='selected-card-details-header2'>{adventure.title} </h2>
      <img src={adventure.image} alt={adventure.title} className='selected-card-details-image' />
      {/* <p className='selected-card-details-date'>Date: {adventure.date}</p> */}
      <p className='selected-card-details-description'>{adventure.description}</p>

      {/* Render the "What's Included" list */}
      <div className="adventure-section">
        <h3 className='selected-card-details-header3'>What's Included:</h3>
        <ul>
          {adventure.fullDescription.included.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      {/* Render the "Safety and Security Measures" list */}
      <div className="adventure-section">
        <h3 className='selected-card-details-header3'>Safety and Security Measures:</h3>
        <ul>
          {adventure.fullDescription.safetyAndSecurity.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      {/* Render the "Group Size" */}
      <div className="adventure-section">
        <h3 className='selected-card-details-header3'>Group Size:</h3>
        <p className='selected-card-details-groupSize'>{adventure.fullDescription.groupSize}</p>
      </div>
    </div>
  );
}

export default SelectedCardDetails;
