import React from 'react';

const VIPGroomingPreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  return (
    <div className="vip-grooming-preferences">
      <h3>VIP Grooming Benefits</h3>
      <ul>
        <li>Deep Teeth Cleaning every 3 months</li>
        <li>Daily Teeth Brushing</li>
        <li>Daily Deshedding/Brushing</li>
        <li>Advanced Paw Care</li>
        <li>Unlimited Full Groomings</li>
        <li>Unlimited Full Baths (Flea & Tick Treatment)</li>
      </ul>
    </div>
  );
};

export default VIPGroomingPreferences;
