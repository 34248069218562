import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../StateProvider';
import { fetchSuggestedFriends } from '../../../../Utilities/api';
import './Friends.css';

import arrowIcon from '../../../../../assets/Utilities/arrowIcon.png';
import blueArrowIcon from '../../../../../assets/Utilities/blueArrowIcon.png';
import tokenIcon from '../../../../../assets/Utilities/tokenIcon.png';

import profilePlaceholder1 from '../../../../../assets/Dashboard/Profile/profilePlaceholder-3.png';
import profilePlaceholder2 from '../../../../../assets/Dashboard/Profile/profilePlaceholder-4.png';
import profilePlaceholder3 from '../../../../../assets/Dashboard/Profile/profilePlaceholder-5.png';

import { XButton } from '../../../../Utilities/components/Buttons';

const Friends = () => {
  const navigate = useNavigate();
  const { user } = useContext(StateContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [friendsList, setFriendsList] = useState([]);
  const [suggestedFriends, setSuggestedFriends] = useState([]);
  
  useEffect(() => {
    const getSuggestedFriends = async () => {
      try {
        const friends = await fetchSuggestedFriends(user.uid);
        setSuggestedFriends(friends);
      } catch (error) {
        console.error('Error fetching suggested friends:', error);
      }
    };

    getSuggestedFriends();
  }, [user]);

  const handleSearch = () => {
    // Logic to search for friends by owner or pet name
    const filteredFriends = suggestedFriends.filter(
      friend => 
        friend.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        friend.petName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFriendsList(filteredFriends);
  };

  return (
    <div>
        <div className='friends-backdrop'>
            <XButton />
            <div className="friends-container">
              <div className='friends-search-container'>
                  <input
                      type="text"
                      placeholder="Search Owner/Pet"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className='friends-search-input'
                  />
                  <button onClick={handleSearch} className='friends-search-button'>Search</button>
                </div>
            </div>

            <div className="friends-container">
                <div className='friends-card-container-one'>
                    <h3 className='friends-card-header'>Friend Suggestions<img className='friends-arrow-image' src={arrowIcon} alt="See Funds" /></h3>
                    <p className='friends-card-paragraph'>Friendships your pet made while playing at Pawsible.</p>
                    <div className='friends-card-token-container'>
                      {user && user.suggestedFriends && user.suggestedFriends.length > 0 ? (
                        user.suggestedFriends.map((friend) => (
                          <img key={friend.id} src={friend.image} className='friends-card-token-image' alt={friend.name} />
                        ))
                      ) : (
                        <p className='friends-suggestion-list'>Try out daycare to find new friends!</p>
                      )}
                    </div>
                    <hr className='friends-breakline'/>
                    <p className='friends-card-history-paragraph'>View your history</p>
                </div>
            </div>

            <div className='friends-container'>
                <div className='friends-card-container-two' onClick={() => navigate(`/store/deals/invite`)}>
                    <h3 className='friends-refer-header'>Refer & earn!</h3>
                    <p className='friends-refer-paragraph'>Invite friends to Pawsible, earn rewards up to $425.</p>
                    <p className='friends-refer-learn-paragraph'>Learn more<img className='wallet-arrow-image' src={blueArrowIcon} alt="Learn More" /></p>
                </div>
            </div>
        </div>
  
        <div className='friends-container-two'>
            <h3 className='friends-list-header'>Friends</h3>
            {user && user.userFriends && user.userFriends.length > 0 ? (
              user.userFriends.map((friend) => (
                <div key={friend.id} className="friends-list-friend">
                  <img className='friends-list-image' src={friend.image} alt={friend.petName} />
                  <p className='friends-list-name'>{friend.petName}</p>
                </div>
              ))
            ) : (
              <p className='friends-list-empty'>Start adding friends from the suggestions!</p>
            )}
        </div>

    </div>
  );
};

export default Friends;