import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './SmartSearchBar.css';

const API_BASE_URL = `https://www.pawsible.app` || 'http://localhost:6060';
// const API_BASE_URL = 'http://localhost:6060';

const SmartSearchBar = ({ placeholder, onSelect, type }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(-1);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSuggestions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const handleChange = async (e) => {
        const value = e.target.value;
        setQuery(value);

        if (value.length > 2 && type === 'location') {
            const response = await axios.get(`${API_BASE_URL}/api/places`, {
                params: {
                    input: value,
                    options: {
                        componentRestrictions: { country: "us" },
                    },
                },
            });

            setSuggestions(response.data.predictions);
        } else if (value.length > 2 && type === 'airport') {
            const response = await axios.get(`${API_BASE_URL}/api/airports`, {
                params: {
                    input: value,
                    options: {
                        types: 'airport',
                        componentRestrictions: { country: "us" },
                    },
                },
            });

            setSuggestions(response.data.predictions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSelect = (suggestion) => {
        setQuery(suggestion.description);
        setSuggestions([]);
        onSelect(suggestion);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setActiveSuggestion((prev) => (prev < suggestions.length - 1 ? prev + 1 : prev));
        } else if (e.key === 'ArrowUp') {
            setActiveSuggestion((prev) => (prev > 0 ? prev - 1 : prev));
        } else if (e.key === 'Enter' && activeSuggestion !== -1) {
            handleSelect(suggestions[activeSuggestion]);
        } else if (e.key === 'Escape') {
            setSuggestions([]);
        }
    };

    return (
        <div className="smart-search-bar" ref={wrapperRef}>
            <input
                type="text"
                value={query}
                placeholder={placeholder}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoComplete="new-password" // Additional attribute
                aria-autocomplete="none" // Additional attribute
            />
            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={suggestion.place_id}
                            className={index === activeSuggestion ? 'active' : ''}
                            onClick={() => handleSelect(suggestion)}
                        >
                            {suggestion.description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SmartSearchBar;