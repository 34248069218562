import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchBooking } from '../../../../Utilities/api';
import axios from 'axios';
import { BackButton } from '../../../../Utilities/components/Buttons';

const BookingDetails = () => {
    const { id } = useParams();
    const [booking, setBooking] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
          fetchBooking(id)
              .then(response => {
                  setBooking(response);
            })
              .catch(error => {
                  console.error('Failed to fetch booking details:', error);
            });
        }
      }, [id]);

    const handleReschedule = async (newDate) => {
        // Logic to reschedule the booking
        try {
            await axios.patch(`http://localhost:6060/api/booking/${id}`, { startTime: newDate });
            fetchBooking();  // Refresh the booking details
        } catch (error) {
            console.error('Failed to reschedule:', error);
        }
    };

    const handleCancel = async () => {
        // Logic to cancel the booking
        try {
            await axios.patch(`http://localhost:6060/api/booking/${id}`, { status: 'Cancelled' });
            navigate('/bookings');  // Redirect or refresh the list
            window.scrollTo(0, 0);
        } catch (error) {
            console.error('Failed to cancel:', error);
        }
    };

    return (
      <div className='container'>
        <BackButton />
        {booking ? (
          <div>
            <h3>Booking Details</h3>
            <p>Service: {booking.bookingType}</p>
            <p>Client: {booking.User.username}</p>
            <p>Pet: {booking.FamilyMember.name} ({booking.FamilyMember.type})</p>
            <p>Start Time: {new Date(booking.startTime).toLocaleString()}</p>
            <p>End Time: {new Date(booking.endTime).toLocaleString()}</p>
            <p>Price: ${booking.originalPrice} (Discounted Price: ${booking.discountedPrice})</p>
            <p>Status: {booking.status}</p>
            <p>Notes: {booking.specialNotes}</p>
            <button onClick={() => handleReschedule('2024-06-01T12:00:00.000Z')}>Reschedule</button>
            <button onClick={handleCancel}>Cancel Booking</button>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
};

export default BookingDetails;
