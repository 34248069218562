import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import './Registration.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import Footer from '../../Footer';
import LetsGetStarted from '../../Utilities/components/LetsGetStarted';

const Registration = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!name || !email || !password) {
      setError('Please fill in all fields');
      return;
    }

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setSuccess(true);
        setError('');
        console.log("User registered:", userCredential.user);
      })
      .catch((error) => {
        setError('Failed to create an account: ' + error.message);
    });
  };

  return (
    <>
      <Container className="register-container">
        <h5 className="register-header">Welcome, Paw Lovers!</h5>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Registration successful!</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="register-name" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="register-email" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="register-password" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="register-confirmPassword" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </Form.Group>
          <Button type="submit" className="register-button">
            CREATE
          </Button>
        </Form>
      </Container>

      <section className='sign-up-section'>
        <LetsGetStarted />
      </section>

      <Footer />
    </>
  );
};

export default Registration;
