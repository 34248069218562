import React, { useContext } from 'react';
import './Shuttle.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../StateProvider';

import groomingPageIcon from '../../../../assets/Store/Services/Grooming/groomingPageIcon.png';
import pageMainPhoto from '../../../../assets/Store/Services/pageMainPhoto.png';

import Footer from '../../../Footer';
import LetsGetStarted from '../../../Utilities/components/LetsGetStarted';

const Shuttle = () => {
  const navigate = useNavigate();
  const { 
    loggedIn, user, setShowAddPetsModal
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    if (loggedIn) {
      const hasPets = user.familyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
      if (hasPets) {
        navigate(`/store/services/shuttle/booking`);
      } else {
        setShowAddPetsModal(true);
      }
    } else {
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className='transportation-background-one-container'>
        <div className='container'>
          <div className="transportation-container">
            <img className='transportation-page-icon' src={groomingPageIcon} />
            <h2 className='transportation-page-heading'>Pawsible Transports: Ensuring Comfort in Every Journey</h2>
            <p className='transportation-page-paragraph'>Safe, comfortable, and reliable transportation for your pets en route to their adventures.</p>
            <div className="centered-container">
              <button className="transportation-page-button" onClick={handleBookingButtonClick}>BOOK A RIDE</button>
            </div>
            <img className='transportation-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>  

      <div className='transportation-background-two-container'>
        <div className='container'>
          <h2 className='transportation-subpage-heading'>WHY CHOOSE PAWSIBLE TRANSPORTS</h2>
          <h3 className='transportation-subpage-heading-3'>Shuttle Features</h3>
          <ul>
            <li>
              <div className="transportation-services-item">
                {/* <img className="transportation-services-item-icon" src={comfortIcon}/> */}
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Comfortable Cabins</h4>
                  <p className="transportation-services-item-paragraph">
                    Spacious and cozy cabins to ensure a relaxed journey for your pets.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                {/* <img className="transportation-services-item-icon" src={staffIcon}/> */}
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Experienced Staff</h4>
                  <p className="transportation-services-item-paragraph">
                    Our team is trained in pet care and safety, ensuring a smooth ride every time.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                {/* <img className="transportation-services-item-icon" src={scheduleIcon}/> */}
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Convenient Schedule</h4>
                  <p className="transportation-services-item-paragraph">
                    Offering flexible pick-up and drop-off times to suit your convenience.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='transportation-background-three-container'>
        <div className='container'>
          <h2 className='transportation-safety-heading'>SAFETY IS OUR PRIORITY</h2>
          <h3 className='transportation-safety-heading-3'>Transportation Safety</h3>
          <ul>
            <li>
              <div className="transportation-services-item">
                {/* <img className="transportation-services-item-icon" src={safetyIcon}/> */}
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Safe Travel</h4>
                  <p className="transportation-services-item-paragraph">
                    Safety measures in place to ensure secure transport in all conditions.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                {/* <img className="transportation-services-item-icon" src={hygieneIcon}/> */}
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Hygiene</h4>
                  <p className="transportation-services-item-paragraph">
                    Adhering to the highest standards of cleanliness and hygiene in all our shuttles.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                {/* <img className="transportation-services-item-icon" src={emergencyIcon}/> */}
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Emergency Preparedness</h4>
                  <p className="transportation-services-item-paragraph">
                    Equipped and ready to manage any emergencies, ensuring the utmost safety of your pets.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='transportation-background-four-container'>
        <LetsGetStarted />
      </div>

      <Footer />


      {/* <div className="adventure-container">
        <h2>Adventure Description:</h2>
        <p>Embark on exciting adventures with Pawsible Adventure! Our adventure programs are tailored to provide outdoor exploration and fun-filled activities for your pets. Whether it's hiking through scenic trails, playing at the beach, or exploring the great outdoors, your furry companions are sure to have a thrilling experience.</p>

        <h2>Adventure Activities:</h2>
        <ul>
          <li>Hiking Excursions: Join our guided hiking trips where your dogs can explore nature and enjoy the fresh air and breathtaking views.</li>
          <li>Beach Playtime: Take your pets to the beach for a splash in the waves and a playful romp on the sandy shores.</li>
          <li>Park Adventures: Our park outings offer ample space for dogs to run, play fetch, and interact with other friendly pups.</li>
        </ul>

        <h2>Adventure Safety:</h2>
        <ul>
          <li>Leash Policy: During adventures, we ensure pets are safely leashed to prevent any accidents and maintain control.</li>
          <li>Group Size: To maintain a safe and enjoyable experience, we limit the group size for each adventure.</li>
          <li>Preparation: We provide essentials like water, treats, and waste bags to keep your pets comfortable throughout the journey.</li>
        </ul>

        <p>With Pawsible Adventure, your pets can explore new horizons, make furry friends, and create unforgettable memories. Join us on our next adventure and let your pets experience the joy of outdoor exploration!</p>
      </div> */}

    </>
  );
};

export default Shuttle;