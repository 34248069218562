import React from 'react';
import './TrainingOptions.css';
import TrainingPreferences from './TrainingPreferences';

const TrainingOptions = ({ selectedPetForTraining, trainingBookingData, setTrainingBookingData }) => {

  const handleOptionSelect = (question, option) => {
    switch (question) {
      case 'trainingType':
        setTrainingBookingData((prevData) => ({
          ...prevData,
          trainingOptions: {
            ...prevData.trainingOptions,
            selectedTrainingType: option,
          },
        }));
        break;
      case 'sessionType':
        setTrainingBookingData((prevData) => ({
          ...prevData,
          trainingOptions: {
            ...prevData.trainingOptions,
            selectedSessionType: option,
          },
        }));
        break;
      // Add more cases for other options specific to training
      default:
        break;
    }
  };

  const selectedTrainingType = trainingBookingData.trainingOptions.selectedTrainingType;
  const selectedSessionType = trainingBookingData.trainingOptions.selectedSessionType;

  return (
    <div>
      <div className="options-container">
        <h2 className='options-header'><span style={{ color: '#FFB516' }}>{selectedPetForTraining.name}</span>'s Training Instructions</h2>
      
        {/* Training Type */}
        <div className='training-list-style'>
          <div className="option-group">
            <h3>Training Type</h3>
            <div className="options">
              <button
                className={`option-button ${selectedTrainingType === 'puppy' ? 'selected-training-type-button' : ''}`}
                onClick={() => handleOptionSelect('trainingType', 'puppy')}
                disabled={selectedTrainingType === 'puppy'}
              >
                Puppy Training
              </button>
              <button
                className={`option-button ${selectedTrainingType === 'basic' ? 'selected-training-type-button' : ''}`}
                onClick={() => handleOptionSelect('trainingType', 'basic')}
                disabled={selectedTrainingType === 'basic'}
              >
                Basic Obedience
              </button>
              <button
                className={`option-button ${selectedTrainingType === 'behavior' ? 'selected-training-type-button' : ''}`}
                onClick={() => handleOptionSelect('trainingType', 'behavior')}
                disabled={selectedTrainingType === 'behavior'}
              >
                Behavior Modification
              </button>
              <button
                className={`option-button ${selectedTrainingType === 'akc' ? 'selected-training-type-button' : ''}`}
                onClick={() => handleOptionSelect('trainingType', 'akc')}
                disabled={selectedTrainingType === 'akc'}
              >
                AKC Canine Good Citizen
              </button>
            </div>
          </div>

          <div className='option-group'>
            <TrainingPreferences 
              trainingBookingData={trainingBookingData} 
              setTrainingBookingData={setTrainingBookingData}
            />
          </div>

          {/* Session Type */}
          <div className="option-group">
            <h3>Session Type</h3>
            <div className="options">
              <button
                className={`option-button ${selectedSessionType === 'group' ? 'selected-session-type-button' : ''}`}
                onClick={() => handleOptionSelect('sessionType', 'group')}
                disabled={selectedTrainingType === 'behavior' || selectedTrainingType === 'akc'}
              >
                Group Session
              </button>
              <button
                className={`option-button ${selectedSessionType === 'private' ? 'selected-session-type-button' : ''}`}
                onClick={() => handleOptionSelect('sessionType', 'private')}
              >
                Private Session
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingOptions;
