import React from 'react';
import './Locations.css'; // Ensure you have a corresponding CSS file for styling
import Footer from '../../../components/Footer';

// Optionally import a map or locations icon
import locationsIcon from '../../../assets/Pages/Locations/locationsIcon.png';
import pageMainPhoto from '../../../assets/Store/Services/pageMainPhoto.png';
import LetsGetStarted from '../../Utilities/components/LetsGetStarted';

import addressIcon from '../../../assets/Utilities/addressIcon.png';
import phoneIcon from '../../../assets/Utilities/phoneIcon.png';

const Locations = () => {
  // This could be replaced with real data fetched from an API
  const locationsList = [
    {
      name: 'Los Angeles, CA',
      addressOne: '555 N Spring St. A111',
      addressTwo: 'Los Angeles, CA 90012',
      phone: '213-600-7282',
    },
    {
      name: 'Anaheim, CA',
      addressOne: '1151 N Euclid St. Suite K',
      addressTwo: 'Anaheim, CA 92801',
      phone: '213-600-7282',
    },
    {
      name: 'Manhattan Beach, CA',
      addressOne: 'COMING SOON',
    },
    // Add as many locations as needed
  ];

  return (
    <>
      <div className='locations-background-one-container'>
        <div className='container'>
          <div className="locations-container">
            <img className='locations-page-icon' src={locationsIcon} alt="Our Locations" />
            <h2 className='locations-page-heading'>Pawsible: Pet's Home Away From Home</h2>
            <p className='locations-page-paragraph'>
              Conveniently located, offering a full range of services to keep your pet happy, healthy, and safe.
            </p>
            <ul className='locations-list'>
              {locationsList.map((location, index) => (
                <li key={index} className='location-item'>
                  <h3 className='location-item-name'>{location.name}</h3>
                  <p className='location-item-address-one'><img src={addressIcon} alt="Address Icon" className="support-icon" /> {location.addressOne}</p>
                  {location.addressTwo ? <p className='location-item-address-two'>{location.addressTwo}</p> : null}
                  {location.phone ? <p><img src={phoneIcon} alt="Phone Icon" className="support-icon" /> {location.phone}</p> : null}
                  {/* You can add a link to a detailed page or Google Maps */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className='locations-background-two-container'>
        <LetsGetStarted />
      </div>

      <Footer />
    </>
  );
};

export default Locations;
