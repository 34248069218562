export const GroomingBookingCalculator = (bookingData, serviceDetails) => {
  const groomingPrices = serviceDetails.grooming ? serviceDetails.grooming[0].additionalPrices : {};
  let originalTotal = 0; // Keep track of the price without any discounts
  let total = 0;

  // Check if grooming options are selected
  if (bookingData.groomingOptions && bookingData.groomingOptions.selectedSize && bookingData.groomingOptions.selectedPackage) {
    const selectedSize = bookingData.groomingOptions.selectedSize;
    const selectedPackage = bookingData.groomingOptions.selectedPackage;

    // Calculate base price based on pet size and selected package
    total += groomingPrices[selectedSize][selectedPackage] || 0;
    originalTotal += groomingPrices[selectedSize][selectedPackage] || 0;

    // Add additional charges for selected additional services under grooming options
    if (bookingData.groomingOptions.additionalServices && groomingPrices[selectedSize].additionalServices) {
      const additionalServices = bookingData.groomingOptions.additionalServices;
      additionalServices.forEach((service) => {
        if (groomingPrices[selectedSize].additionalServices[service]) {
          total += groomingPrices[selectedSize].additionalServices[service];
          originalTotal += groomingPrices[selectedSize].additionalServices[service];
        }
      });
    }

    // Check if a haircut style is selected
    if (bookingData.groomingOptions.selectedHaircutStyle) {
      const selectedHaircutStyle = bookingData.groomingOptions.selectedHaircutStyle;
      const hairStylePrice = serviceDetails.grooming[0].additionalPrices.hairStylePrices[selectedHaircutStyle];

      // Add price for the selected haircut style
      if (hairStylePrice) {
        total += hairStylePrice;
        originalTotal += hairStylePrice;
      }
    }

    // Add charges for special conditions
    if (bookingData.groomingOptions.mattedHair) {
      total += 20;
      originalTotal += 20;
    }

    if (bookingData.groomingOptions.skunkTreatment) {
      total += 50;
      originalTotal += 50;
    }
  }

  // Add $20 charge for leash walking if selected
  if (bookingData.additionalServices && bookingData.additionalServices.walk) {
    total += 20;
    originalTotal += 20;
  }

  return { originalPrice: originalTotal, discountedPrice: total };
};

export default GroomingBookingCalculator;
