import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav } from 'react-bootstrap';
import './Dashboard.css';
import { StateContext } from '../StateProvider';
import BookingDashboard from './BookingDashboard';
import AdminDashboard from '../Console/components/AdminDashboard';

import profileIcon from '../../assets/Dashboard/profileIcon.png';
import storeIcon from '../../assets/Dashboard/storeIcon.png';
import eventIcon from '../../assets/Dashboard/eventIcon.png';
import supportIcon from '../../assets/Dashboard/supportIcon.png';

const DashboardContent = () => {
  const location = useLocation();
  const { user, cart } = useContext(StateContext);
  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    if (Array.isArray(cart)) {
      setCartItemCount(cart.length);
    }
  }, [user])

  // Determine which dashboard to render based on route
  if (location.pathname.includes('/booking') || location.pathname.includes('/store/membership') || location.pathname.includes('/store/services/gift-card-purchase')) {
    return <BookingDashboard />;
  }
  if (location.pathname.includes('/admin')) {
    return <AdminDashboard />;
  }

  // Default content
  return (
    <Nav className="dashboard-menu">
      <Nav.Link as={Link} to="/profile">
        <img className="dashboard-icon" src={profileIcon} alt="Profile" />
        <p className='dashboard-title'>Profile</p>
      </Nav.Link>
      <Nav.Link as={Link} to="/profile/cart" className="cart-icon-link">
        <div className="cart-icon-container">
          <img className="dashboard-icon" src={storeIcon} alt="Cart" />
          {cartItemCount > 0 && (
            <span className="cart-item-count">{cartItemCount}</span>
          )}
        </div>
        <p className='dashboard-title'>Cart</p>
      </Nav.Link>
      <Nav.Link as={Link} to="/events">
        <img className="dashboard-icon" src={eventIcon} alt="Events" />
        <p className='dashboard-title'>Events</p>
      </Nav.Link>
      <Nav.Link as={Link} to="/support">
        <img className="dashboard-icon" src={supportIcon} alt="Support" />
        <p className='dashboard-title'>Chat</p>
      </Nav.Link>
    </Nav>
  );
};

const Dashboard = () => {
  useEffect(() => {
    document.body.style.marginBottom = '140px';
    return () => {
      document.body.style.marginBottom = '0';
    };
  }, []);

  return (
    <footer className="dashboard">
      <Container className='dashboard-container'>
        <DashboardContent />
      </Container>
    </footer>
  );
};

export default Dashboard;
