import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Console.css';
import { StateContext } from '../StateProvider';
import { BackButton } from '../Utilities/components/Buttons';

function Console() {
    const { role } = useContext(StateContext);
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <div className="container">
            <BackButton />
            <h1 className='console-header'>Admin Console</h1>
            <div className="console-sections">
                <div className="console-section" onClick={() => handleNavigate('/admin/schedule-calendar')}>
                    Booking
                </div>
                <div className="console-section" onClick={() => handleNavigate('/admin/staff-manager')}>
                    Staff
                </div>
                <div className="console-section" onClick={() => handleNavigate('/admin/customer-manager')}>
                    Customer
                </div>
                <div className="console-section" onClick={() => handleNavigate('/admin/inventory-manager')}>
                    Inventory
                </div>
                <div className="console-section" onClick={() => handleNavigate('/admin/service-config')}>
                    Service Config
                </div>
                {/* Additional sections can be added here */}
            </div>
        </div>
    );
}

export default Console;
