import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { tooltips } from './../shuttleTooltips';
import './ShuttleOptions.css';
import AirportSelection from './ServiceSelection/AirportSelection';
import DaycareSelection from './ServiceSelection/DaycareSelection';
import HotelSelection from './ServiceSelection/HotelSelection';
import GroomingSelection from './ServiceSelection/GroomingSelection';
import AdventureSelection from './ServiceSelection/AdventureSelection';
import TrainingSelection from './ServiceSelection/TrainingSelection';

const ShuttleOptions = ({ user, selectedService, shuttleBookingData, setShuttleBookingData }) => {
    const [tooltipVisibility, setTooltipVisibility] = useState({
        petSelection: false,
        appointmentDate: false,
        groomingPreferences: false,
    });    

    const showTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: true });
    };
    
    const hideTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: false });
    };

    // Can select multiple pets
    const togglePetSelection = (petId, petName) => {
        setShuttleBookingData(prevBookingData => {
            let newSelectedPets;
    
            // Check if the petId is already included in the selectedPets array
            const isAlreadySelected = prevBookingData.selectedPets.some(selectedPet => selectedPet.petId === petId);
    
            if (isAlreadySelected) {
                // Filter out the petId if it's already selected
                newSelectedPets = prevBookingData.selectedPets.filter(selectedPet => selectedPet.petId !== petId);
            } else {
                // Add a new object with petId and petName if it's not already selected
                newSelectedPets = [...prevBookingData.selectedPets, { petId, petName }];
            }
    
            return {
                ...prevBookingData,
                selectedPets: newSelectedPets
            };
        });
    };

  // This is an example, you would have to create relevant options for each service
  const selectedOptions = shuttleBookingData.shuttleOptions || {};

  return (
    <div>
      <div className="options-container">
        {/* <h2 className='options-header'>Shuttle Options for <span style={{ color: '#FFB516' }}>{selectedPetForShuttle.name}</span></h2> */}

        <div className="option-group">
            <h2>
                Passengers <FaInfoCircle onMouseOver={() => showTooltip('who')} onMouseOut={() => hideTooltip('who')} />
            </h2>
            {tooltipVisibility.who && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.who }}></div>}
            <div className="pet-selection-options">
                {user && user.familyMembers && user.familyMembers.map(pet => (
                    (selectedService === 'Airport' || pet.type === 'Dog' || pet.type === 'Cat') && (
                        <div key={pet.name}>
                            <button 
                                className={`pet-button ${shuttleBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id) ? 'pet-button-selected' : ''}`} 
                                onClick={() => togglePetSelection(pet.id, pet.name)}
                            >
                                <img src={pet.image} alt={pet.name} className="pet-photo" />
                                <p className='pet-name'>{pet.name}</p>
                                <p className='pet-type'>{pet.type}</p>
                            </button>
                        </div>
                    )
                ))}
            </div>
        </div>
      
        {/* Service Specific Options */}
        {
            selectedService === 'Airport' && (
                <AirportSelection 
                    familyData={user}
                    selectedService={selectedService}
                    shuttleBookingData={shuttleBookingData}
                    setShuttleBookingData={setShuttleBookingData}
                />)
        }
        {
            selectedService === 'Daycare' && (
                <DaycareSelection 
                    familyData={user}
                    selectedService={selectedService}
                    shuttleBookingData={shuttleBookingData}
                    setShuttleBookingData={setShuttleBookingData}
                />)
        }
        {
            selectedService === 'Hotel' && (
                <HotelSelection 
                    familyData={user}
                    selectedService={selectedService}
                    shuttleBookingData={shuttleBookingData}
                    setShuttleBookingData={setShuttleBookingData}
                />)
        }
        {
            selectedService === 'Grooming' && (
                <GroomingSelection 
                    familyData={user}
                    selectedService={selectedService}
                    shuttleBookingData={shuttleBookingData}
                    setShuttleBookingData={setShuttleBookingData}
                />)
        }
        {
            selectedService === 'Training' && (
                <TrainingSelection 
                    familyData={user}
                    selectedService={selectedService}
                    shuttleBookingData={shuttleBookingData}
                    setShuttleBookingData={setShuttleBookingData}
                />)
        }
        {
            selectedService === 'Adventure' && (
                <AdventureSelection 
                    familyData={user}
                    selectedService={selectedService}
                    shuttleBookingData={shuttleBookingData}
                    setShuttleBookingData={setShuttleBookingData}
                />)
        }

      </div>
    </div>
  );
};

export default ShuttleOptions;
