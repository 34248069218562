import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className='container'>
            <div className="terms-and-conditions">
                <h2>Terms of Service</h2>
                <h3>Introduction</h3>
                <p>
                    <strong>Welcome to Pawsible</strong>, a premier pet care service proudly operating under Pixel Perfect, LLC. We are dedicated to providing the highest quality of care and a wide range of services for your beloved pets. Our commitment is to ensure a safe, enjoyable, and nurturing environment for your furry family members while they are in our care.
                </p>
                <p>
                    At <strong>Pawsible</strong>, we understand that your pets are more than just animals; they are integral members of your family. That's why we offer a variety of services including pet daycare, boarding, grooming, training, adventure excursions, and transportation, all tailored to meet the unique needs and preferences of your pets.
                </p>

                <h3>1.1 Purpose of the Agreement</h3>
                <p>
                    This Client Service Agreement is designed to outline the terms and conditions under which our services are provided. It serves as a mutual understanding between you, the pet owner, and <strong>Pawsible</strong>, ensuring transparency and clarity in our service delivery. This agreement covers all aspects of our interaction, from the care and handling of your pets to financial arrangements and liability considerations.
                </p>
                <p>
                    The intent of this agreement is to foster a trusting and reliable relationship between you and our team at <strong>Pawsible</strong>. By establishing clear guidelines and expectations, we aim to provide peace of mind and a positive experience for both you and your pets.
                </p>

                <h3>1.2 Acceptance of Terms</h3>
                <p>
                    By utilizing the services of <strong>Pawsible</strong>, you acknowledge and agree to the terms and conditions set forth in this agreement. It is important that you read and understand each aspect of this agreement, as it constitutes a legally binding arrangement between you (the pet owner) and Pixel Perfect, LLC, doing business as <strong>Pawsible</strong>.
                </p>
                <p>
                    Your continued use of our services signifies your acceptance of these terms and your agreement to adhere to them. Should you have any questions or need clarification on any part of this agreement, we encourage you to reach out to our team for assistance.
                </p>

                <h3>2. Description of Services</h3>
                <p>
                    At <strong>Pawsible</strong>, we offer a comprehensive range of services tailored to meet the needs of your pets. Our services are designed to provide your pets with care, enrichment, and enjoyment in a safe and nurturing environment.
                </p>
                <h3>2.1 Pet Daycare</h3>
                <p>
                    Our pet daycare service offers a fun, social, and stimulating environment for your pet during the daytime. This service includes supervised playtime, socialization with other pets, rest periods, and individual attention from our caring staff. We ensure that each pet receives the right balance of activity and relaxation to meet their individual needs.
                </p>
                <h3>2.2 Pet Boarding</h3>
                <p>
                    When you need to be away, <strong>Pawsible</strong>'s pet boarding service provides a comfortable and secure home away from home for your pet. Our boarding facilities are designed to ensure the comfort and safety of your pets, with clean, spacious accommodations and 24/7 supervision. We provide regular exercise, playtime, and individual care to each boarding pet.
                </p>
                <ul>
                    <li><strong>Comfortable Accommodations:</strong> Clean, spacious, and secure living spaces.</li>
                    <li><strong>24/7 Supervision:</strong> Constant care and oversight by our professional team.</li>
                    <li><strong>Exercise and Playtime:</strong> Regularly scheduled physical activities and play.</li>
                    <li><strong>Personalized Care:</strong> Attention to individual dietary and health needs.</li>
                </ul>
                <h3>2.3 Grooming Services</h3>
                <p>
                    Our professional grooming services cater to the hygiene and appearance of your pet. From baths and haircuts to nail trimming and ear cleaning, our experienced groomers are equipped to handle all your pet's grooming needs. We use gentle, pet-friendly products and techniques to ensure your pet has a positive grooming experience.
                </p>
                <ul>
                    <li><strong>Bathing and Haircuts:</strong> Using gentle, pet-friendly products.</li>
                    <li><strong>Nail Trimming:</strong> Safe and careful trimming for comfort and health.</li>
                    <li><strong>Ear Cleaning:</strong> Maintaining ear hygiene and health.</li>
                    <li><strong>Stress-free Experience:</strong> Ensuring a calm and positive grooming session.</li>
                </ul>
                <h3>2.4 Training Programs</h3>
                <p>
                    <strong>Pawsible</strong> offers training programs for pets of all ages and breeds. Our training methods are positive and reward-based, focusing on reinforcing good behavior. From basic obedience to more advanced training, our programs are designed to enhance the bond between you and your pet while promoting good behavior and manners.
                </p>
                <ul>
                    <li><strong>Basic Obedience:</strong> Foundation training for good manners and commands.</li>
                    <li><strong>Advanced Training:</strong> Tailored programs for specific behavioral goals.</li>
                    <li><strong>Positive Reinforcement:</strong> Reward-based methods to encourage good behavior.</li>
                    <li><strong>Bonding and Social Skills:</strong> Enhancing the pet-owner relationship.</li>
                </ul>
                <h3>2.5 Adventure and Outdoor Activities</h3>
                <p>
                    For pets that crave adventure, we offer outdoor activities and adventure outings. These activities are designed to provide mental and physical stimulation, allowing pets to explore and enjoy the great outdoors safely under our supervision. Activities may include walks in nature, trips to dog-friendly parks, and other fun outdoor experiences.
                </p>
                <ul>
                    <li><strong>Nature Walks:</strong> Exploring the outdoors in a safe and guided manner.</li>
                    <li><strong>Dog-friendly Park Visits:</strong> Social and playful outings in pet-safe environments.</li>
                    <li><strong>Mental and Physical Stimulation:</strong> Activities to keep your pet engaged and active.</li>
                    <li><strong>Safety First:</strong> Constant supervision to ensure pet safety and enjoyment.</li>
                </ul>
                <h3>2.6 Pet Transportation</h3>
                <p>
                    We understand the busy schedules of pet owners. Our pet transportation service is available to safely transport your pets to and from our facility, veterinary appointments, grooming sessions, or any other location as needed. We ensure a comfortable and stress-free journey for your pet in our well-equipped, safe vehicles.
                </p>
                <ul>
                    <li><strong>Convenient Pick-up and Drop-off:</strong> To and from our facility or other appointments.</li>
                    <li><strong>Comfortable Travel:</strong> In well-equipped, safe vehicles.</li>
                    <li><strong>Stress-free Journeys:</strong> Ensuring your pet's comfort throughout the trip.</li>
                    <li><strong>Flexible Scheduling:</strong> Accommodating your busy lifestyle.</li>
                </ul>
                <h3>3. Client Responsibilities</h3>
                <p>
                    It's crucial for the effective operation of <strong>Pawsible</strong> and the safety of all pets in our care that clients adhere to the following responsibilities. Non-compliance with these responsibilities may result in the inability to provide service, additional charges, or, in extreme cases, termination of the service agreement.
                </p>
                <h3>3.1 Accurate Information Provision</h3>
                <p>
                    <strong>Responsibilities:</strong><br/>
                    Provide complete, accurate, and up-to-date information about your pet.<br/>
                    Update contact information promptly in case of changes.
                </p>
                <p>
                    <strong>Consequences of Non-Compliance:</strong><br/>
                    Failure to provide accurate information may lead to inadequate care for your pet and might result in <strong>Pawsible</strong> being unable to cater to specific needs or emergencies effectively. Inaccurate information can also lead to delays or difficulties in contacting you, which could impact your pet's welfare.
                </p>
                <h3>3.2 Health and Vaccination Requirements</h3>
                <p>
                    <strong>Responsibilities:</strong><br/>
                    Ensure your pet is up-to-date with vaccinations.<br/>
                    Provide a recent health certificate for pets with health issues.
                </p>
                <p>
                    <strong>Consequences of Non-Compliance:</strong><br/>
                    Pets without the required vaccinations or health certificates will not be admitted to our services. This is to maintain a safe and healthy environment for all pets. If a pet is found to be unvaccinated after admission, you will be required to remove the pet immediately, and you may face suspension of services.
                </p>
                <h3>3.3 Behavior and Temperament Disclosures</h3>
                <p>
                    <strong>Responsibilities:</strong><br/>
                    Disclose any behavioral issues or aggressive tendencies.<br/>
                    Inform us about your pet's reactions to various situations.
                </p>
                <p>
                    <strong>Consequences of Non-Compliance:</strong><br/>
                    Failure to disclose behavioral issues can lead to unsafe conditions for staff, other pets, and your pet. If undisclosed behaviors are revealed, it may result in the immediate removal of your pet from our services. In cases of aggression, we reserve the right to refuse further services.
                </p>
                <h3>4. Health and Safety Policies</h3>
                <p>
                    At Pawsible, prioritizing the health and safety of all pets in our care is fundamental. Our comprehensive policies ensure that pets are protected, healthy, and secure, whether they are playing, resting, or engaging in any of our services.
                </p>
                <h3>4.1 Emergency Care and Veterinary Treatment</h3>
                <p>
                    Immediate medical attention is paramount in the event of an emergency. Our protocol includes:
                </p>
                <ul>
                    <li><strong>Immediate Response:</strong> Quick assessment and first aid response by trained staff.</li>
                    <li><strong>Veterinary Partnership:</strong> Access to professional veterinary care when needed.</li>
                    <li><strong>Owner Communication:</strong> Prompt notification to pet owners regarding any health emergencies.</li>
                    <li><strong>Authorization for Treatment:</strong> Pre-arranged consent for emergency veterinary interventions to ensure swift care.</li>
                </ul>
                <h3>4.2 Medication and Special Care Instructions</h3>
                <p>
                    Tailored health management for each pet includes:
                </p>
                <ul>
                    <li><strong>Medication Administration:</strong> Precise adherence to medication schedules as prescribed by your pet's veterinarian.</li>
                    <li><strong>Special Care:</strong> Customized support for pets with unique health needs, ensuring their comfort and well-being.</li>
                    <li><strong>Documentation and Communication:</strong> Detailed records of all care provided, with transparent communication to pet owners.</li>
                </ul>
                <h3>4.3 Safety and Supervision Protocols</h3>
                <p>
                    Maintaining a secure environment for pets involves:
                </p>
                <ul>
                    <li><strong>Staff Training:</strong> Comprehensive training in pet safety, health, and emergency procedures.</li>
                    <li><strong>Facility Safety:</strong> Design and maintenance of facilities to eliminate hazards and ensure pet safety.</li>
                    <li><strong>Supervision:</strong> Constant oversight during all activities to prevent accidents and ensure a safe environment.</li>
                    <li><strong>Behavior Monitoring:</strong> Observation of pets for any signs of distress or discomfort, with immediate steps taken to address any issues.</li>
                </ul>
                <h3>5. Financial Terms and Conditions</h3>
                <p>
                    To maintain transparency and ensure a smooth financial relationship with our clients, <strong>Pawsible</strong> has established clear financial policies regarding our services.
                </p>
                <h3>5.1 Pricing and Payment Methods</h3>
                <p>
                    <strong>Comprehensive Pricing:</strong> Our service fees are structured to be clear and comprehensive, ensuring clients understand what is included in each service package.
                </p>
                <p>
                    <strong>Payment Methods Accepted:</strong> We accept various payment methods, including credit/debit cards, bank transfers, and cash, to accommodate our clients' preferences.
                </p>
                <p>
                    <strong>Invoicing:</strong> Clients will receive detailed invoices for services rendered, providing a breakdown of costs for clarity and record-keeping.
                </p>
                <h3>5.2 Deposits and Prepayments</h3>
                <p>
                    <strong>Reservation Deposit:</strong> For certain services, such as pet boarding, a deposit may be required to secure your reservation. This deposit counts towards the final service cost.
                </p>
                <p>
                    <strong>Prepayments:</strong> Clients have the option to prepay for services or packages, potentially qualifying for discounts or promotional rates.
                </p>
                <h3>5.3 Cancellation and Refund Policy</h3>
                <p>
                    <strong>Cancellation Notice:</strong> We require clients to provide notice of cancellation within a specified timeframe (e.g., 48 hours before the scheduled service) to avoid charges.
                </p>
                <p>
                    <strong>Refunds:</strong> Deposits for services like boarding may be partially or fully refundable based on the timing of the cancellation.
                </p>
                <p>
                    <strong>Service Credits:</strong> In some cases, instead of a refund, clients may be offered service credits for future use.
                </p>
                <h3>5.4 Late Payment Penalties</h3>
                <p>
                    <strong>Late Fees:</strong> Payments not received by the due date may incur late fees. The fee structure will be clearly communicated in our invoicing.
                </p>
                <p>
                    <strong>Service Suspension:</strong> Continued non-payment may result in a temporary suspension of services until the account is settled.
                </p>
                <p>
                    <strong>Payment Plans:</strong> We understand that unexpected financial situations can arise. We're open to discussing payment plans to accommodate clients in special circumstances.
                </p>
                <h3>6. Liability and Indemnification</h3>
                <p>
                    To ensure a clear understanding between <strong>Pawsible</strong> and our clients regarding the responsibilities and liabilities associated with the use of our services, the following policies are established.
                </p>
                <h3>6.1 Waiver of Liability</h3>
                <p>
                    <strong>Acknowledgment of Risks:</strong> Clients acknowledge that despite the high standard of care provided, inherent risks are involved in pet daycare, boarding, grooming, training, and other pet-related activities.
                </p>
                <p>
                    <strong>Waiver Agreement:</strong> By agreeing to this waiver, clients release <strong>Pawsible</strong> and its employees from liability for any injury, loss, or damage to their pet arising from its stay or participation in any service, except in cases of negligence or failure to perform the standard care duties by <strong>Pawsible</strong>.
                </p>
                <h3>6.2 Indemnification by Client</h3>
                <p>
                    <strong>Client Responsibility:</strong> Clients agree to indemnify and hold harmless <strong>Pawsible</strong>, its owners, employees, and affiliates from any claims, damages, losses, liabilities, and expenses arising from their pet’s behavior, including any harm to other pets, individuals, or property.
                </p>
                <p>
                    <strong>Cost Recovery:</strong> This includes recovery of costs related to legal claims, medical treatments for injuries caused by the client’s pet, and repair or replacement of damaged property.
                </p>
                <h3>6.3 Limitations of Liability</h3>
                <p>
                    <strong>Scope of Liability:</strong> <strong>Pawsible</strong>’s liability, except as expressly provided in this agreement, shall be limited to the total amount paid by the client for the services from which the claim arises.
                </p>
                <p>
                    <strong>Exclusion of Certain Damages:</strong> <strong>Pawsible</strong> shall not be liable for any indirect, special, consequential, or punitive damages, including but not limited to loss of profits, even if advised of the possibility of such damages.
                </p>
                <p>
                    <strong>Notice of Claim:</strong> Clients must provide <strong>Pawsible</strong> with prompt written notice of any claim or condition that may give rise to liability under this agreement.
                </p>
                <h3>7. Operational Procedures</h3>
                <p>
                    To ensure a smooth and efficient experience for both pets and their owners, <strong>Pawsible</strong> has established specific operational procedures that cover various aspects of our service delivery.
                </p>
                <h3>7.1 Check-In and Check-Out Process</h3>
                <p>
                    <strong>Check-In Procedures:</strong> Upon arrival, pets are greeted and checked in by our staff. Owners are required to provide any last-minute instructions and confirm contact information. For boarding and daycare services, a quick health check is performed to ensure the pet is ready for their stay.
                </p>
                <p>
                    <strong>Check-Out Procedures:</strong> At pick-up, owners are briefed on their pet's stay, including any notable events or behavioral observations. Payments are finalized, and any pre-booked future services are confirmed. Pets are carefully handed back to their owners, ensuring they leave happy and well-cared for.
                </p>
                <h3>7.2 Handling of Personal Pet Items</h3>
                <p>
                    <strong>Item Acceptance:</strong> We accept personal items that may comfort your pet during their stay, such as bedding, toys, and special food. All items must be clearly labeled with the pet's name.
                </p>
                <p>
                    <strong>Care and Storage:</strong> Personal items are stored in the pet's designated space and are used as instructed by the owner. We take care to ensure items are kept safe and clean, but we also advise against bringing items of significant value as we cannot guarantee their condition upon return.
                </p>
                <p>
                    <strong>Lost or Damaged Items:</strong> While every effort is made to handle personal items carefully, <strong>Pawsible</strong> is not liable for the loss or damage of any items left with pets. We recommend keeping irreplaceable items at home.
                </p>
                <h3>7.3 Client Updates and Communication</h3>
                <p>
                    <strong>Regular Updates:</strong> For extended stays, we provide regular updates to pet owners via phone, email, or text, including photos or videos showcasing their pet's activities and well-being.
                </p>
                <p>
                    <strong>Immediate Communication:</strong> In case of any health concerns, behavioral issues, or emergencies, we promptly contact the pet owner to discuss the situation and determine the best course of action.
                </p>
                <p>
                    <strong>Feedback and Concerns:</strong> We encourage open communication with our clients and welcome any feedback, questions, or concerns regarding their pet's care. Our goal is to maintain a transparent and responsive relationship with all pet owners.
                </p>
                <h3>8. Property Damage and Behavior Issues</h3>
                <p>
                    <strong>Pawsible</strong> is committed to providing a safe and respectful environment for all pets and staff. To maintain this standard, we have policies in place regarding property damage and behavioral issues.
                </p>
                <h3>8.1 Responsibility for Property Damage</h3>
                <p>
                    <strong>Pet Owner Liability:</strong> Pet owners are financially responsible for any damage caused by their pet to the facility, equipment, or property of <strong>Pawsible</strong> or other pets. An assessment of the damage will be conducted, and the cost for repair or replacement will be charged to the pet owner.
                </p>
                <p>
                    <strong>Reporting Damage:</strong> Any damage caused by a pet will be documented and reported to the pet owner, including a description of the damage and an estimate of the repair or replacement costs.
                </p>
                <h3>8.2 Handling of Behavioral Issues</h3>
                <p>
                    <strong>Behavioral Assessment:</strong> Upon noticing behavioral issues that could potentially harm the pet, other pets, or staff, <strong>Pawsible</strong> will assess the situation and attempt to manage it through behavioral interventions or modifications to the pet’s activities.
                </p>
                <p>
                    <strong>Communication with Owners:</strong> Pet owners will be informed of any behavioral issues observed during their pet’s stay. Recommendations for behavioral training or professional consultation may be provided.
                </p>
                <p>
                    <strong>Safety Measures:</strong> In cases where a pet’s behavior poses a continuous risk, we may isolate the pet from group activities or, in extreme cases, require the pet to be picked up by the owner.
                </p>
                <h3>8.3 Rights to Refuse or Discontinue Service</h3>
                <p>
                    <strong>Refusal of Service:</strong> <strong>Pawsible</strong> reserves the right to refuse service to pets that have a history of causing significant property damage or displaying aggressive or unsafe behavior towards other pets or staff.
                </p>
                <p>
                    <strong>Discontinuation of Service:</strong> If a pet’s behavior or the condition becomes unmanageable or continues to pose a risk despite efforts to accommodate their needs, <strong>Pawsible</strong> may discontinue services. The decision to discontinue will be communicated to the pet owner, along with any observations and recommendations for further action.
                </p>
                <h3>Privacy and Data Protection</h3>
                <p>
                    At <strong>Pawsible</strong>, we are committed to protecting the privacy and personal information of our clients and their pets. Our policies are designed to ensure that all information is handled responsibly and with respect for confidentiality.
                </p>
                <h3>9.1 Collection and Use of Personal Information</h3>
                <p>
                    <strong>Information Collection:</strong> We collect personal information necessary to provide our services, including pet owner contact details, pet health information, and service preferences.
                </p>
                <p>
                    <strong>Purpose of Use:</strong> Personal information is used solely for the purpose of delivering our services, managing bookings, and communicating with pet owners about their pet's care and any promotional offers, where applicable.
                </p>
                <p>
                    <strong>Consent:</strong> By using our services, pet owners consent to the collection and use of their information as outlined. We provide options for clients to opt-out of non-essential communications.
                </p>
                <h3>9.2 Confidentiality Commitments</h3>
                <p>
                    <strong>Data Protection:</strong> We implement robust security measures to protect personal information against unauthorized access, alteration, disclosure, or destruction.
                </p>
                <p>
                    <strong>Access Restrictions:</strong> Access to personal information is limited to staff members who require it to perform their job functions. All staff are trained on our privacy policies and committed to maintaining confidentiality.
                </p>
                <p>
                    <strong>Third-Party Sharing:</strong> Personal information is not shared with third parties without the explicit consent of the pet owner, except as required by law or to protect the rights, property, or safety of <strong>Pawsible</strong>, our clients, or others.
                </p>
                <h3>9.3 Photo and Media Release</h3>
                <p>
                    <strong>Use of Images and Videos:</strong> We often capture photos and videos of pets enjoying our services for use in promotional materials, social media, and other publications.
                </p>
                <p>
                    <strong>Owner Consent:</strong> Upon enrollment, pet owners are asked to sign a photo and media release form, granting or denying permission for the use of their pet's images. Owners have the right to opt-out or limit the use of their pet's images at any time.
                </p>
                <p>
                    <strong>Respect for Privacy:</strong> We respect the wishes of pet owners regarding the use of their pet's images and ensure that any content shared aligns with the permissions granted.
                </p>
                <h3>10. Dispute Resolution</h3>
                <p>
                    <strong>Pawsible</strong> aims to resolve disputes effectively while protecting the interests of our business. The following revised procedures outline our approach to dispute resolution.
                </p>
                <h3>10.1 Governing Law</h3>
                <p>
                    <strong>Choice of Law:</strong> This agreement and any disputes arising from our services shall be governed by the laws of the state/province where <strong>Pawsible</strong> is located. This choice of law is intended to provide predictability in legal proceedings.
                </p>
                <p>
                    <strong>Venue:</strong> Disputes shall be resolved in the jurisdiction most convenient to <strong>Pawsible</strong>, which, unless otherwise required by law, will be the courts nearest to <strong>Pawsible</strong>'s primary place of business.
                </p>
                <h3>10.2 Mediation and Arbitration</h3>
                <p>
                    <strong>Mandatory Mediation:</strong> Prior to pursuing any other form of dispute resolution, the parties agree to engage in good faith mediation, with a mediator selected by <strong>Pawsible</strong>. The costs of mediation shall be shared equally between the parties.
                </p>
                <p>
                    <strong>Binding Arbitration:</strong> If mediation fails to resolve the dispute, the parties shall submit to binding arbitration, with an arbitrator chosen by <strong>Pawsible</strong>. The arbitration process shall be confidential, and the decision of the arbitrator shall be final and binding on both parties, subject to limited review by courts under applicable law.
                </p>
                <p>
                    <strong>Limitation on Claims:</strong> Parties agree that any claim must be brought within one (1) year after the services were provided or the dispute arose, whichever is earlier.
                </p>
                <h3>10.3 Legal Recourse</h3>
                <p>
                    <strong>Waiver of Jury Trial:</strong> Both parties waive any right to a jury trial in any litigation arising out of or related to the services provided by <strong>Pawsible</strong>.
                </p>
                <p>
                    <strong>Limitation of Damages:</strong> To the fullest extent permitted by law, <strong>Pawsible</strong> shall not be liable for any indirect, special, incidental, or consequential damages, including but not limited to loss of profits.
                </p>
                <p>
                    <strong>Attorneys' Fees and Costs:</strong> In any dispute resolution process or court proceeding, the prevailing party shall be entitled to recover its reasonable attorneys' fees and costs from the non-prevailing party.
                </p>
                <h3>11. Miscellaneous Provisions</h3>
                <h3>11.1 Force Majeure</h3>
                <p>
                    <strong>Definition:</strong> A force majeure event refers to any occurrence beyond the reasonable control of either party, such as natural disasters, acts of God, war, terrorism, embargoes, or government orders, which prevents one or both parties from fulfilling their obligations under this agreement.
                </p>
                <p>
                    <strong>Effect:</strong> Upon the occurrence of a force majeure event, the affected party will be relieved from performing the obligations affected by the event for the duration of the event. Both parties agree to take all reasonable steps to minimize the effects of the force majeure event.
                </p>
                <p>
                    <strong>Notification:</strong> The party affected by a force majeure event shall promptly notify the other party, detailing the nature of the event and the expected duration of its impact on their obligations.
                </p>
                <h3>11.2 Amendments and Modifications</h3>
                <p>
                    <strong>Written Agreement Required:</strong> Any amendments or modifications to this agreement must be made in writing and signed by both parties. No verbal agreements or understandings shall be considered valid or binding.
                </p>
                <p>
                    <strong>Exceptions:</strong> This provision ensures that all changes to the agreement are formally documented, preventing misunderstandings and ensuring clarity.
                </p>
                <h3>11.3 Severability</h3>
                <p>
                    <strong>Invalid Provisions:</strong> If any provision of this agreement is found to be invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity of the remaining provisions, which shall remain in full force and effect.
                </p>
                <p>
                    <strong>Adjustment:</strong> In such cases, the parties agree to replace the invalid or unenforceable provision with a valid, enforceable provision that most closely matches the intent of the original provision.
                </p>
                <h3>11.4 Entire Agreement</h3>
                <p>
                    <strong>Superseding Document:</strong> This agreement constitutes the entire agreement between the parties regarding its subject matter, superseding all prior agreements, understandings, negotiations, and discussions, whether oral or written, between the parties.
                </p>
                <p>
                    <strong>Acknowledgment:</strong> Each party acknowledges that they have not relied on any representation, warranty, or agreement of the other party except as expressly stated in this agreement.
                </p>
                <h3>12. Acknowledgment and Agreement</h3>
                <h3>12.1 Client Acknowledgment</h3>
                <p>
                    <strong>Understanding of Terms:</strong> You confirm that you have read, understood, and agree to the terms and conditions detailed in this service agreement.
                </p>
                <p>
                    <strong>Accuracy of Information:</strong> You attest that all information provided to <strong>Pawsible</strong> regarding you and your pet(s) is accurate and complete to the best of your knowledge.
                </p>
                <p>
                    <strong>Agreement to Policies:</strong> You agree to abide by <strong>Pawsible</strong>'s policies, including but not limited to, health and safety protocols, payment terms, and behavioral guidelines.
                </p>
                <h3>12.2 Signature of Parties</h3>
                <p>
                    <strong>Electronic Consent:</strong> In lieu of traditional signatures, your agreement with the terms and conditions of this service will be confirmed through a checkbox within the <strong>Pawsible</strong> app interface. Checking this box is legally binding and serves as your electronic signature, indicating your consent and agreement to the terms outlined.
                </p>
                <p>
                    <strong>Date of Agreement:</strong> The date of your electronic consent will be automatically recorded by the app at the time you check the agreement box, serving as the effective date of this agreement.
                </p>


            </div>
        </div>
    );
};

export default TermsAndConditions;
