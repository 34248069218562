import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BackButton } from '../../../../Utilities/components/Buttons';

function CustomerProfile() {
    const { uid } = useParams(); // Get the ID from URL
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        type: ''
    });

    useEffect(() => {
        fetchCustomer();
    }, [uid]);

    const fetchCustomer = async () => {
        try {
            const response = await axios.get(`http://localhost:6060/api/users/${uid}`);
            setCustomer(response.data);
            setFormData({
                username: response.data.username,
                email: response.data.email,
                type: response.data.role // Assuming role/type are used similarly
            });
        } catch (error) {
            console.error('Error fetching customer:', error);
            navigate('/admin/customer-manager');
            window.scrollTo(0, 0);
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`http://localhost:6060/api/users/${uid}`, formData);
            setIsEditing(false);
            fetchCustomer(); // Refetch the customer details after updating
        } catch (error) {
            console.error('Error updating customer:', error);
        }
    };

    return (
        <div className='container'>
            <BackButton />
            <h2>Customer Profile</h2>
            {customer && !isEditing ? (
                <div>
                    <p><strong>Username:</strong> {customer.username}</p>
                    <p><strong>Email:</strong> {customer.email}</p>
                    <p><strong>Type:</strong> {customer.type}</p>
                    <button onClick={handleEditToggle}>Edit</button>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="type">Type</label>
                    <input
                        type="text"
                        id="type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit">Save</button>
                    <button type="button" onClick={handleEditToggle}>Cancel</button>
                </form>
            )}
        </div>
    );
}

export default CustomerProfile;
