import React, { useState, useEffect } from 'react';
import './Spinner.css';
import spinnerCat from '../../../../assets/spinnerCat.gif';
import spinnerDog from '../../../../assets/spinnerDog.gif';

const Spinner = ({ isActive }) => {
    const [randomImage, setRandomImage] = useState(null);
    const [prevIsActive, setPrevIsActive] = useState(isActive);  // Keep track of the previous isActive state

    useEffect(() => {
        // If the spinner was just activated, choose a random spinner
        if (!prevIsActive && isActive) {
            setRandomImage(Math.random() < 0.5 ? spinnerDog : spinnerCat);
        }
        
        // Update the previous isActive state
        setPrevIsActive(isActive);
    }, [isActive]);

    const spinnerClass = randomImage === spinnerDog ? 'dog-spinner' : 'cat-spinner';

    return (
        <div className={`spinner-container ${isActive ? 'active' : ''}`}>
            <img src={randomImage} alt="Loading..." className={`spinner-image ${spinnerClass}`} />
        </div>
    );
}


export default Spinner;
