import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '../../../../StateProvider';
import { useNavigate } from 'react-router-dom';
import './Wallet.css';
import CardDisplay from '../../../../Utilities/components/CardDisplay/CardDisplay';

import tokenIcon from '../../../../../assets/Utilities/tokenIcon.png';
import addCardIcon from '../../../../../assets/Dashboard/Profile/components/Wallet/addCardIcon.png';

import { XButton, PaymentMethodViewButton, PaymentHistoryViewButton } from '../../../../Utilities/components/Buttons';

import Footer from '../../../../Footer';

const Wallet = () => {
  const navigate = useNavigate();
  const { user, paymentMethods, setLoading } = useContext(StateContext);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [defaultMethod, setDefaultMethod] = useState(null);

  useEffect(() => {
    if (user && user.wallet) {
      setTokenAmount(parseFloat(user.wallet.balance));
    }
  }, [user]);

  const handleAddCard = () => {
    navigate('/profile/wallet/payment-methods/add-payment');
    window.scroll(0,0);
  }
  
  const methodToDisplay = defaultMethod || paymentMethods[0];

  return (
    <div>
      <div className='wallet-backdrop'>
        {/* <BackButton /> */}
        <XButton />
        {/* <h2 className='wallet-header'>Pawsible Wallet</h2> */}
        <div className='wallet-container'>
          <div className='wallet-card-container-one' onClick={() => navigate(`/profile/wallet/payment-history`)}>
            <h3 className='wallet-card-header'>Pawsible Cash & Added Funds</h3>
            <p className='wallet-card-paragraph'>Spend on eligible future services (terms apply)</p>
            <div className='wallet-card-token-container'>
              <img src={tokenIcon} className='wallet-card-token-image'></img>
              <p className='wallet-card-token-amount'>${tokenAmount.toFixed(2)}</p>
            </div>
            <hr className='wallet-breakline'/>
            <PaymentHistoryViewButton />
          </div>
        </div>

        <div className='wallet-container'>
          <div className='wallet-card-container-two' onClick={() => navigate(`/store/deals/invite`)}>
            <h3 className='wallet-refer-header'>Refer & earn!</h3>
            <p className='wallet-refer-paragraph'>Invite friends to Pawsible, earn rewards up to $425.</p>
            <p className='wallet-refer-learn-paragraph'>Learn more</p>
          </div>
        </div>
      </div>

      <div className='wallet-container-two' onClick={() => navigate(`/store/deals/gift-card`)}>
        <h3 className='wallet-gift-card-header'>Gift Cards</h3>
        <p className='wallet-gift-card-paragraph'>The most un-fur-gettable gift card.</p>
        <button className='wallet-gift-card-button'>Send gift card</button>
        <p className='wallet-gift-card-redeem'>Redeem gift card</p>
      </div>

      <hr className='wallet-breakline2'/>

      <div className='wallet-container-three'>
        <div className='wallet-view-button-container'>
          <PaymentMethodViewButton />
        </div>
        <h3 className='wallet-payment-header'>Payments</h3>
        <p className='wallet-payment-paragraph'>Your payment methods.</p>

        {methodToDisplay ? (
            <div onClick={() => navigate(`/profile/wallet/payment-methods/edit-payment/${methodToDisplay.id}`)}>
                <CardDisplay key={methodToDisplay.id} method={methodToDisplay} />
            </div>
            ) : null 
        }

        <hr className='wallet-payment-breakline'/>

        <div className='wallet-card-display'>
            <img src={addCardIcon} alt={`$wallet-{cardType} logo`} className="wallet-card-logo" />
            {/* <button className='wallet-payment-add-button'><img src={addCardIcon} className='wallet-payment-add-image'></img></button> */}
            {/* <div className="wallet-card-number">{cardNumber}</div> */}
            <div className="wallet-card-info">
                <div className="wallet-card-number" onClick={handleAddCard} >Add Card</div>
            </div>
        </div>

      </div>

      <hr className='wallet-breakline2'/>

      <Footer />

    </div>
  );
};

export default Wallet;