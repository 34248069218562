// ButtonUtils.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import xIcon from '../../../../assets/Utilities/xIcon.png';
import arrowIcon from '../../../../assets/Utilities/blueArrowIcon.png';

import './Buttons.css';

export const BackButton = ({ onBack }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <button className="back-button" onClick={handleBack}>
        &lt; Back
    </button>
  );
};

export const WhiteBackButton = ({ onBack }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <button className="white-back-button" onClick={handleBack}>
        &lt; Back
    </button>
  );
};

export const XButton = ({ onBack }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <button className="x-button" onClick={handleBack}>
      <img className='x-button-image' src={xIcon}></img>
    </button>
  );
};

export const DeleteButton = ({ onDelete }) => {
  const handleDelete = async () => {
    if (onDelete) {
      try {
        await onDelete();
      } catch (error) {
        console.error('Error occurred during deletion', error);
      }
    } else {
      console.warn('Delete action not defined');
    }
  };

  return (
    <button className="delete-button" onClick={handleDelete}>
      Delete
    </button>
  );
};

export const BlackDeleteButton = ({ onDelete }) => {
  const handleDelete = async () => {
    if (onDelete) {
      try {
        await onDelete();
      } catch (error) {
        console.error('Error occurred during deletion', error);
      }
    } else {
      console.warn('Delete action not defined');
    }
  };

  return (
    <button className="black-delete-button" onClick={handleDelete}>
      Delete
    </button>
  );
};

export const ViewButton = ({ url, external = false }) => {
  const navigate = useNavigate();

  const handleView = () => {
    if (external) {
      // Navigate to an external URL
      window.location.href = url;
    } else {
      // Navigate within the React app
      navigate(url);
    }
  };

  return (
    <button className="view-button" onClick={handleView}>
      View All <img className='view-button-image' src={arrowIcon}></img>
    </button>
  );
};

export const PaymentMethodViewButton = () => {
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/profile/wallet/payment-methods`);
  };

  return (
    <button className="cart-view-button" onClick={handleView}>
      View All
    </button>
    // <img className='cart-view-button-image' src={arrowIcon}></img>
  );
};

export const PaymentHistoryViewButton = () => {
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/profile/wallet/payment-history`);
  };

  return (
    <button className="payment-history-view-button" onClick={handleView}>
      View Payment History
    </button>
  );
};