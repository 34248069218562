import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchStaffMembers } from '../../../Utilities/api';
import { BackButton } from '../../../Utilities/components/Buttons';
import './StaffManager.css';

function StaffManager() {
    const [staffMembers, setStaffMembers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    function capitalizeFirstChar(string) {
      if (!string) return ''; // return empty string if input is falsy
      return string.charAt(0).toUpperCase() + string.slice(1);
    }  

    useEffect(() => {
        async function loadStaff() {
            try {
                const staff = await fetchStaffMembers();
                setStaffMembers(staff);
            } catch (error) {
                console.error('Error fetching staff:', error);
            }
        }
        loadStaff();
    }, []);

    const handleMemberClick = (uid) => {
        navigate(`/admin/staff-manager/${uid}`);
        window.scrollTo(0, 0);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const filteredStaffMembers = staffMembers.filter(staff =>
        staff.username.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return (
        <div className='container'>
          <div className='console-staff-button'>
            <BackButton />
            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="staff-search-input"
            />
          </div>
          <div className="staff-cards">
              {filteredStaffMembers.map(member => (
                  <div className="staff-card" key={member.id} onClick={() => handleMemberClick(member.uid)}>
                      <img src={member.image} alt={member.username} />
                      <div className="staff-info">
                          <h3><strong>{member.username}</strong></h3>
                          <div className="staff-role-job">
                            <p>Role: {capitalizeFirstChar(member.role)}</p>
                            <p>Job: {capitalizeFirstChar(member.job)}</p>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
        </div>
    );
}

export default StaffManager;
