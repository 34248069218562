import React, { useState, useEffect } from 'react';
import './ServiceConfig.css';
import { fetchConfig, updateConfig } from '../../../../Utilities/api';
import { BackButton } from '../../../../Utilities/components/Buttons';

function AdventureConfig() {
    const [config, setConfig] = useState({
        availableAdventures: [],
        duration: '',
        pricePerAdventure: ''
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await fetchConfig('Adventure');
                setConfig(result.config || {});
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };

        loadData();
    }, []);

    const handleTypeChange = (event) => {
        const options = event.target.options;
        const values = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }
        setConfig(prev => ({ ...prev, availableAdventures: values }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfig(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await updateConfig('Adventure', config);
            alert('Configuration saved successfully!');
        } catch (error) {
            console.error('Error saving configuration:', error);
        }
    };

    return (
        <div className="service-config-container">
            <BackButton />
            <h2>Adventure Configuration</h2>
            <form onSubmit={handleSubmit}>
                <label>Available Adventures:
                    <select multiple name="availableAdventures" value={config.availableAdventures} onChange={handleTypeChange}>
                        <option value="Forest Trail">Forest Trail</option>
                        <option value="Beach Run">Beach Run</option>
                        <option value="Park Adventure">Park Adventure</option>
                        <option value="Hiking Excursion">Hiking Excursion</option>
                        <option value="Mountain Escapades">Mountain Escapades</option>
                    </select>
                </label>
                <label>Adventure Duration (hours):
                    <input type="number" name="durationHours" placeholder="Duration in hours" value={config.duration} onChange={handleChange} />
                </label>
                <label>Price Per Adventure:
                    <input type="number" name="pricePerAdventure" placeholder="Enter price" value={config.pricePerAdventure} onChange={handleChange} />
                </label>
                <button type="submit">Save Changes</button>
            </form>
        </div>
    );
}

export default AdventureConfig;
