import React from 'react';

const BasicPreferences = () => {
  const basicTrainingPreferences = {
    programDuration: 'Typically 6-8 weeks.',
    trainingGoals: [
      'Mastery of basic commands',
      'Improved behavior at home and in public',
      'Leash walking',
      'Recall',
    ],
    curriculum: 'Builds on puppy training, reinforcing basic commands and introducing more advanced behaviors.',
    trainingMethods: 'Positive reinforcement and clicker training techniques.',
    eligibility: 'Open to dogs of all ages.',
    benefits: [
      "Enhances the dog's obedience, making them well-mannered and easier to manage.",
      'Strengthens the bond between dog and owner.',
    ],
  };

  return (
    <div className="basic-training-preferences">
      <h3>Basic Obedience Training Preferences</h3>
      <ul>
        <li>
          <strong>Program Duration:</strong> {basicTrainingPreferences.programDuration}
        </li>
        <li>
          <strong>Training Goals:</strong>
          <ul>
            {basicTrainingPreferences.trainingGoals.map((goal, index) => (
              <li key={index}>{goal}</li>
            ))}
          </ul>
        </li>
        <li>
          <strong>Curriculum:</strong> {basicTrainingPreferences.curriculum}
        </li>
        <li>
          <strong>Training Methods:</strong> {basicTrainingPreferences.trainingMethods}
        </li>
        <li>
          <strong>Eligibility:</strong> {basicTrainingPreferences.eligibility}
        </li>
        <li>
          <strong>Benefits:</strong>
          <ul>
            {basicTrainingPreferences.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default BasicPreferences;
