import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Events.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment-timezone';
import { StateContext } from '../../StateProvider';

const Events = () => {
  const navigate = useNavigate();
  const { user, setLoading } = useContext(StateContext);
  const [date, setDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(null); // Track the active date
  const [selectedDateBookings, setSelectedDateBookings] = useState([]);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (user && user.uid && user.bookings) {
      setBookings(user.bookings);
    }
    setLoading(false);
  }, [user]);

  const formatDate = (inputDate) => {
    return moment(inputDate).format('MM/DD/YY');
  }

  const handleDateChange = (selectedDate) => {
    const localDate = moment(selectedDate).tz(moment.tz.guess()).startOf('day').toDate();
    setDate(localDate);
    setActiveDate(localDate.toISOString().split('T')[0]); // Set active date
    const formattedDate = localDate.toISOString().split('T')[0];
    const dayBookings = (bookings || []).filter(booking =>
      moment(booking.startTime).tz(moment.tz.guess()).format('YYYY-MM-DD') === formattedDate
    );
    setSelectedDateBookings(dayBookings);
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const localDate = moment(date).tz(moment.tz.guess()).startOf('day').toDate();
      const formattedDate = localDate.toISOString().split('T')[0];
      const today = moment().tz(moment.tz.guess()).startOf('day').toISOString().split('T')[0];
      const isActive = formattedDate === activeDate; // Check if this date is active
      const dayBookings = (bookings || []).filter(booking =>
        moment(booking.startTime).tz(moment.tz.guess()).format('YYYY-MM-DD') === formattedDate
      );

      let tileClassName = "";
      if (formattedDate < today && dayBookings.length > 0) {
        tileClassName = isActive ? "history-tile-active" : "history-tile";
      } else if (formattedDate === today) {
        tileClassName = isActive ? "today-tile-active" : "today-tile";
      } else if (dayBookings.length > 0) {
        tileClassName = isActive ? "booked-tile-active" : "booked-tile";
      }

      // Wrap the abbr inside the specific tile div if a style is assigned
      if (tileClassName && !isActive) {
        return (
          <div className={tileClassName}>
            <abbr className='reserved-abbr' aria-label={formattedDate}>{localDate.getDate()}</abbr>
          </div>
        );
      } else {
        return (
          <div className={tileClassName}></div>
        )
      }
    }
    return null;
  };

  return (
    <div className="events-container">
      <div className="legend">
        <div className="legend-item">
          <span className="history-dot" />
            History
        </div>
        <div className="legend-item">
          <span className="today-dot" />
            Today
        </div>
        <div className="legend-item">
          <span className="booked-dot" />
            Booked
        </div>
        <div className="legend-item">
          <span className="events-dot" />
            Events
        </div>
      </div>

      <div className="calendar-container">
        <Calendar
          calendarType="gregory"
          onChange={handleDateChange}
          value={date}
          tileContent={tileContent}
        />
      </div>

      <div className="event-details">
        <h3 className='event-details-header'>Day Overview</h3>
        <hr className='booking-hr' />
        {selectedDateBookings.length > 0 ? (
          <ul>
            {selectedDateBookings.map((transaction, index) => (
              <li key={index} className='event-details-item'>
                <div className="date">{formatDate(transaction.startTime)}</div>
                <div className="title">{transaction.bookingType}</div>
                <div className="description">
                  {transaction.selectedPets.map((pet) => (
                    <p key={pet.petName} className='event-details-pet-name'>({pet.petName})</p>
                  ))}
                </div>
                <button onClick={() => navigate(`/profile/wallet/payment-history/${transaction.id}`, { state: { transaction } })}>View</button>
              </li>
            ))}
          </ul>
        ) : (
          <p className='event-details-message'>No visits or events for the selected date</p>
        )}
      </div>
    </div>
  );
};

export default Events;
