import React from 'react';

const BehaviorPreferences = () => {
  const behaviorTrainingPreferences = {
    programDuration: 'Tailored to individual needs, varies.',
    trainingGoals: [
      'Address and correct specific behavior issues (e.g., aggression, anxiety, or fear)',
    ],
    curriculum: 'Customized to target problem behaviors and implement corrective strategies.',
    trainingMethods: 'Applied behavior analysis, desensitization, counterconditioning.',
    eligibility: 'Open to dogs with behavioral challenges.',
    benefits: [
      'Addresses and resolves problematic behaviors, leading to a happier, more well-adjusted dog.',
    ],
  };

  return (
    <div className="behavior-training-preferences">
      <h3>Behavior Modification Training Preferences</h3>
      <ul>
        <li>
          <strong>Program Duration:</strong> {behaviorTrainingPreferences.programDuration}
        </li>
        <li>
          <strong>Training Goals:</strong>
          <ul>
            {behaviorTrainingPreferences.trainingGoals.map((goal, index) => (
              <li key={index}>{goal}</li>
            ))}
          </ul>
        </li>
        <li>
          <strong>Curriculum:</strong> {behaviorTrainingPreferences.curriculum}
        </li>
        <li>
          <strong>Training Methods:</strong> {behaviorTrainingPreferences.trainingMethods}
        </li>
        <li>
          <strong>Eligibility:</strong> {behaviorTrainingPreferences.eligibility}
        </li>
        <li>
          <strong>Benefits:</strong>
          <ul>
            {behaviorTrainingPreferences.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default BehaviorPreferences;
