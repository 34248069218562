import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeGenerator = () => {
    const [input, setInput] = useState('');
    const [qrValue, setQrValue] = useState('');
    const [fgColor, setFgColor] = useState('#FFB516'); // Default black foreground color
    const [bgColor, setBgColor] = useState('#ffffff'); // Default white background color

    const handleChange = (event) => {
        setInput(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setQrValue(input);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    value={input} 
                    onChange={handleChange} 
                    placeholder="Enter URL or text"
                />
                <button type="submit">Generate QR Code</button>
            </form>
            <div>
                <label>
                    Foreground Color:
                    <input 
                        type="color" 
                        value={fgColor} 
                        onChange={(e) => setFgColor(e.target.value)} 
                    />
                </label>
                <label>
                    Background Color:
                    <input 
                        type="color" 
                        value={bgColor} 
                        onChange={(e) => setBgColor(e.target.value)} 
                    />
                </label>
            </div>
            {qrValue && (
                <QRCodeCanvas 
                    value={qrValue} 
                    size={256} 
                    fgColor={fgColor} 
                    bgColor={bgColor} 
                />
            )}
        </div>
    );
};

export default QRCodeGenerator;
