import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className='container'>
            <div className='privacy-policy'>
                <h2>Privacy Notice</h2>
                <h3>Introduction</h3>
                <p>
                    Welcome to Pawsible. This <strong>Privacy & Liability Agreement</strong> outlines the terms under which we offer our pet care services, including but not limited to pet daycare, boarding, grooming, training, adventure activities, and transportation. It is designed to protect both you, the pet owner, and Pawsible, ensuring a transparent and trustworthy relationship.
                </p>
                <h3>1.1 Purpose of the Agreement</h3>
                <p>
                    The purpose of this agreement is to clearly define the responsibilities and expectations regarding <strong>privacy, liability, and the use of our services</strong>. By understanding and agreeing to these terms, we aim to provide a safe, enjoyable, and privacy-respecting environment for all pets and their owners.
                </p>
                <h3>1.2 Acceptance of Terms</h3>
                <p>
                    By utilizing Pawsible’s services, you acknowledge that you have read, understood, and agreed to the terms set forth in this agreement. Your continued use of our services signifies your ongoing acceptance of these terms, including any updates or amendments we may make to this agreement. We encourage you to review this agreement periodically to stay informed of your rights and obligations.
                </p>
                <h3>Liability Waiver</h3>
                <h3>2.1 Acknowledgment of Risks</h3>
                <p>
                    You acknowledge that, despite our highest standards of care, participation in pet care services, including daycare, boarding, grooming, training, adventure activities, and transportation, involves <strong>inherent risks</strong>. These risks may include, but are not limited to, injuries to your pet from other pets, the possibility of escape, or the transmission of disease. By agreeing to this waiver, you recognize and accept these risks.
                </p>
                <h3>2.2 Release from Liability</h3>
                <p>
                    You hereby release Pawsible, its officers, employees, and agents from any claims, losses, damages, liabilities, and expenses arising from your pet’s participation in any and all services provided by Pawsible, except in cases of <strong>gross negligence or intentional misconduct</strong> by Pawsible. This release covers both known and unknown risks associated with pet care services.
                </p>
                <h3>2.3 Client's Responsibilities</h3>
                <p>
                    As the pet owner, you are responsible for <strong>disclosing all relevant information</strong> about your pet's health, behavior, and history. This includes any known aggression issues, health problems, or special care instructions. Failure to disclose this information may result in voiding this waiver and could lead to your being held liable for any incidents that occur as a result.
                </p>
                <h3>2.4 Limitation of Liability</h3>
                <p>
                    Pawsible’s liability to you for any claim, loss, damage, or expense related to the services provided shall be <strong>limited to the total amount paid</strong> by you for the services from which the claim arises. Under no circumstances will Pawsible be liable for any <strong>indirect, incidental, special, or consequential damages</strong>, including loss of profits.
                </p>
                <h3>2.5 Indemnification by Client</h3>
                <p>
                    You agree to <strong>indemnify, defend, and hold harmless</strong> Pawsible, its officers, employees, and agents from and against any claims, lawsuits, injuries, damages, losses, costs, or expenses, including attorney’s fees, arising out of or resulting from your pet’s participation in services provided by Pawsible, your breach of this agreement, or your pet’s actions or behavior, except in cases of <strong>gross negligence or intentional misconduct</strong> by Pawsible.
                </p>
                <h3>Privacy Policy</h3>
                <h3>3.1 Collection and Use of Personal and Pet Information</h3>
                <p>
                    <strong>Pawsible</strong> collects personal information from clients, including but not limited to, names, addresses, contact details, and pet information (such as breed, age, health conditions). This information is collected to provide and personalize our services, manage bookings, process payments, and communicate with you regarding your pet's care and any promotional offers, where applicable.
                </p>
                <h3>3.2 Confidentiality Commitments</h3>
                <p>
                    We are committed to maintaining the <strong>confidentiality</strong> of the personal and pet information provided by our clients. This information will not be disclosed to third parties except as necessary to provide our services, comply with the law, or protect the rights, property, or safety of Pawsible, our clients, or others.
                </p>
                <h3>3.3 Data Security Measures</h3>
                <p>
                    <strong>Pawsible</strong> implements appropriate physical, technical, and administrative security measures designed to protect your personal and pet information against unauthorized access, alteration, disclosure, or destruction. We regularly review and update our security practices to safeguard your information.
                </p>
                <h3>3.4 Sharing and Disclosure of Information</h3>
                <p>
                    We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and email delivery. These third parties are prohibited from using your information for any purpose other than to provide these services to Pawsible and are obligated to maintain the confidentiality and security of the information.
                </p>
                <h3>3.5 Client's Rights and Choices</h3>
                <p>
                    You have the right to access, correct, update, or request deletion of your personal and pet information held by <strong>Pawsible</strong>. Additionally, you may opt out of receiving promotional communications from us at any time by following the unsubscribe instructions included in those communications or by contacting us directly.
                </p>
                <h3>3.6 Data Retention and Deletion</h3>
                <p>
                    <strong>Pawsible</strong> retains personal and pet information only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements. Upon your request, we will delete or anonymize your personal information, unless we are legally required or permitted to maintain a copy.
                </p>
                <h3>3.7 Payment Information Handling</h3>
                <p>
                    <strong>Pawsible</strong> processes client payments through secure, third-party payment processing services. When you make a payment:
                </p>
                <p>
                    <strong>Collection of Payment Details:</strong> We collect payment information, such as credit card numbers or bank account details, necessary to process your payment. This information is directly transmitted to our third-party payment processors through encrypted channels and is not stored on our servers.
                </p>
                <p>
                    <strong>Third-Party Payment Processors:</strong> Our third-party payment processors are committed to adhering to the standards set by PCI-DSS (Payment Card Industry Data Security Standard) to ensure secure handling of payment information.
                </p>
                <p>
                    <strong>Security Measures:</strong> We implement additional security measures in collaboration with our payment processors to protect against unauthorized transactions and ensure the integrity of your financial data.
                </p>
                <p>
                    <strong>Usage of Payment Information:</strong> Payment information is used exclusively for the purpose of processing or refunding transactions related to our services. We do not use this information for any other purposes without your explicit consent.
                </p>
                <p>
                    <strong>Updates and Notifications:</strong> Should there be any changes to our payment processing practices or if we need to communicate important information related to your payment transactions, we will promptly inform you through your provided contact details.
                </p>
                <h3>Media Release</h3>
                <h3>4.1 Consent for Media Use</h3>
                <p>
                    By agreeing to this <strong>Privacy & Liability Agreement</strong>, you grant <strong>Pawsible</strong> the consent to capture and use images, videos, and other media of your pet(s) during their stay or participation in any of our services. This consent is provided without the expectation of compensation or ownership claim.
                </p>
                <h3>4.2 Scope of Media Use</h3>
                <p>
                    The media captured may be used for a variety of purposes, including but not limited to:
                </p>
                <ul>
                    <li>Promotional materials, such as brochures and flyers.</li>
                    <li>Digital content, including our website, social media platforms, and email newsletters.</li>
                    <li>Internal training or educational materials to improve service quality.</li>
                </ul>
                <h3>4.3 Client's Rights and Revocation of Consent</h3>
                <p>
                    You retain the right to revoke this consent at any time by providing written notice to <strong>Pawsible</strong>. Upon receipt of your revocation, we will cease the creation of new media involving your pet and will make reasonable efforts to remove existing media from public use, where feasible. However, we cannot retract media already distributed or used in printed materials.
                </p>
                <h3>4.4 Use of Media for Promotional Purposes</h3>
                <p>
                    <strong>Pawsible</strong> values the privacy and dignity of your pet. Media used for promotional purposes will be selected with care, ensuring that it reflects positively on your pet and does not compromise their safety or well-being. We aim to celebrate the joy and companionship pets bring, using media to share their stories and experiences in our care.
                </p>
                <h3>4.5 Protection of Pet Identity and Privacy</h3>
                <p>
                    In using pet media, we commit to protecting the identity and privacy of our clients. Personal information, such as the pet owner's name, contact details, or specific location, will not be disclosed in any media without explicit consent. Our focus is on showcasing the activities and care provided by <strong>Pawsible</strong>, not on revealing private details of our clients or their pets.
                </p>
                <h3>Miscellaneous Provisions</h3>
                <h3>5.1 Force Majeure</h3>
                <p>
                    <strong>Pawsible</strong> shall not be held liable for any failure or delay in performing its obligations under this agreement due to circumstances beyond its reasonable control, including natural disasters, acts of government, pandemics, strikes, civil unrest, or other force majeure events. Both parties agree to resume their respective obligations as soon as the situation stabilizes.
                </p>
                <h3>5.2 Amendments and Updates</h3>
                <h3>5.2.1 Changes to the Agreement</h3>
                <p>
                    <strong>Pawsible</strong> reserves the right to amend or update this <strong>Privacy & Liability Agreement</strong> at any time. Such changes will be effective only after they have been communicated to you, either through direct notification or by posting on our website, and will apply prospectively to services rendered after the date of the change.
                </p>
                <h3>5.2.2 Notification of Changes</h3>
                <p>
                    We will provide reasonable notice of any significant changes to this agreement through email, our website, or another appropriate communication channel. Your continued use of our services after such notice will constitute your acceptance of the new terms.
                </p>
                <h3>5.3 Severability</h3>
                <p>
                    If any provision of this agreement is found to be invalid or unenforceable by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect. Both parties commit to negotiating in good faith to replace any invalid or unenforceable provision with a valid and enforceable one that achieves, to the extent possible, the original objectives and intent.
                </p>
                <h3>5.4 Entire Agreement</h3>
                <p>
                    This agreement constitutes the entire agreement between you and <strong>Pawsible</strong> regarding the subject matter hereof and supersedes all prior or contemporaneous understandings, agreements, negotiations, representations, and warranties, both written and oral, regarding such subject matter. No amendment, modification, or waiver of any provision of this agreement shall be effective unless in writing and signed by both parties.
                </p>
                <h3>Dispute Resolution</h3>
                <h3>6.1 Governing Law</h3>
                <p>
                    This agreement and any disputes arising from or related to the agreement or the services provided by <strong>Pawsible</strong> shall be governed by and construed in accordance with the laws of the jurisdiction in which <strong>Pawsible</strong> operates, without regard to its conflict of law provisions.
                </p>
                <h3>6.2 Mediation and Arbitration</h3>
                <p>
                    <strong>Mediation:</strong> In the event of a dispute, both parties agree to first attempt to resolve the matter through mediation, engaging a neutral third-party mediator to facilitate negotiation. Each party will bear its own costs in mediation.
                </p>
                <p>
                    <strong>Binding Arbitration:</strong> If mediation does not resolve the dispute, both parties agree to submit to binding arbitration. The arbitration will be conducted in the jurisdiction where <strong>Pawsible</strong> operates, and the decision of the arbitrator shall be final and binding on both parties, subject to limited rights of appeal under the arbitration law of the governing jurisdiction.
                </p>
                <h3>6.3 Legal Recourse</h3>
                <p>
                    Should arbitration not be an option or if the dispute falls outside of the scope of arbitration agreed upon herein, both parties retain the right to seek legal recourse through the courts. Any legal actions or proceedings arising out of this agreement shall be brought in the courts of the jurisdiction in which <strong>Pawsible</strong> operates, and both parties hereby consent to the jurisdiction and venue of such courts.
                </p>
                <h3>Acknowledgment and Agreement</h3>
                <h3>7.1 Client Acknowledgment</h3>
                <p>
                    By proceeding with the use of <strong>Pawsible</strong>'s services, you, the client, acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this <strong>Privacy & Liability Agreement</strong>, including any amendments made hereafter.
                </p>
                <h3>7.2 Signature of Parties</h3>
                <p>
                    In lieu of traditional signatures, <strong>Pawsible</strong> employs a digital acknowledgment mechanism within its app. By selecting the checkbox labeled "<strong>I Agree</strong>" associated with this agreement in the app, you are providing your electronic signature, which holds the same legal weight and effect as a handwritten signature.
                </p>


            </div>
        </div>
    );
};

export default PrivacyPolicy;
