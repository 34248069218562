import React, { useState } from 'react';
import summerCut1 from '../../../../../../../../assets/Store/Services/Grooming/summerCut-01.png';
import summerCut2 from '../../../../../../../../assets/Store/Services/Grooming/summerCut-02.png';
import summerCut3 from '../../../../../../../../assets/Store/Services/Grooming/summerCut-03.png';
import breedCut1 from '../../../../../../../../assets/Store/Services/Grooming/breedCut-01.png';
import breedCut2 from '../../../../../../../../assets/Store/Services/Grooming/breedCut-02.png';
import breedCut3 from '../../../../../../../../assets/Store/Services/Grooming/breedCut-03.png';
import cuteCut1 from '../../../../../../../../assets/Store/Services/Grooming/cuteCut-01.png';
import cuteCut2 from '../../../../../../../../assets/Store/Services/Grooming/cuteCut-02.png';
import cuteCut3 from '../../../../../../../../assets/Store/Services/Grooming/cuteCut-03.png';
import designerCut1 from '../../../../../../../../assets/Store/Services/Grooming/designerCut-01.png';
import designerCut2 from '../../../../../../../../assets/Store/Services/Grooming/designerCut-02.png';
import designerCut3 from '../../../../../../../../assets/Store/Services/Grooming/designerCut-03.png';

const GroomingPreferences = ({ groomingBookingData, setGroomingBookingData }) => {
    const selectedAdditionalServices = groomingBookingData.groomingOptions.additionalServices || [];

    const handleHaircutStyleChange = (style) => {
        setGroomingBookingData((prevData) => ({
            ...prevData,
            groomingOptions: {
                ...prevData.groomingOptions,
                selectedHaircutStyle: style,
                selectedStyleImage: null, // Reset selected style image when changing style
            },
        }));
    };

    const toggleAdditionalService = (service) => {
        const updatedServices = selectedAdditionalServices.includes(service)
            ? selectedAdditionalServices.filter((s) => s !== service)
            : [...selectedAdditionalServices, service];

        setGroomingBookingData((prevData) => ({
            ...prevData,
            groomingOptions: {
                ...prevData.groomingOptions,
                additionalServices: updatedServices,
            },
        }));
    };

    const handleSpecialConditionChange = (condition) => {
        setGroomingBookingData((prevData) => ({
            ...prevData,
            groomingOptions: {
                ...prevData.groomingOptions,
                [condition]: !prevData.groomingOptions[condition],
            },
        }));
    };

    const isServiceSelected = (service) => selectedAdditionalServices.includes(service);
    const isConditionSelected = (condition) => groomingBookingData.groomingOptions[condition];

    return (
        <div className="grooming-preferences">
            <h3>What's included in the grooming?</h3>
            <ul>
                <li>Full Bath</li>
                <li>Breed Haircut</li>
                <li>Sanitary Trimming</li>
                <li>Ear Cleaning</li>
                <li>Nail Trimming</li>
                <li>Anal Glands Expression</li>
                <li>Blow Drying</li>
                <li>Hair Brushing</li>
                <li>Paw Balm</li>
            </ul>

            <h3>Special Conditions</h3>
            <div className="special-conditions">
                <button
                    className={`condition-button ${isConditionSelected('mattedHair') ? 'selected' : ''}`}
                    onClick={() => handleSpecialConditionChange('mattedHair')}
                >
                    Matted Hair (De-matting)
                </button>
                <button
                    className={`condition-button ${isConditionSelected('skunkTreatment') ? 'selected' : ''}`}
                    onClick={() => handleSpecialConditionChange('skunkTreatment')}
                >
                    Skunk Treatment (Exotic Odor)
                </button>
            </div>

            <h3>Grooming Preferences</h3>
            <div className="haircut-style">
                <div className="style-buttons">
                    <button
                        className={`style-button ${groomingBookingData.groomingOptions.selectedHaircutStyle === 'summer' ? 'selected' : ''}`}
                        onClick={() => handleHaircutStyleChange('summer')}
                    >
                        Short & Neat (Summer)
                    </button>
                    {groomingBookingData.groomingOptions.selectedHaircutStyle === 'summer' && (
                        <>
                            <div className="style-images">
                                <img
                                    src={summerCut1}
                                    alt="Summer Cut 1"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'summerCut1' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('summerCut1')}
                                />
                                <img
                                    src={summerCut2}
                                    alt="Summer Cut 2"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'summerCut2' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('summerCut2')}
                                />
                                <img
                                    src={summerCut3}
                                    alt="Summer Cut 3"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'summerCut3' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('summerCut3')}
                                />
                            </div>
                        </>
                    )}
                    <button
                        className={`style-button ${groomingBookingData.groomingOptions.selectedHaircutStyle === 'classic' ? 'selected' : ''}`}
                        onClick={() => handleHaircutStyleChange('classic')}
                    >
                        Breed Style (Classic)
                    </button>
                    {groomingBookingData.groomingOptions.selectedHaircutStyle === 'classic' && (
                        <>
                            <div className="style-images">
                                <img
                                    src={breedCut1}
                                    alt="Classic Cut 1"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'breedCut1' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('breedCut1')}
                                />
                                <img
                                    src={breedCut2}
                                    alt="Classic Cut 2"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'breedCut2' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('breedCut2')}
                                />
                                <img
                                    src={breedCut3}
                                    alt="Classic Cut 3"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'breedCut3' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('breedCut3')}
                                />
                            </div>
                        </>
                    )}
                    <button
                        className={`style-button ${groomingBookingData.groomingOptions.selectedHaircutStyle === 'cute' ? 'selected' : ''}`}
                        onClick={() => handleHaircutStyleChange('cute')}
                    >
                        Cute & Charming (Adorable)
                    </button>
                    {groomingBookingData.groomingOptions.selectedHaircutStyle === 'cute' && (
                        <>
                            <div className="style-images">
                                <img
                                    src={cuteCut1}
                                    alt="Charming Cut 1"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'cuteCut1' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('cuteCut1')}
                                />
                                <img
                                    src={cuteCut2}
                                    alt="Charming Cut 2"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'cuteCut2' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('cuteCut2')}
                                />
                                <img
                                    src={cuteCut3}
                                    alt="Charming Cut 3"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'cuteCut3' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('cuteCut3')}
                                />
                            </div>
                        </>
                    )}
                    <button
                        className={`style-button ${groomingBookingData.groomingOptions.selectedHaircutStyle === 'premium' ? 'selected' : ''}`}
                        onClick={() => handleHaircutStyleChange('premium')}
                    >
                        Designer Cut (Premium)
                    </button>
                    {groomingBookingData.groomingOptions.selectedHaircutStyle === 'premium' && (
                        <>
                            <div className="style-images">
                                <img
                                    src={designerCut1}
                                    alt="Designer Cut 1"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'designerCut1' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('designerCut1')}
                                />
                                <img
                                    src={designerCut2}
                                    alt="Designer Cut 2"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'designerCut2' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('designerCut2')}
                                />
                                <img
                                    src={designerCut3}
                                    alt="Designer Cut 3"
                                    className={`style-image ${groomingBookingData.groomingOptions.selectedStyleImage === 'designerCut3' ? 'selected' : ''}`}
                                    onClick={() => handleHaircutStyleChange('designerCut3')}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <h3>Additional Services</h3>
            <div className="additional-services">
                <button
                    className={`service-button ${isServiceSelected('deshedding') ? 'selected' : ''}`}
                    onClick={() => toggleAdditionalService('deshedding')}
                >
                    De-shedding
                </button>
                <button
                    className={`service-button ${isServiceSelected('fleaTick') ? 'selected' : ''}`}
                    onClick={() => toggleAdditionalService('fleaTick')}
                >
                    Flea & Tick Treatment
                </button>
                <button
                    className={`service-button ${isServiceSelected('teethBrushing') ? 'selected' : ''}`}
                    onClick={() => toggleAdditionalService('teethBrushing')}
                >
                    Teeth Brushing
                </button>
            </div>
        </div>
    );
};

export default GroomingPreferences;
