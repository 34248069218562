import React from 'react';

const BathPreferences = ({ groomingBookingData, setGroomingBookingData }) => {
  const selectedAdditionalServices = groomingBookingData.groomingOptions.additionalServices || [];

  const toggleAdditionalService = (service) => {
    const updatedServices = selectedAdditionalServices.includes(service)
      ? selectedAdditionalServices.filter((s) => s !== service)
      : [...selectedAdditionalServices, service];

    setGroomingBookingData((prevData) => ({
      ...prevData,
      groomingOptions: {
        ...prevData.groomingOptions,
        additionalServices: updatedServices,
      },
    }));
  };

  const isServiceSelected = (service) => selectedAdditionalServices.includes(service);

  return (
    <div className="bath-preferences">
      <h3>What's included in the bath?</h3>
      <ul>
        <li>Full Bath</li>
        <li>Ear Cleaning</li>
        <li>Nail Trimming</li>
        <li>Anal Glands Expression</li>
        <li>Blow Drying</li>
        <li>Hair Brushing</li>
        <li>Paw Balm</li>
      </ul>

      <h3>Additional Services</h3>
      <div className="additional-services">
        <button
          className={`service-button ${
            isServiceSelected('deshedding') ? 'selected' : ''
          }`}
          onClick={() => toggleAdditionalService('deshedding')}
        >
          Deshedding
        </button>
        <button
          className={`service-button ${
            isServiceSelected('fleaTick') ? 'selected' : ''
          }`}
          onClick={() => toggleAdditionalService('fleaTick')}
        >
          Flea & Tick Treatment
        </button>
        <button
          className={`service-button ${
            isServiceSelected('sanitaryTrim') ? 'selected' : ''
          }`}
          onClick={() => toggleAdditionalService('sanitaryTrim')}
        >
          Sanitary Trimming
        </button>
        <button
          className={`service-button ${
            isServiceSelected('teethBrushing') ? 'selected' : ''
          }`}
          onClick={() => toggleAdditionalService('teethBrushing')}
        >
          Teeth Brushing
        </button>
      </div>
    </div>
  );
};

export default BathPreferences;
