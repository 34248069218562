import React, { useState, useEffect } from 'react';
import './BookingCalendar.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../../Utilities/components/Buttons';
import { fetchBookings } from '../../../Utilities/api';

const BookingCalendar = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(null); // Track the active date
  const [selectedDateBookings, setSelectedDateBookings] = useState([]);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    fetchBookings()
      .then(response => {
          setBookings(response);
    })
      .catch(error => {
          console.error('Failed to fetch booking data:', error);
    });
  }, []);

  function formatDate(inputDate) {
    return new Date(inputDate).toLocaleDateString('en-US', {
      year: '2-digit', month: '2-digit', day: '2-digit'
    });
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    setActiveDate(selectedDate.toISOString().split('T')[0]); // Set active date
    const formattedDate = selectedDate.toISOString().split('T')[0];
    const dayBookings = bookings.filter(booking => 
      new Date(booking.startTime).toISOString().split('T')[0] === formattedDate
    );
    setSelectedDateBookings(dayBookings);
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = date.toISOString().split('T')[0];
      const today = new Date().toISOString().split('T')[0];
      const isActive = formattedDate === activeDate; // Check if this date is active
      const dayBookings = bookings.filter(booking => 
        new Date(booking.startTime).toISOString().split('T')[0] === formattedDate
      );
  
      let tileClassName = "";
      if (formattedDate < today && dayBookings.length > 0) {
        tileClassName = isActive ? "history-tile-active" : "history-tile";
      } else if (formattedDate === today) {
        tileClassName = isActive ? "today-tile-active" : "today-tile";
      } else if (dayBookings.length > 0) {
        tileClassName = isActive ? "booked-tile-active" : "booked-tile";
      }
  
      // Wrap the abbr inside the specific tile div if a style is assigned
      if (tileClassName && !isActive) {
        return (
          <div className={tileClassName}>
            <abbr className='reserved-abbr' aria-label={formattedDate}>{date.getDate()}</abbr>
          </div>
        );
      } else {
        return (
          <div className={tileClassName}></div>
        )
      }
    }
    return null;
  };

  const handleViewClick = (bookingId) => {
    navigate(`/admin/schedule-calendar/event-details/${bookingId}`);
    window.scrollTo(0, 0);
  };

  const handleAddBooking = () => {
    navigate('/admin/schedule-calendar/new-booking');
    window.scrollTo(0, 0);
  };

  const handleViewAll = () => {
    navigate('/admin/schedule-calendar/all-bookings');
    window.scrollTo(0, 0);
  };

  const handleRefresh = () => {
    fetchBookings();
  };
  

  return (
    <div className="events-container">
      <div className='console-booking-button-container'>
      <BackButton />
        <button onClick={handleAddBooking} className="console-booking-button">Add New </button>
        <button onClick={handleViewAll} className="console-booking-button">View All</button>
        <button onClick={handleRefresh} className="console-booking-button">Refresh</button>
      </div>
      <div className="legend">
        <div className="legend-item">
          <span className="history-dot" />
            History
        </div>
        <div className="legend-item">
          <span className="today-dot" />
            Today
        </div>
        <div className="legend-item">
          <span className="booked-dot" />
            Booked
        </div>
        <div className="legend-item">
          <span className="events-dot" />
            Events
        </div>
      </div>

      <div className="calendar-container">
        <Calendar
          calendarType="gregory"
          onChange={handleDateChange}
          value={date}
          tileContent={tileContent}
        />
      </div>
      
      <div className="event-details">
        <h3 className='event-details-header'>Day Overview</h3>
        <hr className='booking-hr' />
        {selectedDateBookings.length > 0 ? (
          <ul>
            {selectedDateBookings.map((booking, index) => (
              <li key={index} className='event-details-item'>
                <div className="date">{formatDate(booking.startTime)}</div> 
                <div className="title">{booking.bookingType}</div>
                <div className="description"><p>{booking.FamilyMember.name} ({booking.FamilyMember.type.toLowerCase()})</p></div>
                <button onClick={() => handleViewClick(booking.id)}>View</button>
              </li> 
            ))} 
          </ul>
        ) : (
          <p className='event-details-message'>No visits or events for the selected date</p>
        )}
      </div>
    </div>
  );
};

export default BookingCalendar;