import React, { useContext } from 'react';
import './Impact.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../StateProvider';

import impactBackground from '../../../../../assets/Store/Deals/Impact/impactBackground.png';
import dog1 from '../../../../../assets/Store/Deals/Impact/dog1.png';
import dog2 from '../../../../../assets/Store/Deals/Impact/dog2.png';
import dog3 from '../../../../../assets/Store/Deals/Impact/dog3.png';
import dog4 from '../../../../../assets/Store/Deals/Impact/dog4.png';
import dog5 from '../../../../../assets/Store/Deals/Impact/dog5.png';
import dog6 from '../../../../../assets/Store/Deals/Impact/dog6.png';
import dog7 from '../../../../../assets/Store/Deals/Impact/dog7.png';
import dog8 from '../../../../../assets/Store/Deals/Impact/dog8.png';
import dog9 from '../../../../../assets/Store/Deals/Impact/dog9.png';
import dog10 from '../../../../../assets/Store/Deals/Impact/dog10.png';
import dog11 from '../../../../../assets/Store/Deals/Impact/dog11.png';
import dog12 from '../../../../../assets/Store/Deals/Impact/dog12.png';
import dog13 from '../../../../../assets/Store/Deals/Impact/dog13.png';
import dog14 from '../../../../../assets/Store/Deals/Impact/dog14.png';
import dog15 from '../../../../../assets/Store/Deals/Impact/dog15.png';
import cat1 from '../../../../../assets/Store/Deals/Impact/cat1.png';
import cat2 from '../../../../../assets/Store/Deals/Impact/cat2.png';
import cat3 from '../../../../../assets/Store/Deals/Impact/cat3.png';
import cat4 from '../../../../../assets/Store/Deals/Impact/cat4.png';
import cat5 from '../../../../../assets/Store/Deals/Impact/cat5.png';
import cat6 from '../../../../../assets/Store/Deals/Impact/cat6.png';
import cat7 from '../../../../../assets/Store/Deals/Impact/cat7.png';
import cat8 from '../../../../../assets/Store/Deals/Impact/cat8.png';
import cat9 from '../../../../../assets/Store/Deals/Impact/cat9.png';
import cat10 from '../../../../../assets/Store/Deals/Impact/cat10.png';
import cat11 from '../../../../../assets/Store/Deals/Impact/cat11.png';
import cat12 from '../../../../../assets/Store/Deals/Impact/cat12.png';
import cat13 from '../../../../../assets/Store/Deals/Impact/cat13.png';
import cat14 from '../../../../../assets/Store/Deals/Impact/cat14.png';
import cat15 from '../../../../../assets/Store/Deals/Impact/cat15.png';

import hotelIcon from '../../../../../assets/Landing/hotelIcon.png';
import daycareIcon from '../../../../../assets/Landing/daycareIcon.png';
import groomingIcon from '../../../../../assets/Landing/groomingIcon.png';
import adventureIcon from '../../../../../assets/Landing/adventureIcon.png';
import shuttleIcon from '../../../../../assets/Landing/shuttleIcon.png';
import trainingIcon from '../../../../../assets/Landing/trainingIcon.png';
import apparelsIcon from '../../../../../assets/Landing/apparelsIcon.png';
import homesIcon from '../../../../../assets/Landing/homesIcon.png';
import foodIcon from '../../../../../assets/Landing/foodIcon.png';

import impactIcon1 from '../../../../../assets/Store/Deals/Impact/impactIcon1.png';
import impactIcon2 from '../../../../../assets/Store/Deals/Impact/impactIcon2.png';
import impactIcon3 from '../../../../../assets/Store/Deals/Impact/impactIcon3.png';

import { XButton } from '../../../../Utilities/components/Buttons';

const Impact = () => {
    const navigate = useNavigate();
    const { 
        loggedIn,
        setBookingData
    } = useContext(StateContext);

  // Assume we have a function to get the number of animals helped
  const totalAnimalsHelped = 50; // This number would be fetched from your data source
  const animalsPerRow = 5;
  const maxAnimalsDisplayed = 25;

  const animalImages = [
    dog1, dog2, dog3, dog4, dog5, dog6, dog7, dog8, dog9, dog10,
    dog11, dog12, dog13, dog14, dog15, cat1, cat2, cat3, cat4, cat5,
    cat6, cat7, cat8, cat9, cat10, cat11, cat12, cat13, cat14, cat15
  ];

  const handleMenuButtonClicked = (page) => {
    navigate(`/${page}`);
    window.scrollTo(0, 0);
    setBookingData(prevData => ({
      ...prevData,
      bookingType: page
    }));
    localStorage.setItem('bookingType', page);
  }
  
  const getRandomAnimalImage = () => {
    const randomIndex = Math.floor(Math.random() * animalImages.length);
    return animalImages[randomIndex];
  };

  return (
    <div>
        <XButton />
        <img className='impact-hero-image' src={impactBackground}></img>
        <div className='impact-container'>
            <div className='impact-how-many-animals'>
                <h3 className='impact-how-many-animals-header'>
                    <span className='impact-how-many-animals-header-bold'>{totalAnimalsHelped} animals helped</span> from your bookings
                </h3>
                <div className='animal-grid'>
                {Array.from({ length: Math.min(totalAnimalsHelped, maxAnimalsDisplayed) }, () => (
                    <img key={Math.random()} src={getRandomAnimalImage()} alt="Animal" className='animal-image'/>
                ))}
                </div>
            </div>
        </div>

        <div className='impact-how-to-impact-background'>
            <div className='impact-container-without-padding'>
                <div className='impact-how-to-impact'>
                        <h3 className='impact-how-to-impact-header'>Make an impact when you book</h3>
                        {/* <div className="store-container"> */}
                            <div className="container impact-menu-container">
                            <div className="row">
                                <div className="col impact-menu-button-container">
                                <div className='impact-button-image-container'>
                                    <img className="impact-menu-button-image" src={hotelIcon} alt="Hotel Icon" onClick={() => handleMenuButtonClicked('store/services/hotel')} />
                                </div>
                                <p className="impact-menu-button-title">Hotel</p>
                                </div>
                                <div className="col impact-menu-button-container">
                                <div className='impact-button-image-container'>
                                    <img className="impact-menu-button-image" src={daycareIcon} alt="Daycare Icon" onClick={() => handleMenuButtonClicked('store/services/daycare')} />
                                </div>
                                <p className="impact-menu-button-title">Daycare</p>
                                </div>
                                <div className="col impact-menu-button-container">
                                <div className='impact-button-image-container'>
                                    <img className="impact-menu-button-image" src={groomingIcon} alt="Grooming Icon" onClick={() => handleMenuButtonClicked('store/services/grooming')} />
                                </div>
                                <p className="impact-menu-button-title">Grooming</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col impact-menu-button-container">
                                <div className='impact-button-image-container'>
                                    <img className="impact-menu-button-image" src={trainingIcon} alt="Dog Training Icon" onClick={() => handleMenuButtonClicked('store/services/training')} />
                                </div>
                                <p className="impact-menu-button-title">Training</p>
                                </div>
                                <div className="col impact-menu-button-container">
                                <div className='impact-button-image-container'>
                                    <img className="impact-menu-button-image" src={adventureIcon} alt="Adventure Icon" onClick={() => handleMenuButtonClicked('store/services/adventure')} />
                                </div>
                                <p className="impact-menu-button-title">Adventure</p>
                                </div>
                                <div className="col impact-menu-button-container">
                                <div className='impact-button-image-container'>
                                    <img className="impact-menu-button-image" src={shuttleIcon} alt="Food Icon" onClick={() => handleMenuButtonClicked('store/services/shuttle')} />
                                </div>
                                <p className="impact-menu-button-title">Shuttle</p>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className="col store-menu-button-container">
                                <div className='store-button-image-container'>
                                    <img className="store-menu-button-image" src={apparelsIcon} alt="Apparels Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
                                </div>
                                <p className="store-menu-button-title">Apparels</p>
                                </div>
                                <div className="col store-menu-button-container">
                                <div className='store-button-image-container'>
                                    <img className="store-menu-button-image" src={homesIcon} alt="Homes Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
                                </div>
                                <p className="store-menu-button-title">Homes</p>
                                </div>
                                <div className="col store-menu-button-container">
                                <div className='store-button-image-container'>
                                    <img className="store-menu-button-image" src={foodIcon} alt="Health & Wellness Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
                                </div>
                                <p className="store-menu-button-title">Food & Health</p>
                                </div>
                            </div> */}
                        {/* </div> */}
                    {/* </div> */}
                    </div>
                </div>
            </div>
        </div>

        <div className='impact-container'>
            <div className='impact-did-you-know'>
                <h3 className='impact-did-you-know-header'>Did you know?</h3>
                <p className='impact-did-you-know-paragraph1'>We <span className='impact-how-many-animals-header-bold'>help animals</span> every time you book on Pawsible.</p>
                <div className="impact-did-you-know-item">
                    <img src={impactIcon1} alt="Icon" className="impact-did-you-know-icon" />
                    <div className="impact-did-you-know-text-container">
                        <p className='impact-did-you-know-paragraph2'>Who do you partner with?</p>
                        <p className='impact-did-you-know-paragraph2Sub'>Local animal rescue organizations & shelters.</p>
                    </div>
                </div>
                <div className="impact-did-you-know-item">
                    <img src={impactIcon2} alt="Icon" className="impact-did-you-know-icon" />
                    <div className="impact-did-you-know-text-container">
                        <p className='impact-did-you-know-paragraph2'>How many animals has Pawsible helped?</p>
                        <p className='impact-did-you-know-paragraph2Sub'>Over 1000 animals and counting!</p>
                    </div>
                </div>
                <div className="impact-did-you-know-item">
                    <img src={impactIcon3} alt="Icon" className="impact-did-you-know-icon" />
                    <div className="impact-did-you-know-text-container">
                        <p className='impact-did-you-know-paragraph2'>What are some other ways to help?</p>
                        <p className='impact-did-you-know-paragraph2Sub'>Volunteer, adopt, and donate to local shelters.</p>
                    </div>
                </div>
            </div>
        </div>


      
    </div>
  )
}

export default Impact;
