import React, { useContext } from 'react';
import './About.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../components/StateProvider';

import aboutIcon from '../../../assets/Pages/About/aboutIcon.png';
import pageMainPhoto from '../../../assets/Store/Services/pageMainPhoto.png';

import communityIcon from '../../../assets/Pages/About/communityIcon.png';
import compassionIcon from '../../../assets/Pages/About/compassionIcon.png';
import innovationIcon from '../../../assets/Pages/About/innovationIcon.png';
import integrityIcon from '../../../assets/Pages/About/integrityIcon.png';
import educationIcon from '../../../assets/Pages/About/educationIcon.png';


import Footer from '../../../components/Footer';
import LetsGetStarted from '../../Utilities/components/LetsGetStarted';

const About = () => {
  const navigate = useNavigate();
  const { 
    loggedIn
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    loggedIn 
    ? navigate(`/`)
    : navigate(`/login`);
  }

  return (
    <>
      <div className='about-background-one-container'>
        <div className='container'>
          <div className="about-container">
            <img className='about-page-icon' src={aboutIcon} />
            <h2 className='about-page-heading'>Pawsible: Where Pets Are Family Members</h2>
            <p className='about-page-paragraph'>Designed by pet lovers, for pet lovers.</p>
            <div className="centered-container">
              <button className="about-page-button" onClick={handleBookingButtonClick}>START NOW</button>
            </div>
            <img className='about-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>

      <div className='about-background-two-container'>
        <div className='mission-container'>
            <h2 className='about-mission-header'>OUR MISSION</h2>
            <p className='about-mission-paragraph'>&nbsp;&nbsp;We believe that every pet deserves <span className='bold-text'>love, care, and a happy home</span>. Through Pawsible, we aim to empower pet owners with knowledge, connect them with a supportive community, and provide practical solutions to everyday pet care challenges.</p>
        </div>

        <div className='about-background-three-container'>
            <div className='container'>
                <h2 className='about-safety-heading'>WHY CHOOSE PAWSIBLE</h2>
                <ul>
                    <li>
                    <div className="about-services-item">
                        <img className="about-services-item-icon" src={communityIcon}/>
                        <div className="about-services-item-content">
                        <h4 className="about-services-item-heading">Community</h4>
                        <p className="about-services-item-paragraph">
                            Building a strong, supportive, and engaged community of pet lovers.
                        </p>
                        </div>
                    </div>
                    </li>
                    <li>
                    <div className="about-services-item">
                        <img className="about-services-item-icon" src={compassionIcon}/>
                        <div className="about-services-item-content">
                        <h4 className="about-services-item-heading">Compassion</h4>
                        <p className="about-services-item-paragraph">
                            Promoting kindness, understanding, and empathy for all animals.
                        </p>
                        </div>
                    </div>
                    </li>
                    <li>
                    <div className="about-services-item">
                        <img className="about-services-item-icon" src={innovationIcon}/>
                        <div className="about-services-item-content">
                        <h4 className="about-services-item-heading">Innovation</h4>
                        <p className="about-services-item-paragraph">
                            Continuously improving and innovating to meet the needs of pets and their owners.
                        </p>
                        </div>
                    </div>
                    </li>
                    <li>
                    <div className="about-services-item">
                        <img className="about-services-item-icon" src={integrityIcon}/>
                        <div className="about-services-item-content">
                        <h4 className="about-services-item-heading">Integrity</h4>
                        <p className="about-services-item-paragraph">
                            Operating with honesty, transparency, and respect for our users and their pets.
                        </p>
                        </div>
                    </div>
                    </li>
                    <li>
                    <div className="about-services-item">
                        <img className="about-services-item-icon" src={educationIcon}/>
                        <div className="about-services-item-content">
                        <h4 className="about-services-item-heading">Education</h4>
                        <p className="about-services-item-paragraph">
                            Providing accurate, vetted information to help owners make informed decisions about their pet’s care.
                        </p>
                        </div>
                    </div>
                    </li>
                </ul>
            </div>
        </div>

          <div className='about-background-four-container'>
            <LetsGetStarted />
          </div>

          <div className='about-background-three-container'>
            <div className='partner-container'>
                <h2 className='partner-heading'>Partner with us</h2>
                <p className='partner-message'>Join forces with Pawsible and enhance your business with our innovative pet care solutions. Collaborate to offer your customers the very best in pet products and services. Let's grow together and make pet ownership rewarding for everyone.</p>
                <button className='about-partner-button'>Learn more > </button>
            </div>
          </div>

          <div className='about-background-three-container'>
            <div className='join-us-container'>
                <h2 className='join-us-heading'>Join our team</h2>
                <p className='join-us-message'>Become a part of Pawsible, where passion for pets meets innovation. Explore our career opportunities and help us make a pawsitive impact!</p>
                <button className='about-join-us-button'>Browse job openings > </button>
            </div>
          </div>

          <div className='about-background-three-container'>
              <div className='franchise-opportunity-container'>
                  <h2 className='franchise-heading'>Franchise opportunities</h2>
                  <p className='franchise-message'>Be at the forefront of the pet care industry with our comprehensive support system, proven business model, and dedication to innovation. Whether you're a seasoned entrepreneur or new to the business world, a Pawsible franchise offers an unparalleled opportunity to grow and succeed.</p>
                  <button className='about-franchise-button'>Discover more > </button>
              </div>
          </div>

          <hr className='deals-breakline' />

          <Footer />
      </div>
    </>
  );
};

export default About;
