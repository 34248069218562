import React from 'react';

const VIPPreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  return (
    <div className="vip-preferences">
      <h3>VIP Membership Benefits</h3>
      <ul>
        <li>30 days of Daycare</li>
        <li>Hand-made Premium Meals</li>
        <li>Shuttle Service for Daycare</li>
        <li>Access to Exclusive Events</li>
        <li>1 Adventure of your choice per month</li>
        <li>20% Discounts on any Service and Products</li>
        <li>Regular Hygiene and Health check-ups</li>
        {/* <li>Priority booking for any Appointments</li> */}
      </ul>
    </div>
  );
};

export default VIPPreferences;
