import React from 'react';
import './Support.css';
import { WhiteBackButton } from '../../Utilities/components/Buttons';
import FAQ from './FAQ';

import phoneIcon from '../../../assets/Utilities/phoneIcon.png';
import emailIcon from '../../../assets/Utilities/emailIcon.png';
import addressIcon from '../../../assets/Utilities/addressIcon.png';

const Support = () => {
  const handlePhoneClick = () => {
    window.location.href = 'tel:213-600-7282';
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:support@pawsible.app';
  };

  const handleAddressClick = () => {
    window.open('https://www.google.com/maps/search/?api=1&query=555+N+Spring+St+A111,+Los+Angeles,+CA+90012', '_blank');
  };

  const handleTextClick = () => {
    window.location.href = 'sms:213-600-7282';
  };

  return (
    <div>
      <div className='support-backdrop'>
        <WhiteBackButton />
        <div className='support-container'>
          <div className="business-info">
            <h2 className='support-heading'>Support Center</h2>
            <div className='support-card-container-one'>
              <p className='support-message-black'>
                <span onClick={handlePhoneClick} className='support-link'>
                  <img src={phoneIcon} alt="Phone Icon" className="support-icon" /> 213-600-7282
                </span><br />
                <span onClick={handleEmailClick} className='support-link'>
                  <img src={emailIcon} alt="Email Icon" className="support-icon" /> support@pawsible.app
                </span><br />
                <span onClick={handleAddressClick} className='support-link'>
                  <img src={addressIcon} alt="Address Icon" className="support-icon" /> 555 N Spring St. A111, Los Angeles, CA 90012
                </span>
              </p>
            </div>
          </div>

          <h2 className='support-heading'>Live Chat</h2>
          <div className='support-card-container-two'>
            <div className="live-chat">
              <p className='support-message-black'>
                Please don't hesitate to reach out to our support team. We are here to help!
              </p>
              <button className='support-text-now-button' onClick={handleTextClick}>Chat Now</button>  
            </div>
          </div >
        </div>
      </div>
      <div className='support-card-container-three'>
        <h3 className='support-faq-heading'>FAQs</h3>
        <div className='support-card-container-two'>
          <div className="faq-section">
            <FAQ />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
