import React from 'react';
import './GroomingOptions.css';
import GroomingPreferences from './GroomingPreferences';

const GroomingOptions = ({ selectedPetForGrooming, groomingBookingData, setGroomingBookingData }) => {

  const handleOptionSelect = (question, option) => {
    switch (question) {
      case 'size':
        setGroomingBookingData((prevData) => ({
          ...prevData,
          groomingOptions: {
            ...prevData.groomingOptions,
            selectedSize: option,
          },
        }));
        break;
      case 'package':
        setGroomingBookingData((prevData) => ({
          ...prevData,
          groomingOptions: {
            ...prevData.groomingOptions,
            selectedPackage: option,
          },
        }));
        break;
      case 'additionalService':
        setGroomingBookingData((prevData) => ({
          ...prevData,
          groomingOptions: {
            ...prevData.groomingOptions,
            selectedPackage: option,
          },
        }));
        break;
      default:
        break;
    }
  };

  const selectedSize = groomingBookingData.groomingOptions.selectedSize;
  const selectedPackage = groomingBookingData.groomingOptions.selectedPackage;

  return (
    <div>
      <div className="options-container">
        <h2 className='options-header'><span style={{ color: '#FFB516' }}>{selectedPetForGrooming.name}</span>'s Grooming Instructions</h2>
      
        {/* Pet Size */}
        <div className="option-group">
          <h3>Pet Size</h3>
          <div className="options">
            <button
              className={`option-button ${selectedSize === 'small' ? 'selected-size-button' : ''}`}
              onClick={() => handleOptionSelect('size', 'small')}
              disabled={selectedSize === 'small'}
            >
              Small (~ 10 lbs)
            </button>
            <button
              className={`option-button ${selectedSize === 'medium' ? 'selected-size-button' : ''}`}
              onClick={() => handleOptionSelect('size', 'medium')}
              disabled={selectedSize === 'medium'}
            >
              Medium (11 ~ 30 lbs)
            </button>
            <button
              className={`option-button ${selectedSize === 'large' ? 'selected-size-button' : ''}`}
              onClick={() => handleOptionSelect('size', 'large')}
              disabled={selectedSize === 'large'}
            >
              Large (31 ~ 50 lbs)
            </button>
            <button
              className={`option-button ${selectedSize === 'x-large' ? 'selected-size-button' : ''}`}
              onClick={() => handleOptionSelect('size', 'x-large')}
              disabled={selectedSize === 'x-large'}
            >
              X-Large (51 lbs ~)
            </button>
          </div>
        </div>

        {/* Grooming Package */}
        <div className="option-group">
          <h3>Grooming Packages</h3>
          <div className="options">
            {['nail', 'bath', 'grooming', 'teeth'].map((option) => (
              <button
                key={option}
                className={`option-button ${selectedPackage === option ? 'selected-package-button' : ''}`}
                onClick={() => handleOptionSelect('package', option)}
              >
                {option === 'nail'
                  ? 'Nail'
                  : option === 'bath'
                  ? 'Bath'
                  : option === 'grooming'
                  ? 'Grooming'
                  : option === 'teeth'
                  ? 'Deep Teeth Cleaning'
                  : option}
              </button>
            ))}
          </div>
        </div>

        <div className='option-group'>
          <GroomingPreferences 
            groomingBookingData={groomingBookingData} 
            setGroomingBookingData={setGroomingBookingData}
          />
        </div>
      </div>
    </div>
  );
};

export default GroomingOptions;