import React, { useState } from 'react';
import './Career.css'; // Make sure to create a CSS file for styling
import Footer from '../../../components/Footer';

// Import any relevant assets
import careerIcon from '../../../assets/Pages/Career/careerIcon.png';
import pageMainPhoto from '../../../assets/Store/Services/pageMainPhoto.png';

import jobListings from './jobListings';

const Career = () => {
  const [expandedJobIndex, setExpandedJobIndex] = useState(null);

  const handleLearnMoreClick = (index) => {
    setExpandedJobIndex(index === expandedJobIndex ? null : index);
  };

  return (
    <>
      <div className='career-background-one-container'>
        <div className='container'>
          <div className="career-container">
            <img className='career-page-icon' src={careerIcon} alt="Careers at Pawsible" />
            <h2 className='career-page-heading'>Pawsible: Where Passion Meets Profession</h2>
            <p className='career-page-paragraph'>
              Transform your passion for pets into a fulfilling career. Explore opportunities with Pawsible and help us shape the future of pet care.
            </p>
          </div>
          <img className='about-page-main-photo' src={pageMainPhoto} />
        </div>
      </div>

      <div className='career-opportunities-container'>
        <div className='container'>
          <h2 className='opportunities-heading'>Current Job Openings</h2>
          <ul className='job-listings'>
            {jobListings.map((job, index) => (
              <li key={index} className='job-item'>
                <h3 className='job-title'>{job.title}</h3>
                <p><strong>Location:</strong> {job.location}</p>
                {/* <p>{job.description}</p> */}
                <div className='job-item-buttons'>
                  <button 
                    className='learn-more-button'
                    onClick={() => handleLearnMoreClick(index)}
                  >
                    {expandedJobIndex === index ? 'Show Less' : 'Learn More'}
                  </button>
                  <button className='apply-now-button'>Apply Now</button>
                </div>
                {expandedJobIndex === index && (
                  <div className='job-details'>
                    <p className='job-details-p'><strong>Responsibilities:</strong></p>
                    <ul>{job.responsibilities.map((task, i) => <li key={i}>{task}</li>)}</ul>
                    <p className='job-details-p'><strong>Requirements:</strong></p>
                    <ul>{job.requirements.map((req, i) => <li key={i}>{req}</li>)}</ul>
                    <p><strong>Working Hours:</strong> {job.workingHours}</p>
                    <p><strong>Compensation:</strong> {job.compensation}</p>
                    <p><strong>Opportunities for Growth:</strong> {job.growthOpportunities}</p>
                    <p><strong>Work Environment:</strong> {job.workEnvironment}</p>
                    <p><strong>Application Process:</strong> {job.applicationProcess}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <hr className='deals-breakline' />

      <Footer />
    </>
  );
};

export default Career;
