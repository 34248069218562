import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BackButton } from '../../../../Utilities/components/Buttons';

function StaffProfile() {
    const { uid } = useParams(); // Get the ID from URL
    const navigate = useNavigate();
    const [member, setMember] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        role: ''
    });

    useEffect(() => {
        fetchMember();
    }, [uid]);

    const fetchMember = async () => {
        try {
            const response = await axios.get(`http://localhost:6060/api/users/${uid}`);
            setMember(response.data);
            setFormData({
                username: response.data.username,
                email: response.data.email,
                role: response.data.role
            });
        } catch (error) {
            console.error('Error fetching staff member:', error);
            navigate('/admin/staff-manager');
            window.scrollTo(0, 0);
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`http://localhost:6060/api/users/${uid}`, formData);
            setIsEditing(false);
            fetchMember(); // Refetch the member details after updating
        } catch (error) {
            console.error('Error updating staff member:', error);
        }
    };

    return (
        <div className='container'>
            <BackButton />
            <h2>Staff Member Profile</h2>
            {member && !isEditing ? (
                <div>
                    <p><strong>Username:</strong> {member.username}</p>
                    <p><strong>Email:</strong> {member.email}</p>
                    <p><strong>Role:</strong> {member.role}</p>
                    <button onClick={handleEditToggle}>Edit</button>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="role">Role</label>
                    <input
                        type="text"
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit">Save</button>
                    <button type="button" onClick={handleEditToggle}>Cancel</button>
                </form>
            )}
        </div>
    );
}

export default StaffProfile;
