export default function sanitizeDate(dateString) {
    // Check if dateString is an array (date range)
    if (Array.isArray(dateString)) {
        const startDate = new Date(dateString[0]);
        const endDate = new Date(dateString[1]);

        return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
    // If dateString is a single date
    else {
        const date = new Date(dateString);
        return formatDate(date);
    }
}

// Helper function for formatting date
function formatDate(date) {
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year

    // Ensuring two digits for month and day
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedMonth}/${formattedDay}/${year}`;
}
