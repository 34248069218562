import React from 'react';

const AKCPreferences = () => {
  const akcTrainingPreferences = {
    programDuration: 'Typically 6-8 weeks.',
    trainingGoals: [
      'Prepare dogs for the AKC CGC evaluation',
      'Demonstrate good manners and obedience in various situations',
    ],
    curriculum: 'Covers advanced obedience, socialization, and testing criteria for the CGC exam.',
    trainingMethods: 'Positive reinforcement and advanced training techniques.',
    eligibility: 'Open to dogs that have completed basic obedience training.',
    benefits: [
      "Achieving CGC certification demonstrates your dog's good behavior",
      'Allows them to participate in therapy work or other canine activities.',
    ],
  };

  return (
    <div className="akc-training-preferences">
      <h3>AKC Canine Good Citizen (CGC) Training Preferences</h3>
      <ul>
        <li>
          <strong>Program Duration:</strong> {akcTrainingPreferences.programDuration}
        </li>
        <li>
          <strong>Training Goals:</strong>
          <ul>
            {akcTrainingPreferences.trainingGoals.map((goal, index) => (
              <li key={index}>{goal}</li>
            ))}
          </ul>
        </li>
        <li>
          <strong>Curriculum:</strong> {akcTrainingPreferences.curriculum}
        </li>
        <li>
          <strong>Training Methods:</strong> {akcTrainingPreferences.trainingMethods}
        </li>
        <li>
          <strong>Eligibility:</strong> {akcTrainingPreferences.eligibility}
        </li>
        <li>
          <strong>Benefits:</strong>
          <ul>
            {akcTrainingPreferences.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AKCPreferences;
