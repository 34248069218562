

export const AdventureBookingCalculator = (bookingData, serviceDetails) => {
  const adventurePrices = serviceDetails.adventure; // Assuming adventure service prices are defined
  const basePriceForWalk = serviceDetails.walk ? serviceDetails.walk[0].price : 20;
  const discountRate = 0.10; // 10% discount rate
  let originalTotal = 0;
  let total = 0;

  if (bookingData.selectedPackage) {
    const selectedPackage = bookingData.selectedPackage;
    const numberOfPets = bookingData.selectedPets.length; // Number of selected pets
    let packagePrice = 100; // Default value in case no package is found

    // Find the matching package price
    if (adventurePrices) {
      const matchedPackage = adventurePrices.find(pkg => pkg.title === selectedPackage);
      if (matchedPackage) {
        packagePrice = matchedPackage.price;
      }
    }

    if (numberOfPets > 0) {
      // Always charge full price for the first pet
      originalTotal += packagePrice;
      total += packagePrice;

      // Food prices adjusted for the number of pets
      if (bookingData.foodPreference === 'premium') {
        total += 20 * numberOfPets;
        originalTotal += 20 * numberOfPets;
      } else if (bookingData.foodPreference === 'basic') {
        total += 5 * numberOfPets;
        originalTotal += 5 * numberOfPets;
      }

      // Add the cost of the "walk" service only if selected
      if (bookingData.additionalServices && bookingData.additionalServices.walk) {
        total += basePriceForWalk * numberOfPets; // Multiply by the number of pets
        originalTotal += basePriceForWalk * numberOfPets; // Apply to original total as well
      }

      // Apply compound discount for additional pets
      for (let i = 1; i < numberOfPets; i++) {
        const discountedPrice = packagePrice * Math.pow(1 - discountRate, i);
        originalTotal += packagePrice;
        total += discountedPrice;
      }
    }
  }

  return { originalPrice: originalTotal, discountedPrice: total };
};

export default AdventureBookingCalculator;
