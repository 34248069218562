import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../../StateProvider';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Popup from '../../../../../../Utilities/components/Popup';
import { getPaymentMethodById, updatePaymentMethod, deletePaymentMethod, setDefaultPaymentMethod } from '../../../../../../Utilities/api';
import { WhiteBackButton, BlackDeleteButton } from '../../../../../../Utilities/components/Buttons';
import './EditPaymentMethod.css';
import Footer from '../../../../../../Footer';

const EditPaymentMethod = () => {
  const { id } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { user, refreshUserData, loading, setLoading } = useContext(StateContext);
  const [showPopup, setShowPopup] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const [paymentMethod, setPaymentMethod] = useState({});
  const [isDefault, setIsDefault] = useState(false);
  const [error, setError] = useState(null);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '12px',
        color: '#424770',
        padding: '15px',
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const method = await getPaymentMethodById(id);
        setPaymentMethod(method);
        setCardholderName(method.cardholderName || '');
        setIsDefault(user.defaultPaymentMethodId === id);
      } catch (error) {
        console.error('Failed to fetch payment method', error);
      }
    };
    fetchPaymentMethod();
  }, [id, user.defaultPaymentMethodId]);

  const handleUpdatePaymentMethod = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod: newPaymentMethod, error: cardError } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName,
        },
      });

      if (cardError) {
        setError(cardError.message);
        setLoading(false);
        return;
      }

      const updatedMethod = {
        paymentMethodId: paymentMethod.stripePaymentMethodId, // The old payment method ID to be replaced
        newPaymentMethodId: newPaymentMethod.id, // The new payment method ID created
        stripeCustomerId: user.stripeCustomerId,
        cardholderName: cardholderName,
      };

      // Send the updated method details to the backend
      await updatePaymentMethod(user.uid, updatedMethod); // Ensure this function sends a proper request to your backend API

      if (isDefault) {
        await setDefaultPaymentMethod(user.uid, newPaymentMethod.id);
      }

      await refreshUserData(user.uid);
      navigate(-1);
    } catch (error) {
      console.error('Failed to update payment method', error);
      setError('Failed to update payment method');
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePaymentMethod = async () => {
    setShowPopup(true);
    window.scroll(0,0);
  };

  const handlePopupDelete = async () => {
    try {
      await deletePaymentMethod(id);
      await refreshUserData(user.uid); // Refresh user data to reflect the updated image
      navigate(-1);
    } catch (error) {
      console.error('Failed to delete payment method', error);
    }
    setShowPopup(false);
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
  };

  const handleDefaultChange = async (e) => {
    const isNowDefault = e.target.checked;
    setIsDefault(isNowDefault);

    try {
      if (isNowDefault) {
        await setDefaultPaymentMethod(user.uid, id);
      } else if (user.defaultPaymentMethodId === id) {
        await setDefaultPaymentMethod(user.uid, null);
      }
      await refreshUserData(user.uid);
    } catch (error) {
      console.error('Failed to set default payment method', error);
    }
  };

  return (
    <>
      <div className='edit-payment-backdrop'>
        <div className="container">
          <Popup isOpen={showPopup} handleClose={handlePopupCancel}>
            <p className='payment-edit-popup-header'>Delete Payment Method?</p>
            <div className='payment-edit-popup-button-container'>
              <button onClick={handlePopupCancel} className='payment-edit-popup-cancel'>Cancel</button>
              <button onClick={handlePopupDelete} className='payment-edit-popup-delete'>Delete</button>
            </div>
          </Popup>
          <div className='payment-edit-button-container'>
            <WhiteBackButton />
            <BlackDeleteButton onDelete={handleDeletePaymentMethod} />
          </div>
          <div className="edit-payment-method">
            <h2 className='edit-payment-header'>Update Payment Method</h2>
            <div className='edit-payment-info'>
              {Object.keys(paymentMethod).length > 0
                ? (
                  <div>
                    <p className='edit-payment-info-header'>Card Info</p>
                    <hr className='edit-payment-breakline' />
                    <p className='edit-payment-card'>Card Number: {paymentMethod.cardNumber}</p>
                    <p className='edit-payment-cardType'>Type: {paymentMethod.cardType.charAt(0).toUpperCase() + paymentMethod.cardType.slice(1)}</p>
                    <p className='edit-payment-expiry'>Expires: {paymentMethod.expiryMonth.padStart(2, '0')}/{paymentMethod.expiryYear.slice(-2)}</p>
                  </div>
                )
                : null
              }
            </div>
            <form>
              <label htmlFor="cardholderName">Cardholder Name</label>
              <input
                type="text"
                id="cardholderName"
                name="cardholderName"
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)}
                placeholder="Enter Here"
              />
            </form>
            <div className='add-payment-stripe-container'>
              <label className='add-payment-stripe-label'>Card Details</label>
              <CardElement className='StripeElement' options={cardElementOptions} />
            </div>
          </div>
        </div>
      </div>
      <div className='edit-payment-method-submit-container'>
        <div className="default-payment-method">
          <label>
            <input
              type="checkbox"
              checked={isDefault}
              onChange={handleDefaultChange}
            />
            Set as Default
          </label>
        </div>
        <button onClick={handleUpdatePaymentMethod} className='edit-payment-method-submit' disabled={loading}>
          {loading ? 'Processing...' : 'Update'}
        </button>
      </div>
      <Footer />
    </>
  );
};

export default EditPaymentMethod;
