import React, { useState, useEffect } from 'react';
import './ServiceConfig.css';
import { fetchConfig, updateConfig } from '../../../../Utilities/api';
import { BackButton } from '../../../../Utilities/components/Buttons';

function GroomingConfig() {
    const [config, setConfig] = useState({
        numberOfGroomers: '',
        sessionDuration: '',
        bufferTime: '',
        prices: { small: '', medium: '', large: '' }
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await fetchConfig('grooming');
                setConfig(result.config || {});
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };

        loadData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (['small', 'medium', 'large'].includes(name)) {
            setConfig(prev => ({
                ...prev,
                prices: { ...prev.prices, [name]: value }
            }));
        } else {
            setConfig(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await updateConfig('grooming', config);
            alert('Configuration saved successfully!');
        } catch (error) {
            console.error('Error saving configuration:', error);
        }
    };

    return (
        <div className="service-config-container">
            <BackButton />
            <h2>Grooming Configuration</h2>
            <form onSubmit={handleSubmit}>
                <label>Number of Groomers:
                    <input type="number" name="numberOfGroomers" placeholder="Enter number of groomers" value={config.numberOfGroomers} onChange={handleChange} />
                </label>
                <label>Grooming Session Duration (minutes):
                    <input type="number" name="sessionDuration" placeholder="Enter duration per session" value={config.sessionDuration} onChange={handleChange} />
                </label>
                <label>Buffer Time Between Sessions (minutes):
                    <input type="number" name="bufferTime" placeholder="Enter buffer time" value={config.bufferTime} onChange={handleChange} />
                </label>
                <div>Grooming Prices by Pet Size:
                    <label>Small:
                        <input type="number" name="small" placeholder="Price" value={config.prices.small} onChange={handleChange} />
                    </label>
                    <label>Medium:
                        <input type="number" name="medium" placeholder="Price" value={config.prices.medium} onChange={handleChange} />
                    </label>
                    <label>Large:
                        <input type="number" name="large" placeholder="Price" value={config.prices.large} onChange={handleChange} />
                    </label>
                </div>
                <button type="submit">Save Changes</button>
            </form>
        </div>
    );
}

export default GroomingConfig;
