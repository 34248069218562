import React from 'react';

const PremiumPreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  return (
    <div className="premium-preferences">
      <h3>Premium Membership Benefits</h3>
      <ul>
        <li>30 days of Daycare</li>
        <li>High Quality & Nutritious Meals</li>
        <li>Access to Exclusive Events</li>
        <li>1 Adventure of your choice per month</li>
        <li>15% Discounts on any Service and Products</li>
        <li>Regular Hygiene and Health check-ups</li>
        {/* <li>Priority booking for any Appointments</li> */}
      </ul>
    </div>
  );
};

export default PremiumPreferences;
