import React, { useEffect, useState } from 'react';
import './TimeSlots.css';

const TimeSlots = ({ type, lessonType, timeSlotsData, handleTimeChange, selectedTimeSlot }) => {
    const [availableSlots, setAvailableSlots] = useState({});

    const formatTime = (timeString) => {
        const date = new Date(timeString);
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        const options = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' };
        return date.toLocaleTimeString('en-US', options);
    };

    useEffect(() => {
        const now = new Date();
        
        // Filter time slots based on lesson type if it's a training booking
        let filteredSlots = type === 'training' && lessonType
            ? timeSlotsData.filter(slot => typeof slot === 'string' || slot.lessonType === lessonType)
            : timeSlotsData;

        // Filter out past time slots
        filteredSlots = filteredSlots.filter(slot => {
            const slotTime = typeof slot === 'string' ? new Date(slot) : new Date(slot.time);
            slotTime.setDate(slotTime.getDate() + 1);
            return slotTime > now;
        });

        // Reduce the time slots to count duplicates and format them
        const slots = filteredSlots.reduce((acc, slot) => {
            const time = typeof slot === 'string' ? formatTime(slot) : formatTime(slot.time);
            if (acc[time]) {
                acc[time].count += 1;
            } else {
                acc[time] = { time: time, count: 1 };
            }
            return acc;
        }, {});

        setAvailableSlots(slots);
    }, [type, lessonType, timeSlotsData]); // Adding dependencies to re-run the effect when they change

    return (
        <div className='booking-time-container'>
            <div className="time-slots-container">
                {Object.values(availableSlots).map((slot, index) => (
                    <button 
                        key={index}
                        className={`time-slot-button ${selectedTimeSlot === slot.time ? 'selected' : ''}`}
                        onClick={() => handleTimeChange('selectedTimeSlot', slot.time)}
                    >
                        {`${slot.time}`}
                    </button>
                ))}
                {Object.keys(availableSlots).length === 0 && (
                    <p className='booking-timeslot-text'>Not available for the selected date.</p>
                )}
            </div>
        </div>
    );
};

export default TimeSlots;
