export const GiftCardPurchaseCalculator = (bookingData) => {
    let originalTotal = 0;
    let total = 0;
  
    // Calculate the gift card amount
    if (bookingData.giftCardData && bookingData.giftCardData.amount) {
      const giftCardAmount = parseFloat(bookingData.giftCardData.amount);
      total += giftCardAmount;
      originalTotal += giftCardAmount;
    }
  
    return { originalPrice: originalTotal, discountedPrice: total };
  };
  
  export default GiftCardPurchaseCalculator;
  