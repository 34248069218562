import React from 'react';
import PuppyPreferences from './PuppyPreferences';
import BasicPreferences from './BasicPreferences';
import BehaviorPreferences from './BehaviorPreferences';
import AKCPreferences from './AKCPreferences';

const TrainingPreferences = ({ trainingBookingData, setTrainingBookingData }) => {
  switch (trainingBookingData.trainingOptions.selectedTrainingType) {
    case 'puppy':
        return <PuppyPreferences 
                    trainingBookingData={trainingBookingData} 
                    setTrainingBookingData={setTrainingBookingData}
                />;
    case 'basic':
        return <BasicPreferences 
                    trainingBookingData={trainingBookingData} 
                    setTrainingBookingData={setTrainingBookingData}
                />;
    case 'behavior':
        return <BehaviorPreferences 
                    trainingBookingData={trainingBookingData} 
                    setTrainingBookingData={setTrainingBookingData}
                />;
    case 'akc':
        return <AKCPreferences 
                    trainingBookingData={trainingBookingData} 
                    setTrainingBookingData={setTrainingBookingData}
                />;
    default:
      return null;
  }
};

export default TrainingPreferences;
