const jobListings = [
    {
        title: 'Receptionist',
        location: 'Los Angeles, CA',
        description: 'The Receptionist role is vital to our customer experience. You will greet clients, schedule appointments, answer inquiries, and manage administrative tasks. A friendly demeanor and excellent communication skills are a must.',
        responsibilities: [
          'Greet clients and their pets with a warm and welcoming attitude.',
          'Schedule appointments and manage the calendar.',
          'Answer phone calls and respond to emails regarding services.',
          'Handle billing, payments, and other administrative tasks.',
        ],
        requirements: [
          'Previous experience in a receptionist or customer service role preferred.',
          'Strong organizational and multitasking skills.',
          'Excellent verbal and written communication abilities.',
          'Comfortable working with pets and their owners.',
        ],
        workingHours: 'Full-time, 40 hours per week, Monday to Friday with occasional weekends.',
        compensation: 'Starting at $16/hour, with benefits including health insurance and paid time off.',
        growthOpportunities: 'Opportunities to grow into administrative or managerial roles within Pawsible.',
        workEnvironment: 'A dynamic, pet-friendly office environment with a focus on customer service.',
        applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    },
    {
      title: 'Daycare Assistant',
      location: 'Los Angeles, CA',
      description: 'As a Daycare Assistant, you will provide attentive care to the pets, ensuring a safe and fun environment. Responsibilities include supervising playtime, feeding, and maintaining a clean and organized daycare area.',
      responsibilities: [
        'Supervise and engage with pets during playtime.',
        'Feed and administer medications as directed.',
        'Maintain cleanliness and organization of the daycare area.',
        'Monitor the health and behavior of pets, reporting any concerns.',
      ],
      requirements: [
        'Passion for animals and experience in pet care.',
        'High school diploma or equivalent.',
        'Previous experience in pet care or similar role preferred.',
        'Strong communication and organizational skills.',
        'Ability to handle pets of various sizes and temperaments.',
        'Strong attention to detail and reliability.',
      ],
      workingHours: 'Full-time, 40 hours per week. Shifts may include weekends and holidays.',
      compensation: 'Starting at $16/hour, with benefits including health insurance and paid time off.',
      growthOpportunities: 'Potential for promotion to Daycare Supervisor or Manager.',
      workEnvironment: 'Fast-paced, fun environment working with a dedicated team of pet lovers.',
      applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    },
    {
      title: 'Boarding Assistant',
      location: 'Los Angeles, CA',
      description: 'The Boarding Assistant role involves caring for pets during their stay with us. Duties include feeding, walking, and providing companionship. You’ll also assist in keeping the boarding area clean and ensuring each pet feels comfortable and loved.',
      responsibilities: [
        'Feed, walk, and care for boarded pets.',
        'Provide companionship and playtime.',
        'Clean and sanitize boarding areas.',
        'Monitor the well-being of pets and report any issues.',
      ],
      requirements: [
        'Passion for animals and experience in pet care.',
        'High school diploma or equivalent.',
        'Previous experience in pet care or similar role preferred.',
        'Strong communication and organizational skills.',
        'Ability to handle pets of various sizes and temperaments.',
        'Strong attention to detail and reliability.',
      ],
      workingHours: 'Full-time, 40 hours per week. Shifts may include weekends and holidays.',
      compensation: 'Starting at $16/hour, with benefits including health insurance and paid time off.',
      growthOpportunities: 'Opportunities for advancement to Senior Boarding Assistant or Manager.',
      workEnvironment: 'A supportive team environment focused on providing the best care for our furry guests.',
      applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    },
    {
      title: 'Groomer',
      location: 'Los Angeles, CA',
      description: 'We are seeking an experienced Groomer who loves working with pets. You will be responsible for grooming dogs and cats, including bathing, trimming, and styling. Ensuring each pet leaves looking their best and feeling great is key to this role.',
      responsibilities: [
        'Perform grooming services including bathing, trimming, and styling.',
        'Communicate with pet owners about grooming needs and preferences.',
        'Maintain grooming tools and equipment.',
        'Ensure a safe and stress-free environment for pets during grooming.',
      ],
      requirements: [
        'Professional grooming experience required.',
        'Certification in pet grooming preferred.',
        'Strong customer service and communication skills.',
        'Ability to handle pets of various sizes and temperaments.',
      ],
      workingHours: 'Full-time, 40 hours per week, with flexibility for weekends and holidays.',
      compensation: 'Starting at $18/hour, with benefits including health insurance and paid time off.',
      growthOpportunities: 'Opportunities to advance to Lead Groomer or Grooming Manager.',
      workEnvironment: 'A creative, supportive environment with a focus on pet care excellence.',
      applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    },
    {
      title: 'Bather',
      location: 'Los Angeles, CA',
      description: 'As a Bather at Pawsible, your primary responsibility will be to bathe and dry pets, preparing them for grooming. Attention to detail and a gentle touch are essential as you work to ensure each pet’s comfort and cleanliness.',
      responsibilities: [
        'Bathe and dry pets in preparation for grooming.',
        'Maintain a clean and sanitary bathing area.',
        'Assist groomers with handling pets as needed.',
        'Ensure pets are comfortable and relaxed during their bath.',
      ],
      requirements: [
        'Previous experience in pet care or bathing preferred.',
        'Ability to handle pets of various sizes and temperaments.',
        'Strong attention to detail and cleanliness.',
        'Physical ability to lift and handle pets safely.',
      ],
      workingHours: 'Full-time, 40 hours per week, with flexibility for weekends and holidays.',
      compensation: 'Starting at $16/hour, with benefits including health insurance and paid time off.',
      growthOpportunities: 'Potential to advance to a grooming position with additional training.',
      workEnvironment: 'A supportive and friendly work environment with a focus on pet well-being.',
      applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    },
    // {
    //   title: 'Veterinary Technician',
    //   location: 'Los Angeles, CA',
    //   description: 'As a Veterinary Technician, you will assist the veterinarian in providing care for our animal patients. Responsibilities include administering medications, assisting in procedures, and maintaining patient records.',
    //   responsibilities: [
    //     'Assist the veterinarian with exams and surgical procedures.',
    //     'Administer medications and vaccines to animals.',
    //     'Maintain accurate patient records and documentation.',
    //     'Provide care and monitoring for animals before and after procedures.',
    //   ],
    //   requirements: [
    //     'Certified Veterinary Technician or equivalent experience required.',
    //     'Strong understanding of veterinary procedures and animal care.',
    //     'Excellent attention to detail and organizational skills.',
    //     'Ability to work well in a team environment.',
    //   ],
    //   workingHours: 'Full-time, 40 hours per week, with some on-call hours.',
    //   compensation: 'Starting at $20/hour, with benefits including health insurance and paid time off.',
    //   growthOpportunities: 'Opportunities to specialize in areas like surgery or anesthesia, or advance to Lead Technician.',
    //   workEnvironment: 'A supportive, professional environment focused on high-quality veterinary care.',
    //   applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    // },
    {
      title: 'Dog Trainer',
      location: 'Los Angeles, CA',
      description: 'We are looking for a skilled Dog Trainer to join our team. You will work with dogs to teach obedience, behavior, and specialty training. Patience, consistency, and a deep understanding of animal behavior are key.',
      responsibilities: [
        'Conduct individual and group training sessions for dogs.',
        'Develop and implement training plans tailored to each dog’s needs.',
        'Provide guidance and support to pet owners on training techniques.',
        'Monitor progress and adjust training methods as needed.',
      ],
      requirements: [
        'Proven experience as a Dog Trainer with references.',
        'Strong understanding of animal behavior and positive reinforcement techniques.',
        'Excellent communication skills and patience.',
        'Ability to handle dogs of all sizes and temperaments.',
      ],
      workingHours: 'Full-time, 35-40 hours per week, with flexibility for evening and weekend sessions.',
      compensation: 'Starting at $18/hour, with benefits including health insurance and paid time off.',
      growthOpportunities: 'Opportunities to develop advanced training programs or move into a training director role.',
      workEnvironment: 'A rewarding environment working with pets and their owners to achieve training goals.',
      applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    },
    {
      title: 'Pet Shuttle Driver',
      location: 'Los Angeles, CA',
      description: 'The Pet Shuttle Driver is responsible for safely transporting pets to and from our facility. A clean driving record and a passion for working with animals are required for this role.',
      responsibilities: [
        'Safely transport pets to and from their homes and our facility.',
        'Ensure all pets are secure and comfortable during transport.',
        'Maintain the cleanliness and safety of the shuttle vehicle.',
        'Communicate with pet owners about pick-up and drop-off times.',
      ],
      requirements: [
        'Valid driver’s license and clean driving record.',
        'Experience working with pets or in a similar role preferred.',
        'Strong customer service skills and reliability.',
        'Ability to handle pets of various sizes and temperaments.',
      ],
      workingHours: 'Part-time, 20-30 hours per week, with some flexibility for early mornings and evenings.',
      compensation: 'Starting at $17/hour, with benefits including health insurance and paid time off.',
      growthOpportunities: 'Potential to expand into logistics coordination or supervisory roles within the transportation department.',
      workEnvironment: 'A mobile work environment with the opportunity to interact with pets and their owners regularly.',
      applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    },
    // {
    //   title: 'Operations Manager',
    //   location: 'Los Angeles, CA',
    //   description: 'The Operations Manager will oversee the daily operations of Pawsible, ensuring all services are delivered to the highest standard. This role involves managing staff, maintaining facilities, and ensuring client satisfaction.',
    //   responsibilities: [
    //     'Supervise and manage all day-to-day operations of the facility.',
    //     'Ensure staff adhere to company policies and procedures.',
    //     'Oversee scheduling and ensure adequate staffing levels.',
    //     'Handle customer complaints and ensure high levels of client satisfaction.',
    //   ],
    //   requirements: [
    //     'Previous experience in operations management, preferably in the pet care industry.',
    //     'Strong leadership and organizational skills.',
    //     'Excellent communication and problem-solving abilities.',
    //     'Ability to work flexible hours, including weekends and holidays as needed.',
    //   ],
    //   workingHours: 'Full-time, 40-45 hours per week, with potential for occasional overtime.',
    //   compensation: 'Competitive salary starting at $60,000/year, with benefits including health insurance, paid time off, and performance bonuses.',
    //   growthOpportunities: 'Opportunities to advance to Regional Manager or other executive positions within the company.',
    //   workEnvironment: 'A dynamic, fast-paced environment where no two days are the same, with the satisfaction of ensuring top-tier pet care services.',
    //   applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    // },
    // {
    //   title: 'Marketing Specialist',
    //   location: 'Los Angeles, CA',
    //   description: 'As a Marketing Specialist at Pawsible, you will create and execute marketing campaigns to promote our services and engage with our community. This role requires creativity, strategic thinking, and a passion for pets.',
    //   responsibilities: [
    //     'Develop and implement marketing strategies to attract new clients.',
    //     'Manage social media accounts and create engaging content.',
    //     'Coordinate with other departments to promote services and special events.',
    //     'Analyze market trends and customer feedback to improve marketing efforts.',
    //   ],
    //   requirements: [
    //     'Bachelor’s degree in Marketing, Communications, or a related field.',
    //     'Experience in digital marketing, including social media management.',
    //     'Strong writing and communication skills.',
    //     'Ability to work independently and manage multiple projects simultaneously.',
    //   ],
    //   workingHours: 'Full-time, 40 hours per week, with occasional evening or weekend work for events.',
    //   compensation: 'Starting at $50,000/year, with benefits including health insurance, paid time off, and professional development opportunities.',
    //   growthOpportunities: 'Potential to advance to Marketing Manager or Director of Marketing.',
    //   workEnvironment: 'A creative and collaborative environment where you can make a significant impact on the growth of Pawsible.',
    //   applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    // },
    // {
    //   title: 'Pet Nutrition Specialist',
    //   location: 'Los Angeles, CA',
    //   description: 'The Pet Nutrition Specialist will provide guidance to pet owners on proper nutrition and dietary plans for their pets. This role involves consulting with clients, developing diet plans, and staying up-to-date on pet nutrition research.',
    //   responsibilities: [
    //     'Consult with pet owners to understand their pet’s nutritional needs.',
    //     'Develop customized diet plans for pets.',
    //     'Provide education on pet nutrition and proper feeding practices.',
    //     'Collaborate with veterinarians and other professionals to ensure optimal pet health.',
    //   ],
    //   requirements: [
    //     'Degree in Veterinary Nutrition, Animal Science, or a related field preferred.',
    //     'Strong knowledge of pet nutrition and dietary needs.',
    //     'Excellent communication and customer service skills.',
    //     'Passion for animal welfare and helping pets achieve optimal health.',
    //   ],
    //   workingHours: 'Full-time, 40 hours per week, with some flexibility for evening consultations.',
    //   compensation: 'Starting at $18/hour, with benefits including health insurance and continuing education support.',
    //   growthOpportunities: 'Opportunities to specialize in specific dietary needs or advance to a senior nutritionist role.',
    //   workEnvironment: 'A rewarding environment where you can make a direct impact on the health and well-being of pets.',
    //   applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    // },
    // {
    //   title: 'Pet Photographer',
    //   location: 'Los Angeles, CA',
    //   description: 'As a Pet Photographer, you will capture the personality and spirit of pets through your lens. This role involves working directly with pets and their owners to create memorable images.',
    //   responsibilities: [
    //     'Photograph pets in various settings, including our facility, outdoor locations, and client homes.',
    //     'Edit and retouch photos to ensure high-quality results.',
    //     'Collaborate with pet owners to understand their vision and preferences.',
    //     'Manage photo sessions, including scheduling, setup, and breakdown.',
    //   ],
    //   requirements: [
    //     'Experience in photography, with a portfolio of pet photography preferred.',
    //     'Proficiency in photo editing software (e.g., Adobe Photoshop, Lightroom).',
    //     'Comfortable working with pets and directing them during photo sessions.',
    //     'Strong interpersonal skills and the ability to work with clients of all types.',
    //   ],
    //   workingHours: 'Part-time, 20-30 hours per week, with flexibility for weekend sessions.',
    //   compensation: 'Starting at $20/hour, with opportunities for additional income through photo sales and packages.',
    //   growthOpportunities: 'Potential to develop a full-time photography business or specialize in pet event photography.',
    //   workEnvironment: 'A fun and creative environment where you can work with pets and their owners to create lasting memories.',
    //   applicationProcess: 'Submit your application via our website or in person at our Los Angeles location.'
    // }
  ];
  
  export default jobListings;
  
  