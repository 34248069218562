import React, { useState } from 'react';

const NailPreferences = ({ selectedSize, selectedPackage }) => {
  const [nailLength, setNailLength] = useState('');

  const handleNailLengthChange = (e) => {
    setNailLength(e.target.value);
  };

  return (
    <div className="nail-preferences">
      <h3>What's included in the nail care?</h3>
      <ul>
        <li>Nail Trim</li>
        <li>Paw Sanitary Trim</li>
        <li>Paw Balm</li>
      </ul>

      {/* <h3>Grooming Preferences</h3>
      <label htmlFor="nailLength">Nail Length:</label>
      <input
        type="text"
        id="nailLength"
        value={nailLength}
        onChange={handleNailLengthChange}
      /> */}
      {/* Add more nail grooming preferences input fields */}
    </div>
  );
};

export default NailPreferences;
