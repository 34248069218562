import React, { useState, useContext, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../StateProvider';
import Popup from '../../../../../Utilities/components/Popup';
import { BackButton, DeleteButton } from '../../../../../Utilities/components/Buttons';
import { uploadFileToS3, editFamilyMember, deleteFamilyMember } from '../../../../../Utilities/api';
import dogBreeds from '../../../../../Utilities/data/dogBreeds';
import catBreeds from '../../../../../Utilities/data/catBreeds'; // Import the cat breeds
import './FamilyMemberEdit.css';

import personPlaceholder from '../../../../../../assets/Dashboard/Profile/personPlaceholder.png';
import dogPlaceholder from '../../../../../../assets/Dashboard/Profile/dogPlaceholder.png';
import catPlaceholder from '../../../../../../assets/Dashboard/Profile/catPlaceholder.png';

const FamilyMemberEdit = () => {
    const navigate = useNavigate();
    const { user, refreshUserData } = useContext(StateContext);
    const { memberType, memberId } = useParams();
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [fileData, setFileData] = useState({
        imageFile: null,
        vaccinationFile: null
    });

    const [uploadedImage, setUploadedImage] = useState(null);
    const profileImageInputRef = useRef(null);
    const vaccinationInputRef = useRef(null);

    const mapTypeFromUrl = (urlType) => {
        if (urlType === 'human') {
            return 'Human';
        } else if (urlType === 'pet') {
            return ['Dog', 'Cat']; // Assuming these are your pet types
        } else {
            return urlType; // Default case if it matches directly
        }
    };
    const actualType = mapTypeFromUrl(memberType);
    const familyMemberData = user && user.familyMembers && user.familyMembers.find(member => {
        return member.id && member.id.toString() === memberId &&
            (Array.isArray(actualType) ? actualType.includes(member.type) : member.type === actualType);
    });

    const [memberDetails, setMemberDetails] = useState({
        name: familyMemberData ? familyMemberData.name : '',
        type: familyMemberData ? familyMemberData.type : '',
        image: familyMemberData ? familyMemberData.image : '',
        vaccination: familyMemberData ? familyMemberData.vaccination : '',
        daycare: familyMemberData ? familyMemberData.daycare : '',
        membership: familyMemberData ? familyMemberData.Memberships[0] : '',
        breed: familyMemberData ? familyMemberData.breed : 'Not Specified', // Default breed
        dob: familyMemberData ? familyMemberData.dob : '',
    });

    const handleNameChange = (e) => setMemberDetails(prev => ({ ...prev, name: e.target.value }));
    const handleTypeChange = (e) => setMemberDetails(prev => ({ ...prev, type: e.target.value }));
    const handleBreedChange = (e) => setMemberDetails(prev => ({ ...prev, breed: e.target.value }));
    const handleDobChange = (e) => setMemberDetails(prev => ({ ...prev, dob: e.target.value }));
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setFileData(prev => ({ ...prev, imageFile: e.target.files[0] }));

            const imageUrl = URL.createObjectURL(e.target.files[0]);
            setUploadedImage(imageUrl);

            profileImageInputRef.current.value = null;
        }
    };
    
    const handleVaccinationUpload = (e) => {
        if (e.target.files[0]) {
            setFileData(prev => ({ ...prev, vaccinationFile: e.target.files[0] }));
            vaccinationInputRef.current.value = null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let updatedDetails = { ...memberDetails };

        try {
            if (fileData.imageFile) {
                const imageUrl = await uploadFileToS3(fileData.imageFile);
                updatedDetails.image = imageUrl;
            }
    
            if (fileData.vaccinationFile) {
                const vaccinationUrl = await uploadFileToS3(fileData.vaccinationFile);
                updatedDetails.vaccination = vaccinationUrl;
            }

            await editFamilyMember(memberId, updatedDetails);   
            await refreshUserData(user.uid);
            navigate(`/profile`);
        } catch (error) {
            console.error('Error updating family member:', error);
        }
    };

    const handleGoToPage = (page) => {
        navigate(`${page}`);
        window.scrollTo(0, 0);
    };

    const placeholderImages = {
        Human: personPlaceholder,
        Dog: dogPlaceholder,
        Cat: catPlaceholder,
    };
    
    function getPlaceholderImage(type) {
        return placeholderImages[type] || placeholderImages.Human;
    }

    const handleDeleteClick = () => {
        setShowDeletePopup(true);
    };
    
    const handleDelete = async () => {
        try {
            await deleteFamilyMember(memberId);
            await refreshUserData(user.uid);
            navigate(`/profile`);
        } catch (error) {
            console.error('Error deleting family member:', error);
        } finally {
            setShowDeletePopup(false);
        }
    };
    
    const handleCancelDelete = () => {
        setShowDeletePopup(false);
    };

    const getBreedOptions = () => {
        if (memberDetails.type === 'Dog') {
            return Object.keys(dogBreeds).sort().map(breed => (
                <option key={breed} value={breed}>{breed}</option>
            ));
        } else if (memberDetails.type === 'Cat') {
            return Object.keys(catBreeds).sort().map(breed => (
                <option key={breed} value={breed}>{breed}</option>
            ));
        } else {
            return <option value="Not Specified">Not Specified</option>;
        }
    };

    if (!familyMemberData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="family-member-edit">
            <div className="family-member-edit-header">
                <BackButton />
                <DeleteButton onDelete={handleDeleteClick} />
                <Popup isOpen={showDeletePopup} handleClose={handleCancelDelete}>
                    <p className='family-member-delete-popup-header'>Are you sure you want to delete this family member?</p>
                    <div className='family-member-delete-popup-button-container'>
                        <button className='family-member-delete-popup-delete' onClick={handleDelete}>OK</button>
                        <button className='family-member-delete-popup-cancel' onClick={handleCancelDelete}>Cancel</button>
                    </div>
                </Popup>
            </div>

            <div className='container'>
                <form onSubmit={handleSubmit}>
                    <div className="image-upload-container">
                        <img 
                            className='profile-image' 
                            src={uploadedImage || familyMemberData.image || getPlaceholderImage(familyMemberData.type)} 
                            alt={`${familyMemberData && familyMemberData.type ? familyMemberData.type : 'Default'} Placeholder`} 
                        />
                        <label htmlFor="image-upload" className="image-upload-label">
                            <input 
                                type="file" 
                                id="image-upload" 
                                className="image-upload" 
                                onChange={handleImageChange} 
                                ref={profileImageInputRef}
                            />
                        </label>
                    </div>

                    {memberDetails.type !== 'Human' 
                        ? (<div className="edit-badges-container">
                                {memberDetails.membership ? <span className="badge membership-verified">Membership</span> : <span className="badge membership-unverified">Membership</span>}
                                {memberDetails.daycare ? <span className="badge daycare-verified">Daycare</span> : <span className="badge daycare-unverified">Daycare</span>}
                                {memberDetails.vaccination ? <span className="badge vaccination-verified">Vaccination</span> : <span className="badge vaccination-unverified">Vaccination</span> }
                            </div>)
                        : null
                    }

                    {memberDetails.daycare 
                        ?   null
                        : (
                            <button type="button" className='family-member-edit-daycare-button' onClick={() => handleGoToPage('/store/services/daycare')}>Get Daycare Badge</button>
                        )
                    }

                    {memberDetails.membership 
                        ?  (
                            <div className='family-member-edit-board'>
                                <ul>
                                    <li><strong>Membership</strong> - {memberDetails.membership.membershipType}</li>
                                    {memberDetails.membership.groomingMembershipType
                                        ? <li><strong>Grooming</strong> - {memberDetails.membership.groomingMembershipType}</li>
                                        : null
                                    }
                                </ul>
                                <ul><strong>Monthly Remainings</strong>:
                                    <li>Daycare - {memberDetails.membership.daycareRemaining}</li>
                                    {memberDetails.membership.groomingMembershipType
                                        ? 
                                        <div>
                                            <li>Grooming - {memberDetails.membership.groomingRemaining}</li>
                                            <li>Bath - {memberDetails.membership.bathRemaining}</li>
                                        </div>
                                        : null
                                    }
                                </ul>
                            </div>
                        )
                        : (
                            <button type="button" className='family-member-edit-membership-button' onClick={() => handleGoToPage('/profile/membership')}>Discover Membership</button>
                        )
                    }

                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" value={memberDetails.name} onChange={handleNameChange} />

                    <label htmlFor="type">Type</label>
                    <select id="type" value={memberDetails.type} onChange={handleTypeChange}>
                        <option value="Human">Human</option>
                        <option value="Dog">Dog</option>
                        <option value="Cat">Cat</option>
                    </select>

                    {memberDetails.type !== 'Human' && (
                        <>
                            <label htmlFor="breed">Breed</label>
                            <select id="breed" value={memberDetails.breed} onChange={handleBreedChange}>
                                <option value="Not Specified">Not Specified</option>
                                {getBreedOptions()}
                            </select>

                            <label htmlFor="dob">Date of Birth</label>
                            <input type="date" id="dob" value={memberDetails.dob} onChange={handleDobChange} />

                            <div className="vaccination-container">
                                <label htmlFor="vaccination">Vaccination Records</label>
                                <input 
                                    type="file" 
                                    id="vaccination" 
                                    onChange={handleVaccinationUpload} 
                                    ref={vaccinationInputRef}
                                />
                                <label htmlFor="vaccination-message">*Records will be verified in timely manner once uploaded.</label>
                            </div>
                        </>
                    )}

                    <input type="submit" value="Save Changes" />
                </form>
            </div>
        </div>
    );
};

export default FamilyMemberEdit;
