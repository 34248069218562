import React, { useState, useContext, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../StateProvider';
import { uploadFileToS3, editMember } from '../../../../../Utilities/api';
import { BackButton } from '../../../../../Utilities/components/Buttons';
import './userEdit.css';

import personPlaceholder from '../../../../../../assets/Dashboard/Profile/personPlaceholder.png';
import dogPlaceholder from '../../../../../../assets/Dashboard/Profile/dogPlaceholder.png';
import catPlaceholder from '../../../../../../assets/Dashboard/Profile/catPlaceholder.png';

const userEdit = () => {
    const navigate = useNavigate();
    const { user, refreshUserData } = useContext(StateContext);
    const { memberType, memberId } = useParams();
    const mapTypeFromUrl = (urlType) => {
        if (urlType === 'human') {
            return 'Human';
        } else if (urlType === 'pet') {
            return ['Dog', 'Cat']; // Assuming these are your pet types
        } else {
            return urlType; // Default case if it matches directly
        }
    };
    const actualType = mapTypeFromUrl(memberType);
    
    // Ensure familyMembers array exists and has elements before accessing
    const familyMemberData = user.familyMembers && user.familyMembers.length > 0 ? user.familyMembers[0] : null;

    const [name, setName] = useState(familyMemberData ? familyMemberData.name : '');
    const [type, setType] = useState(familyMemberData ? familyMemberData.type : '');
    const [image, setImage] = useState(familyMemberData ? familyMemberData.image : '');
    const [membership, setMembership] = useState(familyMemberData ? familyMemberData.membership : '');
    const [daycare, setDaycare] = useState(familyMemberData ? familyMemberData.daycare : '');
    const [vaccination, setVaccination] = useState(familyMemberData ? familyMemberData.vaccination : '');

    const [uploadedImage, setUploadedImage] = useState(null);
    const [vaccinationFile, setVaccinationFile] = useState(null);

    const [fileData, setFileData] = useState({
        imageFile: null,
        vaccinationFile: null
    });

    const profileImageInputRef = useRef(null);
    const vaccinationInputRef = useRef(null);

    const handleNameChange = (e) => setName(e.target.value);
    const handleTypeChange = (e) => setType(e.target.value);
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setFileData(prev => ({ ...prev, imageFile: e.target.files[0] }));

            // Create a URL for the uploaded image file to show immediately
            const imageUrl = URL.createObjectURL(e.target.files[0]);
            setUploadedImage(imageUrl);

            // Clear the input value to deselect the file
            profileImageInputRef.current.value = null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const memberDetails = {
            name,
            type,
            image: uploadedImage || user.image,
            membership,
        };

        if (fileData.imageFile) {
            const imageUrl = await uploadFileToS3(fileData.imageFile);
            memberDetails.image = imageUrl; // Update the image URL in the member details
        }

        try {
            await editMember(user.uid, memberDetails);
            await refreshUserData(user.uid);
            navigate('/profile');
        } catch (error) {
            console.error('Error editing member:', error);
        }
    };

    const handleGoToPage = (page) => {
        navigate(`${page}`);
        window.scrollTo(0, 0);
    };

    const handleVaccinationUpload = (e) => {
        if (e.target.files[0]) {
            setVaccinationFile(e.target.files[0]);  // Store the file
            // Clear the input value to deselect the file
            vaccinationInputRef.current.value = null;
        }
    };

    const placeholderImages = {
        Human: personPlaceholder,
        Dog: dogPlaceholder,
        Cat: catPlaceholder,
      };
    
    function getPlaceholderImage(type) {
        return placeholderImages[type] || placeholderImages.Human;
    }

    if (!familyMemberData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="family-member-edit">
            <div className="family-member-edit-header">
                <BackButton />
                {/* <DeleteButton onDelete={() => console.log('Delete')} /> */}
                {/* <h1>Edit Family Member {memberId}</h1> */}
            </div>

            <div className='container'>
                <form onSubmit={handleSubmit}>
                    <div className="image-upload-container">
                        <img 
                            className='profile-image' 
                            src={uploadedImage || familyMemberData.image || getPlaceholderImage(familyMemberData.type)} 
                            alt={`${familyMemberData.type} Placeholder`} 
                        />
                        <label htmlFor="image-upload" className="image-upload-label">
                            <input 
                                type="file" 
                                id="image-upload" 
                                className="image-upload" 
                                onChange={(e) => handleImageChange(e, familyMemberData.id)} 
                                ref={profileImageInputRef}
                            />
                        </label>
                    </div>

                    {type !== 'Human' 
                        ? (<div className="edit-badges-container">
                                {membership ? <span className="badge membership-verified">Membership</span> : <span className="badge membership-unverified">Membership</span>}
                                {daycare ? <span className="badge daycare-verified">Daycare</span> : <span className="badge daycare-unverified">Daycare</span>}
                                {vaccination ? <span className="badge vaccination-verified">Vaccination</span> : <span className="badge vaccination-unverified">Vaccination</span> }
                            </div>)
                        : null
                    }

                    {membership 
                        ?   null
                        : (
                            <button type="button" className='family-member-edit-membership-button' onClick={() => handleGoToPage('/profile/membership')}>Discover Membership</button>
                        )
                    }

                    {daycare 
                        ?   null
                        : (
                            <button type="button" className='family-member-edit-daycare-button' onClick={() => handleGoToPage('/store/services/daycare')}>Discover Daycare</button>
                        )
                    }

                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" value={name} onChange={handleNameChange} />

                    <label htmlFor="type">Type</label>
                    <select id="type" value={type} onChange={handleTypeChange}>
                        <option value="Human">Human</option>
                        <option value="Dog">Dog</option>
                        <option value="Cat">Cat</option>
                    </select>

                    {type !== 'Human' 
                        ? (<div className="vaccination-container">
                                <label htmlFor="vaccination">Vaccination Records</label>
                                <input type="file" id="vaccination" onChange={handleVaccinationUpload} ref={vaccinationInputRef} />
                                <label htmlFor="vaccination-message">*Records will be verified in a timely manner once uploaded.</label>
                            </div>)
                        : null
                    }

                    <input type="submit" value="Save Changes" />
                </form>
            </div>
        </div>
    );
};

export default userEdit;
