import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import './ResetPassword.css';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import Footer from '../../Footer';
import LetsGetStarted from '../../Utilities/components/LetsGetStarted';

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(''); // Clear error message when user starts typing again
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    sendPasswordResetEmail(auth, formData.email)
      .then(() => {
        setEmailSent(true);
        setError(''); // Clear any previous errors
        setFormData({ email: '' }); // Reset form
      })
      .catch((error) => {
        setEmailSent(false); // Ensure the success message is not shown
        setError(error.message); // Display error message from Firebase
      });
  };

  return (
    <>
      <Container className="reset-password-container">
        <h5 className="reset-password-header">Reset Your Password</h5>
        {emailSent ? (
          <Alert variant="success">
            An email has been sent. Please check your email to reset your password.
          </Alert>
        ) : (
          <>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="reset-password-email" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button type="submit" className="reset-password-button">
                RESET PASSWORD
              </Button>
            </Form>
          </>
        )}
      </Container>

      <section className='sign-up-section'>
        <LetsGetStarted />
      </section>

      <Footer />
    </>
  );
};

export default ResetPassword;
