import React from 'react';
import BasicGroomingPreferences from './BasicGroomingPreferences';
import PremiumGroomingPreferences from './PremiumGroomingPreferences';
import VIPGroomingPreferences from './VIPGroomingPreferences';

const GroomingPackagePreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  switch (membershipBookingData.membershipOptions.selectedGroomingPackage) {
    case 'Basic':
      return <BasicGroomingPreferences 
          membershipBookingData={membershipBookingData} 
          setMembershipBookingData={setMembershipBookingData}
      />;
    case 'Premium':
      return <PremiumGroomingPreferences 
                membershipBookingData={membershipBookingData} 
                setMembershipBookingData={setMembershipBookingData}
            />;
    case 'VIP':
      return <VIPGroomingPreferences 
                membershipBookingData={membershipBookingData} 
                setMembershipBookingData={setMembershipBookingData}
            />;
    default:
      return null;
  }
};

export default GroomingPackagePreferences;
