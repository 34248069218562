import React from 'react';

const BasicPreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  return (
    <div className="basic-preferences">
      <h3>Basic Membership Benefits</h3>
      <ul>
        <li>15 days of Daycare</li>
        <li>High Quality & Nutritious Meals</li>
        <li>Access to Exclusive Events</li>
        <li>10% Discounts on any Service and Products</li>
        <li>Regular Hygiene and Health check-ups</li>
        {/* <li>Priority booking for any Appointments</li> */}
      </ul>
    </div>
  );
};

export default BasicPreferences;
