import React, { useState, useContext, useEffect } from 'react';
import '../../Booking.css';
import { StateContext } from '../../../../StateProvider';
import { FaInfoCircle } from 'react-icons/fa';
import Calendar from 'react-calendar';
import { tooltips } from './hotelTooltips';
import { getHotelAvailabilityByMonth } from '../../../../Utilities/api';
import TimeSlots from '../../../../Utilities/components/TimeSlots';
// import HotelBookingHero from '../../../../../assets/Store/Services/Hotel/hotelBookingHero.png';

function HotelBooking() {
    const { user, setBookingData } = useContext(StateContext);
    const [showCalendar, setShowCalendar] = useState(false);
    const [datesSelected, setDatesSelected] = useState(false);
    const [availabilityCache, setAvailabilityCache] = useState({});
    const [tooltipVisibility, setTooltipVisibility] = useState({
        who: false,
        when: false,
        food: false,
        requests: false,
        services: false,
    });    
    const [hotelBookingData, setHotelBookingData] = useState({
        selectedPets: [],
        dateRange: null, // Initialize with null
        showCalendar: false,
        foodPreference: '',
        specialNotes: '',
        pickupTime: '',
        dropoffTime: '',
        groomingOptions: [],
        trainingOptions: [],
        selectedPackage: '',
        additionalServices: {
            hotel: false,
            daycare: false,
            grooming: false,
            training: false,
            shuttle: false,
            adventure: false,
            walk: false
        }
    });

    useEffect(() => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Month in human-readable format (1-12)
        const year = today.getFullYear();
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1; // Handle December to January transition
        const nextMonthYear = currentMonth === 12 ? year + 1 : year;

        const currentMonthKey = `${year}-${currentMonth}`;
        const nextMonthKey = `${nextMonthYear}-${nextMonth}`;
    
        const fetchAvailability = async () => {
            const [currentMonthData, nextMonthData] = await Promise.all([
                !availabilityCache[currentMonthKey] && getHotelAvailabilityByMonth(currentMonth, year),
                !availabilityCache[nextMonthKey] && getHotelAvailabilityByMonth(nextMonth, nextMonthYear)
            ]);

            setAvailabilityCache(prev => ({
                ...prev,
                ...(currentMonthData ? { [currentMonthKey]: currentMonthData } : {}),
                ...(nextMonthData ? { [nextMonthKey]: nextMonthData } : {})
            }));
        };

        fetchAvailability();
    
        // Set booking data on mount
        setBookingData(prevData => ({
            ...prevData,
            bookingInfo: hotelBookingData
        }));
    }, [hotelBookingData]);
    
    const handleActiveStartDateChange = ({ activeStartDate }) => {
        const month = activeStartDate.getMonth(); // 0-indexed month
        const year = activeStartDate.getFullYear();
    
        const keysToFetch = [
            `${year}-${month + 1}`, // key for the current month
        ];
    
        // Calculate key for the next month, considering December to January transition
        const nextMonth = (month + 1) % 12;
        const nextMonthYear = month === 11 ? year + 1 : year;
        keysToFetch.push(`${nextMonthYear}-${nextMonth + 1}`);
    
        keysToFetch.forEach(key => {
            if (!availabilityCache[key]) {
                const [fetchYear, fetchMonth] = key.split('-').map(Number);

                getHotelAvailabilityByMonth(fetchMonth, fetchYear).then(data => {
                    setAvailabilityCache(prev => ({ ...prev, [key]: data }));
                }).catch(error => {
                    console.error(`Failed to fetch booking data for ${key}:`, error);
                });
            }
        });
    };     

    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Normalize today's date to the start of the day for accurate comparison
            
            // Disable past dates
            if (date < today) {
                return true;
            }
            
            const day = date.getDate();
            const month = date.getMonth() + 1; // JavaScript months are 0-indexed, but your keys are 1-indexed
            const year = date.getFullYear();
            const key = `${year}-${month}`;
            
            // Access the correct month data using the potentially nested structure
            const monthData = availabilityCache[key] ? availabilityCache[key] : null;
    
            // Check if the availability data for the day exists and is valid
            if (monthData && Array.isArray(monthData.dailyAvailability)) {
                // Ensure day is within bounds and check availability
                return day - 1 >= 0 && day - 1 < monthData.dailyAvailability.length && monthData.dailyAvailability[day - 1] <= 0;
            }
            return false; // If data is not loaded or not correct, don't disable (or you might choose to disable)
        }
        return false;
    };    
    
    const showTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: true });
    };
    
    const hideTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: false });
    };    

    // Can select multiple pets
    const togglePetSelection = (petId, petName) => {
        setHotelBookingData(prevBookingData => {
            let newSelectedPets;
    
            // Check if the petId is already included in the selectedPets array
            const isAlreadySelected = prevBookingData.selectedPets.some(selectedPet => selectedPet.petId === petId);
    
            if (isAlreadySelected) {
                // Filter out the petId if it's already selected
                newSelectedPets = prevBookingData.selectedPets.filter(selectedPet => selectedPet.petId !== petId);
            } else {
                // Add a new object with petId and petName if it's not already selected
                newSelectedPets = [...prevBookingData.selectedPets, { petId, petName }];
            }
    
            return {
                ...prevBookingData,
                selectedPets: newSelectedPets
            };
        });
    };    

    const handleDateChange = newDateRange => {
        const start = newDateRange[0];
        const end = newDateRange[1];
        let isRangeValid = true;
    
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            const day = d.getDate();
            const month = d.getMonth() + 1; // JavaScript months are 0-indexed
            const year = d.getFullYear();
            const key = `${year}-${month}`;
    
            // Accessing the nested month data correctly
            const monthData = availabilityCache ? availabilityCache[key] : null;
    
            // Check if the availability data for the month is loaded and valid
            if (!monthData || !Array.isArray(monthData.dailyAvailability)) {
                console.error("No valid availability data found for month:", key);
                isRangeValid = false;
                break;
            }
    
            // Check if the day exists in the daily availability array
            if (day - 1 < 0 || day - 1 >= monthData.dailyAvailability.length) {
                console.error("Day out of bounds for month data:", day, key);
                isRangeValid = false;
                break;
            }
    
            if (monthData.dailyAvailability[day - 1] <= 0) {
                console.error("No slots available on:", `${year}-${month}-${day}`);
                isRangeValid = false;
                break;
            }
        }
    
        if (isRangeValid) {
            setHotelBookingData(prevData => ({ ...prevData, dateRange: newDateRange }));
            setDatesSelected(true); // Only set to true if the range is valid
        } else {
            console.log("The selected range includes fully booked days. Please choose another range.");
        }
    };     

    const handleTimeChange = (timeType, time) => {
        setHotelBookingData(prevData => ({ ...prevData, [timeType]: time }));
    };

    const handleFoodPreferenceChange = (preference) => {
        setHotelBookingData(prevData => ({ ...prevData, foodPreference: preference }));
    };

    const handleSpecialRequestsChange = (e) => {
        setHotelBookingData(prevData => ({ ...prevData, specialNotes: e.target.value }));
    };

    const handleServiceChange = (serviceName) => {
        const currentStatus = hotelBookingData.additionalServices[serviceName];
        const updatedServices = {
            ...hotelBookingData.additionalServices,
            [serviceName]: !currentStatus
        };
        setHotelBookingData(prevData => ({ ...prevData, additionalServices: updatedServices }));
    };    

    return (
        <div className='booking-backdrop'>
            {/* <img className='booking-hero-image' src={HotelBookingHero}></img> */}
            <div className="booking-container">
                <div className="booking-header">
                    <h3>Pawsible Hotel</h3>
                </div>

                <div className="booking-content">
                    <div className="booking-section">
                        <h2>Pet Guests <FaInfoCircle onMouseOver={() => showTooltip('who')} onMouseOut={() => hideTooltip('who')} /></h2>
                        {tooltipVisibility.who && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.who }}></div>}
                        <div className="pet-selection">
                            {user && user.familyMembers && user.familyMembers.map(pet => (
                                (pet.type === 'Dog' || pet.type === 'Cat')
                                    ? (<button 
                                            key={`${pet.id}-${pet.name}`} 
                                            className={`pet-button ${hotelBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id) ? 'pet-button-selected' : ''}`} 
                                            onClick={() => togglePetSelection(pet.id, pet.name)}
                                        >
                                            <img src={pet.image} alt={pet.name} className="pet-photo" />
                                            <p className='pet-name'>{pet.name}</p>
                                            <p className='pet-type'>{pet.type}</p>
                                        </button>)
                                    : null
                            ))}
                        </div>
                    </div>

                    <div className="booking-section">
                        <h2>Stay Dates <FaInfoCircle onMouseOver={() => showTooltip('when')} onMouseOut={() => hideTooltip('when')} /></h2>
                        {tooltipVisibility.when && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.when }}></div>}
                        <button 
                            onClick={() => setShowCalendar(!showCalendar)} 
                            className={datesSelected ? 'calendar-button-selected' : 'calendar-button'}
                        >
                            {hotelBookingData.dateRange ? `${hotelBookingData.dateRange[0].toLocaleDateString()} - ${hotelBookingData.dateRange[1].toLocaleDateString()}` : `Select Dates`}
                        </button>
                        {showCalendar && (
                            <div className='booking-calendar-container'>
                                <div className="legend">
                                    <div className="legend-item">
                                    <span className="history-dot" />
                                        Full
                                    </div>
                                    <div className="legend-item">
                                    <span className="today-dot" />
                                        Today
                                    </div>
                                    <div className="legend-item">
                                    <span className="booked-dot" />
                                        Active
                                    </div>
                                    <div className="legend-item">
                                    <span className="events-dot" />
                                        Events
                                    </div>
                                </div>
                                <Calendar
                                    selectRange
                                    value={hotelBookingData.dateRange}
                                    onChange={handleDateChange}
                                    onActiveStartDateChange={handleActiveStartDateChange}
                                    tileDisabled={tileDisabled}
                                />
                            </div>
                        )}
                    </div>

                    <div className="booking-section">
                        {hotelBookingData.availableTimeSlots && hotelBookingData.availableTimeSlots.length > 0
                            ? (<div>
                                    <h2>Time <FaInfoCircle onMouseOver={() => showTooltip('times')} onMouseOut={() => hideTooltip('times')} /></h2>
                                    {tooltipVisibility.times && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.times }}></div>}
                                    <TimeSlots
                                        timeSlotsData={hotelBookingData.availableTimeSlots}
                                        handleTimeChange={handleTimeChange}
                                        selectedTimeSlot={hotelBookingData.selectedTimeSlot}
                                    />
                                </div>)
                            : null
                        }
                    </div>

                    <div className="booking-section">
                        <h2>Meal Plans <FaInfoCircle onMouseOver={() => showTooltip('food')} onMouseOut={() => hideTooltip('food')} /></h2>
                        {tooltipVisibility.food && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.food }}></div>}
                        <div className="food-preference">
                            <button 
                                className={hotelBookingData.foodPreference === 'own' ? 'food-preference-button-selected' : 'food-preference-button'}
                                onClick={() => handleFoodPreferenceChange('own')}
                            >
                                Own
                            </button>
                            <button 
                                className={hotelBookingData.foodPreference === 'basic' ? 'food-preference-button-selected' : 'food-preference-button'}
                                onClick={() => handleFoodPreferenceChange('basic')}
                            >
                                Basic
                            </button>
                            <button 
                                className={hotelBookingData.foodPreference === 'premium' ? 'food-preference-button-selected' : 'food-preference-button'}
                                onClick={() => handleFoodPreferenceChange('premium')}
                            >
                                Premium
                            </button>
                        </div>
                    </div>
                </div>

                <div className="booking-section">
                    <div className='additional-services'>
                        <h2>Additional Services <FaInfoCircle onMouseOver={() => showTooltip('services')} onMouseOut={() => hideTooltip('services')} /></h2>
                        {tooltipVisibility.services && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.services }}></div>}
                        <div className='booking-additional-button-container'>
                            <button 
                                className={`service-button-${hotelBookingData.additionalServices.grooming ? 'selected' : ''}`}
                                onClick={() => handleServiceChange('grooming')}
                            >
                                Grooming
                            </button>

                            <button 
                                className={`service-button-${hotelBookingData.additionalServices.training ? 'selected' : ''}`}
                                onClick={() => handleServiceChange('training')}
                            >
                                Training
                            </button>

                            <button 
                                className={`service-button-${hotelBookingData.additionalServices.shuttle ? 'selected' : ''}`}
                                onClick={() => handleServiceChange('shuttle')}
                            >
                                Shuttle
                            </button>

                            <button 
                                className={`service-button-${hotelBookingData.additionalServices.adventure ? 'selected' : ''}`}
                                onClick={() => handleServiceChange('adventure')}
                            >
                                Adventure
                            </button>

                            <button 
                                className={`service-button-${hotelBookingData.additionalServices.walk ? 'selected' : ''}`}
                                onClick={() => handleServiceChange('walk')}
                            >
                                Leash Walk
                            </button>
                        </div>
                    </div>
                </div>

                <div className="booking-section">
                    <h2>Special Notes <FaInfoCircle onMouseOver={() => showTooltip('requests')} onMouseOut={() => hideTooltip('requests')} /></h2>
                    {tooltipVisibility.requests && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.requests }}></div>}
                    <textarea 
                        value={hotelBookingData.specialNotes || ''} 
                        onChange={handleSpecialRequestsChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default HotelBooking;