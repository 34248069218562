import React, { useState, useContext, useEffect } from 'react';
import '../../Booking.css';
import { StateContext } from '../../../../StateProvider';
import { FaInfoCircle } from 'react-icons/fa';
import Calendar from 'react-calendar';
import { tooltips } from './groomingTooltips';
import GroomingOptions from './GroomingOptions';
import { getGroomingAvailabilityByMonth } from '../../../../Utilities/api';
import TimeSlots from '../../../../Utilities/components/TimeSlots';

function GroomingBooking() {
    const { user, setBookingData } = useContext(StateContext);
    const [isGroomingOptionsOpen, setIsGroomingOptionsOpen] = useState(false);
    const [selectedPetForGrooming, setSelectedPetForGrooming] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [datesSelected, setDatesSelected] = useState(false);
    const [availabilityCache, setAvailabilityCache] = useState({});
    const [tooltipVisibility, setTooltipVisibility] = useState({
        petSelection: false,
        appointmentDate: false,
        groomingPreferences: false,
    });    
    const [groomingBookingData, setGroomingBookingData] = useState({
        selectedPets: [],
        dateRange: null, // Initialize with null
        showCalendar: false,
        foodPreference: '',
        specialNotes: '',
        pickupTime: '',
        dropoffTime: '',
        groomingOptions: [],
        trainingOptions: [],
        selectedPackage: '',
        additionalServices: {
            hotel: false,
            daycare: false,
            grooming: false,
            training: false,
            shuttle: false,
            adventure: false,
            walk: false
        }
    });

    useEffect(() => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Month in human-readable format (1-12)
        const year = today.getFullYear();
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1; // Handle December to January transition
        const nextMonthYear = currentMonth === 12 ? year + 1 : year;

        const currentMonthKey = `${year}-${currentMonth}`;
        const nextMonthKey = `${nextMonthYear}-${nextMonth}`;
    
        const fetchAvailability = async () => {
            const [currentMonthData, nextMonthData] = await Promise.all([
                !availabilityCache[currentMonthKey] && getGroomingAvailabilityByMonth(currentMonth, year),
                !availabilityCache[nextMonthKey] && getGroomingAvailabilityByMonth(nextMonth, nextMonthYear)
            ]);

            setAvailabilityCache(prev => ({
                ...prev,
                ...(currentMonthData ? { [currentMonthKey]: currentMonthData } : {}),
                ...(nextMonthData ? { [nextMonthKey]: nextMonthData } : {})
            }));
        };

        fetchAvailability();
    
        // Set booking data on mount
        setBookingData(prevData => ({
            ...prevData,
            bookingInfo: groomingBookingData
        }));
    }, [groomingBookingData]);

    const handleActiveStartDateChange = ({ activeStartDate }) => {
        const month = activeStartDate.getMonth(); // 0-indexed month
        const year = activeStartDate.getFullYear();
    
        const keysToFetch = [
            `${year}-${month + 1}`, // key for the current month
        ];
    
        // Calculate key for the next month, considering December to January transition
        const nextMonth = (month + 1) % 12;
        const nextMonthYear = month === 11 ? year + 1 : year;
        keysToFetch.push(`${nextMonthYear}-${nextMonth + 1}`);
    
        keysToFetch.forEach(key => {
            if (!availabilityCache[key]) {
                const [fetchYear, fetchMonth] = key.split('-').map(Number);

                getGroomingAvailabilityByMonth(fetchMonth, fetchYear).then(data => {
                    setAvailabilityCache(prev => ({ ...prev, [key]: data }));
                }).catch(error => {
                    console.error(`Failed to fetch booking data for ${key}:`, error);
                });
            }
        });
    };    
    
    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Normalize today's date to the start of the day for accurate comparison
            
            // Disable past dates
            if (date < today) {
                return true;
            }
            
            const day = date.getDate();
            const month = date.getMonth() + 1; // JavaScript months are 0-indexed, but your keys are 1-indexed
            const year = date.getFullYear();
            const key = `${year}-${month}`;
            
            // Access the correct month data using the potentially nested structure
            const monthData = availabilityCache[key] ? availabilityCache[key] : null;
    
            // Check if the availability data for the day exists and is valid
            if (monthData && Array.isArray(monthData.dailyAvailability)) {
                // Ensure day is within bounds and check availability
                return day - 1 >= 0 && day - 1 < monthData.dailyAvailability.length && monthData.dailyAvailability[day - 1] <= 0;
            }
            return false; // If data is not loaded or not correct, don't disable (or you might choose to disable)
        }
        return false;
    };    

    const showTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: true });
    };
    
    const hideTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: false });
    };

    // Can select single pet
    const togglePetSelection = (pet) => {
        setGroomingBookingData(prevBookingData => {
            // If the selected pet is clicked again, deselect it by setting an empty array
            if (prevBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id)) {
                setSelectedPetForGrooming(null);
                setIsGroomingOptionsOpen(false);
                return { ...prevBookingData, selectedPets: [], groomingOptions: [] };
            } else {
                // If another pet is clicked, replace the selectedPets array with the new pet
                const newSelectedPet = { petId: pet.id, petName: pet.name };
                setSelectedPetForGrooming(pet); // Assuming 'pet' is the complete pet object
                setIsGroomingOptionsOpen(true);
                return { ...prevBookingData, selectedPets: [newSelectedPet], groomingOptions: [] };
            }
        });
    };

    const handleDateChange = (date) => {
        let selectedDate = date;
        if (Array.isArray(date)) {
            selectedDate = date[0];
        }
        
        if (selectedDate instanceof Date && !isNaN(selectedDate)) {
            const formattedDate = [selectedDate, selectedDate];
            setGroomingBookingData(prevData => ({
                ...prevData,
                dateRange: formattedDate
            }));
            setShowCalendar(false);
            setDatesSelected(true);
            // Now fetch time slots for the selected day
            const day = selectedDate.getDate();
            const month = selectedDate.getMonth() + 1; // JavaScript months are 0-based
            var year = selectedDate.getFullYear();
            var key = `${year}-${month}`;
            var dayAvailability = availabilityCache[key] && availabilityCache[key].dailyAvailability ? availabilityCache[key].dailyAvailability[day - 1] : undefined;

            // Assuming dailyAvailability is an array of time slots
            if (dayAvailability && dayAvailability.length > 0) {
                setGroomingBookingData(prev => ({
                    ...prev,
                    availableTimeSlots: dayAvailability
                }));
            }
        } else {
            console.error('Invalid date selected:', selectedDate);
        }
    };  

    const handleTimeChange = (timeType, time) => {
        setGroomingBookingData(prevData => ({ ...prevData, [timeType]: time }));
    };

    const handleNotesChange = (e) => {
        setGroomingBookingData(prevData => ({ ...prevData, specialNotes: e.target.value }));
    };

    const handleServiceChange = (serviceName) => {
        const currentStatus = groomingBookingData.additionalServices[serviceName];
        const updatedServices = {
            ...groomingBookingData.additionalServices,
            [serviceName]: !currentStatus
        };
        setGroomingBookingData(prevData => ({ ...prevData, additionalServices: updatedServices }));
    };    

    return (
        <div className='booking-backdrop'>
            <div className="booking-container">
                <div className="booking-header">
                    <h3>Pawsible Grooming</h3>
                </div>

                <div className="booking-content">
                    <div className="booking-section">
                        <h2>
                            Pet Guests <FaInfoCircle onMouseOver={() => showTooltip('who')} onMouseOut={() => hideTooltip('who')} />
                        </h2>
                        {tooltipVisibility.who && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.who }}></div>}
                        <div className="pet-selection">
                            {user && user.familyMembers && user.familyMembers.map(pet => (
                                (pet.type === 'Dog' || pet.type === 'Cat') && (
                                    <div key={pet.id}>
                                        <button 
                                            className={`pet-button-${groomingBookingData.selectedPets.length > 0 && groomingBookingData.selectedPets[0].petId === pet.id ? 'selected' : ''}`}  
                                            onClick={() => togglePetSelection(pet)}
                                        >
                                            <img src={pet.image} alt={pet.name} className="pet-photo" />
                                            <p className='pet-name'>{pet.name}</p>
                                            <p className='pet-type'>{pet.type}</p>
                                        </button>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>

                    <div className={`booking-section-selectedPetForGrooming ${isGroomingOptionsOpen ? 'open' : 'close'}`}>
                        {selectedPetForGrooming && (
                            <div>
                                <GroomingOptions 
                                    selectedPetForGrooming={selectedPetForGrooming}
                                    groomingBookingData={groomingBookingData}
                                    setGroomingBookingData={setGroomingBookingData}
                                />
                            </div>
                        )}
                    </div>

                    <div className="booking-section">
                        <h2>Date <FaInfoCircle onMouseOver={() => showTooltip('when')} onMouseOut={() => hideTooltip('when')} /></h2>
                        {tooltipVisibility.when && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.when }}></div>}
                        <button 
                            onClick={() => setShowCalendar(!showCalendar)} 
                            className={datesSelected ? 'calendar-button-selected' : 'calendar-button'}
                        >
                            {groomingBookingData.dateRange ? groomingBookingData.dateRange[0].toLocaleDateString() : 'Select Date'}
                        </button>
                        {showCalendar && (
                            <div className='booking-calendar-container'>
                                <div className="legend">
                                    <div className="legend-item">
                                    <span className="history-dot" />
                                        Full
                                    </div>
                                    <div className="legend-item">
                                    <span className="today-dot" />
                                        Today
                                    </div>
                                    <div className="legend-item">
                                    <span className="booked-dot" />
                                        Active
                                    </div>
                                    <div className="legend-item">
                                    <span className="events-dot" />
                                        Events
                                    </div>
                                </div>
                                <Calendar
                                    selectRange={false}
                                    value={groomingBookingData.dateRange}
                                    onChange={handleDateChange}
                                    onActiveStartDateChange={handleActiveStartDateChange}
                                    tileDisabled={tileDisabled}
                                />
                            </div>
                        )}
                    </div>

                    <div className="booking-section">
                        {groomingBookingData.availableTimeSlots && groomingBookingData.availableTimeSlots.length > 0
                            ? (<div>
                                    <h2>Time <FaInfoCircle onMouseOver={() => showTooltip('times')} onMouseOut={() => hideTooltip('times')} /></h2>
                                    {tooltipVisibility.times && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.times }}></div>}
                                    <TimeSlots
                                        type="grooming"
                                        timeSlotsData={groomingBookingData.availableTimeSlots}
                                        handleTimeChange={handleTimeChange}
                                        selectedTimeSlot={groomingBookingData.selectedTimeSlot}
                                    />
                                </div>)
                            : null
                        }
                    </div>

                    <div className="booking-section">
                        <div className='additional-services'>
                            <h2>Additional Services <FaInfoCircle onMouseOver={() => showTooltip('services')} onMouseOut={() => hideTooltip('services')} /></h2>
                            {tooltipVisibility.services && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.services }}></div>}
                            <div className='booking-additional-button-container grooming'>
                                <button 
                                    className={`service-button-${groomingBookingData.additionalServices.hotel ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('hotel')}
                                >
                                    Hotel
                                </button>

                                <button 
                                    className={`service-button-${groomingBookingData.additionalServices.daycare ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('daycare')}
                                >
                                    Daycare
                                </button>

                                <button 
                                    className={`service-button-${groomingBookingData.additionalServices.training ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('training')}
                                >
                                    Training
                                </button>

                                <button 
                                    className={`service-button-${groomingBookingData.additionalServices.shuttle ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('shuttle')}
                                >
                                    Shuttle
                                </button>

                                <button 
                                    className={`service-button-${groomingBookingData.additionalServices.adventure ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('adventure')}
                                >
                                    Adventure
                                </button>

                                <button 
                                    className={`service-button-${groomingBookingData.additionalServices.walk ? 'selected' : ''}`}
                                    onClick={() => handleServiceChange('walk')}
                                >
                                    Leash Walk
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="booking-section">
                        <h2>
                            Special Requests <FaInfoCircle onMouseOver={() => showTooltip('groomingPreferences')} onMouseOut={() => hideTooltip('groomingPreferences')} />
                        </h2>
                        {tooltipVisibility.groomingPreferences && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.groomingPreferences }}></div>}
                        <textarea 
                            placeholder="Tell us specifics of hair style & length!" 
                            value={groomingBookingData.specialNotes} 
                            onChange={handleNotesChange}
                        />
                    </div>
                </div>

                {/* <div className="booking-footer">
                    <button className="skip-button">Skip</button>
                    <button className="next-button" type="submit" onClick={handleSubmit}>Book Appointment</button>
                </div> */}
            </div>
        </div>
    );
}

export default GroomingBooking;
