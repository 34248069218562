import React, { useContext } from 'react';
import { StateContext } from '../StateProvider';
import { useNavigate } from 'react-router-dom';
import './Landing.css';
import Store from './../Store';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import heroImage1 from '../../assets/Landing/heroImage1.png';
import heroImage2 from '../../assets/Landing/heroImage2.png';
import heroImage3 from '../../assets/Landing/heroImage3.png';
import menuBackground1 from '../../assets/Landing/menuBackground1.png';
import menuBackground2 from '../../assets/Landing/menuBackground2.png';
import menuBackground3 from '../../assets/Landing/menuBackground3.png';
import hotelIcon from '../../assets/Landing/hotelIcon.png';
import foodIcon from '../../assets/Landing/foodIcon.png';
import daycareIcon from '../../assets/Landing/daycareIcon.png';
import groomingIcon from '../../assets/Landing/groomingIcon.png';
import adventureIcon from '../../assets/Landing/adventureIcon.png';
import shuttleIcon from '../../assets/Landing/shuttleIcon.png';
import homesIcon from '../../assets/Landing/homesIcon.png';
import apparelsIcon from '../../assets/Landing/apparelsIcon.png';
import wellnessIcon from '../../assets/Landing/wellnessIcon.png';
import trainingIcon from '../../assets/Landing/trainingIcon.png';
import storyImage from '../../assets/Landing/storyImage.png';
import activitiesBackground from '../../assets/Landing/activitiesBackground.png';
import activitiesImage1 from '../../assets/Landing/activities-image-1.png';
import activitiesImage2 from '../../assets/Landing/activities-image-2.png';
import activitiesImage3 from '../../assets/Landing/activities-image-3.png';
import activitiesImage4 from '../../assets/Landing/activities-image-4.png';
import activitiesImage5 from '../../assets/Landing/activities-image-5.png';
import Footer from '../Footer';

import landingService1 from '../../assets/Landing/landingService1.png';
import landingService2 from '../../assets/Landing/landingService2.png';
import landingService3 from '../../assets/Landing/landingService3.png';
import landingService4 from '../../assets/Landing/landingService4.png';
import LetsGetStarted from '../Utilities/components/LetsGetStarted';


const Landing = () => {
  const { 
    loggedIn,
    setBookingData
  } = useContext(StateContext);

  const navigate = useNavigate();

  const handleImageClick = () => {
    console.log(`image clicked`);
  }

  const handleMenuButtonClicked = (page) => {
    navigate(`${page}`);
    window.scrollTo(0, 0);
  }

  return (
    <div>
      <Store />
      {/* { loggedIn ? (
        <Store />
      ) : (
        <div className="landing">
          <section className="hero-section">
            <BootstrapCarousel fade controls={false} indicators={false}>
              <BootstrapCarousel.Item>
                <img className="d-block w-100" src={heroImage1} alt="Hero 1" onClick={handleImageClick} />
              </BootstrapCarousel.Item>
              <BootstrapCarousel.Item>
                <img className="d-block w-100" src={heroImage2} alt="Hero 2" onClick={handleImageClick} />
              </BootstrapCarousel.Item>
              <BootstrapCarousel.Item>
                <img className="d-block w-100" src={heroImage3} alt="Hero 3" onClick={handleImageClick} />
              </BootstrapCarousel.Item>
            </BootstrapCarousel>
          </section>

          <section className="menu-section">
            <div className="menu-background">
              <BootstrapCarousel fade controls={false} indicators={false}>
                <BootstrapCarousel.Item>
                  <img className="d-block w-100 menu-carousel" src={menuBackground1} alt="Hero 1"/>
                </BootstrapCarousel.Item>
                <BootstrapCarousel.Item>
                  <img className="d-block w-100 menu-carousel" src={menuBackground2} alt="Hero 2"/>
                </BootstrapCarousel.Item>
                <BootstrapCarousel.Item>
                  <img className="d-block w-100 menu-carousel" src={menuBackground3} alt="Hero 3"/>
                </BootstrapCarousel.Item>
              </BootstrapCarousel>
            </div>

            <div className="container menu-container">
              <div className="row">
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={hotelIcon} alt="Hotel Icon" onClick={() => handleMenuButtonClicked('store/services/hotel')} />
                  </div>
                  <p className="menu-button-title">Hotel</p>
                </div>
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={daycareIcon} alt="Daycare Icon" onClick={() => handleMenuButtonClicked('store/services/daycare')} />
                  </div>
                  <p className="menu-button-title">Daycare</p>
                </div>
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={groomingIcon} alt="Grooming Icon" onClick={() => handleMenuButtonClicked('store/services/grooming')} />
                  </div>
                  <p className="menu-button-title">Grooming</p>
                </div>
              </div>
              <div className="row">
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={trainingIcon} alt="Dog Training Icon" onClick={() => handleMenuButtonClicked('store/services/training')} />
                  </div>
                  <p className="menu-button-title">Training</p>
                </div>
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={adventureIcon} alt="Adventure Icon" onClick={() => handleMenuButtonClicked('store/services/adventure')} />
                  </div>
                  <p className="menu-button-title">Adventure</p>
                </div>
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={shuttleIcon} alt="Food Icon" onClick={() => handleMenuButtonClicked('store/services/shuttle')} />
                  </div>
                  <p className="menu-button-title">Shuttle</p>
                </div>
              </div>
              <div className='row'>
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={apparelsIcon} alt="Apparels Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
                  </div>
                  <p className="menu-button-title">Apparels</p>
                </div>
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={homesIcon} alt="Homes Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
                  </div>
                  <p className="menu-button-title">Homes</p>
                </div>
                <div className="col menu-button-container">
                  <div className='button-image-container'>
                    <img className="menu-button-image" src={foodIcon} alt="Health & Wellness Icon" onClick={() => handleMenuButtonClicked('coming-soon')} />
                  </div>
                  <p className="menu-button-title">Food & Health</p>
                </div>
              </div>
            </div>
          </section>

          <section className='why-us-section'>
            <div className='container'>
              <h2 className='landing-subpage-heading'>WHY CHOOSE PAWSIBLE</h2>
              <ul>
                <li>
                  <div className="landing-services-item">
                    <div className="landing-services-item-content">
                      <h4 className="landing-services-item-heading1">Safe, Clean & Fun!</h4>
                      <div className="landing-services-item-details">
                      <p className="landing-services-item-paragraph">
                        State-of-the-art facilities ensure a stress-free stay for your pet, focusing on <span className='bold-text'>comfort</span> and <span className='bold-text'>well-being</span>.
                      </p>
                        <img className="landing-services-item-icon1" src={landingService1}/>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="landing-services-item">
                    <img className="landing-services-item-icon2" src={landingService2}/>
                    <div className="landing-services-item-content">
                      <h4 className="landing-services-item-heading2">Best Parenting Experience!</h4>
                      <p className="landing-services-item-paragraph">
                        Enhance <span className='bold-text'>socialization</span> and overall health in a positive environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="landing-services-item">
                    <div className="landing-services-item-content">
                      <h4 className="landing-services-item-heading3">Compassionate Care by the Best!</h4>
                      <div className="landing-services-item-details">
                      <p className="landing-services-item-paragraph">
                        Our caretakers are experienced, highly trained, and <span className='bold-text'>passionate</span> about animals.
                      </p>
                        <img className="landing-services-item-icon3" src={landingService3}/>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="landing-services-item">
                    <img className="landing-services-item-icon4" src={landingService4}/>
                    <div className="landing-services-item-content">
                      <h4 className="landing-services-item-heading4">Convenience at your fingertips!</h4>
                      <p className="landing-services-item-paragraph">
                        Experience <span className='bold-text'>seamless</span> pet drop-off, live updates, and no-wait applications.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <section className='sign-up-section'>
            <LetsGetStarted />
          </section>

          <Footer />
        </div>
      )} */}
    </div>
  );
};

export default Landing;
