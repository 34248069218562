import React, { useState, useEffect } from 'react';
import './ServiceConfig.css';
import { fetchConfig, updateConfig } from '../../../../Utilities/api';
import { BackButton } from '../../../../Utilities/components/Buttons';

function ShuttleConfig() {
    const [config, setConfig] = useState({
        vehicleCapacity: '',
        serviceArea: '',
        pricePerMile: ''
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await fetchConfig('shuttle');
                setConfig(result.config || {});
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };

        loadData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfig(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await updateConfig('shuttle', config);
            alert('Configuration saved successfully!');
        } catch (error) {
            console.error('Error saving configuration:', error);
        }
    };

    return (
        <div className="service-config-container">
            <BackButton />
            <h2>Shuttle Service Configuration</h2>
            <form onSubmit={handleSubmit}>
                <label>Vehicle Capacity (number of pets):
                    <input type="number" name="vehicleCapacity" placeholder="Enter vehicle capacity" value={config.vehicleCapacity} onChange={handleChange} />
                </label>
                <label>Shuttle Service Area (mile):
                    <input type="number" name="serviceArea" placeholder="Enter service area in mile" value={config.serviceArea} onChange={handleChange} />
                </label>
                <label>Price Per Mile:
                    <input type="number" name="pricePerMile" placeholder="Enter price per mile" value={config.pricePerMile} onChange={handleChange} />
                </label>
                <button type="submit">Save Changes</button>
            </form>
        </div>
    );
}

export default ShuttleConfig;
