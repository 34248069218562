const catBreeds = {
    'Abyssinian': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Active, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Kidney Disease', 'Dental Issues']
    },
    'American Bobtail': {
        groomingPrice: 50,
        size: 'Medium',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Obesity']
    },
    'American Curl': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Ear Infections', 'Dental Issues']
    },
    'American Shorthair': {
        groomingPrice: 35,
        size: 'Medium',
        temperament: 'Gentle, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Disease', 'Obesity']
    },
    'American Wirehair': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Loyal, Affectionate',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Skin Allergies', 'Dental Issues']
    },
    'Balinese': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Vocal, Active',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Respiratory Issues', 'Heart Disease']
    },
    'Bengal': {
        groomingPrice: 50,
        size: 'Large',
        temperament: 'Energetic, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Heart Disease']
    },
    'Birman': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Gentle, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Kidney Disease', 'Obesity']
    },
    'Bombay': {
        groomingPrice: 35,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Respiratory Issues']
    },
    'British Shorthair': {
        groomingPrice: 40,
        size: 'Large',
        temperament: 'Easygoing, Playful',
        exerciseNeeds: 'Low',
        sheddingLevel: 'High',
        commonHealthIssues: ['Heart Disease', 'Obesity']
    },
    'Burmese': {
        groomingPrice: 35,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Diabetes', 'Heart Disease']
    },
    'Burmilla': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Kidney Disease', 'Obesity']
    },
    'Chartreux': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Kidney Disease']
    },
    'Cornish Rex': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Active, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Dental Issues']
    },
    'Devon Rex': {
        groomingPrice: 45,
        size: 'Small',
        temperament: 'Friendly, Energetic',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Patellar Luxation']
    },
    'Egyptian Mau': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Active, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Disease', 'Dental Issues']
    },
    'European Shorthair': {
        groomingPrice: 35,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Kidney Disease', 'Heart Disease']
    },
    'Exotic Shorthair': {
        groomingPrice: 50,
        size: 'Medium',
        temperament: 'Gentle, Playful',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Respiratory Issues', 'Dental Issues']
    },
    'Himalayan': {
        groomingPrice: 55,
        size: 'Medium',
        temperament: 'Affectionate, Gentle',
        exerciseNeeds: 'Low',
        sheddingLevel: 'High',
        commonHealthIssues: ['Kidney Disease', 'Respiratory Issues']
    },
    'Japanese Bobtail': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Active, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Dental Issues']
    },
    'Korat': {
        groomingPrice: 35,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Kidney Disease', 'Heart Disease']
    },
    'LaPerm': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Kidney Disease', 'Dental Issues']
    },
    'Maine Coon': {
        groomingPrice: 60,
        size: 'Large',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Heart Disease']
    },
    'Manx': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Spinal Issues', 'Heart Disease']
    },
    'Norwegian Forest Cat': {
        groomingPrice: 55,
        size: 'Large',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Heart Disease']
    },
    'Ocicat': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Active, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Disease', 'Kidney Disease']
    },
    'Oriental Shorthair': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Vocal, Active',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Respiratory Issues', 'Heart Disease']
    },
    'Persian': {
        groomingPrice: 60,
        size: 'Medium',
        temperament: 'Affectionate, Quiet',
        exerciseNeeds: 'Low',
        sheddingLevel: 'High',
        commonHealthIssues: ['Kidney Disease', 'Respiratory Issues']
    },
    'Ragdoll': {
        groomingPrice: 55,
        size: 'Large',
        temperament: 'Affectionate, Gentle',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Disease', 'Obesity']
    },
    'Russian Blue': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Gentle, Intelligent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Kidney Disease']
    },
    'Savannah': {
        groomingPrice: 50,
        size: 'Large',
        temperament: 'Active, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Kidney Disease']
    },
    'Scottish Fold': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Joint Issues', 'Heart Disease']
    },
    'Siamese': {
        groomingPrice: 40,
        size: 'Medium',
        temperament: 'Vocal, Active',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Respiratory Issues']
    },
    'Siberian': {
        groomingPrice: 55,
        size: 'Large',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Heart Disease', 'Kidney Disease']
    },
    'Singapura': {
        groomingPrice: 40,
        size: 'Small',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Kidney Disease', 'Heart Disease']
    },
    'Sphynx': {
        groomingPrice: 50,
        size: 'Medium',
        temperament: 'Friendly, Energetic',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Disease', 'Skin Issues']
    },
    'Turkish Angora': {
        groomingPrice: 45,
        size: 'Medium',
        temperament: 'Active, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Disease', 'Kidney Disease']
    },
    'Turkish Van': {
        groomingPrice: 45,
        size: 'Large',
        temperament: 'Active, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Disease', 'Kidney Disease']
    }
};

export default catBreeds;
