import React, { useState, useContext, useEffect } from 'react';
import '../../Booking.css';
import './MembershipBooking.css';
import { StateContext } from '../../../../StateProvider';
import { FaInfoCircle } from 'react-icons/fa';
import { tooltips } from './membershipTooltips';
import MembershipOptions from './MembershipOptions';

function MembershipBooking() {
    const { user, setBookingData } = useContext(StateContext);
    const [isMembershipOptionsOpen, setIsMembershipOptionsOpen] = useState(false);
    const [selectedPetForMembership, setSelectedPetForMembership] = useState(null);
    const [tooltipVisibility, setTooltipVisibility] = useState({
        petSelection: false,
        membershipPreferences: false,
    });
    const today = new Date();
    const [membershipBookingData, setMembershipBookingData] = useState({
        selectedPets: [],
        membershipOptions: [],
        selectedPackage: '',
        dateRange: [today], // Initialize with today's date
        additionalServices: {
            grooming: false
        }
    });

    useEffect(() => {   
        // Set booking data on mount
        setBookingData(prevData => ({
            ...prevData,
            bookingInfo: membershipBookingData
        }));
    }, [membershipBookingData]);

    const showTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: true });
    };

    const hideTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: false });
    };

    // Can select single pet
    const togglePetSelection = (pet) => {
        setMembershipBookingData(prevBookingData => {
            // If the selected pet is clicked again, deselect it by setting an empty array
            if (prevBookingData.selectedPets.some(selectedPet => selectedPet.petId === pet.id)) {
                setSelectedPetForMembership(null);
                setIsMembershipOptionsOpen(false);
                return { ...prevBookingData, selectedPets: [], membershipOptions: [] };
            } else {
                // If another pet is clicked, replace the selectedPets array with the new pet
                const newSelectedPet = { petId: pet.id, petName: pet.name };
                setSelectedPetForMembership(pet); // Assuming 'pet' is the complete pet object
                setIsMembershipOptionsOpen(true);
                return { ...prevBookingData, selectedPets: [newSelectedPet], membershipOptions: [] };
            }
        });
    };

    const handleNotesChange = (e) => {
        setMembershipBookingData(prevData => ({ ...prevData, specialNotes: e.target.value }));
    };

    const handleServiceChange = (serviceName) => {
        const currentStatus = membershipBookingData.additionalServices[serviceName];
        const updatedServices = {
            ...membershipBookingData.additionalServices,
            [serviceName]: !currentStatus
        };
        setMembershipBookingData(prevData => ({ ...prevData, additionalServices: updatedServices }));
    };

    return (
        <div className='booking-backdrop'>
            <div className="booking-container">
                <div className="booking-header">
                    <h3>Pawsible Membership</h3>
                </div>

                <div className="booking-content">
                    <div className="booking-section">
                        <h2>
                            Pet Guests <FaInfoCircle onMouseOver={() => showTooltip('petSelection')} onMouseOut={() => hideTooltip('petSelection')} />
                        </h2>
                        {tooltipVisibility.petSelection && <div className="tooltip">Select your pet for the membership</div>}
                        <div className="pet-selection">
                            {user && user.familyMembers && user.familyMembers.map(pet => (
                                (pet.type === 'Dog' || pet.type === 'Cat') && (!pet.Memberships || !pet.Memberships[0] || !pet.Memberships[0].membershipType) && (
                                    <div key={pet.id}>
                                        <button
                                            className={`pet-button-${membershipBookingData.selectedPets.length > 0 && membershipBookingData.selectedPets[0].petId === pet.id ? 'selected' : ''}`}
                                            onClick={() => togglePetSelection(pet)}
                                        >
                                            <img src={pet.image} alt={pet.name} className="pet-photo" />
                                            <p className='pet-name'>{pet.name}</p>
                                            <p className='pet-type'>{pet.type}</p>
                                        </button>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>

                    <div className={`booking-section-selectedPetForMembership ${isMembershipOptionsOpen ? 'open' : 'close'}`}>
                        {selectedPetForMembership && (
                            <div>
                                <MembershipOptions
                                    selectedPetForMembership={selectedPetForMembership}
                                    membershipBookingData={membershipBookingData}
                                    setMembershipBookingData={setMembershipBookingData}
                                />
                            </div>
                        )}
                    </div>

                    <div className="booking-section">
                        <h2>
                            Special Requests <FaInfoCircle onMouseOver={() => showTooltip('groomingPreferences')} onMouseOut={() => hideTooltip('groomingPreferences')} />
                        </h2>
                        {tooltipVisibility.groomingPreferences && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.groomingPreferences }}></div>}
                        <textarea 
                            placeholder="" 
                            value={membershipBookingData.specialNotes} 
                            onChange={handleNotesChange}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default MembershipBooking;
