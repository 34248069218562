import React from 'react';

const PremiumGroomingPreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  return (
    <div className="premium-grooming-preferences">
      <h3>Premium Grooming Benefits</h3>
      <ul>
        <li>Daily Teeth Brushing</li>
        <li>Daily Deshedding/Brushing</li>
        <li>Basic Paw Care</li>
        <li>2 Full Groomings</li>
        <li>2 Full Baths (Flea & Tick Treatment)</li>
      </ul>
    </div>
  );
};

export default PremiumGroomingPreferences;
