import React, { useState, useEffect, useContext } from 'react';
import swapIcon from './../../../../../../../assets/Store/Services/Booking/swapIcon.png';
import SmartSearchBar from '../../../../../../Utilities/components/SmartSearchBar';
import { StateContext } from '../../../../../../StateProvider';
import Calendar from 'react-calendar';
import { FaInfoCircle } from 'react-icons/fa';
import { tooltips } from './../../shuttleTooltips';
import { fetchShuttleAvailabilityForAirport, getShuttleAvailabilityByMonth } from '../../../../../../Utilities/api';
import TimeSlots from '../../../../../../Utilities/components/TimeSlots';

const AirportSelection = ({ shuttleBookingData, setShuttleBookingData }) => {
    const { user, setBookingData } = useContext(StateContext);
    const [isFormToggleActive, setIsFormToggleActive] = useState(true);
    const [showCalendar, setShowCalendar] = useState(false);
    const [datesSelected, setDatesSelected] = useState(false);
    const selectedOptions = shuttleBookingData.shuttleOptions || {};
    const [availabilityCache, setAvailabilityCache] = useState({});
    const [tooltipVisibility, setTooltipVisibility] = useState({
        ride: false,
        petSelection: false,
        appointmentDate: false,
        groomingPreferences: false,
    });    

    useEffect(() => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Month in human-readable format (1-12)
        const year = today.getFullYear();
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1; // Handle December to January transition
        const nextMonthYear = currentMonth === 12 ? year + 1 : year;

        const currentMonthKey = `${year}-${currentMonth}`;
        const nextMonthKey = `${nextMonthYear}-${nextMonth}`;
    
        const fetchAvailability = async () => {
            const [currentMonthData, nextMonthData] = await Promise.all([
                !availabilityCache[currentMonthKey] && getShuttleAvailabilityByMonth(currentMonth, year),
                !availabilityCache[nextMonthKey] && getShuttleAvailabilityByMonth(nextMonth, nextMonthYear)
            ]);

            setAvailabilityCache(prev => ({
                ...prev,
                ...(currentMonthData ? { [currentMonthKey]: currentMonthData } : {}),
                ...(nextMonthData ? { [nextMonthKey]: nextMonthData } : {})
            }));
        };

        fetchAvailability();
    
        // Set booking data on mount
        setBookingData(prevData => ({
            ...prevData,
            bookingInfo: shuttleBookingData
        }));
    }, [shuttleBookingData]);

    useEffect(() => {
        if (shuttleBookingData.dateRange && shuttleBookingData.dateRange[0] && shuttleBookingData.pickUpLocation && shuttleBookingData.dropOffLocation) {
            const getShuttleAvailability = async (pickup, dropoff, departureTime, departureDate) => {
                try {
                    const result = await fetchShuttleAvailabilityForAirport(pickup, dropoff, departureTime, departureDate);

                    // Extract the selected date
                    const selectedDate = new Date(departureDate).getDate();
                    const month = new Date(departureDate).getMonth() + 1; // JavaScript months are 0-based
                    const year = new Date(departureDate).getFullYear();
                    const key = `${year}-${month}`;
    
                    // Extract available slots for the selected date
                    const dayAvailability = result.availability[selectedDate];
    
                    if (dayAvailability && dayAvailability.length > 0) {
                        setShuttleBookingData(prev => ({
                            ...prev,
                            availableTimeSlots: dayAvailability,
                            distanceDuration: result.distanceDuration
                        }));
                    }
                } catch (error) {
                    console.error('Error fetching distance and duration:', error);
                }
            };
    
            const pickup = shuttleBookingData.pickUpLocation;
            const dropoff = shuttleBookingData.dropOffLocation;
            const departureTime = shuttleBookingData.departureTime;
            const departureDate = shuttleBookingData.dateRange[0];
    
            getShuttleAvailability(pickup, dropoff, departureTime, departureDate);
        }
    }, [shuttleBookingData.departureTime, shuttleBookingData.dateRange, shuttleBookingData.pickUpLocation, shuttleBookingData.dropOffLocation]);      

    const handlePickUpSelect = (suggestion) => {
        // Update the shuttleBookingData with the selected suggestion
        setShuttleBookingData((prevData) => ({
            ...prevData,
            pickUpLocation: suggestion.description,
        }));
    };

    const handleDropOffSelect = (suggestion) => {
        // Update the shuttleBookingData with the selected suggestion
        setShuttleBookingData((prevData) => ({
            ...prevData,
            dropOffLocation: suggestion.description,
        }));
    };

    const handleLocationButtonSelect = (location, pickUpOrDropOff) => {
        if (pickUpOrDropOff === 'pickUp') {
            setShuttleBookingData(prevData => ({
                ...prevData,
                locationButton: location,
                pickUpLocation: location === 'LAX' ? 'Los Angeles International Airport (LAX), World Way, Los Angeles, CA, USA' : ''
            }));
        } else {
            setShuttleBookingData(prevData => ({
                ...prevData,
                locationButton: location,
                dropOffLocation: location === 'LAX' ? 'Los Angeles International Airport (LAX), World Way, Los Angeles, CA, USA' : ''
            }));
        }
    };

    const handleOptionSelect = (optionCategory, option) => {
        setShuttleBookingData((prevData) => ({
        ...prevData,
        shuttleOptions: {
            ...prevData.shuttleOptions,
            [optionCategory]: option,
        },
        }));
    };

    const handleDateChange = (date) => {
        let selectedDate = date;
        if (Array.isArray(date)) {
            selectedDate = date[0];
        }
        
        if (selectedDate instanceof Date && !isNaN(selectedDate)) {
            const formattedDate = [selectedDate, selectedDate];
            setShuttleBookingData(prevData => ({
                ...prevData,
                dateRange: formattedDate
            }));
            setShowCalendar(false);
            setDatesSelected(true);
        } else {
            console.error('Invalid date selected:', selectedDate);
        }
    };  

    const handleActiveStartDateChange = ({ activeStartDate }) => {
        const month = activeStartDate.getMonth(); // 0-indexed month
        const year = activeStartDate.getFullYear();
    
        const keysToFetch = [
            `${year}-${month + 1}`, // key for the current month
        ];
    
        // Calculate key for the next month, considering December to January transition
        const nextMonth = (month + 1) % 12;
        const nextMonthYear = month === 11 ? year + 1 : year;
        keysToFetch.push(`${nextMonthYear}-${nextMonth + 1}`);
    
        keysToFetch.forEach(key => {
            if (!availabilityCache[key]) {
                const [fetchYear, fetchMonth] = key.split('-').map(Number);

                getShuttleAvailabilityByMonth(fetchMonth, fetchYear).then(data => {
                    setAvailabilityCache(prev => ({ ...prev, [key]: data }));
                }).catch(error => {
                    console.error(`Failed to fetch booking data for ${key}:`, error);
                });
            }
        });
    };   

    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Normalize today's date to the start of the day for accurate comparison
            
            // Disable past dates
            if (date < today) {
                return true;
            }
            
            const day = date.getDate();
            const month = date.getMonth() + 1; // JavaScript months are 0-indexed, but your keys are 1-indexed
            const year = date.getFullYear();
            const key = `${year}-${month}`;
            
            // Access the correct month data using the potentially nested structure
            const monthData = availabilityCache[key] ? availabilityCache[key] : null;
    
            // Check if the availability data for the day exists and is valid
            if (monthData && Array.isArray(monthData.dailyAvailability)) {
                // Ensure day is within bounds and check availability
                return day - 1 >= 0 && day - 1 < monthData.dailyAvailability.length && monthData.dailyAvailability[day - 1] <= 0;
            }
            return false; // If data is not loaded or not correct, don't disable (or you might choose to disable)
        }
        return false;
    };    

    const handleShuttleTimeChange = (e) => {
        setShuttleBookingData(prevData => ({
            ...prevData,
            departureTime: e.target.value
        }));
    };

    const handleTimeChange = (timeType, time) => {
        setShuttleBookingData(prevData => ({ ...prevData, [timeType]: time }));
    };

    const toggleInfoGroup = () => {
        setIsFormToggleActive(prevState => !prevState);
    };

    const showTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: true });
    };
    
    const hideTooltip = (key) => {
        setTooltipVisibility({ ...tooltipVisibility, [key]: false });
    };

  return (
    <div>
        <div className="option-group">
            <div className="booking-section">
                <h2>Time & Date <FaInfoCircle onMouseOver={() => showTooltip('appointmentDate')} onMouseOut={() => hideTooltip('appointmentDate')} /></h2>
                {tooltipVisibility.appointmentDate && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.appointmentDate }}></div>}
                <div className="shuttle-booking-container">
                    <div className='shuttle-booking-time'>
                        <input 
                            type="time" 
                            id="time" 
                            name="time" 
                            value={shuttleBookingData.departureTime || ''} 
                            onChange={handleShuttleTimeChange} 
                        />
                    </div>
                    <button 
                        onClick={() => setShowCalendar(!showCalendar)} 
                        className={datesSelected ? 'calendar-button-selected' : 'calendar-button'}
                    >
                        {shuttleBookingData.dateRange ? `${shuttleBookingData.dateRange[0].toLocaleDateString()}` : `Select Date`}
                    </button>
                </div>
                {showCalendar && (
                    <div className='booking-calendar-container'>
                        <div className="legend">
                            <div className="legend-item">
                                <span className="history-dot" />
                                Full
                            </div>
                            <div className="legend-item">
                                <span className="today-dot" />
                                Today
                            </div>
                            <div className="legend-item">
                                <span className="booked-dot" />
                                Active
                            </div>
                            <div className="legend-item">
                                <span className="events-dot" />
                                Events
                            </div>
                        </div>
                        <Calendar
                            selectRange={false}
                            value={shuttleBookingData.dateRange}
                            onChange={handleDateChange}
                            onActiveStartDateChange={handleActiveStartDateChange}
                            tileDisabled={tileDisabled}
                        />
                    </div>
                )}
            </div>

            {!isFormToggleActive && (
                <div className="option-group">
                    <h2>Pick Up Airport</h2>
                    <div className="option-group">
                        <div className="button-group">
                            {shuttleBookingData.locationButton === 'Other' 
                                ? (
                                    <div className="input-field">
                                        <SmartSearchBar placeholder="Enter Airport" onSelect={handlePickUpSelect} type={'airport'}/>
                                    </div>)
                                : (
                                    <>
                                        <button 
                                            className={`location-button ${shuttleBookingData.locationButton === 'LAX' ? 'selected' : ''}`} 
                                            onClick={() => handleLocationButtonSelect('LAX', 'pickUp')}
                                        >
                                            Los Angeles (LAX)
                                        </button>
                                        <button 
                                            className={`location-button ${shuttleBookingData.locationButton === 'Other' ? 'selected' : ''}`} 
                                            onClick={() => handleLocationButtonSelect('Other', 'pickUp')}
                                        >
                                            Other
                                        </button>
                                    </>)
                            } 
                        </div>
                    </div>

                    {/* Flight Number */}
                    <div className="input-field">
                        <input 
                        type="text" 
                        id="flightNumber" 
                        placeholder="Flight Number" 
                        onChange={(e) => handleOptionSelect('flightNumber', e.target.value)}
                        />
                    </div>

                    {/* Terminal Information */}
                    <div className="input-field">
                        <input 
                        type="text" 
                        id="terminal" 
                        placeholder="Terminal Number" 
                        onChange={(e) => handleOptionSelect('terminal', e.target.value)}
                        />
                    </div>
                </div>
            )}

            {isFormToggleActive && (
                <div className="option-group">
                    <h2 className='option-group-header'>Pick Up Location</h2>
                    <SmartSearchBar placeholder="Enter Address" onSelect={handlePickUpSelect} type={'location'}/>
                </div>
            )}

            {/* Button to Switch Information Groups */}
            <div className="option-group-button">
                <img src={swapIcon} alt="Swap" className="swap-icon" onClick={toggleInfoGroup} />
            </div>

            {/* Conditionally render Flight Information Group */}
            {isFormToggleActive && (
                <div className="option-group">
                    <h2 className='option-group-header'>Drop Off Airport</h2>
                    <div className="option-group">
                        <div className="button-group">
                            {shuttleBookingData.locationButton === 'Other' 
                                ? (
                                    <div className="input-field">
                                        <SmartSearchBar placeholder="Enter Airport" onSelect={handleDropOffSelect} type={'airport'}/>
                                    </div>)
                                : (
                                    <>
                                        <button 
                                            className={`location-button ${shuttleBookingData.locationButton === 'LAX' ? 'selected' : ''}`} 
                                            onClick={() => handleLocationButtonSelect('LAX', 'dropOff')}
                                        >
                                            Los Angeles (LAX)
                                        </button>
                                        <button 
                                            className={`location-button ${shuttleBookingData.locationButton === 'Other' ? 'selected' : ''}`} 
                                            onClick={() => handleLocationButtonSelect('Other', 'dropOff')}
                                        >
                                            Other
                                        </button>
                                    </>)
                            }                      
                        </div>
                    </div>

                    {/* Flight Number */}
                    <div className="input-field">
                        <input 
                        type="text" 
                        id="flightNumber" 
                        placeholder="Flight Number" 
                        onChange={(e) => handleOptionSelect('flightNumber', e.target.value)}
                        />
                    </div>

                    {/* Terminal Information */}
                    <div className="input-field">
                        <input 
                        type="text" 
                        id="terminal" 
                        placeholder="Terminal Number" 
                        onChange={(e) => handleOptionSelect('terminal', e.target.value)}
                        />
                    </div>
                </div>
            )}

            {!isFormToggleActive && (
                <div className="option-group">
                    <h2 className='option-group-header'>Drop Off Location</h2>
                    <SmartSearchBar placeholder="Enter Address" onSelect={handleDropOffSelect} type={'location'}/>
                </div>
            )}

            <div className="booking-section">
                {shuttleBookingData.availableTimeSlots && shuttleBookingData.availableTimeSlots.length > 0
                    ? (<div>
                            <h2>Availability <FaInfoCircle onMouseOver={() => showTooltip('times')} onMouseOut={() => hideTooltip('times')} /></h2>
                            {tooltipVisibility.times && <div className="tooltip" dangerouslySetInnerHTML={{ __html: tooltips.times }}></div>}
                            <TimeSlots
                                type="shuttle"
                                timeSlotsData={shuttleBookingData.availableTimeSlots}
                                handleTimeChange={handleTimeChange}
                                selectedTimeSlot={shuttleBookingData.selectedTimeSlot}
                            />
                        </div>)
                    : null
                }
            </div>

        </div>
    </div>
  )
}

export default AirportSelection