export const tooltips = {
    who: `
        Select the pets that will be staying with us.<br /><br />
        <strong>Prices & Discounts:</strong><br />
        - 1st pet = $90/night.<br />
        - Each additional pet gets an added 10% off. <br /> 
        &nbsp;&nbsp;(e.g., 2nd dog: 10% off, 3rd: 20% off, etc.).<br />
        - Comfortable bedding and food bowls included.<br /><br />
        <strong>Notes:</strong><br />
        - A maximum of 1 pet per room. Size and breed restrictions may apply.<br />
        - Longer stays may qualify for additional discounts.<br />
        - Peak season rates may vary.<br />`,
        when: `
        Choose your check-in and check-out dates.<br /><br />
        <strong>Booking Window:</strong><br />
        - Select the dates to see availability and pricing.<br />
        - Adjusting your dates may affect the price due to seasonality.<br /><br />
        <strong>Notes:</strong><br />
        - Check-in: 3 PM, Check-out: 11 AM.<br />
        - Contact us for early check-in/late check-out requests.<br />`,        
    food: `
        Select a food preference for your pet's stay.<br /><br />
        <strong>Food Options:</strong><br />
        - Own: Bring your pet's own food.<br />
        - Basic: Standard meals provided.<br />
        - Premium: Premium meals with extra care.<br /><br />
        <strong>Notes:</strong><br />
        - Food preferences can be changed upon request.<br />
        - Please inform us of any allergies or dietary restrictions.<br />`,
    requests: `
        Let us know of any special needs for your pet.<br /><br />
        <strong>Special Care:</strong><br />
        - Use this space to specify any particular care instructions or needs.<br />
        &nbsp;&nbsp;(e.g., medication, behavioral, etc.).<br />
        <strong>Notes:</strong><br />
        - Providing detailed information helps us tailor the care to your pet's needs.<br />
        - Feel free to contact our staff for any detailed discussions about care.<br />`,
    services: `
        Select any additional services you'd like during your pet's stay.<br /><br />
        <strong>Additional Services:</strong><br />
        - Grooming: Expert grooming services for your pet's comfort and hygiene.<br />
        - Training: Professional training sessions for behavior and obedience.<br />
        - Shuttle: Convenient shuttle service to and from our facility.<br />
        - Adventure: Fun and engaging outdoor adventures for your pet.<br />
        - Walk: Daily leash walks for exercise and enjoyment.<br /><br />
        **Additional charges may be applied for the services.**<br /><br />
        <strong>Notes:</strong><br />
        - Additional services can be selected now or added during your pet's stay.<br />
        - Our staff will confirm all services and schedules upon arrival.<br />`
};    
