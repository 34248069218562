import { initializeApp } from "firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    signOut,
    RecaptchaVerifier,
    linkWithCredential,
    fetchSignInMethodsForEmail,
    EmailAuthProvider
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA5AJOzUJUqeCLnal4rHX0czYdFWfnhJ4U",
    authDomain: "pawsible-c7fa7.firebaseapp.com",
    databaseURL: "https://pawsible-c7fa7-default-rtdb.firebaseio.com",
    projectId: "pawsible-c7fa7",
    storageBucket: "pawsible-c7fa7.appspot.com",
    messagingSenderId: "241896188892",
    appId: "1:241896188892:web:12d32ff05b3067543ebd19",
    measurementId: "G-CWFJDHP36W"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export { RecaptchaVerifier }

export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

const handleProviderLogin = async (provider, providerName, setIsInAppBrowser) => {
    try {
        const result = await signInWithPopup(auth, provider);
        // console.log(`${providerName} login successful`, result.user);
        return { success: true, user: result.user };
    } catch (error) {
        console.error(`${providerName} login error:`, error);

        // Check for 403 or 304 errors
        if (error.code === 'auth/unauthorized-domain' || error.message.includes('403') || error.message.includes('304')) {
            // Trigger the browser popup by setting the state
            setIsInAppBrowser(true);
        }

        if (error.code === 'auth/account-exists-with-different-credential') {
            const email = error.customData.email;
            const methods = await fetchSignInMethodsForEmail(auth, email);
            console.log(`${providerName} detected existing methods:`, methods);
            return { 
                success: false, 
                error: `An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.`,
                methods, 
                email 
            };
        }
        return { success: false, error: error.message };
    }
};

export const signInWithGoogle = () => handleProviderLogin(googleProvider);
export const signInWithFacebook = () => handleProviderLogin(facebookProvider);

export const signInWithEmailAndPass = async (email, password) => {
    try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        return { success: true, user: result.user };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return null;
    // Remove any non-numeric characters.
    const numericOnly = phoneNumber.replace(/\D/g, '');

    // Ensure the phone number includes a country code.
    if (numericOnly.length === 10) { // Assuming it's a US number without country code.
        return '+1' + numericOnly;
    } else if (numericOnly.length > 10 && numericOnly.startsWith('1')) {
        return '+' + numericOnly;
    } else if (!numericOnly.startsWith('+')) {
        return '+' + numericOnly; // Assume the number is complete with a country code.
    }
    return numericOnly;
};

// Enhanced logging for RecaptchaVerifier
export const createRecaptchaVerifier = (containerId) => {
    console.log(`Creating RecaptchaVerifier for container: ${containerId}`);
    
    // Create a new RecaptchaVerifier instance
    const recaptchaVerifier = new RecaptchaVerifier(auth, containerId, {
        'size': 'invisible',
        'callback': (response) => {
            console.log('reCAPTCHA solved:', response);
        },
        'expired-callback': () => {
            console.log('reCAPTCHA expired, please resolve again.');
        }
    });

    return recaptchaVerifier; // Return the instance
};

export const signInWithSMS = async (phoneNumber, recaptchaContainerId) => {
    console.log(`Attempting SMS sign-in for number: ${phoneNumber}`);
    const formattedNumber = formatPhoneNumber(phoneNumber);  // Ensure you have a function to format the number
    console.log(`Formatted phone number for SMS: ${formattedNumber}`);

    if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
    }

    if (!document.getElementById(recaptchaContainerId)) {
        console.error(`Recaptcha container with ID '${recaptchaContainerId}' does not exist.`);
        return { success: false, error: "Recaptcha container not found" };
    }

    const verifier = createRecaptchaVerifier(recaptchaContainerId);
    try {
        const confirmationResult = await signInWithPhoneNumber(auth, formattedNumber, verifier);
        console.log('SMS verification code sent.', confirmationResult);
        return { success: true, confirmationResult };
    } catch (error) {
        console.error('Error during SMS sign-in:', error);
        return { success: false, error: error.message };
    }
};

// Function to verify the code entered by the user
export const verifyCode = async (code, confirmationResult) => {
    try {
        const response = await confirmationResult.confirm(code);
        console.log("Phone number is verified and user is signed in:", response.user);
        return { success: true, user: response.user };
    } catch (error) {
        console.error("Code verification error:", error);
        return { success: false, error: error.message };
    }
};

export const linkAccounts = async (currentAuthUser, email, credential) => {
    try {
        const linkedAccountResult = await currentAuthUser.linkWithCredential(credential);
        return { success: true, user: linkedAccountResult.user };
    } catch (linkError) {
        return { success: false, error: linkError.message };
    }
};

export const signOutUser = () => {
    return signOut(auth);
};