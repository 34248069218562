import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../StateProvider';
import Popup from '../../../../../Utilities/components/Popup';
import { uploadFileToS3, addFamilyMember } from '../../../../../Utilities/api';
import { BackButton } from '../../../../../Utilities/components/Buttons';
import dogBreeds from '../../../../../Utilities/data/dogBreeds';
import './FamilyMemberAdd.css';

const FamilyMemberAdd = () => {
    const navigate = useNavigate();
    const { user, refreshUserData } = useContext(StateContext);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [fileData, setFileData] = useState({
        imageFile: null,
        vaccinationFile: null
    });

    const [memberDetails, setMemberDetails] = useState({
        userId: user.uid,
        name: '',
        type: 'Human',
        breed: 'Not Specified', // Default breed
        age: 0,
        dob: '2024-01-01',
        daycare: false,
        vaccination: '',
        image: '',
    });

    useEffect(() => {
        if (user.id) {
            setMemberDetails(prev => ({ ...prev, userId: user.id }));
        }
    }, [user]);

    const handleNameChange = (e) => setMemberDetails(prev => ({ ...prev, name: e.target.value }));
    const handleTypeChange = (e) => setMemberDetails(prev => ({ ...prev, type: e.target.value }));
    const handleBreedChange = (e) => setMemberDetails(prev => ({ ...prev, breed: e.target.value }));
    const handleDobChange = (e) => setMemberDetails(prev => ({ ...prev, dob: e.target.value }));
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setFileData(prev => ({ ...prev, imageFile: e.target.files[0] }));

            const imageUrl = URL.createObjectURL(e.target.files[0]);
            setUploadedImage(imageUrl);
        }
    };
    
    const handleVaccinationUpload = (e) => {
        if (e.target.files[0]) {
            setFileData(prev => ({ ...prev, vaccinationFile: e.target.files[0] }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (fileData.imageFile) {
                const imageUrl = await uploadFileToS3(fileData.imageFile);
                memberDetails.image = imageUrl;
            } else {
                // Set placeholder image if no image is uploaded
                memberDetails.image = getPlaceholderImageUrl(memberDetails.type);
            }
    
            if (fileData.vaccinationFile) {
                const vaccinationUrl = await uploadFileToS3(fileData.vaccinationFile);
                memberDetails.vaccination = vaccinationUrl;
            }
            
            await addFamilyMember(memberDetails);
            await refreshUserData(user.uid);
            navigate('/profile');
            setShowPopup(true);
        } catch (error) {
            console.error('Error adding family member:', error);
        }
    };      

    const handleClosePopup = () => {
        setShowPopup(false);
        navigate(-1);
    };

    const placeholderImages = {
        Human: 'https://pawsible-s3.s3.us-west-1.amazonaws.com/user_files/defaultPerson.png',
        Dog: 'https://pawsible-s3.s3.us-west-1.amazonaws.com/user_files/defaultDog.png',
        Cat: 'https://pawsible-s3.s3.us-west-1.amazonaws.com/user_files/defaultCat.png',
    };
    
    const getPlaceholderImageUrl = (type) => placeholderImages[type] || placeholderImages.Human;

    return (
        <div className="family-member-edit">
            <Popup isOpen={showPopup} handleClose={handleClosePopup}>
                <p>New family member has been added successfully!</p>
                <button onClick={handleClosePopup}>OK</button>
            </Popup>
            <div className="family-member-edit-header">
                <BackButton />
            </div>

            <div className='container'>
                <form onSubmit={handleSubmit}>
                    <div className="image-upload-container">
                        <img 
                            className='profile-image' 
                            src={uploadedImage || getPlaceholderImageUrl(memberDetails.type)}
                            alt={`${memberDetails.type} Placeholder`} 
                        />
                        <label htmlFor="image-upload" className="image-upload-label">
                            <input type="file" id="image-upload" className="image-upload" onChange={handleImageChange} />
                        </label>
                    </div>

                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" value={memberDetails.name} onChange={handleNameChange} />

                    <label htmlFor="type">Type</label>
                    <select id="type" value={memberDetails.type} onChange={handleTypeChange}>
                        <option value="Human">Human</option>
                        <option value="Dog">Dog</option>
                        <option value="Cat">Cat</option>
                    </select>

                    {memberDetails.type !== 'Human' && (
                        <>
                            <label htmlFor="breed">Breed</label>
                            <select id="breed" value={memberDetails.breed} onChange={handleBreedChange}>
                                <option value="Not Specified">Not Specified</option>
                                {Object.keys(dogBreeds).sort().map(breed => (
                                    <option key={breed} value={breed}>{breed}</option>
                                ))}
                            </select>

                            <label htmlFor="dob">Date of Birth</label>
                            <input type="date" id="dob" value={memberDetails.dob} onChange={handleDobChange} />

                            <div className="vaccination-container">
                                <label htmlFor="vaccination">Vaccination Records</label>
                                <input type="file" id="vaccination" onChange={handleVaccinationUpload} />
                                <label htmlFor="vaccination-message">*Records will be verified in a timely manner once uploaded.</label>
                            </div>
                        </>
                    )}

                    <input type="submit" value="Add Family Member" />
                </form>
            </div>
        </div>
    );
};

export default FamilyMemberAdd;
