import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo_long.png';

import instagramIcon from '../../assets/Footer/instragramIcon.png';
import facebookIcon from '../../assets/Footer/facebookIcon.png';
import youtubeIcon from '../../assets/Footer/youtubeIcon.png';
import twitterIcon from '../../assets/Footer/twitterIcon.png';

const Footer = () => {
  const navigate = useNavigate();

  const logoClicked = () => {
    navigate('/');
    window.scrollTo(0, 0);
  }

  // Define social links
  const socialLinks = [
    { href: 'https://www.instagram.com/pawsible_official/', icon: instagramIcon },
    { href: 'https://www.facebook.com/profile.php?id=61561500761920', icon: facebookIcon },
    { href: 'https://www.youtube.com/@Pawsible_Youtube', icon: youtubeIcon },
    { href: 'https://x.com/pawsible_x', icon: twitterIcon },
  ];

  // Define navigation links
  const navigationLinks = [
    { text: 'About', href: '/about' },
    { text: 'Locations', href: '/locations' },
    { text: 'Franchise', href: '/franchise' },
    { text: 'Career', href: '/career' },
    { text: 'Blog', href: '/coming-soon' },
  ];

  // Define service links
  const serviceLinks = [
    { text: 'Taxi', href: '/store/services/shuttle' },
    { text: 'Hotel', href: '/store/services/hotel' },
    { text: 'Daycare', href: '/store/services/daycare' },
    { text: 'Training', href: '/store/services/training' },
    { text: 'Grooming', href: '/store/services/grooming' },
    { text: 'Adventures', href: '/store/services/adventure' },
    // Uncomment or add more services as needed
    // { text: 'Private Events', href: '/services/private-events' },
  ];

  // Define FAQ links
  const faqLinks = [
    { text: 'Membership', href: '/faqs/membership' },
    { text: 'Using the App', href: '/faqs/using-the-app' },
    { text: 'Pawsible Services', href: '/faqs/services' },
    { text: 'Billing and Payment', href: '/faqs/billing' },
    { text: 'Terms and Conditions', href: '/faqs/terms' },
  ];

  return (
    <footer className="footer-section">
      <div className='container'>
        <div className="footer-logo">
          <img className='footer-logo' src={logo} alt="logo" onClick={logoClicked}/>
        </div>
        <div className="footer-social">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.href}>
              <img className='footer-social-icon' src={link.icon} alt="" />
            </a>
          ))}
        </div>
        <div className="footer-navigation">
          <ul>
            <h3 className='footer-header'>Company</h3>
            {navigationLinks.map((link, index) => (
              <li key={index}><a href={link.href}>{link.text}</a></li>
            ))}
          </ul>
        </div>
        <div className="footer-services">
          <ul>
            <h3 className='footer-header'>Services</h3>
            {serviceLinks.map((link, index) => (
              <li key={index}><a href={link.href}>{link.text}</a></li>
            ))}
          </ul>
        </div>
        <div className="footer-faqs">
          <ul>
            <h3 className='footer-header'>FAQs</h3>
            {faqLinks.map((link, index) => (
              <li key={index}><a href={link.href}>{link.text}</a></li>
            ))}
          </ul>
        </div>
        <div className="footer-links">
          <a href="/legal/terms-and-conditions">Terms & Conditions</a> | <a href="/legal/privacy-policy">Privacy Policy</a>
        </div>
        <div className="footer-copyright">
          Pawsible Inc. 2023. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
