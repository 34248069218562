import React, { useState, useEffect } from 'react';
import './MobileAccessPrompt.css'; // Make sure to import the CSS
import whiteLogo from '../../../../assets/logo_white.png';

const MobileAccessPrompt = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Basic check for mobile devices
      const mobileRegex = /android|iPhone|iPad|iPod|webOS|BlackBerry|Windows Phone/i;
      if (mobileRegex.test(userAgent)) {
        setIsMobile(true);
      }
      setLoading(false); // Stop loading after the check
    };

    checkIsMobile();
  }, []);

  // While loading, do not render anything
  if (loading) return null;

  // If not mobile, show the overlay
  if (!isMobile) {
    return (
      <div className="mobile-access-overlay">
        <div className="mobile-access-content">
          <img src={whiteLogo} alt="Pawsible Logo" className="browser-prompt-logo" />
          <h2>Mobile Access Only</h2>
          <p>Please access the app using a mobile device. The desktop view will be available soon.</p>
          <p>www.pawsible.app</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MobileAccessPrompt;
