import React, { useContext } from 'react';
import './Grooming.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../StateProvider';

import groomingPageIcon from '../../../../assets/Store/Services/Grooming/groomingPageIcon.png';
import pageMainPhoto from '../../../../assets/Store/Services/pageMainPhoto.png';

import bathIcon from '../../../../assets/Store/Services/Grooming/groomingPageBathIcon.png';
import hairIcon from '../../../../assets/Store/Services/Grooming/groomingPageHairIcon.png';
import nailIcon from '../../../../assets/Store/Services/Grooming/groomingPageNailIcon.png';
import earIcon from '../../../../assets/Store/Services/Grooming/groomingPageEarIcon.png';
import analIcon from '../../../../assets/Store/Services/Grooming/groomingPageAnalIcon.png';

import gentleIcon from '../../../../assets/Store/Services/Grooming/groomingPageGentleIcon.png';
import qualityIcon from '../../../../assets/Store/Services/Grooming/groomingPageQualityIcon.png';
import preventive from '../../../../assets/Store/Services/Grooming/groomingPagePreventiveIcon.png';

import Footer from '../../../Footer';
import LetsGetStarted from '../../../Utilities/components/LetsGetStarted';

const Grooming = () => {
  const navigate = useNavigate();
  const { 
    loggedIn, user, setShowAddPetsModal
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    if (loggedIn) {
      const hasPets = user.familyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
      if (hasPets) {
        navigate(`/store/services/grooming/booking`);
      } else {
        setShowAddPetsModal(true);
      }
    } else {
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className='grooming-background-one-container'>
        <div className='container'>
          <div className="grooming-container">
            <img className='grooming-page-icon' src={groomingPageIcon} />
            <h2 className='grooming-page-heading'>Cherish Elegance: Pawsible Salon</h2>
            <p className='grooming-page-paragraph'>Grooming services designed to keep your pets looking and feeling their finest, providing the utmost comfort.</p>
            <div className="centered-container">
              <button className="grooming-page-button" onClick={handleBookingButtonClick}>BOOK A GROOMING</button>
            </div>
            <img className='grooming-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>  

      <div className='grooming-background-two-container'>
        <div className='container'>
          <h2 className='grooming-subpage-heading'>WHY CHOOSE PAWSIBLE</h2>
          <h3 className='grooming-subpage-heading-3'>Grooming Treatments</h3>
          <ul>
            <li>
              <div className="grooming-services-item">
                <img className="grooming-services-item-icon" src={bathIcon}/>
                <div className="grooming-services-item-content">
                  <h4 className="grooming-services-item-heading">Bathing & Brushing</h4>
                  <p className="grooming-services-item-paragraph">
                    Remove dirt, tangles, and mats from the coat to help shed less at home.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="grooming-services-item">
                <img className="grooming-services-item-icon" src={hairIcon}/>
                <div className="grooming-services-item-content">
                  <h4 className="grooming-services-item-heading">Haircuts & Styling</h4>
                  <p className="grooming-services-item-paragraph">
                    Stylish haircut and grooming according to their breed and your preferences.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="grooming-services-item">
                <img className="grooming-services-item-icon" src={nailIcon}/>
                <div className="grooming-services-item-content">
                  <h4 className="grooming-services-item-heading">Nail Trimming</h4>
                  <p className="grooming-services-item-paragraph">
                    Keep your pet's nails well-maintained to prevent discomfort and promote well-being.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="grooming-services-item">
                <img className="grooming-services-item-icon" src={earIcon}/>
                <div className="grooming-services-item-content">
                  <h4 className="grooming-services-item-heading">Ear Cleaning</h4>
                  <p className="grooming-services-item-paragraph">
                    Gentle cleaning of ears to prevent infections and maintain hygiene.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="grooming-services-item">
                <img className="grooming-services-item-icon" src={analIcon}/>
                <div className="grooming-services-item-content">
                  <h4 className="grooming-services-item-heading">Anal Glands Extraction</h4>
                  <p className="grooming-services-item-paragraph">
                    Reduce unpleasant odors and significantly lower the risk of anal glands related health problems.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='grooming-background-three-container'>
          <div className='container'>
            <h2 className='grooming-safety-heading'>WHY CHOOSE PAWSIBLE</h2>
            <h3 className='grooming-safety-heading-3'>Grooming Safety</h3>
              <ul>
                <li>
                  <div className="grooming-services-item">
                    <img className="grooming-services-item-icon" src={gentleIcon}/>
                    <div className="grooming-services-item-content">
                      <h4 className="grooming-services-item-heading">Gentle Handling</h4>
                      <p className="grooming-services-item-paragraph">
                        Gentle and compassionate handling to ensure your pet's comfort during the grooming.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grooming-services-item">
                    <img className="grooming-services-item-icon" src={qualityIcon}/>
                    <div className="grooming-services-item-content">
                      <h4 className="grooming-services-item-heading">Quality Products</h4>
                      <p className="grooming-services-item-paragraph">
                        High-quality, pet-safe grooming products to nourish and protect skin and coat.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grooming-services-item">
                    <img className="grooming-services-item-icon" src={preventive}/>
                    <div className="grooming-services-item-content">
                      <h4 className="grooming-services-item-heading">Preventive Measures</h4>
                      <p className="grooming-services-item-paragraph">
                        Adequate precautions to prevent any accidents or discomfort during grooming sessions.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className='daycare-background-four-container'>
            <LetsGetStarted />
          </div>

          <Footer />
{/* 
      <div className="grooming-container">
        <h2>Grooming Services:</h2>
        <p>Pamper your pets with Pawsible Grooming! Our professional grooming services are designed to keep your pets looking and feeling their best. Our experienced groomers are skilled in providing various grooming treatments to ensure your pets are clean, comfortable, and stylish.</p>

        <h2>Grooming Treatments:</h2>
        <ul>
          <li>Bathing and Brushing: We offer baths and brushing to remove dirt, tangles, and mats from your pet's coat.</li>
          <li>Haircuts and Styling: Our groomers can give your pets a stylish haircut and grooming according to their breed and your preferences.</li>
          <li>Nail Trimming: Keep your pet's nails well-maintained with our nail trimming services.</li>
          <li>Ear Cleaning: We gently clean your pet's ears to prevent infections and maintain hygiene.</li>
        </ul>

        <h2>Grooming Safety:</h2>
        <ul>
          <li>Gentle Handling: Our groomers use gentle and compassionate handling to ensure your pet's comfort during the grooming process.</li>
          <li>Quality Products: We use high-quality, pet-safe grooming products to nourish and protect your pet's skin and coat.</li>
          <li>Preventive Measures: We take necessary precautions to prevent any accidents or discomfort during grooming sessions.</li>
        </ul>

        <p>At Pawsible Grooming, we understand that grooming is an essential part of your pet's well-being. Treat your pets to a day at the spa and let our expert groomers make them look and feel their absolute best!</p>
      </div> */}
    </>
  );
};

export default Grooming;
