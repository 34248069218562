export const tooltips = {
    petSelection: `
        Choose the pet you want to book grooming services for.<br /><br />
        <strong>Things to remember:</strong><br />
        - Only pets in good health are suitable for grooming.<br />
        - Please provide recent vaccination records.<br />
        - Inform us about any medical conditions or behavioral traits that may affect grooming.<br />
    `,
    appointmentDate: `
        Select a date for your grooming appointment.<br /><br />
        <strong>Availability:</strong><br />
        - Grooming services are available from Monday to Saturday.<br />
        - No grooming services on Sundays and public holidays.<br />
        - Booking in advance is highly recommended to secure your preferred date.<br />
    `,
    groomingPreferences: `
        Specify any grooming preferences or special instructions.<br /><br />
        <strong>Include Details Like:</strong><br />
        - Specific haircut style or length.<br />
        - Sensitive areas to handle with care.<br />
        - Shampoo preferences or allergies.<br />
        - Nail trimming or ear cleaning requests.<br /><br />
        <strong>Note:</strong><br />
        - A pre-grooming consultation may be conducted for specific needs.<br />
        - Extra charges may apply for special treatments or products.<br />
    `
};
