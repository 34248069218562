import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NewBooking = () => {
  const [bookingData, setBookingData] = useState({
    userId: '', // You might want to fetch and select users
    serviceId: '',
    familyMemberId: '',
    startTime: '',
    endTime: '',
    bookingType: '',
    specialNotes: '',
    status: 'Scheduled' // Default status
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:6060/api/booking', bookingData);
      navigate('/admin/schedule-calendar');
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Failed to create new booking:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Input fields for each bookingData property */}
      <button type="submit">Submit New Booking</button>
    </form>
  );
};

export default NewBooking;
