import React, { useState, useEffect } from 'react';
import './ServiceConfig.css';
import { fetchConfig, updateConfig } from '../../../../Utilities/api';
import { BackButton } from '../../../../Utilities/components/Buttons';

function TrainingConfig() {
    const [config, setConfig] = useState({
        sessionTypes: [],
        pricePerSession: 0,
        numberOfTrainers: '',
        startTime: '09:00',
        endTime: '17:00',
        sessionDuration: 60,
        bufferTime: 10,
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await fetchConfig('training');
                setConfig(result.config || {});
            } catch (error) {
                console.error('Failed to fetch configuration:', error);
            }
        };

        loadData();
    }, []);

    const handleTypeChange = (event) => {
        const options = event.target.options;
        const values = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }
        setConfig(prev => ({ ...prev, sessionTypes: values }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfig(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await updateConfig('training', config);
            alert('Configuration saved successfully!');
        } catch (error) {
            console.error('Error saving configuration:', error);
        }
    };

    return (
        <div className="service-config-container">
            <BackButton />
            <h2>Training Configuration</h2>
            <form onSubmit={handleSubmit}>
                <label>Training Session Types:
                    <select multiple name="sessionTypes" value={config.sessionTypes} onChange={handleTypeChange}>
                        <option value="Basic Obedience">Basic Obedience</option>
                        <option value="Advanced Obedience">Advanced Obedience</option>
                        <option value="Behavioral Modification">Behavioral Modification</option>
                        <option value="Agility Training">Agility Training</option>
                    </select>
                </label>
                <label>Session Price:
                    <input type="number" name="pricePerSession" placeholder="Price per session" value={config.pricePerSession} onChange={handleChange} />
                </label>
                <label>Trainer Availability:
                    <input type="number" name="numberOfTrainers" placeholder="Number of trainers available" value={config.numberOfTrainers} onChange={handleChange} />
                </label>
                <label>Start Time:
                    <input type="time" name="startTime" value={config.startTime} onChange={handleChange} />
                </label>
                <label>End Time:
                    <input type="time" name="endTime" value={config.endTime} onChange={handleChange} />
                </label>
                <label>Session Duration (minutes):
                    <input type="number" name="sessionDuration" value={config.sessionDuration} onChange={handleChange} />
                </label>
                <label>Buffer Time (minutes):
                    <input type="number" name="bufferTime" value={config.bufferTime} onChange={handleChange} />
                </label>
                <button type="submit">Save Changes</button>
            </form>
        </div>
    );
}

export default TrainingConfig;
