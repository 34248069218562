import React from 'react';
import { Button } from 'react-bootstrap';
import visaIcon from '../../../../assets/Dashboard/Profile/components/Wallet/visaIcon.png';

const CardDisplay = ({ method }) => {
  const { cardType, cardNumber, expiryMonth, expiryYear, cardholderName } = method;

  return (
    <div className="wallet-card-display">
      <img src={visaIcon} alt={`$wallet-{cardType} logo`} className="wallet-card-logo" />
      <div className="wallet-card-info">
        {/* <div className="wallet-card-number">{cardNumber}</div> */}
        <div className='wallet-card-number'>**** **** **** {cardNumber.slice(-4)}</div>
        <div className="wallet-card-details">
          <span className="wallet-expiry-date">{`Expires ${expiryMonth}/${expiryYear.slice(-2)}`}</span>
          {/* <span className="wallet-cardholder-name">{cardholderName}</span> */}
        </div>
      </div>
      {/* <Button variant="outline-danger" size="sm" onClick={() => onDelete(method.id)}>
        Delete
      </Button> */}
    </div>
  );
};

export default CardDisplay;
