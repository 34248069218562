const dogBreeds = {
    'Affenpinscher': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Stubborn, Curious',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Afghan Hound': {
        groomingPrice: 80,
        size: 'Large',
        temperament: 'Independent, Dignified',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Cataracts', 'Hypothyroidism']
    },
    'Airedale Terrier': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Hypothyroidism']
    },
    'Akita': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Courageous, Dignified',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Hypothyroidism']
    },
    'Alaskan Malamute': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Arthritis']
    },
    'American Bulldog': {
        groomingPrice: 65,
        size: 'Large',
        temperament: 'Confident, Loyal',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'American Staffordshire Terrier': {
        groomingPrice: 60,
        size: 'Medium',
        temperament: 'Confident, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Heart Disease']
    },
    'Anatolian Shepherd Dog': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Loyal, Independent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Australian Cattle Dog': {
        groomingPrice: 55,
        size: 'Medium',
        temperament: 'Energetic, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Deafness']
    },
    'Australian Shepherd': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Smart, Work-Oriented',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Australian Terrier': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Friendly, Spirited',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Diabetes']
    },
    'Basenji': {
        groomingPrice: 45,
        size: 'Small',
        temperament: 'Independent, Poised',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Fanconi Syndrome']
    },
    'Basset Hound': {
        groomingPrice: 60,
        size: 'Medium',
        temperament: 'Charming, Patient',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Beagle': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Curious, Merry',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Bearded Collie': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Bouncy, Charismatic',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Addison’s Disease']
    },
    'Bedlington Terrier': {
        groomingPrice: 65,
        size: 'Small',
        temperament: 'Affectionate, Spirited',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Copper Toxicosis', 'Retinal Dysplasia']
    },
    'Bernese Mountain Dog': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Good-Natured, Calm',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Bichon Frise': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Playful, Curious',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Bladder Issues', 'Allergies']
    },
    'Bloodhound': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Affectionate, Inquisitive',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Border Collie': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Smart, Energetic',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Border Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Affectionate, Happy',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Heart Issues']
    },
    'Borzoi': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Loyal, Affectionate',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Bloat', 'Hip Dysplasia']
    },
    'Boston Terrier': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Friendly, Bright',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Cataracts', 'Heart Issues']
    },
    'Boxer': {
        groomingPrice: 65,
        size: 'Large',
        temperament: 'Fun-Loving, Loyal',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Cancer', 'Hip Dysplasia']
    },
    'Briard': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Loyal, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Brittany': {
        groomingPrice: 60,
        size: 'Medium',
        temperament: 'Bright, Fun-Loving',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Brussels Griffon': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Alert, Curious',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Bulldog': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Calm, Courageous',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Respiratory Issues']
    },
    'Bullmastiff': {
        groomingPrice: 80,
        size: 'Large',
        temperament: 'Loyal, Brave',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Cairn Terrier': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Alert, Cheerful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Craniomandibular Osteopathy']
    },
    'Canaan Dog': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Alert, Confident',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Cane Corso': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Affectionate, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Cardigan Welsh Corgi': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Affectionate, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Degenerative Myelopathy']
    },
    'Cavalier King Charles Spaniel': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Affectionate, Gentle',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Issues', 'Hip Dysplasia']
    },
    'Chesapeake Bay Retriever': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Bright, Affectionate',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Chihuahua': {
        groomingPrice: 45,
        size: 'Small',
        temperament: 'Charming, Graceful',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Issues', 'Eye Issues']
    },
    'Chinese Crested': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Affectionate, Alert',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Eye Issues', 'Dental Issues']
    },
    'Chow Chow': {
        groomingPrice: 80,
        size: 'Large',
        temperament: 'Dignified, Bright',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Clumber Spaniel': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Mellow, Amusing',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Entropion']
    },
    'Cocker Spaniel': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Gentle, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Collie': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Devoted, Graceful',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Coton de Tulear': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Hip Dysplasia']
    },
    'Dachshund': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Friendly, Curious',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Back Issues', 'Hip Dysplasia']
    },
    'Dalmatian': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Energetic, Outgoing',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Deafness']
    },
    'Dandie Dinmont Terrier': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Independent, Smart',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Back Issues', 'Eye Issues']
    },
    'Doberman Pinscher': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Alert, Fearless',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Cardiomyopathy']
    },
    'Dogue de Bordeaux': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Affectionate, Courageous',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'English Bulldog': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Calm, Courageous',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Respiratory Issues']
    },
    'English Setter': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Gentle, Friendly',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Deafness']
    },
    'English Springer Spaniel': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Friendly, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'English Toy Spaniel': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Affectionate, Alert',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Heart Issues', 'Eye Issues']
    },
    'French Bulldog': {
        groomingPrice: 65,
        size: 'Small',
        temperament: 'Adaptable, Playful',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Respiratory Issues']
    },
    'German Shepherd': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Confident, Courageous',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'German Shorthaired Pointer': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Friendly, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'German Wirehaired Pointer': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Affectionate, Eager',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Giant Schnauzer': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Loyal, Trainable',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Golden Retriever': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Cancer']
    },
    'Goldendoodle': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Allergies']
    },
    'Gordon Setter': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Confident, Eager',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Great Dane': {
        groomingPrice: 90,
        size: 'Giant',
        temperament: 'Friendly, Patient',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Great Pyrenees': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Patient, Calm',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Greater Swiss Mountain Dog': {
        groomingPrice: 80,
        size: 'Large',
        temperament: 'Affectionate, Faithful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Greyhound': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Gentle, Independent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Havanese': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Outgoing, Funny',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Ibizan Hound': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Affectionate, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Thyroid Issues']
    },
    'Irish Setter': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Outgoing, Sweet-Natured',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Irish Terrier': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Bold, Dashing',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Hyperkeratosis']
    },
    'Irish Wolfhound': {
        groomingPrice: 90,
        size: 'Giant',
        temperament: 'Gentle, Noble',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Italian Greyhound': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Playful, Alert',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Jack Russell Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Energetic, Fearless',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Japanese Chin': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Charming, Noble',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Heart Issues', 'Eye Issues']
    },
    'Keeshond': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Outgoing, Friendly',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Kerry Blue Terrier': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Smart, Alert',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Allergies']
    },
    'Komondor': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Loyal, Dignified',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Kuvasz': {
        groomingPrice: 80,
        size: 'Large',
        temperament: 'Loyal, Protective',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Hypothyroidism']
    },
    'Labradoodle': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Allergies']
    },
    'Labrador Retriever': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Friendly, Outgoing',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Obesity']
    },
    'Lakeland Terrier': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Friendly, Bold',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Legg-Calve-Perthes Disease', 'Eye Issues']
    },
    'Leonberger': {
        groomingPrice: 90,
        size: 'Giant',
        temperament: 'Friendly, Gentle',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Panosteitis']
    },
    'Lhasa Apso': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Confident, Smart',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Maltese': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Gentle, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Liver Shunt']
    },
    'Maltipoo': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Friendly, Playful',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Epilepsy']
    },
    'Manchester Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Alert, Spirited',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Legg-Calve-Perthes Disease', 'Patellar Luxation']
    },
    'Mastiff': {
        groomingPrice: 85,
        size: 'Giant',
        temperament: 'Courageous, Affectionate',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Miniature Pinscher': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Fearless, Fun-Loving',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Legg-Calve-Perthes Disease']
    },
    'Miniature Schnauzer': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Friendly, Smart',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Bladder Stones']
    },
    'Morkie': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Playful, Affectionate',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Eye Issues']
    },
    'Newfoundland': {
        groomingPrice: 90,
        size: 'Giant',
        temperament: 'Sweet-Tempered, Gentle',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Norfolk Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Alert, Fearless',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Norwegian Elkhound': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Confident, Friendly',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Hypothyroidism']
    },
    'Norwich Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Affectionate, Curious',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Nova Scotia Duck Tolling Retriever': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Outgoing, Affectionate',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Old English Sheepdog': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Adaptable, Gentle',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Cataracts']
    },
    'Papillon': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Friendly, Alert',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Heart Issues']
    },
    'Pekingese': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Affectionate, Loyal',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Eye Issues', 'Heart Issues']
    },
    'Pembroke Welsh Corgi': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Smart, Friendly',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Degenerative Myelopathy']
    },
    'Petit Basset Griffon Vendeen': {
        groomingPrice: 65,
        size: 'Small',
        temperament: 'Happy, Curious',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Pharaoh Hound': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Smart, Friendly',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Plott Hound': {
        groomingPrice: 65,
        size: 'Large',
        temperament: 'Loyal, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Pointer': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Friendly, Hardworking',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Polish Lowland Sheepdog': {
        groomingPrice: 75,
        size: 'Medium',
        temperament: 'Loyal, Obedient',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Progressive Retinal Atrophy']
    },
    'Pomeranian': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Playful, Friendly',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Pomsky': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Playful, Energetic',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Poodle': {
        groomingPrice: 70,
        size: 'Varies',
        temperament: 'Smart, Loyal',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Addison’s Disease']
    },
    'Portuguese Water Dog': {
        groomingPrice: 75,
        size: 'Medium',
        temperament: 'Energetic, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Progressive Retinal Atrophy']
    },
    'Pug': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Charming, Loving',
        exerciseNeeds: 'Low',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Respiratory Issues']
    },
    'Puli': {
        groomingPrice: 75,
        size: 'Medium',
        temperament: 'Loyal, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Progressive Retinal Atrophy']
    },
    'Puggle': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Playful, Loving',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Respiratory Issues']
    },
    'Rhodesian Ridgeback': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Affectionate, Dignified',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Rottweiler': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Confident, Courageous',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Saint Bernard': {
        groomingPrice: 90,
        size: 'Giant',
        temperament: 'Gentle, Friendly',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Saluki': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Gentle, Dignified',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Heart Issues']
    },
    'Samoyed': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Friendly, Gentle',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Diabetes']
    },
    'Schipperke': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Alert, Curious',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Schnoodle': {
        groomingPrice: 65,
        size: 'Varies',
        temperament: 'Smart, Charming',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Progressive Retinal Atrophy']
    },
    'Scottish Deerhound': {
        groomingPrice: 80,
        size: 'Large',
        temperament: 'Gentle, Polite',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Scottish Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Confident, Brave',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Craniomandibular Osteopathy', 'Von Willebrand’s Disease']
    },
    'Sealyham Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Alert, Fearless',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Shetland Sheepdog': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Playful, Energetic',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Collie Eye Anomaly']
    },
    'Shiba Inu': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Alert, Active',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Shih Tzu': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Affectionate, Playful',
        exerciseNeeds: 'Low',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Shorkie': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Playful, Affectionate',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Hypoglycemia']
    },
    'Siberian Husky': {
        groomingPrice: 75,
        size: 'Large',
        temperament: 'Friendly, Outgoing',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Silky Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Friendly, Quick',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Legg-Calve-Perthes Disease']
    },
    'Skye Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Fearless, Loyal',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Disc Disease']
    },
    'Smooth Fox Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Friendly, Fearless',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Soft Coated Wheaten Terrier': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Friendly, Happy',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Protein-Losing Nephropathy']
    },
    'Spinone Italiano': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Gentle, Friendly',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Staffordshire Bull Terrier': {
        groomingPrice: 60,
        size: 'Medium',
        temperament: 'Brave, Tenacious',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Elbow Dysplasia']
    },
    'Standard Schnauzer': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Fearless, Smart',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Sussex Spaniel': {
        groomingPrice: 70,
        size: 'Medium',
        temperament: 'Friendly, Happy',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Intervertebral Disc Disease']
    },
    'Swedish Vallhund': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Fearless, Energetic',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Progressive Retinal Atrophy']
    },
    'Tibetan Mastiff': {
        groomingPrice: 85,
        size: 'Large',
        temperament: 'Independent, Protective',
        exerciseNeeds: 'High',
        sheddingLevel: 'High',
        commonHealthIssues: ['Hip Dysplasia', 'Hypothyroidism']
    },
    'Tibetan Spaniel': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Playful, Independent',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Progressive Retinal Atrophy']
    },
    'Tibetan Terrier': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Loyal, Gentle',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Toy Fox Terrier': {
        groomingPrice: 50,
        size: 'Small',
        temperament: 'Friendly, Intelligent',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Legg-Calve-Perthes Disease']
    },
    'Vizsla': {
        groomingPrice: 65,
        size: 'Large',
        temperament: 'Gentle, Energetic',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Weimaraner': {
        groomingPrice: 70,
        size: 'Large',
        temperament: 'Friendly, Fearless',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Bloat']
    },
    'Welsh Springer Spaniel': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Friendly, Loyal',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Welsh Terrier': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Friendly, Spirited',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Glaucoma']
    },
    'West Highland White Terrier': {
        groomingPrice: 60,
        size: 'Small',
        temperament: 'Friendly, Happy',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Patellar Luxation']
    },
    'Whippet': {
        groomingPrice: 65,
        size: 'Medium',
        temperament: 'Gentle, Playful',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Wire Fox Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Friendly, Alert',
        exerciseNeeds: 'High',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Hip Dysplasia', 'Epilepsy']
    },
    'Wirehaired Pointing Griffon': {
        groomingPrice: 65,
        size: 'Large',
        temperament: 'Friendly, Loyal',
        exerciseNeeds: 'High',
        sheddingLevel: 'Moderate',
        commonHealthIssues: ['Hip Dysplasia', 'Eye Issues']
    },
    'Yorkipoo': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Friendly, Playful',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Epilepsy']
    },
    'Yorkshire Terrier': {
        groomingPrice: 55,
        size: 'Small',
        temperament: 'Confident, Bold',
        exerciseNeeds: 'Moderate',
        sheddingLevel: 'Low',
        commonHealthIssues: ['Patellar Luxation', 'Tracheal Collapse']
    }
};

export default dogBreeds;
