import React from 'react';

const PuppyPreferences = ({ trainingBookingData, setTrainingBookingData }) => {
  // Define the preferences and options specific to Puppy Training
  const puppyTrainingPreferences = {
    programDuration: 'Typically 6-8 weeks.',
    trainingGoals: [
      'Socialization',
      'Basic commands (e.g., sit, stay, come)',
      'Potty training',
      'Leash manners',
    ],
    curriculum:
      'Focuses on building a strong foundation for puppies, teaching them essential skills for a well-behaved and confident adult dog.',
    trainingMethods: 'Positive reinforcement techniques with treats, toys, and praise.',
    eligibility: 'Open to puppies aged 8-20 weeks.',
    benefits: [
      'Helps puppies adapt to new environments, people, and other dogs.',
      'Promotes early learning and behavior development.',
    ],
  };

  return (
    <div className="puppy-training-preferences">
      <h3>Puppy Training Preferences</h3>
      <ul>
        <li>
          <strong>Program Duration:</strong> {puppyTrainingPreferences.programDuration}
        </li>
        <li>
          <strong>Training Goals:</strong>
          <ul>
            {puppyTrainingPreferences.trainingGoals.map((goal, index) => (
              <li key={index}>{goal}</li>
            ))}
          </ul>
        </li>
        <li>
          <strong>Curriculum:</strong> {puppyTrainingPreferences.curriculum}
        </li>
        <li>
          <strong>Training Methods:</strong> {puppyTrainingPreferences.trainingMethods}
        </li>
        <li>
          <strong>Eligibility:</strong> {puppyTrainingPreferences.eligibility}
        </li>
        <li>
          <strong>Benefits:</strong>
          <ul>
            {puppyTrainingPreferences.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default PuppyPreferences;
