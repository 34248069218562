import React, { useContext } from 'react'
import { StateContext } from '../../../StateProvider';
import './LetsGetStarted.css';
import { useNavigate } from 'react-router-dom';

const LetsGetStarted = () => {
  const navigate = useNavigate();
  const { 
    loggedIn
  } = useContext(StateContext);

  const handleStartNowClick = () => {
    loggedIn ? navigate('/') : navigate('/login');
    window.scroll(0,0);
  }

  return (
    <div>
        <div className='getStarted-container'>
            <h2 className='getStarted-heading'>Let's Get Started</h2>
            <p className='getStarted-paragraph'>
                Claim your <span className='bold-text'>$30 Welcome Voucher</span> to explore premium pet services & unforgettable experiences!  
            </p>
            <button className="getStarted-button" onClick={handleStartNowClick}>START NOW</button>
            <p className='getStart-subparagraph'>
                Embark on pawsitive journey with us.
            </p>
        </div>
    </div>
  )
}

export default LetsGetStarted;