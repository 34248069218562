import React from 'react';
import BasicPreferences from './BasicPreferences';
import PremiumPreferences from './PremiumPreferences';
import VIPPreferences from './VIPPreferences';

const MembershipPreferences = ({ membershipBookingData, setMembershipBookingData }) => {
  switch (membershipBookingData.membershipOptions.selectedPackage) {
    case 'Basic':
      return <BasicPreferences 
          membershipBookingData={membershipBookingData} 
          setMembershipBookingData={setMembershipBookingData}
      />;
    case 'Premium':
      return <PremiumPreferences 
                membershipBookingData={membershipBookingData} 
                setMembershipBookingData={setMembershipBookingData}
            />;
    case 'VIP':
      return <VIPPreferences 
                membershipBookingData={membershipBookingData} 
                setMembershipBookingData={setMembershipBookingData}
            />;
    default:
      return null;
  }
};

export default MembershipPreferences;
