import React from 'react';
import './ComingSoon.css'; // Assuming you've placed your styles in a separate CSS file

import whiteLogo from '../../../../assets/logo_white.png';
import { WhiteBackButton } from '../Buttons';

function ComingSoon() {
    return (
        <div className='coming-soon-container'>
            <div className='container'>
                <WhiteBackButton />
                <div className="coming-soon">
                    <h2 className="coming-soon-title">Coming Soon</h2>
                    <img src={whiteLogo} alt="Pawsible Logo" className="coming-soon-logo"/>
                    <p className="coming-soon-description">We're working hard to bring you this feature. Stay tuned!</p>
                    {/* <a href="/sign-in" className="coming-soon-link">Sign up for updates</a> */}
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;
