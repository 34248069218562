import React, { useEffect, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import applePayIcon from '../../../../assets/Dashboard/Profile/components/Wallet/applePayIcon.png';
import './ApplePayDisplay.css';

const ApplePayDisplay = ({ cartTotal }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe && !isNaN(cartTotal)) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: cartTotal * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setCanMakePayment(true);
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (event) => {
        const { error } = await stripe.confirmCardPayment(
          'your-client-secret-from-backend', // Replace with your client secret
          {
            payment_method: event.paymentMethod.id,
          },
          { handleActions: false }
        );

        if (error) {
          event.complete('fail');
          console.error(error);
        } else {
          event.complete('success');
          console.log('Payment succeeded!');
        }
      });
    }
  }, [stripe, cartTotal]);

  if (!canMakePayment) {
    return (
      <div className='apple-card-display'>
        <img src={applePayIcon} alt="Apple Pay" className="apple-card-logo" />
        <div className="apple-card-info">
          <div className="apple-card-number" onClick={() => alert('Apple Pay is not available on your device')}>Apple Pay</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {elements && (
        <div
          ref={(el) => {
            if (el && paymentRequest) {
              const prButton = elements.create('paymentRequestButton', {
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    type: 'default',
                    theme: 'dark',
                    height: '64px',
                  },
                },
              });
              prButton.mount(el);
            }
          }}
        />
      )}
    </div>
  );
};

export default ApplePayDisplay;
