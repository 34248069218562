const formatDateRange = (dates) => {
    // Helper function to extract month and day from a date
    const extractMonthDay = (date) => {
        const options = { month: 'short', day: 'numeric' };
        return date.toLocaleString('en-US', options);
    };

    // Check if input is a single date, and convert it to an array of that date twice if it is
    if (!Array.isArray(dates)) {
        if (dates instanceof Date) {
            dates = [dates, dates]; // Duplicate the date for start and end
        } else {
            throw new TypeError("formatDateRange expects an array of two dates or a single date object.");
        }
    }

    if (dates.length === 1) {
        dates.push(dates[0]); // Duplicate the single date if only one is provided
    }

    const [startDate, endDate] = dates;
    const start = extractMonthDay(startDate);
    const end = extractMonthDay(endDate);

    // If start day and end day are the same, return just the start date
    if (startDate.toDateString() === endDate.toDateString()) {
        return start;
    }

    const [startMonth, startDay] = start.split(' ');
    const [endMonth, endDay] = end.split(' ');

    // If months are the same, format without repeating the month
    if (startMonth === endMonth) {
        return `${startMonth} ${startDay} - ${endDay}`;
    }

    // Otherwise, include both months
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
};

export default formatDateRange;
