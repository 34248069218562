import React, { useEffect, useState } from 'react';
import './OpenInBrowserPrompt.css'; // Import your styles
import whiteLogo from '../../../../assets/logo_white.png';

const OpenInBrowserPrompt = () => {
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [copyButtonText, setCopyButtonText] = useState('Copy URL');

  useEffect(() => {
    const checkBrowser = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // List of known browsers or environments where we don't show the prompt
      const isStandardBrowser =
        userAgent.includes('Chrome') ||
        userAgent.includes('Safari') ||
        userAgent.includes('Firefox') ||
        userAgent.includes('Edg') || // Edge browser
        userAgent.includes('Opera');

      // If it's not one of the standard browsers, assume it's an in-app browser
      setIsInAppBrowser(!isStandardBrowser);

      // Once browser detection is complete, we stop loading
      setLoading(false);
    };

    checkBrowser();
  }, []);

  // Do not render anything until loading completes
  if (loading) return null;

  // Function to trigger the prompt
  const handleOpenInBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Android-specific intent for Chrome
      window.location.href = 'intent://www.pawsible.app#Intent;scheme=https;package=com.android.chrome;end;';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // iOS-specific approach
      window.location.href = 'https://www.pawsible.app';
    } else {
      // Fallback for other environments
      window.open('https://www.pawsible.app', '_blank');
    }
  };

  // Copy URL to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText('https://www.pawsible.app')
      .then(() => {
        setCopyButtonText('Woof!');
        setTimeout(() => setCopyButtonText('Copy URL'), 2000); // Reset button text after 2 seconds
      })
      .catch(() => {
        alert('Failed to copy the URL.');
      });
  };

  // Render the prompt only if it's detected as in-app browser
  if (isInAppBrowser) {
    return (
      <div className="open-browser-prompt">
        <img src={whiteLogo} alt="Pawsible Logo" className="browser-prompt-logo" />
        <h2>Open in Browser</h2>
        <p>
          It looks like you're using an in-app browser. For the best experience, please open this page in your native browser.
        </p>
        <button onClick={handleCopyLink}>
          {copyButtonText}
        </button>
      </div>
    );
  }

  return null;
};

export default OpenInBrowserPrompt;
