import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../StateProvider';
import { Container } from 'react-bootstrap';
import './BookingDashboard.css';
import { addCartItem } from '../../Utilities/api';

import Popup from '../../Utilities/components/Popup';
import formatDateRange from '../../Utilities/functions/FormatDateRange';

import cartIcon from '../../../assets/Dashboard/storeIcon.png';

import HotelBookingCalculator from '../../Utilities/components/Calculations/HotelBookingCalculator';
import DaycareBookingCalculator from '../../Utilities/components/Calculations/DaycareBookingCalculator';
import GroomingBookingCalculator from '../../Utilities/components/Calculations/GroomingBookingCalculator';
import TrainingBookingCalculator from '../../Utilities/components/Calculations/TrainingBookingCalculator';
import AdventureBookingCalculator from '../../Utilities/components/Calculations/AdventureBookingCalculator';
import ShuttleBookingCalculator from '../../Utilities/components/Calculations/ShuttleBookingCalculator';
import MembershipBookingCalculator from '../../Utilities/components/Calculations/MembershipBookingCalculator';
import GiftCardPurchaseCalculator from '../../Utilities/components/Calculations/GiftCardPurchaseCalculator';

const BookingDashboard = () => {
    const navigate = useNavigate();
    const { 
      bookingData,
      setBookingData,
      cart,
      setCart,
      user,
      serviceDetails,
      refreshUserData,
    } = useContext(StateContext);
    const cartItemCount = Array.isArray(cart) ? cart.length : 0;
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [serviceId, setServiceId] = useState(0);

    useEffect(() => {
      if (serviceDetails && bookingData.bookingType) {
        const foundServiceId = findServiceId(serviceDetails, bookingData.bookingType);
        setServiceId(foundServiceId);
      }
    }, [serviceDetails, bookingData.bookingType]);

    const calculatePrice = () => {
      switch (bookingData.bookingType) {
        case 'store/membership':
          return MembershipBookingCalculator(bookingData.bookingInfo || {}, serviceDetails);
        case 'store/services/gift-card-purchase':
          return GiftCardPurchaseCalculator(bookingData || {}, serviceDetails);
        case 'store/services/daycare' || 'store/services/daycare/booking':
          return DaycareBookingCalculator(bookingData.bookingInfo || {}, serviceDetails);
        case 'store/services/hotel' || 'store/services/hotel/booking':
          return HotelBookingCalculator(bookingData.bookingInfo || {}, serviceDetails);
        case 'store/services/grooming' || 'store/services/grooming/booking':
          return GroomingBookingCalculator(bookingData.bookingInfo || {}, serviceDetails);
        case 'store/services/training' || 'store/services/training/booking':
          return TrainingBookingCalculator(bookingData.bookingInfo || {}, serviceDetails);
        case 'store/services/adventure' || 'store/services/adventure/booking':
          return AdventureBookingCalculator(bookingData.bookingInfo || {}, serviceDetails);
        case 'store/services/shuttle' || 'store/services/shuttle/booking':
          return ShuttleBookingCalculator(bookingData || {}, serviceDetails);
        default:
          return { originalPrice: 0, discountedPrice: 0 };
      }
    };
    const { originalPrice, discountedPrice } = calculatePrice();

    const handleAddToCart = async () => {
     
      // General Error Handlers
      if (!serviceId) {
        setPopupMessage('Service ID not found, refresh the page or try again!');
        setShowPopup(true);
        return;
      }

      // Hotel Booking Error Handlers
      if (bookingData.bookingType === 'store/services/hotel') {
        if (!bookingData.bookingInfo.selectedPets || bookingData.bookingInfo.selectedPets.length < 1) {
          setPopupMessage('Please select a pet!');
          setShowPopup(true);
          return;
        }

        if (!bookingData.bookingInfo.dateRange || bookingData.bookingInfo.dateRange.length !== 2) {
            setPopupMessage('Please select both start and end dates!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.foodPreference) {
            setPopupMessage('Please select a meal plan!');
            setShowPopup(true);
            return;
        }
      }

      // Daycare Booking Error Handlers
      if (bookingData.bookingType === 'store/services/daycare') {
        if (!bookingData.bookingInfo.selectedPets || bookingData.bookingInfo.selectedPets.length < 1) {
          setPopupMessage('Please select a pet!');
          setShowPopup(true);
          return;
        }

        if (!bookingData.bookingInfo.dateRange || bookingData.bookingInfo.dateRange.length !== 2) {
            setPopupMessage('Please select both start and end dates!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.foodPreference) {
            setPopupMessage('Please select a meal plan!');
            setShowPopup(true);
            return;
        }
      }

      // Grooming Booking Error Handlers
      if (bookingData.bookingType === 'store/services/grooming') {
        if (!bookingData.bookingInfo.selectedPets || bookingData.bookingInfo.selectedPets.length < 1) {
          setPopupMessage('Please select a pet!');
          setShowPopup(true);
          return;
        }
        
        if (!bookingData.bookingInfo.groomingOptions || !bookingData.bookingInfo.groomingOptions.selectedSize) {
            setPopupMessage('Please select a pet size!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.groomingOptions || !bookingData.bookingInfo.groomingOptions.selectedPackage) {
            setPopupMessage('Please select a grooming package!');
            setShowPopup(true);
            return;
        }
        
        if (bookingData.bookingInfo.groomingOptions.selectedPackage === 'grooming' && !bookingData.bookingInfo.groomingOptions.selectedHaircutStyle) {
            setPopupMessage('Please select a grooming preference!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.dateRange || bookingData.bookingInfo.dateRange.length !== 2) {
            setPopupMessage('Please select a date!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.selectedTimeSlot || !bookingData.bookingInfo.selectedTimeSlot) {
            setPopupMessage('Please select a time slot!');
            setShowPopup(true);
            return;
        }
      }

      // Training Booking Error Handlers
      if (bookingData.bookingType === 'store/services/training') {
        if (!bookingData.bookingInfo.selectedPets || bookingData.bookingInfo.selectedPets.length < 1) {
          setPopupMessage('Please select a pet!');
          setShowPopup(true);
          return;
        }
        
        if (!bookingData.bookingInfo.trainingOptions || !bookingData.bookingInfo.trainingOptions.selectedTrainingType) {
            setPopupMessage('Please select a training type!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.groomingOptions || !bookingData.bookingInfo.trainingOptions.selectedSessionType) {
            setPopupMessage('Please select a session type!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.dateRange || bookingData.bookingInfo.dateRange.length !== 2) {
            setPopupMessage('Please select a date!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.selectedTimeSlot || !bookingData.bookingInfo.selectedTimeSlot) {
          setPopupMessage('Please select a time slot!');
          setShowPopup(true);
          return;
        }
      }

      // Shuttle Booking Error Handlers
      if (bookingData.bookingType === 'store/services/shuttle') {
        if (!bookingData.serviceDetails || !bookingData.serviceDetails.serviceName) {
            setPopupMessage('Please select a service needed for the ride!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo.selectedPets || bookingData.bookingInfo.selectedPets.length < 1) {
          setPopupMessage('Please select passengers!');
          setShowPopup(true);
          return;
        }

        // Airport Shuttle
        if (bookingData.serviceDetails.serviceName === 'Airport Shuttle') {
          if (!bookingData.bookingInfo.departureTime) {
            setPopupMessage('Please provide a departure time!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.dateRange || bookingData.bookingInfo.dateRange.length !== 2) {
            setPopupMessage('Please select a date!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.pickUpLocation) {
            setPopupMessage('Please provide a pick up address!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.dropOffLocation) {
            setPopupMessage('Please provide a drop off address!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.shuttleOptions || !bookingData.bookingInfo.shuttleOptions.flightNumber) {
            setPopupMessage('Please provide the flight number!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.shuttleOptions || !bookingData.bookingInfo.shuttleOptions.terminal) {
            setPopupMessage('Please provide the terminal number!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.selectedTimeSlot || !bookingData.bookingInfo.selectedTimeSlot) {
            setPopupMessage('Please select an available time slot!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.shuttleOptions || !bookingData.bookingInfo.shuttleOptions.emergencyContactName) {
            setPopupMessage('Please provide emergency contact name!');
            setShowPopup(true);
            return;
          }

          if (!bookingData.bookingInfo.shuttleOptions || !bookingData.bookingInfo.shuttleOptions.emergencyContactNumber) {
            setPopupMessage('Please provide emergency contact number!');
            setShowPopup(true);
            return;
          }
        }

      }

      // Adventure Booking Error Handlers
      if (bookingData.bookingType === 'store/services/adventure') {
        if (!bookingData.bookingInfo || !bookingData.bookingInfo.selectedPackage) {
            setPopupMessage('Please select an adventure!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.bookingInfo || !bookingData.bookingInfo.foodPreference) {
            setPopupMessage('Please select a meal plan!');
            setShowPopup(true);
            return;
        }
      }

      // Gift Card Purchase Error Handlers
      if (bookingData.bookingType === 'store/services/gift-card-purchase') {
        if (!bookingData.giftCardData || !bookingData.giftCardData.amount) {
            setPopupMessage('Please select the amount!');
            setShowPopup(true);
            return;
        }

        if (!bookingData.giftCardData || !bookingData.giftCardData.recipientEmail) {
            setPopupMessage('Please provide an email!');
            setShowPopup(true);
            return;
        }
      }

      const { originalPrice, discountedPrice } = calculatePrice();
      let bookingTypeCapitalized = bookingData.bookingType.split('/').pop().charAt(0).toUpperCase() + bookingData.bookingType.split('/').pop().slice(1);

      // Concatenate Membership Categories
      if (bookingData.bookingInfo && bookingData.bookingInfo.membershipOptions && bookingData.bookingInfo.membershipOptions.selectedPackage) {
        bookingTypeCapitalized = `${bookingTypeCapitalized} - ${bookingData.bookingInfo.membershipOptions.selectedPackage}`;
      }
      
      const bookingDetails = {
        userId: user.uid,
        serviceId: serviceId,
        quantity: bookingData.quantity || 1,
        originalPrice,
        discountedPrice,
        bookingType: bookingTypeCapitalized,
        bookingInfo: bookingData.bookingInfo || bookingData.giftCardData
      };
  
      let selectedAdditionalServices = bookingData.selectedAdditionalServices || []; // This will store the additional services selected

      // Set the bookingInfo based on bookingType
      switch (bookingData.bookingType) {
          case 'store/membership':
          case 'store/services/gift-card-purchase':
          case 'store/services/daycare' || 'store/services/daycare/booking':
          case 'store/services/hotel' || 'store/services/hotel/booking':
          case 'store/services/grooming' || 'store/services/grooming/booking':
          case 'store/services/training' || 'store/services/training/booking':
          case 'store/services/adventure' || 'store/services/adventure/booking':
          case 'store/services/shuttle' || 'store/services/shuttle/booking':
            if (bookingData.bookingInfo && bookingData.selectedAdditionalServices) {
              navigateUserThroughServices(selectedAdditionalServices);
            } else if (bookingData.bookingInfo && bookingData.bookingInfo.additionalServices) {
              selectedAdditionalServices = Object.keys(bookingData.bookingInfo.additionalServices)
                .filter(service => bookingData.bookingInfo.additionalServices[service]);
            }          
            break;
          default:
            break;
      }

      setBookingData(prevData => ({
        ...prevData,
        selectedAdditionalServices: selectedAdditionalServices
      }));
  
      try {
        const addedItem = await addCartItem(bookingDetails);
        setCart(currentCart => [...currentCart, addedItem]);

        // Only show popup if any additional service is selected
        if (selectedAdditionalServices.length > 0) {
          setPopupMessage(`${bookingTypeCapitalized} added to cart`);
          setShowPopup(true);
          // Start navigating through services
          navigateUserThroughServices(selectedAdditionalServices);
       } else {
            refreshUserData(user.uid);
            navigate('/profile/cart');
            window.scrollTo(0, 0);
        }
      } catch (error) {
          console.error('Error adding to cart:', error);
      }
    };

    const navigateUserThroughServices = (servicesQueue) => {
      // Function to navigate to the next service booking page
      const navigateToNextService = (remainingServices) => {
          if (remainingServices.length === 0) {
              // All services booked, navigate to the cart or summary page
              navigate('/profile/cart');
              window.scrollTo(0, 0);
          } else {
              const nextService = remainingServices.shift(); // Remove the first service in the queue
  
              // Redirect to the respective booking page based on the next service
              switch (nextService) {
                  case 'daycare':
                      setBookingData(prevData => ({
                        ...prevData,
                        bookingType: 'store/services/daycare'
                      }));
                      navigate('/store/services/daycare/booking');
                      break;
                  case 'hotel':
                      setBookingData(prevData => ({
                        ...prevData,
                        bookingType: 'store/services/hotel'
                      }));
                      navigate('/store/services/hotel/booking');
                      break;
                  case 'grooming':
                      setBookingData(prevData => ({
                        ...prevData,
                        bookingType: 'store/services/grooming'
                      }));
                      navigate('/store/services/grooming/booking');
                      break;
                  case 'training':
                      setBookingData(prevData => ({
                        ...prevData,
                        bookingType: 'store/services/training'
                      }));
                      navigate('/store/services/training/booking');
                      break;
                  case 'adventure':
                      setBookingData(prevData => ({
                        ...prevData,
                        bookingType: 'store/services/adventure'
                      }));
                      navigate('/store/services/adventure/booking');
                      break;
                  case 'shuttle':
                      setBookingData(prevData => ({
                        ...prevData,
                        bookingType: 'store/services/shuttle'
                      }));
                      navigate('/store/services/shuttle/booking');
                      break;
                  default:
                      navigate('/profile/cart');
                      break;
              }
  
              // Scroll to the top for the new page
              window.scrollTo(0, 0);
          }
      };

      // Delete the first service
      setBookingData(prevData => ({
        ...prevData,
        selectedAdditionalServices: prevData.selectedAdditionalServices.slice(1)
      }));
  
      // Navigate the user through the services queue
      navigateToNextService([...servicesQueue]);
    };  

    const findServiceId = (serviceDetails, bookingType) => {
      const serviceType = bookingType.split('/').pop().toLowerCase();
      const isShuttle = bookingType.includes('shuttle');
      const isMembership = bookingType.includes('membership');
      const isGiftCard = bookingType.includes('gift-card');
      const isGroomingPackage = bookingType.includes('grooming') && !isShuttle;    
  
      for (const category of Object.keys(serviceDetails)) {
          if (isShuttle && category.toLowerCase().includes('shuttle')) {
              const service = serviceDetails[category].find(service => service.title.toLowerCase().includes(serviceType));
              if (service) {
                  return service.id;
              }
          } else if (isMembership && category.toLowerCase().includes('membership')) {
              const service = serviceDetails[category].find(service => service.title.toLowerCase().includes(serviceType) || service.serviceName.toLowerCase().includes(serviceType));
              if (service) {
                  return service.id;
              }
          } else if (isGiftCard && category.toLowerCase().includes('gift')) {
              let newServiceType = serviceType.split('-')[0];
              const service = serviceDetails[category].find(service => service.title.toLowerCase().includes(newServiceType) || service.serviceName.toLowerCase().includes(serviceType));
              if (service) {
                  return service.id;
              }
          } else if (isGroomingPackage && category.toLowerCase().includes('grooming') && category.toLowerCase() !== 'grooming') {
              const service = serviceDetails[category].find(service => service.title.toLowerCase().includes(serviceType) || service.serviceName.toLowerCase().includes(serviceType));
              if (service) {
                  return service.id;
              }
          } else if (category.toLowerCase() === serviceType) {
              const service = serviceDetails[category].find(service => service.title.toLowerCase().includes(serviceType));
              if (service) {
                  return service.id;
              }
          }
      }
      return null;
    };
  
    const handleCartClick = () => {
      navigate('/profile/cart');
      window.scrollTo(0, 0);
    }

    let dateRangeText;
    if (bookingData.bookingType !== 'store/services/hotel') {
        dateRangeText = formatDateRange((bookingData.bookingInfo && bookingData.bookingInfo.dateRange)
            ? bookingData.bookingInfo.dateRange
            : new Date());
    } else {
        dateRangeText = formatDateRange((bookingData.bookingInfo && bookingData.bookingInfo.dateRange)
            ? bookingData.bookingInfo.dateRange
            : [new Date(), new Date()]);
    }  
  
    return (
      <div>
        <Popup isOpen={showPopup} handleClose={() => setShowPopup(false)}>
            <div className="booking-dashboard-popup-message-container">
              <p className='booking-dashboard-popup-message'>{popupMessage}</p>
            </div>
            <div className="booking-dashboard-popup-buttons-container">
                <button className='booking-dashboard-popup-ok-button' onClick={() => setShowPopup(false)}>OK</button>
            </div>
        </Popup>

        <footer className="booking-dashboard">
          <Container className='booking-dashboard-container'>
            <div className="booking-payment-info">
              <div className='booking-dashboard-prices'>
                {(originalPrice === discountedPrice) ? null : (<p className='booking-dashboard-total'>${originalPrice.toFixed(0)}</p>)}
                <p className='booking-dashboard-discount'>${discountedPrice.toFixed(0)} total</p>
              </div>
              <p className='booking-dashboard-date'>{dateRangeText}</p>
            </div>
            <button onClick={handleAddToCart}>Add</button>
          </Container>
        </footer>

        <div className="booking-dashboard-cart-icon-container">
          <img className="booking-dashboard-cart-icon" src={cartIcon} onClick={handleCartClick} />
          {cartItemCount > 0 && (
            <span className="cart-item-count">{cartItemCount}</span>
          )}
        </div>
      </div>
    );
};

export default BookingDashboard;
